import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { useOrganizationInvitationCodesCreate } from './OrganizationInvitationCodesCreate-ViewModel'

export const OrganizationInvitationCodesCreate: FC = () => {
  const vm = useOrganizationInvitationCodesCreate()
  const { closeModal, form, isLoading, t, durationOptions } = vm
  const { errors, handleSubmit, register, formValidation, control } = form

  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="amount"
              title={t('amount')}
              register={register(formValidation.amount)}
              type="number"
              error={errors.amount?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.Dropdown
              control={control}
              name={'duration'}
              label={t('duration')}
              options={durationOptions}
              marginTop={0}
              fullWidth
              rules={formValidation.duration}
              error={errors.duration?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
              disabled={isLoading}
            />
            <Shared.Button title={t('submit')} type="submit" disabled={isLoading} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
