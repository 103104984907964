import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { I18nModal } from 'utils/i18n/i18nModal'

export const useParkingDelete = () => {
  const dispatch = useDispatch()

  const parkingId = useSelector(({ modals }: TState) => modals.data?.id)

  const onSubmit = () => {
    dispatch(Actions_Parkings.saga.deleteParking({ id: parkingId, isList: true }))
    dispatch(Actions_Parkings.store.changeFilter({}))
    dispatch(Actions_Modals.store.hideModal())
  }

  const onCancel = () => dispatch(Actions_Modals.store.hideModal())

  return { onSubmit, onCancel }
}
