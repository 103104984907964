import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Breakdowns } from './TActions'
import { TBreakdown } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_UpdateBreakdown } from 'redux/sagas/events/breakdowns/SagaWorker_UpdateBreakdown'

const { StoreActions, SagaEvents } = ActionTypes_Breakdowns

export const Actions_Breakdowns = {
  saga: {
    getBreakdowns: createAction(SagaEvents.GET_BREAKDOWNS),
    updateBreakdown: createAction<ISagaWorker_UpdateBreakdown>(SagaEvents.UPDATE_BREAKDOWN),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveBreakdowns: createAction<TStoreTemplate<TBreakdown[]>>(StoreActions.SAVE_BREAKDOWNS),
    clearBreakdowns: createAction(StoreActions.CLEAR_BREAKDOWNS),
    editBreakdown: createAction<TStoreTemplate<TBreakdown>>(StoreActions.EDIT_BREAKDOWN),
  },
}
