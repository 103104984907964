enum StoreActions {
  IS_LOADER_SHOW = 'CURRENT_ROLE/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CURRENT_ROLE/IS_LOADER_HIDE',
  SAVE_CURRENT_ROLE = 'CURRENT_ROLE/SAVE_CURRENT_ROLE',
  CLEAR_CURRENT_ROLE = 'CURRENT_ROLE/CLEAR_CURRENT_ROLE',
}

enum SagaEvents {
  GET_CURRENT_ROLE = 'CURRENT_ROLE/GET_CURRENT_ROLE',
}

export const ActionTypes_CurrentRole = {
  StoreActions,
  SagaEvents,
}
