import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_PromoCode } from './TActions'
import { TPromoCode } from './TReducer'
import { TStoreTemplate, TStoreTemplateWithFilter } from 'redux/config/root-types'
import { TTableFilterRedux } from 'utils/helpers/table'
import { ISagaWorker_CreatePromoCode } from 'redux/sagas/events/promoCode/SagaWorker_CreatePromoCode'
import { ISagaWorker_UpdatePromoCode } from 'redux/sagas/events/promoCode/SagaWorker_UpdatePromoCode'
import { ISagaWorker_DeletePromoCode } from 'redux/sagas/events/promoCode/SagaWorker_DeletePromoCode'
import { ISagaWorker_GeneratePromoCode } from 'redux/sagas/events/promoCode/SagaWorker_GeneratePromoCode'
import { TSelectedTrailerTypeIdsPayload } from '../trailerTypes/TReducer'
import { TSelectedOrganizationIdsPayload } from '../organizations/TReducer'

const { SagaEvents, StoreActions } = ActionTypes_PromoCode

export const Actions_PromoCode = {
  saga: {
    getPromoCode: createAction(SagaEvents.GET_PROMO_CODE),
    generatePromoCode: createAction<ISagaWorker_GeneratePromoCode>(SagaEvents.GENERATE_PROMO_CODE),
    createPromoCode: createAction<ISagaWorker_CreatePromoCode>(SagaEvents.CREATE_PROMO_CODE),
    updatePromoCode: createAction<ISagaWorker_UpdatePromoCode>(SagaEvents.UPDATE_PROMO_CODE),
    deletePromoCode: createAction<ISagaWorker_DeletePromoCode>(SagaEvents.DELETE_PROMO_CODE),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    setSelectedIds: createAction<number>(StoreActions.SET_SELECTED_IDS),
    clearSelectedIds: createAction(StoreActions.CLEAR_SELECTED_IDS),
    setDateFilter: createAction<Date | null>(StoreActions.SET_DATE_FILTER),
    setBonusMinutesFilter: createAction<number | null>(StoreActions.SET_BONUS_MINUTES_FILTER),
    setPromoCodeNameFilter: createAction<string | null>(StoreActions.SET_PROMO_CODE_NAME_FILTER),
    savePromoCode: createAction<TStoreTemplateWithFilter<TPromoCode[]>>(StoreActions.SAVE_PROMO_CODE),
    changeFilter: createAction<TTableFilterRedux>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TTableFilterRedux>(StoreActions.INCREASE_FILTER_OFFSET),
    editPromoCode: createAction<TStoreTemplate<TPromoCode>>(StoreActions.EDIT_PROMO_CODE),
    reset: createAction(StoreActions.RESET),
    changeSelectedTrailerTypesIds: createAction<TSelectedTrailerTypeIdsPayload>(StoreActions.SELECT_TRAILER_TYPES_IDS),
    changeSelectedOrganizationIds: createAction<TSelectedOrganizationIdsPayload>(StoreActions.SELECT_ORGANIZATION_IDS),
  },
}
