import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import MainRouter from './router/Router'
import { StoreConfig } from 'redux/config/store'
import CssBaseline from '@mui/material/CssBaseline'
import './index.scss'
import { ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { theme } from 'utils/conf/theme'
import 'utils/i18n/i18n'
import initAxios from 'utils/axios/config'

initAxios()

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

const App = () => (
  // <React.StrictMode>
  <CacheProvider value={muiCache}>
    <ThemeProvider theme={theme}>
      <Provider store={StoreConfig.store}>
        <CssBaseline />
        <MainRouter />
      </Provider>
    </ThemeProvider>
  </CacheProvider>
  // </React.StrictMode>
)

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
