import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { TCurrentTrailer } from 'redux/modules/currentTrailer/TReducer'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { get } = RequestMethods()

export interface ISagaWorker_GetCurrentTrailer {
  id: string
}

export default function* SagaWorker_GetCurrentTrailer(action: PayloadAction<ISagaWorker_GetCurrentTrailer>) {
  const { id } = action.payload

  try {
    yield put(Actions_CurrentTrailer.store.showLoader())
    //------------------------- Getting and saving trailer ---------------------------//
    const trailerResponse: TStoreTemplate<TCurrentTrailer> = yield call(
      async () => await get<TCurrentTrailer>(`/trailers/${id}`),
    )
    yield put(Actions_CurrentTrailer.store.saveCurrentTrailer(trailerResponse))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentTrailer.store.hideLoader())
  }
}
