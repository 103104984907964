import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_TemplateRoles } from './TActions'
import { TTemplateRoles, TSelectedRoleId } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { StoreActions, SagaEvents } = ActionTypes_TemplateRoles

export const Actions_TemplateRoles = {
  saga: {
    getTemplateRoles: createAction(SagaEvents.GET_ROLES),
  },

  store: {
    changeSelectedRoleId: createAction<TSelectedRoleId>(StoreActions.SELECT_ROLE_ID),
    clearSelectedRoleId: createAction(StoreActions.CLEAR_SELECTED_ROLE_ID),
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveRoles: createAction<TStoreTemplate<TTemplateRoles>>(StoreActions.SAVE_ROLES),
  },
}
