import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { ActionTypes_MeRole } from './TAction'
import { TMeRole } from './TReducer'

const { StoreActions } = ActionTypes_MeRole

export const Actions_MeRole = {
  saga: {},

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveMeRole: createAction<TStoreTemplate<TMeRole>>(StoreActions.SAVE_ME_ROLE),
    setInitialized: createAction(StoreActions.SET_INITIALIZED),
  },
}
