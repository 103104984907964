import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_OrganizationTypes } from 'redux/modules/organizationTypes/Actions'
import { TOrganizationTypes } from 'redux/modules/organizationTypes/TReducer'

const { get } = RequestMethods()

export default function* SagaWorker_GetOrganizationTypes() {
  yield put(Actions_OrganizationTypes.store.showLoader())
  try {
    const organizationTypes: TStoreTemplate<TOrganizationTypes> = yield call(
      async () => await get<TOrganizationTypes>('/organizations/types'),
    )
    yield put(Actions_OrganizationTypes.store.saveOrganizationTypes(organizationTypes))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_OrganizationTypes.store.hideLoader())
  }
}
