import { createReducer } from '@reduxjs/toolkit'
import { IStateAuth } from './TReducer'
import { Actions_Auth } from './Actions'

const initialState: IStateAuth = {
  isLoading: false,
  data: null,
  responseInfo: null,
}

const ReducerAuth = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Auth.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Auth.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Auth.store.saveAuth, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Auth.store.logout, (state) => {
      state.data = null
    })
})

export default ReducerAuth
