import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  //------------------------- Theme colors ---------------------------//
  palette: {
    primary: {
      main: '#F59D0E',
      light: '#FFC364',
    },
    secondary: {
      main: '#fff',
      light: '#EFEFEF',
    },
    background: {
      default: '#797979',
    },
    text: {
      primary: '#4F4E4E',
      secondary: '#797979',
    },
    success: { main: '#07C530' },
    warning: { main: '#E82626' },
    error: { main: '#E82626' },
  },
  //------------------------- Typography settings ---------------------------//
  typography: {
    h3: {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1.5rem',
      lineHeight: '2.1875rem',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: '1.125rem',
      lineHeight: '1.6875rem',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: '1.5rem',
      lineHeight: '1.6875rem',
      fontWeight: 'normal',
      color: '#fff',
    },
    overline: {
      fontSize: '1.125rem',
      lineHeight: '1.6875rem',
      color: '#797979',
    },
    body1: {
      fontSize: '1.125rem',
      lineHeight: '1.6875rem',
      color: '#4F4E4E',
    },
    body2: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    button: {
      fontSize: '1.25rem',
      lineHeight: '1.8125rem',
    },
  },
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    //------------------------- Paper checkbox ---------------------------//
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: '#F59D0E',
        },
      },
    },
    //------------------------- Paper overrides ---------------------------//
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#4F4E4E',
        },
      },
    },
    //------------------------- Table overrides ---------------------------//
    MuiTable: {
      styleOverrides: {
        root: {
          borderColor: '#797979',
          color: '#797979',
        },
      },
    },
    //------------------------- Input label overrides ---------------------------//
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#797979',
        },
        shrink: {
          color: '#ABABAB',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          transform: 'translate(0, 6px) scale(1)',
        },
      },
    },
    //------------------------- Form label overrides ---------------------------//
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#ABABAB',
          },
        },
      },
    },
    //------------------------- Input overrides ---------------------------//
    MuiInput: {
      styleOverrides: {
        underline: {
          border: '1px solid #ABABAB',
          borderRadius: 50,
          padding: '0.75em',
          '&::before': {
            borderBottom: 'none !important',
          },
          '&::after': {
            borderBottom: 'none !important',
          },
        },
      },
    },
    //hide auto-fill white background color
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: '#4F4E4E',
          padding: 0,
          '&:-webkit-autofill': {
            // transitionDelay: '9999s',
            transitionProperty: 'background-color, none',
          },
        },
      },
    },
    //------------------------- Select overrides ---------------------------//
    MuiSelect: {
      styleOverrides: {
        // selectMenu: {
        //   border: 'none',
        // },
        select: {
          background: 'none',
          '&:focus': {
            backgroundColor: 'none',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
      },
    },
  },
})
