import React, { FC } from 'react'

import { makeStyles } from 'tss-react/mui'
import { TColor } from 'utils/types'
import { IconButton as MuiIconButton } from '@mui/material'

interface IProps {
  onClick: () => void
  color?: TColor
  backgroundColor?: TColor
  size?: 'small' | 'medium'
  icon: React.FC<{ color: TColor }>
}

export const IconButton: FC<IProps> = ({
  onClick,
  color = 'secondary',
  backgroundColor = 'primary',
  size = 'medium',
  icon: Icon,
}) => {
  const { classes } = useStyles({ color, backgroundColor })

  return (
    <MuiIconButton size={size} onClick={onClick} className={classes.root}>
      <Icon color={color} />
    </MuiIconButton>
  )
}

interface IStyle {
  color: TColor
  backgroundColor: TColor
}

const useStyles = makeStyles<IStyle>()((theme, { color, backgroundColor }) => ({
  root: {
    backgroundColor: theme.palette[backgroundColor].main,
    color: theme.palette[color].main,
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette[backgroundColor].main,
    },
  },
}))
