import React from 'react'
import { useDispatch } from 'react-redux'
import { Actions_notificationHistory } from 'redux/modules/notificationHistory/Actions'
import { IProblemsNotification } from 'redux/modules/notificationHistory/TReducer'
import { TPusherEventRespone } from 'utils/pusher/types'
import { useChannel } from 'utils/pusher/useChannel'
import { useEvent } from 'utils/pusher/useEvent'

export type TStationLockUpdateAction = 'UNLOCK_SUCCESS' | 'LOCK_SUCCESS' | 'LOCK_FAIL' | 'UNLOCK_FAIL'

export type TStationLockUpdate = {
  action: TStationLockUpdateAction
}

export type TStationOfflineAction = 'STATION_OFFLINE' | 'STATION_OFFLINE_LONG'

export type TStationOffline = {
  action: TStationOfflineAction
  parkingId: number
  parkingName: string
}

export type TRentEventAction =
  | 'BOOKING_START'
  | 'BOOKING_START_CANCEL'
  | 'RENT_START'
  | 'RENT_START_COMPLETED'
  | 'RENT_START_ERROR'
  | 'BOOKING_FINISH'
  | 'BOOKING_FINISH_CANCEL'
  | 'RENT_FINISH'
  | 'RENT_FINISH_COMPLETED'
  | 'RENT_FINISH_ERROR'

export type TRentEvent = {
  action: TRentEventAction
  userId: number
  userName: string
  stationId?: number
  trailerId?: number
  parkingName?: string
  trailerPlate?: string
}

export type TTrailerIssueReportEventAction = 'WHEELS' | 'CABLE' | 'ROPE' | 'SUPPORT_WHEEL' | 'LIGHTS' | 'AXIS' | 'OTHER'

export type TTrailerIssueReportEvent = {
  action: TTrailerIssueReportEventAction
  userId: number
  userName: string
  trailerId: number
  trailerPlate: string
}

export const PusherGlobalEvents = () => {
  const dispatch = useDispatch()

  const stationChannel = useChannel('STATION_CRM')

  useEvent(stationChannel, 'STATION_LOCK_UPDATE', ({ data }: TPusherEventRespone<TStationLockUpdate>) => {
    const action = data?.action

    switch (action) {
      case 'UNLOCK_SUCCESS':
        dispatch(
          Actions_notificationHistory.saga.showNotificationAndgetNotificationHistory({
            message: 'Station successfully unlocked',
            type: 'Success',
          }),
        )
        break
      case 'LOCK_SUCCESS':
        dispatch(
          Actions_notificationHistory.saga.showNotificationAndgetNotificationHistory({
            message: 'Station successfully locked',
            type: 'Success',
          }),
        )
        break
      case 'UNLOCK_FAIL':
        dispatch(
          Actions_notificationHistory.saga.showNotificationAndgetNotificationHistory({
            message: 'Station is not unlocked',
            type: 'Error',
          }),
        )
        break
      case 'LOCK_FAIL':
        dispatch(
          Actions_notificationHistory.saga.showNotificationAndgetNotificationHistory({
            message: 'Station is not locked',
            type: 'Error',
          }),
        )
        break
      default:
        break
    }
  })

  useEvent(stationChannel, 'STATION_OFFLINE', ({ data }: TPusherEventRespone<TStationOffline>) => {
    const type = data?.action

    const message = [
      {
        key: 'Parking ID',
        value: data?.parkingId || '',
      },
      {
        key: 'Parking name',
        value: data?.parkingName || '',
      },
    ]

    if (type) {
      dispatch(
        Actions_notificationHistory.saga.showNotificationAndgetNotificationHistory({
          message,
          type,
        }),
      )
    }
  })

  useEvent(stationChannel, 'DEVICE_PROBLEMS', ({ data }: TPusherEventRespone<IProblemsNotification>) => {
    if (data) {
      if (data?.problemParkings > 0) {
        dispatch(Actions_notificationHistory.store.showParkingsProblemIndicator())
      } else {
        dispatch(Actions_notificationHistory.store.hideParkingsProblemIndicator())
      }
      if (data?.problemStations > 0) {
        dispatch(Actions_notificationHistory.store.showStationsProblemIndicator())
      } else {
        dispatch(Actions_notificationHistory.store.hideStationsProblemIndicator())
      }
      dispatch(
        Actions_notificationHistory.store.addProblems({
          problemParkings: data.problemParkings,
          problemStations: data.problemStations,
        }),
      )
    }
  })

  const rentChannel = useChannel('RENT_CRM')

  useEvent(rentChannel, 'RENT_EVENT', ({ data }: TPusherEventRespone<TRentEvent>) => {
    const type = data?.action

    const message = [
      {
        key: 'User ID',
        value: data?.userId || '',
      },
      {
        key: 'User name',
        value: data?.userName || '',
      },
      {
        key: 'Stand',
        value: data?.stationId || '',
      },
      {
        key: 'Parking name',
        value: data?.parkingName || '',
      },
      {
        key: 'Trailer number',
        value: `${data?.trailerPlate || ''}${data?.trailerId ? `(${data?.trailerId})` : ''}`,
      },
    ]

    if (type) {
      dispatch(
        Actions_notificationHistory.saga.showNotificationAndgetNotificationHistory({
          message,
          type,
        }),
      )
    }
  })

  useEvent(rentChannel, 'TRAILER_ISSUE_REPORT', ({ data }: TPusherEventRespone<TRentEvent>) => {
    const type = data?.action

    const message = [
      {
        key: 'User ID',
        value: data?.userId || '',
      },
      {
        key: 'User name',
        value: data?.userName || '',
      },
      {
        key: 'Trailer number',
        value: `${data?.trailerPlate || ''}${data?.trailerId ? `(${data?.trailerId})` : ''}`,
      },
    ]

    if (type) {
      dispatch(
        Actions_notificationHistory.saga.showNotificationAndgetNotificationHistory({
          message,
          type,
        }),
      )
    }
  })

  return <></>
}
