import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { TInsurance, TRent, TRents, TRentsFilter } from 'redux/modules/rents/TReducer'
import TableFilterReduxAdapter from 'utils/helpers/table'
import { Actions_Rents } from 'redux/modules/rents/Actions'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { get } = RequestMethods()

export default function* SagaWorker_GetRents({ payload }: PayloadAction<TRentsFilter>) {
  yield put(Actions_Rents.store.showLoader())
  const getFilter = (state: TState) => state.rents.filter
  const getRents = (state: TState) => state.rents.data

  //------------------------- Getting request params ---------------------------//
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)
  const oldRents: ReturnType<typeof getRents> = yield select(getRents)

  const isNeedToConcatData = !!payload

  const formData = {
    ...filter,
  }

  try {
    //------------------------- Getting rents ---------------------------//
    const result: TStoreTemplate<TRents> = yield call(async () => await get<TRents>('/rents', formData))

    const newRentsData = result.data || []
    yield put(
      Actions_Rents.store.saveRents({
        ...result,
        data: isNeedToConcatData ? TableFilterReduxAdapter.concatData<TRent>(newRentsData, oldRents) : result.data,
        isAllDataLoaded: TableFilterReduxAdapter.checkIsAllDataLoaded(newRentsData, filter),
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_Rents.store.hideLoader())
}
