import React, { forwardRef } from 'react'
import { Shared } from 'ui/shared'
import { IInputFieldProps } from 'ui/shared/InputField/InputField'
import { Controls } from 'assets/images/Controls'

export const CustomInput = forwardRef<HTMLDivElement, IInputFieldProps>((props, ref) => {
  return (
    <Shared.InputField
      EndAdornment={Controls.Calendar}
      inputProps={{ autoComplete: 'off' }}
      register={ref}
      {...props}
    />
  )
})
