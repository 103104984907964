enum StoreActions {
  IS_LOADER_SHOW = 'CUSTOM_ROLES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CUSTOM_ROLES/IS_LOADER_HIDE',
  SAVE_ROLES = 'CUSTOM_ROLES/SAVE_ROLES',
}

enum SagaEvents {
  GET_ROLES = 'CUSTOM_ROLES/GET_ROLES',
}

export const ActionTypes_CustomRoles = {
  SagaEvents,
  StoreActions,
}
