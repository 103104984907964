import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Regions } from 'redux/modules/regions/Actions'
import { TRegion } from 'redux/modules/regions/TReducer'

const { get } = RequestMethods()

export default function* SagaWorker_GetRegions() {
  try {
    yield put(Actions_Regions.store.showLoader())

    const regions: TStoreTemplate<TRegion[]> = yield call(async () => await get<TRegion[]>('/organizations/regions'))

    yield put(Actions_Regions.store.saveRegions(regions))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_Regions.store.hideLoader())
}
