import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { ActionTypes_AccessRules } from './TAction'
import { TAccessRuleList } from './TReducer'

const { StoreActions, SagaEvents } = ActionTypes_AccessRules

export const Actions_AccessRules = {
  saga: {
    getAccessRules: createAction(SagaEvents.GET_ACCESS_RULES),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveAccessRules: createAction<TStoreTemplate<TAccessRuleList>>(StoreActions.SAVE_ACCESS_RULES),
  },
}
