import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { TTrailersCounters } from 'redux/modules/trailersCounters/TReducer'
import { Actions_TrailersCounters } from 'redux/modules/trailersCounters/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { get } = RequestMethods()

export default function* SagaWorker_UpdateTrailers() {
  const getSelectedTrailerTypeIds = (state: TState) => state.trailerTypes.selectedTrailerTypeIds
  const getSelectedTrailerStatusIds = (state: TState) => state.trailerStatuses.selectedStatusIds
  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds

  const types: ReturnType<typeof getSelectedTrailerTypeIds> = yield select(getSelectedTrailerTypeIds)
  const status: ReturnType<typeof getSelectedTrailerStatusIds> = yield select(getSelectedTrailerStatusIds)
  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)

  try {
    //------------------------- Getting and saving trailers ---------------------------//
    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () =>
        await get<TTrailers>('/trailers/list', {
          types,
          status,
          organization,
        }),
    )
    yield put(Actions_Trailers.store.saveTrailers(trailerResponse))

    //------------------------- Getting and saving trailer counters ----------------------//

    const t_counters: TStoreTemplate<TTrailersCounters> = yield call(
      async () => await get<TTrailersCounters>('/trailers/counters', { types, status, organization }),
    )
    yield put(Actions_TrailersCounters.store.saveTrailersCounters(t_counters))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
