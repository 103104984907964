import { call, put } from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import { AxiosRequestConfig } from 'axios'
import { RequestMethods } from 'utils/axios/methods'

const { get } = RequestMethods()

export interface ISagaWorker_GetBill {
  id: number
}

export default function* SagaWorker_GetBill(action: PayloadAction<number>) {
  yield put(Actions_CurrentUser.store.showLoader())

  try {
    const id = action.payload

    yield call(async () => get(`/rents/${id}/invoice/resend`))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentUser.store.hideLoader())
  }
}
