import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { usePhoto } from './Photo-ViewModel'
import { IconButton, Typography } from '@mui/material'
import { Controls } from 'assets/images/Controls'
import { ImageLoad } from 'ui/shared/ImageLoad/ImageLoad'
export const Photo: FC = () => {
  const vm = usePhoto()
  const { closeModal, choosen, setChoosen, rent, cdnUrl, onImageClick } = vm

  return (
    <Shared.Card style={{ width: 800, overflowY: 'auto', maxHeight: '100%' }}>
      <Grid container spacing={3}>
        <Grid container direction={'row'} justifyContent={'space-between'} style={{ margin: 16, marginBottom: 8 }}>
          <Shared.CardTitle title={`Foto ${choosen}`} />
          <IconButton onClick={closeModal}>
            <Controls.Close />
          </IconButton>
        </Grid>
        <Grid container direction={'row'} justifyContent={'space-between'}>
          <Grid item style={{ marginRight: 8, marginLeft: 16 }}>
            <Typography style={{ color: '#3E3E3E', fontWeight: '700', fontSize: 20 }}>{'Before'}</Typography>
            {/* <img
              src={cdnUrl + rent.startCheck.photoNames[choosen - 1]}
              style={{ height: 360, width: 360, cursor: 'pointer', objectFit: 'contain' }}
              onClick={() => onImageClick(rent.startCheck.photoNames[choosen - 1])}
            /> */}
            <ImageLoad name={rent.startCheck.photoNames[choosen - 1]} />
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ color: '#3E3E3E', fontWeight: '700', fontSize: 20 }}>{'After'}</Typography>
            {rent.finishCheck === null ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Controls.NoPhoto />
                <Typography>{'No photo'}</Typography>
              </div>
            ) : (
              // <img
              //   src={cdnUrl + rent.finishCheck.photoNames[choosen - 1]}
              //   style={{ height: 360, width: 360, cursor: 'pointer', objectFit: 'contain' }}
              //   onClick={() => onImageClick(rent.finishCheck.photoNames[choosen - 1])}
              // />
              <ImageLoad name={rent.finishCheck.photoNames[choosen - 1]} />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} justifyContent={'center'} direction={'row'} display={'flex'}>
          <IconButton onClick={() => setChoosen(1)}>
            <Controls.ChecklistPhoto1 />
          </IconButton>
          <IconButton onClick={() => setChoosen(2)}>
            <Controls.ChecklistPhoto2 />
          </IconButton>
          <IconButton onClick={() => setChoosen(3)}>
            <Controls.ChecklistPhoto3 />
          </IconButton>
          <IconButton onClick={() => setChoosen(4)}>
            <Controls.ChecklistPhoto4 />
          </IconButton>
          <IconButton onClick={() => setChoosen(5)}>
            <Controls.ChecklistPhoto5 />
          </IconButton>
        </Grid>
      </Grid>
    </Shared.Card>
  )
}
