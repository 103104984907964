/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { Actions_Auth } from 'redux/modules/auth/Actions'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TAuth } from 'redux/modules/auth/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AuthStorage } from 'utils/helpers/auth'

const { post } = RequestMethods()

export interface ISagaWorker_ChangePassword {
  resetCode: string
  password: string
}

export default function* SagaWorker_ChangePassword(action: PayloadAction<ISagaWorker_ChangePassword>) {
  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting token and authorize ---------------------------//
    const auth: TStoreTemplate<TAuth> = yield call(
      async () => await post<TAuth>('/users/password/reset', action.payload),
    )
    AuthStorage.signIn(auth.data!.token)
    yield put(Actions_Auth.store.saveAuth(auth))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
