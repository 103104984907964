import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { Form } from 'utils/helpers/form'
import { useCompleteRent } from './CompleteRent-ViewModel'

export const CompleteRent: FC = () => {
  const vm = useCompleteRent()
  const { closeModal, form, isLoading, isCompleteRentInProgress, t } = vm
  const { errors, handleSubmit, control, stationsOptions } = form

  return (
    <Shared.Card style={{ width: 375 }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
          </Grid>

          <Grid item xs={12}>
            <Shared.AutocompleteForForm
              name="stationId"
              label={t('station')}
              options={stationsOptions}
              control={control}
              rules={Form.required}
              error={errors.stationId?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
              disabled={isCompleteRentInProgress}
            />
            <Shared.Button title={t('submit')} type={'submit'} disabled={isCompleteRentInProgress} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
