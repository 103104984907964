import React, { FC } from 'react'
import { Controls } from 'assets/images/Controls'
import { Shared } from 'ui/shared/index'
import { useInfoWindow } from 'ui/shared/InfoWindow/InfoWindow-ViewModel'
interface IProps {
  className?: string
  onClose?: () => void
  hideDivider?: boolean
  Content: FC
  Footer: FC
}

const InfoWindow: FC<IProps> = ({ Content, onClose = () => {}, Footer, hideDivider, className = '' }) => {
  const vm = useInfoWindow(onClose)
  const { classes, cx, wrapperRef } = vm

  return (
    <div ref={wrapperRef} className={cx(classes.root, className)}>
      <Content />
      {!hideDivider && <Shared.Divider />}
      <Footer />
      <Controls.CardTail className={classes.cardTail} />
    </div>
  )
}

export default InfoWindow
