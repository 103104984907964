import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Organizations } from './TActions'
import { TOrganizations, TSelectedOrganizationIdsPayload } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { StoreActions, SagaEvents } = ActionTypes_Organizations

export const Actions_Organizations = {
  saga: {
    getOrganizations: createAction(SagaEvents.GET_ORGANIZATIONS),
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    loadXlsx: createAction(SagaEvents.LOAD_XLSX),
  },

  store: {
    changeSelectedOrganizationIds: createAction<TSelectedOrganizationIdsPayload>(StoreActions.SELECT_ORGANIZATION_IDS),
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveOrganizations: createAction<TStoreTemplate<TOrganizations>>(StoreActions.SAVE_ORGANIZATIONS),
    reset: createAction(StoreActions.RESET),
  },
}
