import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TUser } from 'redux/modules/users/TReducer'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'

const client = RequestMethods()

export interface ISagaWorker_RemoveBooking {
  id: string
}

export default function* SagaWorker_RemoveBooking(action: PayloadAction<ISagaWorker_RemoveBooking>) {
  const { id } = action.payload

  yield put(Actions_CurrentUser.store.showLoader())

  try {
    yield call(async () => await client.post<TUser>(`/rents/cancel`, { id }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentUser.store.hideLoader())
  }
}
