import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { TOrganizationInvitationCode } from 'redux/modules/organizationInvitationCodes/TReducer'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import { TUser } from 'redux/modules/users/TReducer'

const client = RequestMethods()

export interface ISagaWorker_ConnectToOrganizationDiscount {
  userId: number
  data: {
    organizationId: number
    endDate: string
  }
}

export default function* SagaWorker_ConnectToOrganizationDiscount(
  action: PayloadAction<ISagaWorker_ConnectToOrganizationDiscount>,
) {
  const { data, userId } = action.payload
  try {
    yield put(Actions_CurrentUser.store.showLoader())

    const user: TStoreTemplate<TUser> = yield call(
      async () => await client.post<TOrganizationInvitationCode>(`/users/${userId}/organization`, data),
    )

    yield put(Actions_CurrentUser.store.saveCurrentUser(user))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Organization discount successfully connected',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CurrentUser.store.hideLoader())
}
