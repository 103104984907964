import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { TChecklistParameters } from 'redux/modules/rents/TReducer'
import { Actions_Rents } from 'redux/modules/rents/Actions'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { get } = RequestMethods()

export interface TSagaWorker_GetChecklistsParameters {
  id: number
}

export default function* SagaWorker_GetChecklistsParameters(
  action: PayloadAction<TSagaWorker_GetChecklistsParameters>,
) {
  yield put(Actions_Rents.store.showLoader())
  const { id } = action.payload
  try {
    //------------------------- Getting rents ---------------------------//
    const result: TStoreTemplate<TChecklistParameters[]> = yield call(
      async () => await get<TChecklistParameters[]>(`/trailer_types/${id}/checklist/parameters`),
    )

    const newRentsData = result.data || []
    yield put(Actions_Rents.store.saveChecklistsParameters(newRentsData))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_Rents.store.hideLoader())
}
