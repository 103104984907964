import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { TOrganizations } from 'redux/modules/organizations/TReducer'
import { Actions_Organizations } from 'redux/modules/organizations/Actions'
import { TOrganizationTypes } from 'redux/modules/organizationTypes/TReducer'
import { Actions_OrganizationTypes } from 'redux/modules/organizationTypes/Actions'
import { TParentOrganization } from 'redux/modules/parentOrganizations/TReducer'
import { Actions_ParentOrganizations } from 'redux/modules/parentOrganizations/Actions'

const { get } = RequestMethods()

export default function* SagaPage_Organizations() {
  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting and saving organizations ----------------------//

    const organizations: TStoreTemplate<TOrganizations> = yield call(
      async () => await get<TOrganizations>('/organizations', { hideInactive: false }),
    )

    yield put(Actions_Organizations.store.saveOrganizations(organizations))

    //------------------------- Getting and saving parent organizations ----------------------//

    const parentOrganizations: TStoreTemplate<TParentOrganization[]> = yield call(
      async () => await get<TParentOrganization[]>('/organizations/parents'),
    )

    yield put(Actions_ParentOrganizations.store.saveParentOrganizations(parentOrganizations))

    //------------------------- Getting and saving organization types ----------------------//

    const organizationTypes: TStoreTemplate<TOrganizationTypes> = yield call(
      async () => await get<TOrganizationTypes>('/organizations/types'),
    )
    yield put(Actions_OrganizationTypes.store.saveOrganizationTypes(organizationTypes))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
