import { createReducer } from '@reduxjs/toolkit'
import { IStateUsers } from './TReducer'
import TableFilterReduxAdapter from 'utils/helpers/table'
import { Actions_Users } from './Actions'

const initialState: IStateUsers = {
  isLoading: false,
  data: null,
  isAllDataLoaded: false,
  responseInfo: null,
  filter: {
    ...TableFilterReduxAdapter.DEFAULT_FILTERS,
    sorting: 'id',
  },
}

const ReducerUsers = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Users.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Users.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Users.store.saveUsers, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Users.store.changeFilter, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Users.store.increaseFilterOffset, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Users.store.reset, () => {
      return initialState
    })
})

export default ReducerUsers
