import { createReducer } from '@reduxjs/toolkit'
import { Actions_TrailerStatuses } from './Actions'
import { IStateTrailerStatuses } from './TReducer'

const initialState: IStateTrailerStatuses = {
  isLoading: false,
  data: null,
  responseInfo: null,
  selectedStatusIds: [],
}

const ReducerTrailerStatuses = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_TrailerStatuses.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_TrailerStatuses.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_TrailerStatuses.store.saveTrailerStatuses, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_TrailerStatuses.store.changeSelectedStatusesIds, (state, action) => {
      state.selectedStatusIds = action.payload
    })
    .addCase(Actions_TrailerStatuses.store.resetTrailerStatuses, () => {
      return initialState
    })
})

export default ReducerTrailerStatuses
