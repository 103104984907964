import React, { FC } from 'react'
import Box from '@mui/material/Box'
import { Shared } from '..'

export type TableBatteryCellProps = {
  charge: number
}

export const TableBatteryCell: FC<TableBatteryCellProps> = ({ charge }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
      <Shared.ButteryIcon charge={charge} />
    </Box>
  )
}
