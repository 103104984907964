enum StoreActions {
  IS_LOADER_SHOW = 'STATIONSTATUSES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'STATIONSTATUSES/IS_LOADER_HIDE',
  SAVE_STATION_STATUSES = 'STATION_STATUSES/SAVE_STATION_STATUSES',
}

enum SagaEvents {
  GET_STATION_STATUSES = 'STATION_STATUSES/GET_STATION_STATUSES',
}

export const ActionTypes_StationStatuses = {
  SagaEvents,
  StoreActions,
}
