import React, { useCallback } from 'react'
import { Shared } from 'ui/shared'
import { languageOptions } from 'utils/i18n/i18n'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const SelectLanguage = () => {
  const theme = useTheme()
  const { i18n } = useTranslation()

  const onChange = useCallback(
    (language: string) => {
      return i18n.changeLanguage(language)
    },
    [i18n],
  )

  return (
    <Shared.Dropdown
      formControlStyles={{ width: 90 }}
      onChange={onChange}
      value={i18n.language}
      color={theme.palette.primary.main}
      hideBorder
      marginTop={0}
      name={'lang'}
      options={languageOptions}
    />
  )
}

export default SelectLanguage
