/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TOrganizations } from 'redux/modules/organizations/TReducer'
import { Actions_Organizations } from 'redux/modules/organizations/Actions'

const { get } = RequestMethods()

export default function* SagaWorker_Organizations() {
  try {
    yield put(Actions_Organizations.store.showLoader())

    const orgs: TStoreTemplate<TOrganizations> = yield call(
      async () => await get<TOrganizations>('/organizations', { hideInactive: true }),
    )
    yield put(Actions_Organizations.store.saveOrganizations(orgs))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_Organizations.store.hideLoader())
}
