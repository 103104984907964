enum StoreActions {
  IS_LOADER_SHOW = 'ORGANIZATION_TYPES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'ORGANIZATION_TYPES/IS_LOADER_HIDE',
  SAVE_ORGANIZATION_TYPES = 'ORGANIZATION_TYPES/SAVE_ORGANIZATION_TYPES',
}

enum SagaEvents {
  GET_ORGANIZATION_TYPES = 'ORGANIZATION_TYPES/GET_ORGANIZATION_TYPES',
}

export const ActionTypes_OrganizationTypes = {
  SagaEvents,
  StoreActions,
}
