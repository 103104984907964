import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TCurrentTrailer } from 'redux/modules/currentTrailer/TReducer'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_CurrentStation } from 'redux/modules/currentStation/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const client = RequestMethods()

export interface ISagaWorker_UpdateStation {
  id: number
  data: {
    vin: string
    Station: string
    organization: number[]
  }
}

export default function* SagaWorker_UpdateStation(action: PayloadAction<ISagaWorker_UpdateStation>) {
  yield put(Actions_CurrentStation.store.showLoader())

  try {
    //------------------------- Update station ---------------------------//
    yield call(async () => await client.put<TCurrentTrailer>(`/station/${action.payload.id}`, action.payload.data))

    yield put(Actions_Modals.store.hideModal())

    // if (trailerData.data) {
    //   const { organization, ...trailer } = action.payload.data
    //   yield put(
    //     Actions_CurrentStation.store.saveCurrentStation({
    //       ...trailerData,
    //       data: { ...trailerData.data, trailer: { ...trailerData.data.trailer, ...trailer }, organization },
    //     }),
    //   )
    // }
    yield put(Actions_Notifications.store.setNotification({ message: 'Station successfully updated', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CurrentStation.store.hideLoader())
}
