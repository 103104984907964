import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { TTrailersCounters } from 'redux/modules/trailersCounters/TReducer'
import { Actions_TrailersCounters } from 'redux/modules/trailersCounters/Actions'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { Actions_TrailerStatuses } from 'redux/modules/trailerStatuses/Actions'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'

const { get } = RequestMethods()

export default function* SagaPage_TrailersMap() {
  yield put(Actions_PageLoader.store.showLoader())

  const getSelectedOrganizations = (state: TState) => state.organizations.selectedOrganizationIds
  const organization: ReturnType<typeof getSelectedOrganizations> = yield select(getSelectedOrganizations)
  const getSelectedTrailerTypes = (state: TState) => state.trailerTypes.selectedTrailerTypeIds
  const types: ReturnType<typeof getSelectedTrailerTypes> = yield select(getSelectedTrailerTypes)
  const getSelectedTrailerStatusIds = (state: TState) => state.trailerStatuses.selectedStatusIds
  let status: ReturnType<typeof getSelectedTrailerStatusIds> = yield select(getSelectedTrailerStatusIds)

  try {
    //------------------------- Getting and saving trailer types ----------------------//

    const t_types: TStoreTemplate<TTrailerTypes> = yield call(async () => await get<TTrailerTypes>('/trailer_types'))
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(t_types))

    //------------------------- Getting and saving trailer statuses -------------------//

    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await get<TTrailerStatuses>('/trailers/statuses'),
    )
    yield put(Actions_TrailerStatuses.store.saveTrailerStatuses(t_statuses))

    if (!status.length && t_statuses.data?.statuses) {
      status = t_statuses.data.statuses.map((t_statuse) => t_statuse.id)
      yield put(Actions_TrailerStatuses.store.changeSelectedStatusesIds(status))
    }

    //------------------------- Getting and saving trailers ---------------------------//
    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () =>
        await get<TTrailers>('/trailers/list', {
          status,
          organization,
          types,
        }),
    )
    yield put(Actions_Trailers.store.saveTrailers(trailerResponse))
    //------------------------- Getting and saving trailer counters ----------------------//

    const t_counters: TStoreTemplate<TTrailersCounters> = yield call(
      async () => await get<TTrailersCounters>('/trailers/counters', { status, organization, types }),
    )
    yield put(Actions_TrailersCounters.store.saveTrailersCounters(t_counters))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_PageLoader.store.hideLoader())
}
