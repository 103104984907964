enum StoreActions {
  IS_LOADER_SHOW = 'USERS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'USERS/IS_LOADER_HIDE',
  SAVE_USERS = 'USERS/SAVE_USERS',
  CHANGE_FILTER = 'USERS/CHANGE_FILTER',
  INCREASE_FILTER_OFFSET = 'USERS/INCREASE_FILTER_OFFSET',
  RESET = 'USERS/RESET',
}

enum SagaEvents {
  GET_USERS = 'USERS/GET_USERS',
  LOAD_XLSX = 'USERS/LOAD_XLSX',
  DELETE_USER = 'USERS/DELETE_USER',
}

export const ActionTypes_Users = {
  SagaEvents,
  StoreActions,
}
