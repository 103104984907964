import { TState, TStoreTemplate } from 'redux/config/root-types'
import { TSettings } from 'redux/modules/settings/TReducer'
import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Settings } from 'redux/modules/settings/Actions'
import { Actions_MeRole } from 'redux/modules/meRole/Actions'
import { TMeRole } from 'redux/modules/meRole/TReducer'
import { Actions_Redirect } from 'redux/modules/redirect/Actions'
import { AuthStorage } from 'utils/helpers/auth'
import { To } from 'router/To'
import { Actions_Auth } from 'redux/modules/auth/Actions'
import { TAuth } from 'redux/modules/auth/TReducer'
import { Actions_MeUser } from 'redux/modules/meUser/Actions'
import { TUser } from 'redux/modules/users/TReducer'

const { get } = RequestMethods()

export default function* SagaWorker_Initial() {
  try {
    yield put(Actions_Settings.store.showLoader())
    //------------------------- Getting settings ---------------------------//
    const settings: TStoreTemplate<TSettings> = yield call(async () => await get<TSettings>('/settings'))

    yield put(Actions_Settings.store.saveSettings(settings))
    //------------------------- Getting me role ---------------------------//
    const meRole: TStoreTemplate<TMeRole> = yield call(async () => await get<TMeRole>('/users/roles/me'))

    yield put(Actions_MeRole.store.saveMeRole(meRole))
    //------------------------- Getting me user ---------------------------//
    const meUser: TStoreTemplate<TUser> = yield call(async () => await get<TUser>('/users/me'))

    yield put(Actions_MeUser.store.saveMeUser(meUser))
    //------------------------- Get token from local storage and save---------------------------//
    const auth: TStoreTemplate<TAuth> = {
      data: {
        token: AuthStorage.token,
      },
      responseInfo: null,
    }
    yield put(Actions_Auth.store.saveAuth(auth))
    //------------------------- Save main route ---------------------------//
    const getHasFullAccess = (state: TState) => state.meRole.data?.role.hasFullAccess
    const getCanTrailerView = (state: TState) => state.meRole.data?.role.TRAILER_VIEW
    const getcanTrailerTypeView = (state: TState) => state.meRole.data?.role.RAILER_TYPE_VIEW
    const getcanParkingView = (state: TState) => state.meRole.data?.role.PARKING_VIEW
    const getcanStaffView = (state: TState) => state.meRole.data?.role.STAFF_VIEW
    const getcanOrganizationView = (state: TState) => state.meRole.data?.role.ORGANIZATION_VIEW
    const getcanPriceView = (state: TState) => state.meRole.data?.role.PRICE_VIEW
    const getcanReportView = (state: TState) => state.meRole.data?.role.REPORT_VIEW
    const getcanInspectionView = (state: TState) => state.meRole.data?.role.INSPECTION_VIEW

    const hasFullAccess: ReturnType<typeof getHasFullAccess> = yield select(getHasFullAccess)
    const canTrailerView: ReturnType<typeof getCanTrailerView> = yield select(getCanTrailerView)
    const canTrailerTypeView: ReturnType<typeof getcanTrailerTypeView> = yield select(getcanTrailerTypeView)
    const canStaffView: ReturnType<typeof getcanParkingView> = yield select(getcanParkingView)
    const canOrganizationView: ReturnType<typeof getcanStaffView> = yield select(getcanStaffView)
    const canParkingView: ReturnType<typeof getcanOrganizationView> = yield select(getcanOrganizationView)
    const canPriceView: ReturnType<typeof getcanPriceView> = yield select(getcanPriceView)
    const canInspectionView: ReturnType<typeof getcanReportView> = yield select(getcanReportView)
    const canReportView: ReturnType<typeof getcanInspectionView> = yield select(getcanInspectionView)

    const url =
      ((hasFullAccess || canTrailerView) && To.trailersMap) ||
      (canTrailerTypeView && To.trailerTypes) ||
      (canParkingView && To.parkings) ||
      (canReportView && To.reports) ||
      (canStaffView && To.staff) ||
      // (canPriceView && To.) || // TODO: add it when done
      // (canInspectionView && To.) ||
      (canOrganizationView && To.organizations) ||
      '/'
    yield put(Actions_Redirect.store.redirectTo(url))
    //------------------------- Set initialized ---------------------------//
    yield put(Actions_MeRole.store.setInitialized())
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Settings.store.hideLoader())
  }
}
