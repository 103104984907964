import { createReducer } from '@reduxjs/toolkit'
import { IStateNotificationHistory } from './TReducer'
import { Actions_notificationHistory } from './Actions'

const initialState: IStateNotificationHistory = {
  isShowDrawer: false,
  isShowParkingsDrawer: false,
  isShowStationsDrawer: false,
  isLoading: false,
  isUnreadMessages: false,
  isParkingsHaveProblem: false,
  isStationsHaveProblem: false,
  data: null,
  responseInfo: null,
  problems: {
    problemParkings: 0,
    problemStations: 0,
  },
}

const ReducerNotificationHistory = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_notificationHistory.store.showDrawer, (state) => {
      state.isShowDrawer = true
    })
    .addCase(Actions_notificationHistory.store.hideDrawer, (state) => {
      state.isShowDrawer = false
    })
    .addCase(Actions_notificationHistory.store.showParkingsDrawer, (state) => {
      state.isShowParkingsDrawer = true
    })
    .addCase(Actions_notificationHistory.store.hideParkingsDrawer, (state) => {
      state.isShowParkingsDrawer = false
    })
    .addCase(Actions_notificationHistory.store.showStationsDrawer, (state) => {
      state.isShowStationsDrawer = true
    })
    .addCase(Actions_notificationHistory.store.hideStationsDrawer, (state) => {
      state.isShowStationsDrawer = false
    })

    .addCase(Actions_notificationHistory.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_notificationHistory.store.hideLoader, (state) => {
      state.isLoading = false
    })

    .addCase(Actions_notificationHistory.store.showUnreadMessagesIndicator, (state) => {
      state.isUnreadMessages = true
    })
    .addCase(Actions_notificationHistory.store.hideUnreadMessagesIndicator, (state) => {
      state.isUnreadMessages = false
    })

    .addCase(Actions_notificationHistory.store.showParkingsProblemIndicator, (state) => {
      state.isParkingsHaveProblem = true
    })
    .addCase(Actions_notificationHistory.store.hideParkingsProblemIndicator, (state) => {
      state.isParkingsHaveProblem = false
    })

    .addCase(Actions_notificationHistory.store.showStationsProblemIndicator, (state) => {
      state.isStationsHaveProblem = true
    })
    .addCase(Actions_notificationHistory.store.hideStationsProblemIndicator, (state) => {
      state.isStationsHaveProblem = false
    })

    .addCase(Actions_notificationHistory.store.saveNotifications, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_notificationHistory.store.clearNotifications, () => {
      return initialState
    })

    .addCase(Actions_notificationHistory.store.addProblems, (state, action) => {
      state.problems = action.payload
    })
})

export default ReducerNotificationHistory
