import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_ParentOrganizations } from 'redux/modules/parentOrganizations/Actions'
import { TParentOrganization } from 'redux/modules/parentOrganizations/TReducer'

const client = RequestMethods()

export interface ISagaWorker_ActivateParentOrganization {
  id: number
}

export default function* SagaWorker_ActivateParentOrganization(
  action: PayloadAction<ISagaWorker_ActivateParentOrganization>,
) {
  const { id } = action.payload

  yield put(Actions_ParentOrganizations.store.showLoader())

  try {
    const organization: TStoreTemplate<TParentOrganization> = yield call(
      async () => await client.put<TParentOrganization>(`/organizations/parents/${id}/activate`),
    )

    yield put(Actions_ParentOrganizations.store.editParentOrganization(organization))

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Parent organization successfully activated',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_ParentOrganizations.store.hideLoader())
  }
}
