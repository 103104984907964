import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'
import { TStoreTemplate } from 'redux/config/root-types'
import { IOrganization } from 'redux/modules/organizations/TReducer'

const client = RequestMethods()

export interface ISagaWorker_ActivateOrganization {
  id: number
}

export default function* SagaWorker_ActivateOrganization(action: PayloadAction<ISagaWorker_ActivateOrganization>) {
  const { id } = action.payload

  yield put(Actions_CurrentOrganization.store.showLoader())

  try {
    const organization: TStoreTemplate<IOrganization> = yield call(
      async () => await client.put<IOrganization>(`/organizations/${id}/activate`),
    )
    yield put(Actions_CurrentOrganization.store.saveCurrentOrganization(organization))

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Organization successfully activated', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentOrganization.store.hideLoader())
  }
}
