import { createReducer } from '@reduxjs/toolkit'
import { IStatePromoCode } from './TReducer'
import TableFilterReduxAdapter from 'utils/helpers/table'
import { Actions_PromoCode } from './Actions'

const initialState: IStatePromoCode = {
  isLoading: false,
  data: null,
  isAllDataLoaded: false,
  responseInfo: null,
  selectedIds: [],
  dateFilter: null,
  bonusMinutesFilter: null,
  promoCodeNameFilter: null,
  selectedTrailerTypeIds: [],
  selectedOrganizationIds: [],
  filter: {
    ...TableFilterReduxAdapter.DEFAULT_FILTERS,
    sorting: 'code',
  },
}

const ReducerPromoCode = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_PromoCode.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_PromoCode.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_PromoCode.store.savePromoCode, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_PromoCode.store.changeFilter, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_PromoCode.store.increaseFilterOffset, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_PromoCode.store.setSelectedIds, (state, action) => {
      const ids = state.selectedIds
      if (ids.includes(action.payload)) {
        ids.splice(ids.indexOf(action.payload), 1)
      } else {
        ids.push(action.payload)
      }
      state.selectedIds = ids
    })
    .addCase(Actions_PromoCode.store.clearSelectedIds, (state) => {
      state.selectedIds = []
    })
    .addCase(Actions_PromoCode.store.editPromoCode, (state, action) => {
      if (!state.data) {
        return {
          ...state,
        }
      }

      const payload = action.payload
      const updatedPromoCodeList = state.data?.map((promoCode) => {
        if (promoCode.id !== payload.data?.id) {
          return promoCode
        }
        return {
          ...promoCode,
          ...payload.data,
        }
      })
      return {
        ...state,
        data: updatedPromoCodeList,
      }
    })
    .addCase(Actions_PromoCode.store.reset, () => {
      return initialState
    })
    .addCase(Actions_PromoCode.store.setDateFilter, (state, action) => {
      const payload = action.payload
      state.dateFilter = payload
    })
    .addCase(Actions_PromoCode.store.setBonusMinutesFilter, (state, action) => {
      const payload = action.payload
      state.bonusMinutesFilter = payload
    })
    .addCase(Actions_PromoCode.store.setPromoCodeNameFilter, (state, action) => {
      const payload = action.payload
      state.promoCodeNameFilter = payload
    })
    .addCase(Actions_PromoCode.store.changeSelectedTrailerTypesIds, (state, action) => {
      state.selectedTrailerTypeIds = action.payload
    })
    .addCase(Actions_PromoCode.store.changeSelectedOrganizationIds, (state, action) => {
      state.selectedOrganizationIds = action.payload
    })
})

export default ReducerPromoCode
