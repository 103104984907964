import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { Actions_Stations } from 'redux/modules/stations/Actions'
import { useForm } from 'react-hook-form'

export const useStationNameFilter = () => {
  const dispatch = useDispatch()
  const { parkings, stations } = useSelector(
    ({ parkings, stations }: TState) => ({
      parkings: parkings,
      stations: stations,
    }),
    shallowEqual,
  )

  const isFilterOn = stations.filter.stationName ? true : false
  const { handleSubmit, register, errors, control } = useForm<{ value: string }>({
    defaultValues: {
      value: stations.filter.stationName ? stations.filter.stationName : '',
    },
  })

  const onSubmit = useCallback(
    (data: { value: string }) => {
      if (data.value.length >= 2) {
        dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, stationName: data.value }))
        dispatch(Actions_Modals.store.hideModal())
      }
    },
    [dispatch, parkings],
  )

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  const onClearFilter = () => {
    dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, stationName: null }))
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    onCancel,
    isFilterOn,
    onClearFilter,
    form: { register, handleSubmit: handleSubmit(onSubmit) },
  }
}
