import { createReducer } from '@reduxjs/toolkit'
import { IStateStaff } from './TReducer'
import TableFilterReduxAdapter from 'utils/helpers/table'
import { Actions_Staff } from './Actions'

const initialState: IStateStaff = {
  isLoading: false,
  data: null,
  isAllDataLoaded: false,
  responseInfo: null,
  filter: {
    ...TableFilterReduxAdapter.DEFAULT_FILTERS,
    sorting: 'firstName',
    organization: [],
  },
}

const ReducerStaff = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Staff.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Staff.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Staff.store.saveStaff, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Staff.store.changeFilter, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Staff.store.increaseFilterOffset, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Staff.store.reset, () => {
      return initialState
    })
})

export default ReducerStaff
