import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'

interface IProps {
  style?: {}
  className?: string
  children?: React.ReactNode | React.ReactNode[]
}

export const Card: FC<IProps> = ({ children, style = {}, className = '' }) => {
  const { classes, cx } = useStyles()
  return (
    <div className={cx(classes.root, className)} style={style}>
      {children}
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    padding: '1.5rem',
  },
}))
