enum StoreActions {
  IS_LOADER_SHOW = 'TEMPALTE_ROLES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'TEMPALTE_ROLES/IS_LOADER_HIDE',
  SAVE_ROLES = 'TEMPALTE_ROLES/SAVE_ROLES',
  SELECT_ROLE_ID = 'TEMPALTE_ROLES/SELECT_ROLE_ID',
  CLEAR_SELECTED_ROLE_ID = 'TEMPALTE_ROLES/CLEAR_SELECTED_ROLE_ID',
}

enum SagaEvents {
  GET_ROLES = 'TEMPALTE_ROLES/GET_ROLES',
}

export const ActionTypes_TemplateRoles = {
  SagaEvents,
  StoreActions,
}
