import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_TrailersCounters } from './TActions'
import { TTrailersCounters } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { StoreActions } = ActionTypes_TrailersCounters

export const Actions_TrailersCounters = {
  saga: {},

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveTrailersCounters: createAction<TStoreTemplate<TTrailersCounters>>(StoreActions.SAVE_TRAILERS_COUNTERS),
  },
}
