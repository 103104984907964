import { createReducer } from '@reduxjs/toolkit'
import { Actions_TrailerTypes } from './Actions'
import { IStateTrailerTypes } from './TReducer'

const initialState: IStateTrailerTypes = {
  isLoading: false,
  data: null,
  responseInfo: null,
  selectedTrailerTypeIds: [],
  currentTrailerTypeId: undefined,
}

const ReducerTrailerTypes = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_TrailerTypes.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_TrailerTypes.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_TrailerTypes.store.saveTrailerTypes, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_TrailerTypes.store.changeSelectedTrailerTypesIds, (state, action) => {
      state.selectedTrailerTypeIds = action.payload
    })
    .addCase(Actions_TrailerTypes.store.selectCurrentTrailerTypeId, (state, action) => {
      state.currentTrailerTypeId = action.payload
    })
})

export default ReducerTrailerTypes
