import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { Autocomplete as MuiAutocomplete } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import { Controls } from 'assets/images/Controls'

export type TAutocompleteOption = {
  id: number
  value: string | string[] | number
  label: string
}

interface IProps {
  id?: string
  name: string
  control?: any
  label?: string
  options: TAutocompleteOption[]
  error?: null | string
  rules?: any
  hideErrorText?: boolean
  height?: number
}

const AutocompleteForForm: FC<IProps> = ({ id, name, label, options, control, error, rules, height = 48 }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      error={error}
      render={({ onChange, value, ...props }) => (
        <MuiAutocomplete
          // fullWidth
          id={id || name}
          options={options}
          getOptionLabel={(option: TAutocompleteOption) => option.label}
          onChange={(e, data) => onChange(data?.value)}
          popupIcon={<Controls.ArrowDownPrimary />}
          title={label}
          renderInput={(params) => {
            return (
              <>
                <InputLabel
                  {...params.InputLabelProps}
                  sx={{
                    color: '#ABABAB',
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                  }}
                >
                  {label}
                </InputLabel>
                <Input
                  error
                  {...params.InputProps}
                  id={params.id}
                  fullWidth={params.fullWidth}
                  inputProps={params.inputProps}
                  sx={{
                    height: height,
                    borderColor: error ? 'red' : '#ABABAB',

                    '&.MuiInput-root': {
                      paddingBottom: '0.75em',
                      '& .MuiInput-input': {
                        padding: 0,
                      },
                      '& .MuiAutocomplete-endAdornment': {
                        right: '1.1875rem',
                        top: '11.5px',

                        '& svg': {
                          height: 16,
                          fill: (theme) => theme.palette.primary.main,
                        },

                        '& .MuiAutocomplete-popupIndicator': {
                          padding: '4px',
                        },

                        '& .MuiTouchRipple-root': {
                          display: 'none',
                        },

                        '& .MuiAutocomplete-clearIndicator:hover': {
                          backgroundColor: 'transparent',
                        },
                        '& .MuiAutocomplete-popupIndicator:hover': {
                          backgroundColor: 'transparent',
                        },
                      },
                    },
                  }}
                />
              </>
            )
          }}
          {...props}
        />
      )}
    />
  )
}

export default AutocompleteForForm
