import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { PayloadAction } from '@reduxjs/toolkit'
import { TTrailerNote } from 'redux/modules/trailerNotes/TReducer'
import { Actions_TrailerNotes } from 'redux/modules/trailerNotes/Actions'
import { TGetNotesPayload } from 'redux/modules/parkingNotes/TReducer'

const { get } = RequestMethods()

export interface ISagaWorker_GetTrailerNotes {
  trailerId: number
}

export default function* SagaWorker_GetTrailerNotes(action: PayloadAction<ISagaWorker_GetTrailerNotes>) {
  try {
    yield put(Actions_TrailerNotes.store.showLoader())

    const { trailerId } = action.payload

    const data: TGetNotesPayload = {
      entityId: trailerId,
      type: 'trailer',
    }

    const trailerNotes: TStoreTemplate<TTrailerNote[]> = yield call(
      async () => await get<TTrailerNote[]>('/notes', data),
    )

    yield put(Actions_TrailerNotes.store.saveTrailerNotes(trailerNotes))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_TrailerNotes.store.hideLoader())
  }
}
