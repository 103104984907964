import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TState } from 'redux/config/root-types'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { To } from 'router/To'
import { I18nModal } from 'utils/i18n/i18nModal'

export const useTrailerDelete = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.trailerDelete)
  const navigate = useNavigate()

  const trailerId = useSelector(({ modals }: TState) => modals.data.id)

  const onSubmit = useCallback(() => {
    const navigateToTrailersMap = () => navigate(To.trailersMap)
    dispatch(Actions_CurrentTrailer.saga.deleteTrailer({ id: trailerId, navigateToTrailersMap }))
    dispatch(Actions_Modals.store.hideModal())
  }, [navigate, dispatch, trailerId])

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  return { t, onSubmit, onCancel }
}
