import Pusher from 'pusher-js'
import config from 'utils/conf/config'

const cluster = config.PUSHER_CLUSTER
const appKey = config.PUSHER_KEY

const options = { cluster }

const pusher = new Pusher(appKey, options)

export default pusher
