import { TState, TStoreTemplate } from 'redux/config/root-types'
import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStations } from 'redux/modules/stations/TReducer'
import { Actions_Stations } from 'redux/modules/stations/Actions'
import { PayloadAction } from '@reduxjs/toolkit'
import { TTableFilterRedux } from 'utils/helpers/table'

const { get } = RequestMethods()

export default function* SagaWorker_GetAllStations(action?: PayloadAction<TTableFilterRedux>) {
  yield put(Actions_Stations.store.showLoader())

  const getFilter = (state: TState) => state.stations.filter
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)
  const getSelectedTrailerTypeIds = (state: TState) => state.stations.selectedTrailerTypeIds
  const types: ReturnType<typeof getSelectedTrailerTypeIds> = yield select(getSelectedTrailerTypeIds)
  const isNeedToConcatData = !!action?.payload
  const formData = {
    limit: filter.limit,
    orderBy: filter.orderBy,
    sorting: filter.sorting,
    offset: !isNeedToConcatData ? 0 : filter.offset,
    parkingName: filter.stationName === null ? undefined : filter.stationName,
    organizationName: filter.organizationName === null ? undefined : filter.organizationName,
    parkingImei: filter.stationImei === null ? undefined : filter.stationImei,
    stationCode: filter.stationNumber === null ? undefined : filter.stationNumber,
    status: filter.stationStatus === null ? undefined : filter.stationStatus?.map((status) => status.id),
    trailerImei: filter.imei === null ? undefined : filter.imei,
    trailerNumber: filter.gvmnNumber === null ? undefined : filter.gvmnNumber,
    isOnline: filter.connection === null ? undefined : filter.connection,
    lockStatus: filter.lock === null ? undefined : filter.lock,
    types: types,
  }
  try {
    //------------------------- Getting and saving stations ---------------------------//
    const stationResponse: TStoreTemplate<TStations> = yield call(
      async () => await get<TStations>('/stations', formData),
    )
    yield put(Actions_Stations.store.saveStations(stationResponse))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Stations.store.hideLoader())
  }
}
