import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Users } from './TActions'
import { TUser } from './TReducer'
import { TStoreTemplateWithFilter } from 'redux/config/root-types'
import { TTableFilterRedux } from 'utils/helpers/table'
import { ISagaWorker_RemoveUserFromUsersTable } from 'redux/sagas/events/users/SagaWorker_RemoveUserFromUsersTable'

const { SagaEvents, StoreActions } = ActionTypes_Users

export const Actions_Users = {
  saga: {
    getUsers: createAction(SagaEvents.GET_USERS),
    loadXlsx: createAction(SagaEvents.LOAD_XLSX),
    deleteUser: createAction<ISagaWorker_RemoveUserFromUsersTable>(SagaEvents.DELETE_USER),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveUsers: createAction<TStoreTemplateWithFilter<TUser[]>>(StoreActions.SAVE_USERS),
    changeFilter: createAction<TTableFilterRedux>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TTableFilterRedux>(StoreActions.INCREASE_FILTER_OFFSET),
    reset: createAction(StoreActions.RESET),
  },
}
