enum StoreActions {
  IS_LOADER_SHOW = 'AUTH/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'AUTH/IS_LOADER_HIDE',
  SAVE_AUTH = 'AUTH/SAVE_AUTH',
  LOGOUT = 'AUTH/LOGOUT',
}

enum SagaEvents {
  GET_AUTH_INFO = 'AUTH/GET_AUTH_INFO',
  GET_INITIAL_DATA = 'AUTH/GET_INITIAL_DATA',
  CHANGE_PASSWORD = 'AUTH/CHANGE_PASSWORD',
}

export const ActionTypes_Auth = {
  SagaEvents,
  StoreActions,
}
