import { call } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AxiosRequestConfig } from 'axios'

const { download } = RequestMethods()

type TGetUsersFilters = {
  hideStaff: boolean
  hideDeleted: boolean
}

export default function* SagaWorker_GetUsersXlsx() {
  const formData: TGetUsersFilters = {
    hideStaff: true,
    hideDeleted: true,
  }

  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig

  try {
    yield call(async () => download('/users/report/xlsx', formData, config, 'users.xlsx'))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
