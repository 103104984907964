import { createReducer } from '@reduxjs/toolkit'
import { IStateCurrentUser } from './TReducer'
import { Actions_CurrentUser } from './Actions'

const initialState: IStateCurrentUser = {
  isLoading: false,
  data: null,
  responseInfo: null,
  veriffInfo: null,
}

const ReducerCurrentUser = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_CurrentUser.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_CurrentUser.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_CurrentUser.store.saveCurrentUser, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_CurrentUser.store.clearCurrentUser, (state) => {
      state.data = null
    })
    .addCase(Actions_CurrentUser.store.saveVeriffInfo, (state, action) => {
      state.veriffInfo = action.payload.data
    })
})

export default ReducerCurrentUser
