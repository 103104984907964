import Box from '@mui/material/Box'
import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Link } from 'react-router-dom'

export type ITableIconCell = {
  Icon: FC<any>
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  link?: string
  className?: string
}

interface IProps {
  value: ITableIconCell
}

export const TableIconCell: FC<IProps> = ({ value }) => {
  const { Icon, onClick = () => {}, link, className } = value
  const { classes, cx } = useStyles()
  const Component = (
    <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1} onClick={onClick}>
      <Icon className={cx(classes.icon, !!className && className)} />
    </Box>
  )

  if (link) {
    return (
      <Link className={classes.link} to={link}>
        {Component}
      </Link>
    )
  }

  return Component
}

const useStyles = makeStyles()({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    flexGrow: 1,
  },
  icon: {
    cursor: 'pointer',
  },
})
