import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { Actions_Users } from 'redux/modules/users/Actions'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import TableFilterReduxAdapter, { TTableFilterRedux } from 'utils/helpers/table'
import { TUsers } from 'redux/modules/users/TReducer'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'

type TGetUsersFilters = {
  hideStaff: boolean
  hideDeleted: boolean
} & TTableFilterRedux

const { remove, get } = RequestMethods()

export interface ISagaWorker_RemoveUserFromUsersTable {
  id: number
}

export default function* SagaWorker_RemoveUserFromUsersTable(
  action: PayloadAction<ISagaWorker_RemoveUserFromUsersTable>,
) {
  const { id } = action.payload
  yield put(Actions_PageLoader.store.showLoader())

  try {
    yield call(async () => await remove(`/users/${id}`))

    //------------------------- Getting and saving users ----------------------//
    const getFilter = (state: TState) => state.users.filter
    const filter: ReturnType<typeof getFilter> = yield select(getFilter)
    const updatedFilter = { ...filter, offset: 0 }

    yield put(Actions_Users.store.changeFilter(updatedFilter))

    const formData: TGetUsersFilters = {
      ...updatedFilter,
      hideStaff: true,
      hideDeleted: true,
    }

    const response: TStoreTemplate<TUsers> = yield call(async () => await get<TUsers>('/users', formData))
    const newUsers = response.data?.users || []
    const responseInfo = response.responseInfo

    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newUsers, updatedFilter)

    yield put(Actions_Users.store.saveUsers({ data: newUsers, responseInfo, isAllDataLoaded }))

    yield put(Actions_Notifications.store.setNotification({ message: 'User deleted successfully', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
