import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import { TPromoCode, TPromoCodeGenerate } from 'redux/modules/promoCode/TReducer'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import TableFilterReduxAdapter, { TTableFilterRedux } from 'utils/helpers/table'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { AxiosRequestConfig } from 'axios'

const { post, get } = RequestMethods()

export interface ISagaWorker_GeneratePromoCode {
  amount?: number
  bonusMinutes?: number
  organizationIds: number[] | []
  trailerTypeIds: number[] | []
  activeUntil?: string
  file?: File
}

export default function* SagaWorker_GeneratePromoCode(action: PayloadAction<ISagaWorker_GeneratePromoCode>) {
  yield put(Actions_PromoCode.store.showLoader())
  const dataFile = {
    organizationIds: action.payload.organizationIds.join(),
    trailerTypeIds: action.payload.trailerTypeIds.join(),
    file: action.payload.file,
  }
  const headers: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  try {
    if (!action.payload.file) {
      yield call(async () => await post<TPromoCodeGenerate>(`/promo_codes/bulk`, action.payload))
    } else {
      yield call(async () => await post<TPromoCodeGenerate>(`/promo_codes/xlsx`, dataFile, headers))
    }
    const getFilter = (state: TState) => state.promoCode.filter
    const filter: ReturnType<typeof getFilter> = yield select(getFilter)
    const updatedFilter = { ...filter, offset: 0 }

    yield put(Actions_PromoCode.store.changeFilter(updatedFilter))

    const formData: TTableFilterRedux = {
      ...updatedFilter,
    }

    const response: TStoreTemplate<TPromoCode[]> = yield call(
      async () => await get<TPromoCode[]>('/promo_codes', formData),
    )
    const newPromoCodeList = response.data || []
    const responseInfo = response.responseInfo

    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newPromoCodeList, updatedFilter)

    yield put(Actions_PromoCode.store.savePromoCode({ data: newPromoCodeList, responseInfo, isAllDataLoaded }))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Promo code successfully created', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PromoCode.store.hideLoader())
  }
}
