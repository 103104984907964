enum StoreActions {
  IS_LOADER_SHOW = 'STAFF/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'STAFF/IS_LOADER_HIDE',
  SAVE_STAFF = 'STAFF/SAVE_STAFF',
  CHANGE_FILTER = 'STAFF/CHANGE_FILTER',
  INCREASE_FILTER_OFFSET = 'STAFF/INCREASE_FILTER_OFFSET',
  RESET = 'STAFF/RESET',
}

enum SagaEvents {
  LOAD_PAGE = 'STAFF/LOAD_PAGE',
  GET_STAFF = 'STAFF/GET_STAFF',
  DELETE_STAFF = 'STAFF/DELETE_STAFF',
}

export const ActionTypes_Staff = {
  SagaEvents,
  StoreActions,
}
