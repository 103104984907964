import { createReducer } from '@reduxjs/toolkit'
import { IStateRoles } from './TReducer'
import { Actions_TemplateRoles } from './Actions'

const initialState: IStateRoles = {
  isLoading: false,
  data: null,
  responseInfo: null,
  selectedRoleId: '',
}

const ReducerTemplateRoles = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_TemplateRoles.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_TemplateRoles.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_TemplateRoles.store.saveRoles, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_TemplateRoles.store.changeSelectedRoleId, (state, action) => {
      state.selectedRoleId = action.payload
    })
    .addCase(Actions_TemplateRoles.store.clearSelectedRoleId, (state) => {
      state.selectedRoleId = ''
    })
})

export default ReducerTemplateRoles
