/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TCustomRoles } from 'redux/modules/customRoles/TReducer'
import { Actions_CustomRoles } from 'redux/modules/customRoles/Actions'

const { get } = RequestMethods()

export default function* SagaWorker_CustomRoles() {
  try {
    yield put(Actions_CustomRoles.store.showLoader())

    const customRoles: TStoreTemplate<TCustomRoles> = yield call(
      async () => await get<TCustomRoles>('/users/roles', { hideTemplateRoles: true }),
    )
    yield put(Actions_CustomRoles.store.saveRoles(customRoles))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CustomRoles.store.hideLoader())
}
