import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { usePromoCodeGenerateFromExcel } from './PromoCodeGenerateFromExcel-ViewModel'

export const PromoCodeGenerateFromExcel: FC = () => {
  const vm = usePromoCodeGenerateFromExcel()
  const {
    closeModal,
    form,
    isLoading,
    t,
    trailerTypeOptions,
    isSelectedTrailerTypes,
    onClickGenerateFromExcel,
    inputFile,
    handleFileChange,
    file,
    deleteFile,
  } = vm
  const { errors, handleSubmit, register, control, watch } = form

  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.MultiOrganization
              type={'form'}
              showTitle={false}
              control={control}
              divider={false}
              hideBorder={false}
              name="organizationIds"
              label={t('organizations')}
              watch={watch}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.Dropdown
              name="trailerTypeIds"
              label={t('trailerTypes')}
              options={trailerTypeOptions}
              marginTop={0}
              fullWidth
              multiple
              control={control}
              isSelected={isSelectedTrailerTypes}
              disabled={isLoading}
            />
          </Grid>
          <Shared.Button
            style={{
              margin: '0.875em 0 0.875em 0',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              justifyContent: file && file.name.length > 18 ? 'left' : 'center',
            }}
            title={file ? file.name : t('Add excel file')}
            onClick={onClickGenerateFromExcel}
          />
          {file && (
            <Shared.Button style={{ margin: '0.875em 0 0.275em 0' }} title={t('Delete file')} onClick={deleteFile} />
          )}

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
            />
            <Shared.Button title={t('generate')} type={'submit'} disabled={file ? false : true} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
          <input type="file" ref={inputFile} hidden onInput={handleFileChange} accept=".xlsx" />
        </Grid>
      </form>
    </Shared.Card>
  )
}
