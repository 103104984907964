import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar, SnackbarKey } from 'notistack'
import { TState } from 'redux/config/root-types'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { Message, SnackbarContent } from './components'

let displayed: SnackbarKey[] = []

export const Notification: FC = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const notifications = useSelector((state: TState) => state.notifications.notifications)

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }

  useEffect(() => {
    notifications.forEach(({ key, type, message, duration = 5000, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return

      const messageComponent = <Message type={type} message={message} />
      const content = (key: SnackbarKey, message: React.ReactNode) => (
        <SnackbarContent id={key} type={type} message={message} />
      )

      // display snackbar using notistack
      enqueueSnackbar(messageComponent, {
        key,
        content,
        autoHideDuration: duration,
        preventDuplicate: true,
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(Actions_Notifications.store.removeNotification(myKey))
          removeDisplayed(myKey)
        },
      })

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return <></>
}

export default Notification
