import { put } from 'redux-saga/effects'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { AuthStorage } from 'utils/helpers/auth'

type THttpStatus = 400 | 401 | 404 | 500

export type TError = {
  response: {
    status: THttpStatus
    data: {
      statusCode: keyof TStatusCode
      error: string //same statusCode message, but we use i18n on frontend, so we should check only code
    }
  }
}
type TStatusCode = typeof SERVER_STATUS_CODE
const UNEXPECTED_ERROR = 'Oops! Something went wrong'

const SERVER_STATUS_CODE: { [key: number]: string } = {
  400: 'Validation Error',
  1001: 'Invalid credentials',
  1003: 'Sms request too often',
  1004: 'Object not found',
  1005: 'Incorrect verification data',
  1006: 'User not found',
  1007: 'User`s password is empty',
  1008: 'Email already used',
  1100: 'User is already customer',
  1101: 'User is already has a card',
  1102: 'User is not a customer',
  1103: 'User does not have a card',
  1104: 'Cannot remove card while rent',
  1105: 'Cannot remove card while booking',
  1106: 'User is already has a sepa',
  1107: 'User has both payment methods',
  1108: 'User has not any payment methods',
  1109: 'User has not preferable payment methods',
  1110: 'User does not have a sepa',
  1111: 'Cannot remove sepa while rent',
  1112: 'Cannot remove sepa while booking',
  1113: 'Cannot set not existed role',
  1114: 'Cannot set not template role',
  1115: 'User does not have role',
  1116: 'Cannot delete account while booking',
  1117: 'Cannot delete account while renting',
  1118: 'This account was deleted',
  1119: 'This action is allowed only for staff',
  1120: 'Your account was deactivated',
  1121: 'Cannot delete favorite payment method',
  1200: 'Payment error',
  1201: 'The card has been declined',
  1202: 'The balance is insufficient',
  1203: 'Card is expired',
  1204: 'Incorrect CVC',
  1205: 'Incorrect card number',
  1206: 'Invalid CVC',
  1207: 'Setup Attempt has failed',
  1300: 'Booking not found',
  1301: 'Rent not found',
  1302: 'Action not allowed',
  1303: 'This station was already booked',
  1304: 'You already have a booking',
  1305: 'You have to book a station before rent',
  1306: 'This booking was already cancelled',
  1307: 'You already have a rent',
  1308: 'You dont have a rent',
  1309: 'You cant rent the empty station',
  1310: 'Station not found',
  1311: 'You can not rent without booking',
  1312: 'You can not finish rent without booking',
  1313: 'You can not finish other rents',
  1314: 'Trailer not found',
  1315: 'Trailer type not found',
  1316: 'Organization not found',
  1317: 'Incorrect status filter',
  1318: 'IMEI already used',
  1319: 'Incorrect trailer type id',
  1320: 'Parking not found',
  1321: 'This station code is not available',
  1322: 'This parking code is not available',
  1323: 'Cannot set unexisted organization',
  1324: 'INCORRECT_RATE_PERIOD',
  1325: 'Trailer subtype not found',
  1326: 'Incorrect trailer subtype id',
  1327: 'Cannot delete a subtype with related trailers',
  1328: 'Cannot delete a type with related trailers',
  1329: 'You have to book only empty station',
  1330: 'You have to book station with trailer',
  1331: 'Rent start in progress',
  1332: 'Rent finish in progress',
  1333: 'Rent station not found',
  1334: 'Incorrect finish station',
  1335: 'Incorrect booking',
  1336: 'IMEI is empty',
  1337: 'You have to book free trailer',
  1338: 'You do not have a booking',
  1339: 'You cannot cancel booking',
  1340: 'You have to book a station before rent',
  1341: 'Rent is already completed',
  1342: 'This booking was already stopped',
  1343: 'Command not found',
  1344: 'Cannot delete parking with stations',
  1345: 'Rent not in start progress',
  1346: 'Rent not in finish progress',
  1347: 'This tax number is not available',
  1348: 'Organization type not found',
  1350: 'This trailer is on another station',
  1401: 'File is not attached',
  1402: 'File upload failed',
  1501: 'Xlsx create error',
  1601: 'This promo code is not available',
  1602: 'You already has an active promo code',
  1603: 'Promo code not found',
  1604: 'Promo code expired',
  1605: 'You already user this promo code',
  1606: 'Do not have active promo code',
  1607: 'Your promo code had expired',
  1608: 'Can not rent with expired promo code',
  1609: 'Cannot create promocode in the past',
  1610: 'These promocodes already exist',
  1700: 'Position not found',
  1800: 'Invoice not found',
  2000: 'Connection error',
  2001: 'Lock stuck error',
  2002: 'Wrong station error',
  2003: 'Booking cancelled',
  2004: 'No stations connected error',
  2100: 'Trailer issue report not found',
}

const HTTP_STATUS_ERRORS: { [key: number]: typeof SERVER_STATUS_CODE } = {
  400: SERVER_STATUS_CODE,
}

export function* sagaAction_Error(newError: unknown) {
  const error = newError as TError // TODO: fix the error check
  const httpStatus = error.response.status

  checkUnauthorized(httpStatus)

  const message = getValidationMessage(error, httpStatus)

  yield put(Actions_Notifications.store.setNotification({ message, type: 'Error', duration: 3000 }))
}

function checkUnauthorized(httpStatus: THttpStatus) {
  if (httpStatus === 401) {
    AuthStorage.signOut()
  }
}

function getValidationMessage(error: TError, httpStatus: THttpStatus) {
  const serverStatusesErrors = HTTP_STATUS_ERRORS[httpStatus]

  let message: string = UNEXPECTED_ERROR

  if (serverStatusesErrors) {
    const statusCode = error.response.data.statusCode
    message = serverStatusesErrors[statusCode]
  }
  return message
}
