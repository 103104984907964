import { call, put } from 'redux-saga/effects'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import SagaWorker_CustomRoles from 'redux/sagas/events/SagaWorker_CustomRoles'
import SagaWorker_GetParentOrganizations from '../events/parentOrganization/SagaWorker_GetParentOrganizations'
import SagaWorker_GetRegions from '../events/regions/SagaWorker_GetRegions'

export default function* SagaPage_Staff() {
  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting and saving custom roles ----------------------//
    yield call(SagaWorker_CustomRoles)
    //------------------------- Getting and saving parent organizations ----------------------//
    yield call(SagaWorker_GetParentOrganizations)
    //------------------------- Getting and saving regions ----------------------//
    yield call(SagaWorker_GetRegions)
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
