import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { ISagaWorker_CreateStation } from 'redux/sagas/events/station/SagaWorker_CreateStation'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { Actions_CurrentStation } from 'redux/modules/currentStation/Actions'
import { TCurrentStation } from 'redux/modules/currentStation/TReducer'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { TStoreTemplate } from 'redux/config/root-types'
import { TCurrentParking } from 'redux/modules/currentParking/TReducer'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'
import { Actions_TrailerStatuses } from 'redux/modules/trailerStatuses/Actions'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'

const { post, get } = RequestMethods()

export interface ISagaWorker_CreateStationAndGetCurrentParking {
  id: number
  data: ISagaWorker_CreateStation
}

export default function* SagaWorker_CreateStationAndGetCurrentParking(
  action: PayloadAction<ISagaWorker_CreateStationAndGetCurrentParking>,
) {
  try {
    yield put(Actions_CurrentStation.store.showLoader())

    const { id, data } = action.payload

    const newStationData = {
      ...data,
      parkingId: +data.parkingId,
    }
    //------------------------- Create station ---------------------------//
    yield call(async () => await post<TCurrentStation>('/stations', newStationData))

    yield put(Actions_Modals.store.hideModal())
    yield put(Actions_Notifications.store.setNotification({ message: 'Station successfully created', type: 'Success' }))

    //------------------------- Getting and saving parking ---------------------------//
    yield put(Actions_PageLoader.store.showLoader())
    const parkingResponse: TStoreTemplate<TCurrentParking> = yield call(
      async () => await get<TCurrentParking>(`/parking/${id}`),
    )
    yield put(Actions_CurrentParking.store.saveCurrentParking(parkingResponse))
    const parkingInfo = parkingResponse.data?.parking

    if (parkingInfo) {
      const { id, latitude, longitude } = parkingInfo
      yield put(Actions_CurrentParking.store.changeSelectedParkingInfo({ id, lat: latitude, lng: longitude }))
    }

    //------------------------- Getting and saving trailer statuses -------------------//
    let status: number[] = []

    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await get<TTrailerStatuses>('/trailers/statuses'),
    )
    yield put(Actions_TrailerStatuses.store.saveTrailerStatuses(t_statuses))
    if (t_statuses.data?.statuses) {
      const statusesWithoutFree = t_statuses.data.statuses.filter((t_statuse) => t_statuse.name !== 'free')
      status = statusesWithoutFree.map((t_statuse) => t_statuse.id)
    }
    //------------------------- Getting and saving trailers ---------------------------//
    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () =>
        await get<TTrailers>('/trailers/list', {
          status,
        }),
    )
    yield put(Actions_Trailers.store.saveTrailers(trailerResponse))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentStation.store.hideLoader())
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
