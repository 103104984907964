import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { IOrganization } from 'redux/modules/organizations/TReducer'
import { ActionTypes_CurrentOrganization } from './TAction'
import { ISagaWorker_ActivateOrganization } from 'redux/sagas/events/organization/SagaWorker_ActivateOrganization'
import { ISagaWorker_CreateOrganization } from 'redux/sagas/events/organization/SagaWorker_CreateOrganization'
import { ISagaWorker_DeactivateOrganization } from 'redux/sagas/events/organization/SagaWorker_DeactivateOrganization'
import { ISagaWorker_UpdateOrganization } from 'redux/sagas/events/organization/SagaWorker_UpdateOrganization'
import { ISagaPage_OrganizationDetails } from 'redux/sagas/pages/SagaPage_OrganizationDetails'
import { ISagaWorker_AddParkingToOrganization } from 'redux/sagas/events/organization/SagaWorker_AddParkingToOrganization'
import { ISagaWorker_RemoveParkingFromOrganization } from 'redux/sagas/events/organization/SagaWorker_RemoveParkingFromOrganization'
import { ISagaWorker_AddTrailerToOrganization } from 'redux/sagas/events/organization/SagaWorker_AddTrailerToOrganization'
import { ISagaWorker_RemoveTrailerFromOrganization } from 'redux/sagas/events/organization/SagaWorker_RemoveTrailerFromOrganization'
import { ISagaWorker_CreateOrganizationInvitationCodes } from 'redux/sagas/events/organization/SagaWorker_CreateOrganizationInvitationCodes'
import { ISagaWorker_GetOrganizationInvitationCodesXlsx } from 'redux/sagas/events/organization/SagaWorker_GetOrganizationInvitationCodesXlsx'

const { StoreActions, SagaEvents } = ActionTypes_CurrentOrganization

export const Actions_CurrentOrganization = {
  saga: {
    loadPage: createAction<ISagaPage_OrganizationDetails>(SagaEvents.LOAD_PAGE),
    createOrganization: createAction<ISagaWorker_CreateOrganization>(SagaEvents.CREATE_ORGANIZATION),
    updateOrganization: createAction<ISagaWorker_UpdateOrganization>(SagaEvents.UPDATE_ORGANIZATION),
    activateOrganization: createAction<ISagaWorker_ActivateOrganization>(SagaEvents.ACTIVATE_ORGANIZATION),
    deactivateOrganization: createAction<ISagaWorker_DeactivateOrganization>(SagaEvents.DEACTIVATE_ORGANIZATION),
    addParkingToOrganization: createAction<ISagaWorker_AddParkingToOrganization>(
      SagaEvents.ADD_PARKING_TO_ORGANIZATION,
    ),
    createInvitationCodes: createAction<ISagaWorker_CreateOrganizationInvitationCodes>(
      SagaEvents.CREATE_ORGANIZATION_INVITATION_CODES,
    ),
    addTrailerToOrganization: createAction<ISagaWorker_AddTrailerToOrganization>(
      SagaEvents.ADD_TRAILER_TO_ORGANIZATION,
    ),
    removeParkingFromOrganization: createAction<ISagaWorker_RemoveParkingFromOrganization>(
      SagaEvents.REMOVE_PARKING_FROM_ORGANIZATION,
    ),
    removeTrailerFromOrganization: createAction<ISagaWorker_RemoveTrailerFromOrganization>(
      SagaEvents.REMOVE_TRAILER_FROM_ORGANIZATION,
    ),
    loadInvitationCodesXlsx: createAction<ISagaWorker_GetOrganizationInvitationCodesXlsx>(
      SagaEvents.LOAD_ORGANIZATION_INVITATION_CODES_XLSX,
    ),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveCurrentOrganization: createAction<TStoreTemplate<IOrganization>>(StoreActions.SAVE_CURRENT_ORGANIZATION),
    clearCurrentOrganization: createAction(StoreActions.CLEAR_CURRENT_ORGANIZATION),
  },
}
