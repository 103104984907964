enum StoreActions {
  IS_LOADER_SHOW = 'PARKINGS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'PARKINGS/IS_LOADER_HIDE',
  SAVE_PARKINGS = 'PARKINGS/SAVE_PARKINGS',
  SAVE_ALL_PARKINGS = 'PARKINGS/SAVE_ALL_PARKINGS',
  CHANGE_OCCUPANCY_FILTER = 'PARKINGS/CHANGE_OCCUPANCY_FILTER',
  CHANGE_FILTER = 'PARKINGS/CHANGE_FILTER',
  CREATE_PARKINGS = 'PARKINGS/CREATE_PARKINGS',
  UPDATE_PARKINGS = 'PARKINGS/UPDATE_PARKINGS',
  CHANGE_IS_CREATE_PARKING = 'PARKINGS/CHANGE_IS_CREATE_PARKING',
  REMOVE_PARKING = 'PARKINGS/REMOVE_PARKING',
  REMOVE_PARKING_FROM_ALL_PARKINGS = 'PARKINGS/REMOVE_PARKING_FROM_ALL_PARKINGS',
  CLEAR_PARKINGS = 'PARKINGS/CLEAR_PARKINGS',
  INCREASE_FILTER_OFFSET = 'PARKINGS/INCREASE_FILTER_OFFSET',
  CHANGE_START_PAGE = 'PARKINGS/CHANGE_START_PAGE',
}

enum SagaEvents {
  GET_PARKINGS = 'PARKINGS/GET_PARKINGS',
  CREATE_STATION_AND_GET_PARKINGS = 'PARKINGS/CREATE_STATION_AND_GET_PARKINGS',
  GET_ALL_PARKINGS = 'PARKINGS/GET_ALL_PARKINGS',
  LOAD_PAGE = 'PARKINGS/LOAD_PAGE',
  DELETE_PARKING = 'PARKINGS/DELETE_PARKING',
  RESET_PARKING = 'PARKINGS/RESET',
}

export const ActionTypes_Parkings = {
  SagaEvents,
  StoreActions,
}
