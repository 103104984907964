import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Rents } from './TActions'
import { TChecklistParameters, TInsurance, TRents, TRentsFilter } from './TReducer'
import { TStoreTemplateWithFilter } from 'redux/config/root-types'
import { TSagaWorker_GetChecklistsParameters } from 'redux/sagas/events/SagaWorker_GetChecklistsParameters'

const { StoreActions, SagaEvents } = ActionTypes_Rents

export const Actions_Rents = {
  saga: {
    getRents: createAction(SagaEvents.GET_RENTS),
    loadXlsx: createAction(SagaEvents.LOAD_XLSX),
    getInsurance: createAction(SagaEvents.GET_INSURANCE),
    getChecklistsParameters: createAction<TSagaWorker_GetChecklistsParameters>(SagaEvents.GET_CHECKLISTS_PARAMETERS),
  },
  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveRents: createAction<TStoreTemplateWithFilter<TRents> & { trailerId?: string; userId?: string }>(
      StoreActions.SAVE_RENTS,
    ),
    changeFilter: createAction<TRentsFilter>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TRentsFilter>(StoreActions.INCREASE_FILTER_OFFSET),
    resetFilter: createAction(StoreActions.RESET_FILTER),
    saveInsurances: createAction<TInsurance[] | null>(StoreActions.SAVE_INSURANCES),
    saveChecklistsParameters: createAction<TChecklistParameters[]>(StoreActions.SAVE_CHECKLISTS_PARAMETERS),
  },
}
