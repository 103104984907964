import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { makeStyles } from 'tss-react/mui'
import { TRangeCalendarProps } from './RangeCalendarModal'

export const DAY_WIDTH = 50

export const useRangeCalendarModal = ({
  initialStartDate,
  initialEndDate,
  onDatesChange,
  onClose,
}: TRangeCalendarProps) => {
  const { t } = useTranslation(I18nModal.rangeCalendar)
  const { classes, cx } = useStyles()

  const [startDate, setStartDate] = useState<Date | null | undefined>(initialStartDate)
  const [endDate, setEndDate] = useState<Date | null | undefined>(initialEndDate)

  const onChange = (dates: (Date | null)[]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const onSubmit = () => {
    onDatesChange(startDate, endDate)
  }

  const closeModal = () => {
    onClose()
  }

  return {
    t,
    classes,
    cx,
    closeModal,
    onSubmit,
    startDate,
    endDate,
    onChange,
  }
}

const useStyles = makeStyles()((theme) => ({
  card: {
    width: 400,
    overflowY: 'auto',
    maxHeight: '100%',
    padding: 24,

    '&& .react-datepicker': {
      border: 'none',
      borderRadius: 0,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
  },
  listItemText: {
    // ...theme.typography.body1, TODO:
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  cancel: {
    margin: '0.875em 0',
  },

  datePicker: {},
  calendar: {
    '&& .react-datepicker__month': {
      margin: 0,
    },

    '&& .react-datepicker__header': {
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      padding: 0,
    },

    '&& .react-datepicker__day-names': {
      display: 'none',
    },

    '&& .react-datepicker__day': {
      borderRadius: 0,
      width: DAY_WIDTH,
      margin: 0,
      color: theme.palette.text.primary,
      lineHeight: '38px',
    },
  },
  day: {
    '&&:hover': {
      borderRadius: 0,
      background: '#e4e7e7',
    },

    '&&:focus-visible': {
      outlineColor: theme.palette.text.secondary,
      outlineWidth: 2,
      outlineStyle: 'solid',
      borderRadius: '0',
    },

    '&&.react-datepicker__day--in-selecting-range': {
      background: '#fff2dc',
    },

    '&&.react-datepicker__day--selected': {
      background: theme.palette.primary.main,
    },

    '&&.react-datepicker__day--in-range': {
      background: '#fff2dc',

      '&&:hover': {
        background: theme.palette.primary.main,
      },
    },
  },

  edgeSelectedDays: {
    '&&.react-datepicker__day--in-range': {
      background: theme.palette.primary.main,
    },
  },
}))
