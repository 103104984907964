import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_ParkingNotes } from './TActions'
import { TParkingNote } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_GetParkingNotes } from 'redux/sagas/events/notes/SagaWorker_GetParkingNotes'
import { ISagaWorker_CreateParkingNote } from 'redux/sagas/events/notes/SagaWorker_CreateParkingNote'
import { ISagaWorker_DeleteParkingNote } from 'redux/sagas/events/notes/SagaWorker_DeleteParkingNote'

const { SagaEvents, StoreActions } = ActionTypes_ParkingNotes

export const Actions_ParkingNotes = {
  saga: {
    getParkingNotes: createAction<ISagaWorker_GetParkingNotes>(SagaEvents.GET_PARKING_NOTES),
    createParkingNote: createAction<ISagaWorker_CreateParkingNote>(SagaEvents.CREATE_PARKING_NOTE),
    deleteParkingNote: createAction<ISagaWorker_DeleteParkingNote>(SagaEvents.DELETE_PARKING_NOTE),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveParkingNotes: createAction<TStoreTemplate<TParkingNote[]>>(StoreActions.SAVE_PARKING_NOTES),
    reset: createAction(StoreActions.RESET),
  },
}
