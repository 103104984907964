import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { Actions_Staff } from 'redux/modules/staff/Actions'
import { TStaff, TStaffFilter } from 'redux/modules/staff/TReducer'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import TableFilterReduxAdapter from 'utils/helpers/table'

const { remove, get } = RequestMethods()

type TGetStaffFilters = {
  hideCustomers: boolean
  hideDeleted: boolean
} & TStaffFilter

export interface ISagaWorker_RemoveStaffFromStaffTable {
  id: number
}

export default function* SagaWorker_RemoveStaffFromStaffTable(
  action: PayloadAction<ISagaWorker_RemoveStaffFromStaffTable>,
) {
  const { id } = action.payload
  yield put(Actions_Staff.store.showLoader())

  try {
    yield call(async () => await remove(`/users/${id}`))

    //------------------------- Getting and saving staff ----------------------//
    const getFilter = (state: TState) => state.staff.filter
    const filter: ReturnType<typeof getFilter> = yield select(getFilter)
    const updatedFilter = { ...filter, offset: 0 }

    yield put(Actions_Staff.store.changeFilter(updatedFilter))

    const formData: TGetStaffFilters = {
      ...updatedFilter,
      hideCustomers: true,
      hideDeleted: true,
    }

    const response: TStoreTemplate<TStaff> = yield call(async () => await get<TStaff>('/users', formData))
    const newStaff = response.data?.users || []
    const responseInfo = response.responseInfo

    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newStaff, updatedFilter)

    yield put(Actions_Staff.store.saveStaff({ data: newStaff, responseInfo, isAllDataLoaded }))

    yield put(Actions_Notifications.store.setNotification({ message: 'Staff deleted successfully', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Staff.store.hideLoader())
  }
}
