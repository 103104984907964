enum StoreActions {
  IS_LOADER_SHOW = 'SEARCH/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'SEARCH/IS_LOADER_HIDE',
  SAVE_SEARCH_TERM = 'SEARCH/SAVE_SEARCH_TERM',
  CLEAR_SEARCH_TERM = 'SEARCH/CLEAR_SEARCH_TERM',
  SAVE_SEARCH_RESULT = 'SEARCH/SAVE_SEARCH_RESULT',
  CLEAR_SEARCH_RESULT = 'SEARCH/CLEAR_SEARCH_RESULT',
}

enum SagaEvents {
  GET_SEARCH_RESULT = 'SEARCH/GET_SEARCH_RESULT',
}

export const ActionTypes_Search = {
  StoreActions,
  SagaEvents,
}
