import { useEffect } from 'react'
import { Channel } from 'pusher-js'

export type TPusherChannelEvent =
  | 'TRAILERS_UPDATE'
  | 'STATION_LOCK_UPDATE'
  | 'RENT_EVENT'
  | 'STATION_OFFLINE'
  | 'TRAILER_ISSUE_REPORT'
  | 'DEVICE_PROBLEMS'

export const useEvent = (
  channel: Channel | undefined,
  eventName: TPusherChannelEvent,
  callback: (data?: any, metadata?: { user_id: string }) => void,
) => {
  useEffect(() => {
    if (channel === undefined) {
      return
    } else channel.bind(eventName, callback)
    return () => {
      channel.unbind(eventName, callback)
    }
  }, [channel, eventName, callback])

  return channel
}
