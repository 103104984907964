export enum I18nPage {
  auth = 'auth',
  trailerTypes = 'trailerTypes',
  trailersList = 'trailersList',
  trailerDetails = 'trailerDetails',
  parking = 'parking',
  parkingDetails = 'parkingDetails',
  staff = 'staff',
  staffDetails = 'staffDetails',
  changePassword = 'changePassword',
  users = 'users',
  userDetails = 'userDetails',
  organizations = 'organizations',
  organizationDetails = 'organizationDetails',
  promoCode = 'promoCode',
  inspection = 'inspection',
  reports = 'reports',
  parentOrganizations = 'parentOrganizations',
}
