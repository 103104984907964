import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import DateConverter from 'utils/helpers/date'
import { useForm } from 'react-hook-form'
import { Form } from 'utils/helpers/form'
import { TUserToOrganizationDiscount } from 'redux/modules/users/TReducer'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { IOrganization } from 'redux/modules/organizations/TReducer'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'

type TModalProps = {
  id: number
}

interface IForm {
  organization: string
  activeUntil: Date | null | undefined
}

const formValidation = {
  organization: Form.combine(Form.required),
  activeUntil: Form.combine(Form.required, Form.isSameOrAfter()),
}

export const useUserToOrganizationDiscountCreate = () => {
  const { t } = useTranslation(I18nModal.UserToOrganizationDiscountCreate)
  const dispatch = useDispatch()

  const userId = useSelector(({ modals }: TState) => (modals.data as TModalProps).id)
  const isLoading = useSelector(({ promoCode }: TState) => promoCode.isLoading)
  const organizations = useSelector(({ organizations }: TState) => organizations?.data?.organizations || [])

  const { handleSubmit, errors, control } = useForm<IForm>({
    defaultValues: {
      organization: '',
      activeUntil: null,
    },
  })

  const onSubmit = (formData: IForm) => {
    const data = {
      organizationId: Number(formData.organization),
      endDate: DateConverter.toUtcEndOfDay(formData.activeUntil),
    }
    dispatch(Actions_CurrentUser.saga.connectToOrganizationDiscount({ userId, data }))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const options = useMemo(() => toDropdownOption(organizations), [organizations])

  return {
    t,
    closeModal,
    isLoading,
    options,
    form: {
      formValidation,
      errors,
      control,
      handleSubmit: handleSubmit(onSubmit),
    },
  }
}

const toDropdownOption = (organizations: IOrganization[]): TDropdownOption[] =>
  organizations.map((organization) => ({
    id: organization.id,
    label: organization.name,
    value: organization.id,
  }))
