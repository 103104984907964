import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { Actions_report } from 'redux/modules/report/Actions'
import DateConverter from 'utils/helpers/date'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { TDetailedReport, TReportFilter } from 'redux/modules/report/TReducer'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { get } = RequestMethods()

export default function* SagaWorker_GetDetailedReportTotalCost({ payload }: PayloadAction<TReportFilter>) {
  yield put(Actions_report.store.showLoader())

  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds
  const getFilter = (state: TState) => state.report.filter
  const getInvoicesFilter = (state: TState) => state.report.invoicesFilter

  //------------------------- Getting request params ---------------------------//
  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)
  const invoicesFilter: ReturnType<typeof getInvoicesFilter> = yield select(getInvoicesFilter)

  const formData = {
    organization,
    invoiceNumber: invoicesFilter.invoiceNumber.length > 0 ? invoicesFilter.invoiceNumber : undefined,
    fromDate: filter.fromDate ? DateConverter.toISOString(DateConverter.toStartOfDay(filter.fromDate)) : undefined,
    toDate: filter.toDate ? DateConverter.toISOString(DateConverter.toEndOfDay(filter.toDate)) : undefined,
    startParking: invoicesFilter.startParkings,
    finishParking: invoicesFilter.finishParkings,
  }

  try {
    //------------------------- Getting report ---------------------------//
    const result: TStoreTemplate<{ totalCost: number }> = yield call(
      async () => await get<TStoreTemplate<TDetailedReport>>('/trailers/report/details/totalCost', formData),
    )

    yield put(Actions_report.store.saveDetailedReportRentsTotalCost(result.data!.totalCost))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_report.store.hideLoader())
}
