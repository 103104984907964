import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { SagaWorker_GetParkingNotes } from 'redux/sagas'
import { TParkingNote } from 'redux/modules/parkingNotes/TReducer'
import { Actions_ParkingNotes } from 'redux/modules/parkingNotes/Actions'

const { post } = RequestMethods()

export interface ISagaWorker_CreateParkingNote {
  parkingId: number
  text: string
}

export default function* SagaWorker_CreateParkingNote(action: PayloadAction<ISagaWorker_CreateParkingNote>) {
  try {
    yield put(Actions_ParkingNotes.store.showLoader())

    const { parkingId, text } = action.payload

    const data = {
      type: 'parking',
      entityId: parkingId,
      text,
    }

    yield call(async () => await post<TParkingNote>(`/notes`, data))

    yield call(SagaWorker_GetParkingNotes, action)

    yield put(Actions_Notifications.store.setNotification({ message: 'Note successfully added', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_ParkingNotes.store.hideLoader())
  }
}
