import React, { FC } from 'react'
import { Shared } from 'ui/shared/index'
import { useOrganization } from 'ui/shared/Organization/Organization-ViewModel'
import { TObject } from 'utils/types'

interface IProps {
  /**
   * required if you store ids through  redux or useState
   */
  onClick?: (value: number) => void
  selectedId?: number

  /**
   * required if selected ids stored in react-form-hook
   */
  control?: any
  /**
   * required if selected ids stored in react-form-hook
   */
  error?: null | string
  name?: string
  rules?: any

  /**
   * load all options again, because someone can add new one, we should reload everytime
   * default value true
   */
  loadOptions?: boolean

  className?: string
  divider?: boolean
  hideBorder?: boolean
  style?: TObject
  label?: string
  showTitle?: boolean
  type?: 'form' | 'card'

  title?: string
  disabled?: boolean
}

/**
 react-form-hook example
 <Shared.Organization
  control={control}
  watch={watch}
 />

 state example
 <Shared.Organization
  onClick={onClick}
  isSelected={isSelected}
 />
 */
const Organization: FC<IProps> = ({
  className,
  onClick,
  style,
  hideBorder = true,
  divider = true,
  showTitle = true,
  type = 'card',
  label,
  control,
  selectedId,
  error,
  rules,
  name = 'organizationId',
  title = 'Organization',
  loadOptions,
  disabled,
}) => {
  const vm = useOrganization(loadOptions)

  return (
    <div className={className} style={style}>
      {showTitle && <Shared.CardTitle title={title} divider={divider} />}
      <Shared.Dropdown
        marginTop={type === 'card' ? 16 : '1.5em'}
        control={control}
        name={name}
        options={vm.options}
        onChange={onClick}
        fullWidth
        hideBorder={hideBorder}
        label={label}
        value={selectedId}
        error={error}
        rules={rules}
        disabled={disabled}
      />
    </div>
  )
}

export default React.memo(Organization)
