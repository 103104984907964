import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { TCurrentTrailer } from 'redux/modules/currentTrailer/TReducer'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'
import { Actions_Rents } from 'redux/modules/rents/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'
import { Actions_TrailerStatuses } from 'redux/modules/trailerStatuses/Actions'

const { get } = RequestMethods()

export interface ISagaWorker_TrailerDetails {
  id: string
}

export default function* SagaPage_TrailerDetails(action: PayloadAction<ISagaWorker_TrailerDetails>) {
  const { id } = action.payload

  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting and saving trailer types ----------------------//

    const result: TStoreTemplate<TTrailerTypes> = yield call(async () => await get<TTrailerTypes>('/trailer_types'))
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(result))

    //------------------------- Getting and saving trailer statuses -------------------//

    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await get<TTrailerStatuses>('/trailers/statuses'),
    )
    yield put(Actions_TrailerStatuses.store.saveTrailerStatuses(t_statuses))

    //------------------------- Getting and saving trailer ---------------------------//
    const trailerResponse: TStoreTemplate<TCurrentTrailer> = yield call(
      async () => await get<TCurrentTrailer>(`/trailers/${id}`),
    )
    yield put(Actions_CurrentTrailer.store.saveCurrentTrailer(trailerResponse))

    //------------------------- Change rents filter ---------------------------//
    const getFilter = (state: TState) => state.rents.filter
    const rentsFilter: ReturnType<typeof getFilter> = yield select(getFilter)

    yield put(
      Actions_Rents.store.changeFilter({
        ...rentsFilter,
        trailerId: id,
        userId: undefined,
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
