import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import SagaPage_Parkings from 'redux/sagas/pages/SagaPage_Parkings'
import { ISagaWorker_CreateStation } from 'redux/sagas/events/station/SagaWorker_CreateStation'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { Actions_CurrentStation } from 'redux/modules/currentStation/Actions'
import { TCurrentStation } from 'redux/modules/currentStation/TReducer'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { post } = RequestMethods()

export default function* SagaWorker_CreateStationAndGetParkings(action: PayloadAction<ISagaWorker_CreateStation>) {
  yield put(Actions_CurrentStation.store.showLoader())

  try {
    const data = {
      ...action.payload,
      parkingId: +action.payload.parkingId,
    }
    //------------------------- Create station ---------------------------//
    yield call(async () => await post<TCurrentStation>('/stations', data))

    yield put(Actions_Modals.store.hideModal())
    yield put(Actions_Notifications.store.setNotification({ message: 'Station successfully created', type: 'Success' }))
    //------------------------- Update stations and parkings ---------------------------//
    yield call(SagaPage_Parkings)
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentStation.store.hideLoader())
  }
}
