import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Redirect } from './TActions'
import { TRedirect } from './TReducer'

const { StoreActions } = ActionTypes_Redirect

export const Actions_Redirect = {
  store: {
    redirectTo: createAction<TRedirect>(StoreActions.REDIRECT_TO),
  },
}
