enum StoreActions {
  IS_LOADER_SHOW = 'PROMO_CODE/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'PROMO_CODE/IS_LOADER_HIDE',
  SAVE_PROMO_CODE = 'PROMO_CODE/SAVE_PROMO_CODE',
  CHANGE_FILTER = 'PROMO_CODE/CHANGE_FILTER',
  INCREASE_FILTER_OFFSET = 'PROMO_CODE/INCREASE_FILTER_OFFSET',
  EDIT_PROMO_CODE = 'PROMO_CODE/EDIT_PROMO_CODE',
  RESET = 'PROMO_CODE/RESET',
  SET_SELECTED_IDS = 'PROMO_CODE/SET_SELECTED_IDS',
  CLEAR_SELECTED_IDS = 'PROMO_CODE/CLEAR_SELECTED_IDS',
  SET_DATE_FILTER = 'PROMO_CODE/SET_DATE_FILTER',
  SET_BONUS_MINUTES_FILTER = 'PROMO_CODE/SET_BONUS_MINUTES_FILTER',
  SET_PROMO_CODE_NAME_FILTER = 'PROMO_CODE/SET_PROMO_CODE_NAME_FILTER',
  SELECT_TRAILER_TYPES_IDS = 'PROMO_CODE/SELECT_TRAILER_TYPES_IDS',
  SELECT_ORGANIZATION_IDS = 'PROMO_CODE/SELECT_ORGANIZATION_IDS',
}

enum SagaEvents {
  GET_PROMO_CODE = 'PROMO_CODE/GET_PROMO_CODE',
  CREATE_PROMO_CODE = 'PROMO_CODE/CREATE_PROMO_CODE',
  UPDATE_PROMO_CODE = 'PROMO_CODE/UPDATE_PROMO_CODE',
  DELETE_PROMO_CODE = 'PROMO_CODE/DELETE_PROMO_CODE',
  GENERATE_PROMO_CODE = 'PROMO_CODE/GENERATE_PROMO_CODE',
}

export const ActionTypes_PromoCode = {
  SagaEvents,
  StoreActions,
}
