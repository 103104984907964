import React, { useState, FC } from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import NoteSendButton from '../NoteSendButton/NoteSendButton'
import NoteInput from '../NoteInput/NoteInput'
import Box from '@mui/material/Box'

type TPorps = {
  sendNote: (text: string) => void
  sx?: SxProps<Theme>
}

const NoteInputGroup: FC<TPorps> = ({ sendNote, sx }) => {
  const [content, setContent] = useState('')

  const onChangeContent = (value: string) => {
    setContent(value)
  }

  const onSend = () => {
    if (!content) return

    sendNote(content)
    setContent('')
  }

  return (
    <Box sx={[containerStyle, ...(Array.isArray(sx) ? sx : [sx])]}>
      <NoteInput onChangeNote={onChangeContent} onSend={onSend} note={content} />

      <NoteSendButton onSendClick={onSend} sx={sendButtonStyle} />
    </Box>
  )
}

export default NoteInputGroup

const containerStyle = {
  display: 'flex',
}

const sendButtonStyle = {
  marginLeft: 1,
  alignSelf: 'flex-end',
  marginBottom: '2.5px',

  '@media (min-width: 1920px)': {
    marginBottom: '4px',
  },
}
