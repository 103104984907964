import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_CurrentTrailerBreakdowns } from './TActions'
import { TCurrentTrailerBreakdown } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_UpdateCurrentTrailerBreakdown } from 'redux/sagas/events/breakdowns/SagaWorker_UpdateCurrentTrailerBreakdown'
import { ISagaWorker_GetCurrentTrailerBreakdowns } from 'redux/sagas/events/breakdowns/SagaWorker_GetCurrentTrailerBreakdowns'

const { StoreActions, SagaEvents } = ActionTypes_CurrentTrailerBreakdowns

export const Actions_CurrentTrailerBreakdowns = {
  saga: {
    getBreakdowns: createAction<ISagaWorker_GetCurrentTrailerBreakdowns>(SagaEvents.GET_CURRENT_TRAILER_BREAKDOWNS),
    loadXlsx: createAction(SagaEvents.LOAD_XLSX),
    updateBrekdown: createAction<ISagaWorker_UpdateCurrentTrailerBreakdown>(
      SagaEvents.UPDATE_CURRENT_TRAILER_BREAKDOWN,
    ),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveBreakdowns: createAction<TStoreTemplate<TCurrentTrailerBreakdown[]>>(
      StoreActions.SAVE_CURRENT_TRAILER_BREAKDOWNS,
    ),
    clearBreakdowns: createAction(StoreActions.CLEAR_CURRENT_TRAILER_BREAKDOWNS),
    editBreakdown: createAction<TStoreTemplate<TCurrentTrailerBreakdown>>(StoreActions.EDIT_CURRENT_TRAILER_BREAKDOWN),
  },
}
