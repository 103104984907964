import React, { FC } from 'react'
import Typography from '@mui/material/Typography'
import { Divider } from '../../Divider/Divider'
import { Shared } from 'ui/shared/index'

interface IProps {
  title: string
  marginBottom?: number | 'string'
  marginTop?: number | 'string'
  alignItems?: string
  divider?: boolean
  noWrap?: boolean
  suffixIcon?: {
    icon: React.FC
    onClick: () => void
  }
}

export const CardTitle: FC<IProps> = ({
  title,
  divider,
  marginBottom,
  marginTop,
  noWrap = false,
  alignItems = 'baseline',
  suffixIcon,
}) => {
  return (
    <div style={{ marginBottom, marginTop }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems }}>
        <Typography title={title} variant="h4" color="primary" noWrap={noWrap}>
          {title}
        </Typography>
        {suffixIcon && <Shared.IconButton size={'small'} icon={suffixIcon.icon} onClick={suffixIcon.onClick} />}
      </div>
      {divider && <Divider />}
    </div>
  )
}
