import humanizeDuration, { Options } from 'humanize-duration'

import { isValid as _isValid, addDays as _addDays, isToday as _isToday } from 'date-fns'
import isSameDay from 'date-fns/isSameDay'
import format from 'date-fns/format'
import startOfWeek from 'date-fns/startOfWeek'
import isBefore from 'date-fns/isBefore'
import formatISO from 'date-fns/formatISO'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import getDate from 'date-fns/getDate'
import setISODay from 'date-fns/setISODay'
import differenceInMinutes from 'date-fns/differenceInMinutes'

const humanizer = humanizeDuration.humanizer()
humanizer.languages.shortEn = {
  d: () => 'd',
  h: () => 'h',
  m: () => 'min',
}

const DATE_TYPE_FORMAT = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  // @ts-ignore
  hourCycle: 'h23',
})

export default class DateConverter {
  private static humanizeDurationOptions: Options = {
    delimiter: ' ',
    units: ['d', 'h', 'm'],
    language: 'shortEn',
    spacer: '',
    round: true,
  }

  static compareDates(first: string, second: string): number {
    if (!first || !second) {
      return -1
    }
    const a = new Date(first)
    const b = new Date(second)
    return a.getTime() - b.getTime()
  }

  /**
   *  formatDate('2020-07-13T06:30:59.576Z'); //'22 May 2020 18:30'
   * @param dateString
   */
  static formatDate = (dateString: string): string => {
    if (!dateString) {
      return ''
    }
    const date = new Date(dateString)
    const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }] =
      DATE_TYPE_FORMAT.formatToParts(date)
    return `${day} ${month} ${year} ${hour}:${minute}`
  }

  /**
   *  formatDateUTCWithoutTime('2023-03-08T23:59:59.999Z'); //'08 Mar 2023'
   * @param dateString
   */
  static formatDateUTCWithoutTime = (dateString: string): string => {
    if (!dateString) {
      return ''
    }

    const date = new Date(dateString)

    if (!DateConverter.isValid(date)) return ''

    const utcYear = date.getUTCFullYear()
    const utcMonth = date.getUTCMonth()
    const utcDay = date.getUTCDate()
    const localStartOfDay = new Date(utcYear, utcMonth, utcDay)

    const utcDateStringWithoutTime: string = format(localStartOfDay, 'dd MMM yyyy')
    return utcDateStringWithoutTime
  }
  /**
   * getDiffInMinutes('2020-07-13T06:30:59.576Z', ''2020-07-13T06:30:59.576Z'); //123456
   * @param futureDay
   * @param pastDay
   */
  static getDiffInMinutes(futureDay: string, pastDay: string): number {
    if (!(futureDay || pastDay)) {
      return 0
    }

    const _futureDay = new Date(futureDay)
    const _pastDay = new Date(pastDay)

    if (!DateConverter.isValid(_futureDay)) return 0
    if (!DateConverter.isValid(_pastDay)) return 0

    return differenceInMinutes(_futureDay, _pastDay)
  }

  static getISODayOfWeek(day: number): string {
    return format(setISODay(new Date(), day), 'EEE')
  }

  /**
   *  utcStringToEndOfDayLocal('2023-04-24T23:59:59.999Z'); //new Date('Mon Apr 24 2023 23:59:59 GMT+0300') | null
   * @param isoString
   */
  static utcStringToEndOfDayLocal = (isoString?: string): Date | null => {
    if (!isoString) return null

    const date = new Date(isoString)

    if (!DateConverter.isValid(date)) return null

    const utcYear = date.getUTCFullYear()
    const utcMonth = date.getUTCMonth()
    const utcDay = date.getUTCDate()
    const localDate = new Date(utcYear, utcMonth, utcDay)

    return DateConverter.toEndOfDay(localDate)
  }

  /**
   *  toUtcEndOfDay(new Date('Fri Mar 03 2023 00:00:00 GMT+0300')); //'2023-03-03T23:59:00.000Z' | ''
   * @param date
   */
  static toUtcEndOfDay = (date?: Date | number | null): string => {
    if (!date) return ''
    if (!DateConverter.isValid(date)) return ''

    const year = getYear(date)
    const month = getMonth(date)
    const day = getDate(date)

    const utcEndOfDay = new Date(Date.UTC(year, month, day, 23, 59))

    return utcEndOfDay.toISOString()
  }

  /**
   * minutesToDDHHMM(97320000); // '1d 3h 2min'
   * @param minutes
   */
  static minutesToDDHHMM = (minutes: number): string =>
    humanizer(minutes * 1000 * 60, DateConverter.humanizeDurationOptions)

  static isValid = (date: any) => {
    return _isValid(date)
  }

  static addDays = (date: Date | number, amount: number) => {
    return _addDays(date, amount)
  }

  static isToday = (date?: Date | number | string | null): boolean => {
    if (!date) return false
    const newDate = new Date(date)
    if (!DateConverter.isValid(newDate)) return false

    return _isToday(newDate)
  }

  static isSameDay = (dateLeft?: Date | number | string | null, dateRight?: Date | number | string | null): boolean => {
    if (!dateLeft || !dateRight) return false
    const newDateLeft = new Date(dateLeft)
    const newDateRight = new Date(dateRight)
    if (!DateConverter.isValid(newDateLeft)) return false
    if (!DateConverter.isValid(newDateRight)) return false

    return isSameDay(newDateLeft, newDateRight)
  }

  static isSameOrBefore = (date?: Date | number | null, dateToCompare?: Date | number | null): boolean => {
    if (!date || !dateToCompare) return false
    if (!DateConverter.isValid(date)) return false
    if (!DateConverter.isValid(dateToCompare)) return false

    if (isSameDay(date, dateToCompare)) return true

    return isBefore(date, dateToCompare)
  }

  static isNextDay(date?: Date | number | null, prevDate?: Date | number | null): boolean {
    if (!date || !prevDate) return false
    if (!DateConverter.isValid(date)) return false
    if (!DateConverter.isValid(prevDate)) return false

    return isSameDay(date, _addDays(prevDate, 1))
  }

  static toStartOfDay = (date: Date | number): Date => {
    return startOfDay(date)
  }

  static toEndOfDay = (date: Date | number): Date => {
    return endOfDay(date)
  }

  /**
   *  Returns e.g. new Date('Fri Feb 10 2023 00:00:00 GMT+0300')
   * @param date e.g. new Date('2023-02-10T00:00:00.000Z')
   */
  static toStartOfDayWithRemovedUTCTimeZone = (date: Date): Date => {
    const utcYear = date.getUTCFullYear()
    const utcMonth = date.getUTCMonth()
    const utcDay = date.getUTCDate()

    return new Date(utcYear, utcMonth, utcDay)
  }

  static toISOString = (date?: Date | number | null): string => {
    if (!date) return ''
    if (!DateConverter.isValid(date)) return ''

    return new Date(date).toISOString()
  }

  /**
   *  Returns e.g. '2023-02-08' | ''
   * @param date
   */
  static toISOStringWithoutTime = (date?: Date | number | null): string => {
    if (!date) return ''
    if (!DateConverter.isValid(date)) return ''

    const ISOStringWithoutTime = formatISO(date, { representation: 'date' })
    return ISOStringWithoutTime
  }

  /**
   * Returns e.g. '2020 May 26' | ''
   * @param date
   */
  static getDateStringWithoutTime = (date?: Date | number | string | null): string => {
    if (!date) return ''
    const newDate = new Date(date)
    if (!DateConverter.isValid(newDate)) return ''

    return format(newDate, 'yyyy MMM dd')
  }

  /**
   * Returns e.g. 'May 2020' | ''
   * @param date
   */
  static getFullMonthAndYear = (date?: Date | number | null): string => {
    if (!date) return ''
    if (!DateConverter.isValid(date)) return ''

    return format(date, 'MMMM y')
  }

  /**
   * Returns e.g. '15:02' | ''
   * @param date
   */
  static getTimeString = (date?: Date | number | string | null): string => {
    if (!date) return ''
    const newDate = new Date(date)
    if (!DateConverter.isValid(newDate)) return ''

    return format(newDate, 'HH:mm')
  }

  static getDaysOfWeek = () => {
    const firstDOW = startOfWeek(new Date())
    const shortWeekDaysArray = Array.from(Array(7)).map((e, i) => format(_addDays(firstDOW, i), 'EEE'))
    return shortWeekDaysArray
  }
}
