import React, { FC } from 'react'
import { Controls } from 'assets/images/Controls'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'

interface IProps {
  onExcelClick?: () => void
  onViewColumnsClick?: () => void
  columnHeight: number
}

export const TableHeaderMenu: FC<IProps> = ({ columnHeight, onExcelClick, onViewColumnsClick }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const { classes } = useStyles({ columnHeight })

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleColumnViewClick = () => {
    onViewColumnsClick && onViewColumnsClick()
    handleClose()
  }

  const handleExcelClick = () => {
    onExcelClick && onExcelClick()
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'headerRowPopover' : undefined

  return (
    <>
      <Button variant={'contained'} aria-describedby={id} className={classes.menuButton} onClick={handleClick}>
        <Controls.Excel />
      </Button>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          className: classes.paperPoper,
        }}
      >
        {onViewColumnsClick && (
          <MenuItem onClick={handleColumnViewClick}>
            <Typography className={classes.text} noWrap variant={'subtitle1'}>
              Table columns
            </Typography>
          </MenuItem>
        )}

        {onExcelClick && (
          <MenuItem onClick={handleExcelClick}>
            <Typography className={classes.text} noWrap variant={'subtitle1'}>
              Export to excel
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

const useStyles = makeStyles<{ columnHeight: number }>()((theme, { columnHeight }) => ({
  paperPoper: {
    borderRadius: 10,
  },
  excelPopover: {
    cursor: 'pointer',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.24)',
    '& .MuiPaper-rounded': {
      borderRadius: 10,
    },
  },
  menuButton: {
    position: 'absolute',
    right: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    height: columnHeight,
    width: 'calc( 1.5em - 2px )',
    minWidth: 'calc( 1.5em - 2px )',
    borderBottom: '1px solid #797979',
    borderTop: '1px solid #797979',
    borderRadius: 0,
    boxShadow: 'none',
    zIndex: 2,
    backgroundColor: '#FFF2DC',
    '&:hover': {
      background: '#FFF2DC',
      opacity: 1,
      boxShadow: 'none',
    },
  },
  text: {
    padding: '7px 16px 6px',
    color: theme.palette.primary.main,
  },
}))
