import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import useAutocomplete from '@mui/material/useAutocomplete'
import { SearchIcons } from 'assets/images/SearchIcons'
import { To } from 'router/To'
import useDebounce from 'ui/hooks/useDebounce'
import { TSearchEntityName, TSearchResults } from 'redux/modules/search/TReducer'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Search } from 'redux/modules/search/Actions'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'

export type TSearchOption = {
  id: string
  label: string
  route: string
  type: TSearchEntityName
  icon: React.FC
}

type TSearchEntityInfo = {
  route: string
  icon: React.FC
}

const searchEntities: Record<TSearchEntityName, TSearchEntityInfo> = {
  user: { route: To.userDetails, icon: SearchIcons.User },
  staff: { route: To.staffDetails, icon: SearchIcons.User },
  trailer: { route: To.trailerDetails, icon: SearchIcons.Trailer },
  trailer_type: { route: To.trailerTypes, icon: SearchIcons.Trailer },
  parking: { route: To.parkingDetails, icon: SearchIcons.Parking },
  station: { route: To.parkingDetails, icon: SearchIcons.Station },
}

const SEARCH_DELAY = 500

export const useSearch = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const searchTerm = useSelector((state: TState) => state.search.searchTerm)
  const searchResult = useSelector((state: TState) => state.search.data || [])
  const isLoading = useSelector((state: TState) => state.search.isLoading)

  const debouncedSearchTerm = useDebounce<string>(searchTerm, SEARCH_DELAY)

  useEffect(() => {
    if (searchTerm.length > 1) {
      dispatch(Actions_Search.saga.getSearchResult())
    } else {
      dispatch(Actions_Search.store.clearSearchResult())
    }
  }, [dispatch, debouncedSearchTerm])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(Actions_Search.store.saveSearchTerm(event.target.value))
  }

  const onOptionClick = useCallback(
    (option: TSearchOption) => {
      if (option.type === 'trailer_type') {
        const id = +option.id
        dispatch(Actions_TrailerTypes.store.selectCurrentTrailerTypeId(id))
        navigate(`${option.route}/`)
      } else {
        navigate(`${option.route}/${option.id}`)
      }
    },
    [navigate, dispatch],
  )

  const optionsData = useMemo(() => toOptionsData(searchResult), [searchResult])

  const autocompleteOptions = useAutocomplete({
    id: 'use-autocomplete',
    options: optionsData,
    getOptionLabel: (option) => option.label,
  })

  const isExpanded = autocompleteOptions.groupedOptions.length > 0
  const { classes } = useStyles({ isExpanded })

  return {
    classes,
    isLoading,
    onOptionClick,
    autocompleteOptions,
    onChange,
    value: searchTerm,
  }
}

const toOptionsData = (options: TSearchResults) =>
  options.map((item) => {
    const searchEntity = searchEntities[item.entity as TSearchEntityName]

    return {
      id: item.entityId,
      label: item.name || '',
      type: item.entity,
      icon: searchEntity?.icon,
      route: searchEntity?.route,
    }
  })

const useStyles = makeStyles<{ isExpanded: boolean }>()((theme, { isExpanded }) => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.text.secondary,
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
    borderBottomRightRadius: isExpanded ? 0 : 22,
    borderBottomLeftRadius: isExpanded ? 0 : 22,
    height: 44,
    padding: '0 16px',
    position: 'relative',
    zIndex: 2,

    '&::after': {
      content: "''",
      display: isExpanded ? 'block' : 'none',
      borderBottom: '1px solid white',
      position: 'absolute',
      bottom: 0,
      left: 16,
      right: 16,
    },
  },
  searchInput: {
    fontSize: 18,
    color: theme.palette.secondary.main,
    height: 36,
    width: 352,
    borderRadius: 50,
    backgroundColor: theme.palette.text.secondary,
    outline: 'none',
    border: 0,
    marginLeft: 8,

    '&::placeholder': {
      color: theme.palette.secondary.main,
    },
  },
  loader: {
    color: 'white',
  },
  listbox: {
    width: 415,
    maxHeight: 168,
    backgroundColor: theme.palette.text.secondary,
    color: 'white',
    fontSize: 18,
    lineHeight: '22px',
    borderBottomLeftRadius: 22,
    borderBottomRightRadius: 22,
    padding: '16px 0',
    margin: 0,
    border: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    overflow: 'auto',
    boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.25)',
    '& li.Mui-focused': {
      backgroundColor: theme.palette.text.primary,
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: theme.palette.text.primary,
      color: 'white',
    },
  },
  option: {
    display: 'flex',
    padding: '0 16px',
  },
  icon: {
    margin: '6px 8px 0 0',
  },
  label: {
    margin: '8px 0',
  },
}))
