import { ReactComponent as Inspection } from 'assets/images/MenuIcons/Inspection.svg'
import { ReactComponent as Organizations } from 'assets/images/MenuIcons/Organizations.svg'
import { ReactComponent as ParentOrganizations } from 'assets/images/MenuIcons/ParentOrganizations.svg'
import { ReactComponent as Price } from 'assets/images/MenuIcons/Price.svg'
import { ReactComponent as Reports } from 'assets/images/MenuIcons/Reports.svg'
import { ReactComponent as Staff } from 'assets/images/MenuIcons/Staff.svg'
import { ReactComponent as Trailers } from 'assets/images/MenuIcons/Trailers.svg'
import { ReactComponent as Users } from 'assets/images/MenuIcons/Users.svg'
import { ReactComponent as TrailerTypes } from 'assets/images/MenuIcons/TrailerTypes.svg'
import { ReactComponent as Stations } from 'assets/images/MenuIcons/Stations.svg'
import { ReactComponent as PDF } from 'assets/images/MenuIcons/PDF.svg'
import { ReactComponent as PromoCodes } from 'assets/images/MenuIcons/PromoCodes.svg'

import { FC } from 'react'

export const MenuIcons: TMenuIcons = {
  Trailers,
  TrailerTypes,
  Staff,
  Organizations,
  ParentOrganizations,
  Stations,
  Users,
  Price,
  Inspection,
  Reports,
  PDF,
  PromoCodes,
}

export type TMenuIcons = {
  Trailers: FC
  TrailerTypes: FC
  Staff: FC
  Organizations: FC
  ParentOrganizations: FC
  Stations: FC
  Users: FC
  Price: FC
  Inspection: FC
  Reports: FC
  PDF: FC
  PromoCodes: FC
}
