import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TTrailerType, TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const client = RequestMethods()

export interface ISagaWorker_DeleteTrailerType {
  id: number
}

export default function* SagaWorker_DeleteTrailerType(action: PayloadAction<ISagaWorker_DeleteTrailerType>) {
  const { id } = action.payload

  try {
    yield put(Actions_TrailerTypes.store.showLoader())

    yield call(async () => await client.remove<TTrailerType>(`/trailer_types/${id}`))

    yield put(Actions_TrailerTypes.store.selectCurrentTrailerTypeId(undefined))

    const result: TStoreTemplate<TTrailerTypes> = yield call(
      async () => await client.get<TTrailerTypes>('/trailer_types'),
    )
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(result))

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Trailer type successfully deleted',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_TrailerTypes.store.hideLoader())
  }
}
