import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { useSelector, useDispatch } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_CurrentStation } from 'redux/modules/currentStation/Actions'

type TModalProps = {
  stationId: number
}

export const useLockStation = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.lockStation)

  const modalProps = useSelector(({ modals }: TState) => modals.data as TModalProps)

  const onSubmit = () => {
    dispatch(Actions_CurrentStation.saga.lockStation({ id: modalProps.stationId }))
    dispatch(Actions_Modals.store.hideModal())
  }

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  return { t, onSubmit, onCancel }
}
