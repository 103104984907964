import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TTemplateRoles } from 'redux/modules/templateRoles/TReducer'
import { Actions_TemplateRoles } from 'redux/modules/templateRoles/Actions'

const { get } = RequestMethods()

export default function* SagaWorker_TemplateRoles() {
  try {
    yield put(Actions_TemplateRoles.store.showLoader())

    const templateRoles: TStoreTemplate<TTemplateRoles> = yield call(
      async () => await get<TTemplateRoles>('/users/roles', { hideCustomRoles: true }),
    )
    yield put(Actions_TemplateRoles.store.saveRoles(templateRoles))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_TemplateRoles.store.hideLoader())
}
