import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { TState } from 'redux/config/root-types'
import { Actions_TemplateRoles } from 'redux/modules/templateRoles/Actions'
import { Actions_Modals } from 'redux/modules/modals/Actions'

export const useStaffChangeRole = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.staffChangeRole)

  const id = useSelector(({ modals }: TState) => modals.data.id)

  const onSubmit = useCallback(() => {
    dispatch(Actions_TemplateRoles.store.changeSelectedRoleId(id))
    dispatch(Actions_Modals.store.hideModal())
  }, [dispatch, id])

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  return { t, onSubmit, onCancel }
}
