enum StoreActions {
  IS_LOADER_SHOW = 'TRAILERS_COUNTERS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'TRAILERS_COUNTERS/IS_LOADER_HIDE',
  SAVE_TRAILERS_COUNTERS = 'TRAILERS_COUNTERS/SAVE_TRAILERS_COUNTERS',
}

enum SagaEvents {}

export const ActionTypes_TrailersCounters = {
  SagaEvents,
  StoreActions,
}
