import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'

export const useUserActivation = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.userActivation)

  const { id, isActive } = useSelector(({ modals }: TState) => modals.data)

  const title = useMemo(() => (isActive ? t('deactivationTitle') : t('activationTitle')), [t, isActive])
  const message = useMemo(() => (isActive ? t('deactivationMessage') : t('activationMessage')), [t, isActive])

  const onSubmit = useCallback(() => {
    if (isActive) {
      dispatch(Actions_CurrentUser.saga.deactivateUser({ id: id }))
    } else {
      dispatch(Actions_CurrentUser.saga.activateUser({ id: id }))
    }
    dispatch(Actions_Modals.store.hideModal())
  }, [isActive, dispatch, id])

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  return { t, title, message, onSubmit, onCancel }
}
