import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_Stations } from 'redux/modules/stations/Actions'

export const useStationConnectionFilter = () => {
  const dispatch = useDispatch()
  const { stations } = useSelector(
    ({ stations }: TState) => ({
      stations: stations,
    }),
    shallowEqual,
  )
  const title = stations.filter.connection === true ? 'Online' : 'Offline'
  const [value, setValue] = useState<string>(stations.filter.connection !== null ? title : '')
  const data = ['Online', 'Offline']
  const isFilterOn = stations.filter.connection !== null ? true : false

  const onSubmit = useCallback(
    (value?: string) => {
      const status = value?.toLowerCase() === 'online' ? true : false
      dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, connection: status }))
      dispatch(Actions_Modals.store.hideModal())
    },
    [dispatch, value, stations],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  const onClearFilter = () => {
    dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, connection: null }))
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    data,
    onSubmit,
    onCancel,
    onChange,
    setValue,
    value,
    isFilterOn,
    onClearFilter,
  }
}
