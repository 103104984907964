import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { useParentOrganizationCreate } from './ParentOrganizationCreate-ViewModel'

export const ParentOrganizationCreate: FC = () => {
  const vm = useParentOrganizationCreate()
  const { closeModal, form, isLoading, t } = vm
  const { errors, handleSubmit, register, formValidation } = form

  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
            <Shared.InputField
              name="name"
              title={t('name')}
              register={register(formValidation.name)}
              error={errors.name?.message}
            />

            <Shared.InputField
              name="discount"
              title={t('discount')}
              register={register(formValidation.discount)}
              error={errors.discount?.message}
              type="number"
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
            />
            <Shared.Button title={t('submit')} type={'submit'} disabled={isLoading} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
