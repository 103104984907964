import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { makeStyles } from 'tss-react/mui'
import { TColumns } from 'ui/shared/Table/Table'

export const TRAILERS_TABLE_LOCAL_STORAGE_KEY = 'trailersColumns'

type TModalProps = {
  columns: TColumns
}

export const useViewTrailersTableColumns = () => {
  const { t } = useTranslation(I18nModal.viewTrailersTableColumns)
  const dispatch = useDispatch()
  const { classes } = useStyles()

  const { columns } = useSelector(({ modals }: TState) => modals.data as TModalProps)
  const trailersColumns = localStorage.getItem(TRAILERS_TABLE_LOCAL_STORAGE_KEY) as string
  const initialChecked: string[] = JSON.parse(trailersColumns) || []

  const [checked, setChecked] = useState(initialChecked)

  const columnsThatCanBeHidden = columns?.filter((column) => column.canHide) || []

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const isSelectAll = checked?.length === columnsThatCanBeHidden?.length

  const handleToogleAll = () => {
    if (isSelectAll) {
      setChecked([])
    } else {
      const allIds = columnsThatCanBeHidden.map((column) => column.dataKey) || []
      setChecked(allIds)
    }
  }

  const onSubmit = () => {
    const checkedColumnsAsString = JSON.stringify(checked)
    localStorage.setItem(TRAILERS_TABLE_LOCAL_STORAGE_KEY, checkedColumnsAsString)
    window.dispatchEvent(new Event('storage'))
    dispatch(Actions_Modals.store.hideModal())
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    classes,
    closeModal,
    onSubmit,
    data: columnsThatCanBeHidden || [],
    checked,
    isSelectAll,
    handleToggle,
    handleToogleAll,
  }
}

const useStyles = makeStyles()((theme) => ({
  card: {
    width: 375,
    overflowY: 'auto',
    maxHeight: '100%',
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
  },
  listItemText: {
    // ...theme.typography.body1, TODO:
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  cancel: {
    margin: '0.875em 0',
  },
}))
