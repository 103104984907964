import React, { FC } from 'react'
import SortButton from '../Table/SortButton/SortButton'
import FilterButton from '../Table/FilterButton/FilterButton'
import Typography from '@mui/material/Typography'
import { DEFAULT_COLUMN_WIDTH, IColumn } from '../Table/Table'
import { makeStyles } from 'tss-react/mui'
import { IOrder } from 'utils/types'

interface IProps {
  headerHeight: number
  sortBy?: string
  sortDirection: IOrder
  isShowMenuButton: boolean
  column: IColumn
  columns: IColumn[]
  onFilterClick?: (dataKey: string) => void
  onSortClick?: (dataKey: string) => void
}

export const TableHeaderCell: FC<IProps> = ({
  headerHeight,
  sortBy,
  sortDirection,
  isShowMenuButton,
  column,
  columns,
  onFilterClick,
  onSortClick,
}) => {
  const { classes } = useStyles({ column, headerHeight })

  const isSelected = sortBy === column.dataKey
  const _sortDirection = isSelected ? sortDirection : 'DESC'

  const handleFilterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    onFilterClick && onFilterClick(column.dataKey)
  }

  return (
    <div className={classes.cell}>
      {column.label && (
        <Typography variant={'subtitle1'} title={column.label} noWrap>
          {column.label}
        </Typography>
      )}

      <div className={classes.actions}>
        {column.isShowFilter && (
          <div style={{ display: 'flex', marginRight: 5 }} onClick={handleFilterClick}>
            <FilterButton isSelected={column.isFilterSelected} />
          </div>
        )}

        {!column.disableSort && (
          <SortButton
            isSelected={isSelected}
            sortDirection={_sortDirection}
            onClick={onSortClick}
            dataKey={column.dataKey}
          />
        )}
      </div>
    </div>
  )
}

type TStyle = {
  column: IColumn
  headerHeight: number
}

const useStyles = makeStyles<TStyle, 'actions'>()((theme, { column, headerHeight }, classes) => ({
  cell: {
    alignItems: 'center',
    justifyContent: column.label ? 'space-between' : 'center',
    padding: '0.4em',
    borderTop: '1px solid #797979',
    borderBottom: '1px solid #797979',
    borderRight: '1px solid #797979',
    height: headerHeight,
    backgroundColor: '#fff2dc',
    display: column ? 'flex' : 'none',
    flexBasis: column?.width || DEFAULT_COLUMN_WIDTH,
    flexGrow: 1,
    flexShrink: 1,
    minWidth: column?.minWidth || 0,
    maxWidth: column?.maxWidth || 'none',

    '&:first-of-type': {
      borderLeft: `1px solid ${theme.palette.background.default}`,
    },

    [`&:last-of-type .${classes.actions}`]: {
      marginRight: column.minWidth && column.minWidth > 36 ? '1.5em' : 0,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
}))
