enum StoreActions {
  IS_LOADER_SHOW = 'CURRENT_PARKING/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CURRENT_PARKING/IS_LOADER_HIDE',
  SELECT_POSITION_INFO = 'CURRENT_POSITION/SELECT_POSITION_INFO',
  CLEAR_SELECTED_POSITION_INFO = 'CURRENT_POSITION/CLEAR_SELECTED_POSITION_INFO',
}

enum SagaEvents {}

export const ActionTypes_CurrentPosition = {
  SagaEvents,
  StoreActions,
}
