import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_TrailerStatuses } from './TActions'
import { TSelectedTrailerStatusesIdsPayload, TTrailerStatuses } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { StoreActions, SagaEvents } = ActionTypes_TrailerStatuses

export const Actions_TrailerStatuses = {
  saga: {
    getTrailerStatuses: createAction(SagaEvents.GET_TRAILER_STATUSES),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    changeSelectedStatusesIds: createAction<TSelectedTrailerStatusesIdsPayload>(StoreActions.SELECT_STATUS_IDS),
    saveTrailerStatuses: createAction<TStoreTemplate<TTrailerStatuses>>(StoreActions.SAVE_TRAILERSTATUSES),
    resetTrailerStatuses: createAction(StoreActions.RESET),
  },
}
