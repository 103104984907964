import React, { FC } from 'react'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import TraxiLogo from 'assets/images/TraxiLogo.svg'
import { Controls } from 'assets/images/Controls'
import { useStaticControls } from './StaticControls-ViewModel'
import MenuList from './MenuList/MenuList'
import SelectLanguage from 'ui/root-components/StaticControls/SelectLanguage/SelectLanguage'
import config from 'utils/conf/config'
import Search from './Search/Search'
import NotificationDrawer from './NotificationDrawer/NotificationDrawer'
import ParkingsProblemDrawer from './ParkingsProblemDrawer/ParkingsProblemDrawer'
import StationsProblemDrawer from './StationsProblemDrawer/StationsProblemDrawer'

type TProps = {
  children?: React.ReactNode
}

export const StaticControls: FC<TProps> = ({ children }) => {
  const vm = useStaticControls()
  const {
    styles,
    methods,
    data: {
      open,
      classes,
      cx,
      email,
      showUsername,
      isShowUnreadMessageIndicator,
      isShowNotificationButton,
      isParkingsHaveProblem,
      isStationsHaveProblem,
    },
  } = vm

  const easterEgg = () => {
    if (config.IS_DEV) {
      document.body.style.transform = 'rotate(360deg)'
      document.body.style.transition = '3s'
    }
  }
  return (
    <>
      <NotificationDrawer />
      <ParkingsProblemDrawer />
      <StationsProblemDrawer />
      <Box flexDirection="row">
        <div style={styles.xlStyles} className={cx(classes.appBar)}>
          <div className={classes.toolbar}>
            <Search />

            <Box display="flex" flexDirection="row" alignItems="center">
              {isShowNotificationButton && (
                <IconButton onClick={methods.handleParkingsNotificationsDrawerOpen} size="large">
                  <Badge color="secondary" overlap="circular" variant="dot" invisible={!isParkingsHaveProblem}>
                    <Controls.Battery />
                  </Badge>
                </IconButton>
              )}

              {isShowNotificationButton && (
                <IconButton onClick={methods.handleStationsNotificationsDrawerOpen} size="large">
                  <Badge color="secondary" overlap="circular" variant="dot" invisible={!isStationsHaveProblem}>
                    <Controls.Locked className={classes.lockIcon} />
                  </Badge>
                </IconButton>
              )}

              {isShowNotificationButton && (
                <IconButton onClick={methods.handleNotificationsDrawerOpen} size="large">
                  <Badge color="secondary" overlap="circular" variant="dot" invisible={!isShowUnreadMessageIndicator}>
                    <Controls.Notifications />
                  </Badge>
                </IconButton>
              )}

              {showUsername && (
                <Typography style={{ margin: '0 1.5rem 0 1rem' }} variant="subtitle2" noWrap>
                  {email}
                </Typography>
              )}

              <SelectLanguage />

              <IconButton onClick={methods.showConfirmExitModal} size="large">
                <Controls.Logout />
              </IconButton>
            </Box>
          </div>
        </div>
        <Drawer
          variant="permanent"
          className={cx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: cx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <img
            onClick={easterEgg}
            src={TraxiLogo}
            alt="Traxi Logo"
            style={{
              width: open ? 160 : 0,
              height: '12vh',
              maxHeight: 91,
              minHeight: 68,
              margin: '1.5rem',
              marginBottom: 0,
            }}
          />
          <MenuList open={open} />
          <div className={classes.leftMenu}>
            <IconButton onClick={methods.handleDrawerOpen} size="large">
              {open ? <Controls.ArrowLeft /> : <Controls.ArrowRight />}
            </IconButton>
          </div>
        </Drawer>
        <div style={styles.xlStyles} className={classes.content}>
          {children}
        </div>
      </Box>
    </>
  )
}
