import { createReducer } from '@reduxjs/toolkit'
import { IStateModals } from './TReducer'
import { Actions_Modals } from './Actions'

const initialState: IStateModals = {
  currentModal: null,
  data: null,
}

const ReducerModals = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Modals.store.showModal, (state, action) => {
      const payload = action.payload
      return { currentModal: payload.type, data: payload.data }
    })
    .addCase(Actions_Modals.store.hideModal, () => {
      return { currentModal: null, data: null }
    })
})

export default ReducerModals
