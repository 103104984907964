import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Stations } from './TActions'
import { TStations } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { TChangeOccupancyFilterPayload } from '../parkings/TReducer'
import { TTableFilterRedux } from 'utils/helpers/table'
import { TSelectedTrailerTypeIdsPayload } from '../trailerTypes/TReducer'
const { StoreActions, SagaEvents } = ActionTypes_Stations

export const Actions_Stations = {
  saga: {
    getAllStations: createAction(SagaEvents.GET_ALL_STATIONS),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveStations: createAction<TStoreTemplate<TStations>>(StoreActions.SAVE_STATIONS),
    clearStations: createAction(StoreActions.CLEAR_STATIONS),
    changeOccupancyFilter: createAction<TChangeOccupancyFilterPayload>(StoreActions.CHANGE_OCCUPANCY_FILTER),
    changeFilter: createAction<any>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TTableFilterRedux>(StoreActions.INCREASE_FILTER_OFFSET),
    changeSelectedTrailerTypesIds: createAction<TSelectedTrailerTypeIdsPayload>(StoreActions.SELECT_TRAILER_TYPES_IDS),
  },
}
