import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TUser } from 'redux/modules/users/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'

const client = RequestMethods()

export interface ISagaWorker_ActivateUser {
  id: number
}

export default function* SagaWorker_ActivateUser(action: PayloadAction<ISagaWorker_ActivateUser>) {
  const { id } = action.payload

  yield put(Actions_CurrentUser.store.showLoader())

  try {
    const user: TStoreTemplate<TUser> = yield call(async () => await client.put<TUser>(`/users/${id}/activate`))
    yield put(Actions_CurrentUser.store.saveCurrentUser(user))

    yield put(Actions_Notifications.store.setNotification({ message: 'User successfully activated', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentUser.store.hideLoader())
  }
}
