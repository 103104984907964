import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { TOrganizationInvitationCode } from 'redux/modules/organizationInvitationCodes/TReducer'
import { Actions_OrganizationInvitationCodes } from 'redux/modules/organizationInvitationCodes/Actions'

const client = RequestMethods()

export interface ISagaWorker_CreateOrganizationInvitationCodes {
  organizationId: number
  data: {
    amount: number
    duration: number
  }
}

export default function* SagaWorker_CreateOrganizationInvitationCodes(
  action: PayloadAction<ISagaWorker_CreateOrganizationInvitationCodes>,
) {
  const { data, organizationId } = action.payload
  try {
    yield put(Actions_OrganizationInvitationCodes.store.showLoader())

    yield call(
      async () =>
        await client.post<TOrganizationInvitationCode>(`/organizations/${organizationId}/invitationCodes`, data),
    )

    //------------------------- Getting and saving organization invitation codes ---------------------------//

    const organizationInvitationCodesResponse: TStoreTemplate<TOrganizationInvitationCode[]> = yield call(
      async () =>
        await client.get<TOrganizationInvitationCode[]>(`/organizations/${organizationId}/invitationCodes`, {
          hideInactive: false,
        }),
    )
    yield put(Actions_OrganizationInvitationCodes.store.saveCodes({ ...organizationInvitationCodesResponse }))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Codes successfully created',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_OrganizationInvitationCodes.store.hideLoader())
}
