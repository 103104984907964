import { makeStyles } from 'tss-react/mui'

export const useMessage = () => {
  const { classes, cx } = useStyles()

  return {
    classes,
    cx,
  }
}

const useStyles = makeStyles()({
  hoverSpace: {
    padding: '8px 24px',
    transition: 'all .25s linear',

    '&:hover': {
      backgroundColor: 'rgba(255, 242, 220, 0.7 )',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    margin: '0 24px',
    flexShrink: 0,
    flexBasis: 24,
    height: 'auto',
  },
  title: {
    fontSize: 14,
    lineHeight: '16.41px',
    fontWeight: 700,
    wordBreak: 'break-word',
    marginBottom: 10,

    flexGrow: 0,
    flexBasis: '100%',
    maxWidth: '100%',
  },
  message: {
    flexGrow: 1,
  },
  messageItem: {
    display: 'flex',

    '&:not(:last-child)': {
      marginBottom: 2,
    },
  },

  text: {
    fontSize: 14,
    lineHeight: '16.41px',
    wordBreak: 'break-word',
  },
  value: {
    marginLeft: 8,
  },
})
