import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStationStatuses } from 'redux/modules/stationStatuses/TReducer'
import { Actions_StationStatuses } from 'redux/modules/stationStatuses/Actions'

const { get } = RequestMethods()

export default function* SagaWorker_GetStationStatuses() {
  yield put(Actions_StationStatuses.store.showLoader())
  try {
    const statuses: TStoreTemplate<TStationStatuses> = yield call(
      async () => await get<TStationStatuses>('/stations/statuses'),
    )
    yield put(Actions_StationStatuses.store.saveStationStatuses(statuses))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_StationStatuses.store.hideLoader())
  }
}
