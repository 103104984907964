import { createReducer } from '@reduxjs/toolkit'
import { Actions_Parkings } from './Actions'
import { IParking, IStateParkings } from './TReducer'

const initialState: IStateParkings = {
  isLoading: false,
  data: null,
  allParkings: [],
  responseInfo: null,
  isCreateParking: false,
  occupancyFilter: {
    hideFreeStations: false,
    hideNoFreeStations: false,
    hideOneFreeStation: false,
  },
  filter: {
    parkingName: null,
    batteryCharge: null,
    organizationName: null,
    imei: null,
    hideNoFreeStations: null,
    hideOneFreeStation: null,
    hideFreeStations: null,
  },
  startPage: 0,
}

const ReducerParkings = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Parkings.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Parkings.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Parkings.store.changeOccupancyFilter, (state, action) => {
      state.occupancyFilter = action.payload
    })
    .addCase(Actions_Parkings.store.changeFilter, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Parkings.store.saveParkings, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Parkings.store.clearParkings, (state) => {
      state.data = null
    })
    .addCase(Actions_Parkings.store.increaseFilterOffset, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Parkings.store.saveAllParkings, (state, action) => {
      state.allParkings = action.payload
    })
    .addCase(Actions_Parkings.store.changeIsCreateParking, (state, action) => {
      state.isCreateParking = action.payload
    })
    .addCase(Actions_Parkings.store.changeStartPage, (state, action) => {
      state.startPage = action.payload
    })
    .addCase(Actions_Parkings.store.deleteParking, (state, action) => {
      const payload = action.payload
      const id = payload.id
      const parkings = state.data?.parking || []
      const updatedParkings: IParking[] = parkings.filter((parking) => parking.parkingInfo.id !== id)

      return {
        ...state,
        data: {
          parking: updatedParkings,
        },
      }
    })
    .addCase(Actions_Parkings.store.deleteParkingFromAllParkings, (state, action) => {
      const payload = action.payload
      const id = payload.id
      const parkings = state.allParkings || []
      const updatedParkings: IParking[] = parkings.filter((parking) => parking.parkingInfo.id !== id)

      return {
        ...state,
        allParkings: updatedParkings,
      }
    })
})

export default ReducerParkings
