import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import DateConverter from 'utils/helpers/date'
import { TPromoCode } from 'redux/modules/promoCode/TReducer'
import { useForm } from 'react-hook-form'
import { Form } from 'utils/helpers/form'
import { useMemo } from 'react'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'

type TModalProps = {
  promoCode: TPromoCode
}

interface IForm {
  code: string
  bonusMinutes: number
  activeUntil?: Date | null
  organizationIds?: number[]
  trailerTypeIds?: number[]
}

const formValidation = {
  code: Form.combine(Form.required, Form.minLength(4), Form.maxLength(14)),
  bonusMinutes: Form.combine(Form.required, Form.min(1)),
  activeUntil: Form.combine(Form.required, Form.isSameOrAfter()),
}

export const usePromoCodeUpdate = () => {
  const { t } = useTranslation(I18nModal.promoCodeUpdate)
  const dispatch = useDispatch()

  const modalProps = useSelector(({ modals }: TState) => modals.data as TModalProps)
  const isLoading = useSelector(({ promoCode }: TState) => promoCode.isLoading)
  const trailerTypes = useSelector(({ trailerTypes }: TState) => trailerTypes.data || [])

  const { handleSubmit, register, errors, control, watch } = useForm<IForm>({
    defaultValues: {
      code: modalProps.promoCode.code || '',
      bonusMinutes: modalProps.promoCode.bonusMinutes || 0,
      activeUntil: DateConverter.utcStringToEndOfDayLocal(modalProps.promoCode.activeUntil),
      organizationIds: modalProps.promoCode.organizationIds,
      trailerTypeIds: modalProps.promoCode.trailerTypeIds,
    },
  })

  const onSubmit = (formData: IForm) => {
    const id = modalProps.promoCode.id
    const data = {
      ...formData,
      bonusMinutes: +formData.bonusMinutes,
      activeUntil: DateConverter.toUtcEndOfDay(formData.activeUntil),
      organizationIds: formData.organizationIds,
      trailerTypeIds: formData.trailerTypeIds,
    }
    dispatch(Actions_PromoCode.saga.updatePromoCode({ id, data }))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const trailerTypeOptions = useMemo(() => toTrailerTypeDropdownOptions(trailerTypes), [trailerTypes])
  const selectedTrailerTypesIds = watch('trailerTypeIds') || []
  const isSelectedTrailerTypes = (id: number) => (selectedTrailerTypesIds as number[]).includes(id)

  return {
    t,
    closeModal,
    isLoading,
    trailerTypeOptions,
    isSelectedTrailerTypes,
    form: {
      formValidation,
      errors,
      register,
      control,
      handleSubmit: handleSubmit(onSubmit),
      watch,
    },
  }
}

const toTrailerTypeDropdownOptions = (trailerTypes: TTrailerTypes): TDropdownOption[] =>
  trailerTypes.map((trailerType) => ({
    id: trailerType.type.id,
    label: trailerType.type.name,
    value: trailerType.type.id,
  }))
