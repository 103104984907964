import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TStoreTemplate } from 'redux/config/root-types'
import { TTrailerType, TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'

const client = RequestMethods()

export interface ISagaWorker_ActivateTrailerType {
  id: number
}

export default function* SagaWorker_ActivateTrailerType(action: PayloadAction<ISagaWorker_ActivateTrailerType>) {
  const { id } = action.payload

  yield put(Actions_TrailerTypes.store.showLoader())

  try {
    yield call(async () => await client.put<TTrailerType>(`/trailer_types/${id}/activate`))

    const result: TStoreTemplate<TTrailerTypes> = yield call(
      async () => await client.get<TTrailerTypes>('/trailer_types'),
    )
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(result))

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Trailer type successfully activated', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_TrailerTypes.store.hideLoader())
  }
}
