import { ReactComponent as User } from './User.svg'
import { ReactComponent as Trailer } from './Trailer.svg'
import { ReactComponent as Station } from './Station.svg'
import { ReactComponent as Parking } from './Parking.svg'

export const SearchIcons = {
  User,
  Trailer,
  Station,
  Parking,
}
