import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_Stations } from 'redux/modules/stations/Actions'

export const useStationStatusFilter = () => {
  const dispatch = useDispatch()
  const { stations, stationStatuses } = useSelector(
    ({ stations, stationStatuses }: TState) => ({
      stations: stations,
      stationStatuses: stationStatuses.data?.statuses,
    }),
    shallowEqual,
  )
  const data = stationStatuses ? stationStatuses : []
  const [checkedIds, setCheckedIds] = useState(
    stations.filter.stationStatus ? stations.filter.stationStatus.map((status) => status.id) : [],
  )
  const isSelectAll = checkedIds.length === data?.length

  const onSubmit = useCallback(() => {
    const selectedStatuses = stationStatuses?.filter((status) => {
      return checkedIds.indexOf(status.id) > -1
    })
    const data = selectedStatuses ? (selectedStatuses.length > 0 ? selectedStatuses : null) : null
    dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, stationStatus: data }))
    dispatch(Actions_Modals.store.hideModal())
  }, [dispatch, stations, checkedIds])

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  const handleToggle = (value: number) => () => {
    const currentIndex = checkedIds.indexOf(value)
    const newCheckedIds = [...checkedIds]

    if (currentIndex === -1) {
      newCheckedIds.push(value)
    } else {
      newCheckedIds.splice(currentIndex, 1)
    }

    setCheckedIds(newCheckedIds)
  }

  const handleToogleAll = () => {
    if (isSelectAll) {
      setCheckedIds([])
    } else {
      const allIds = data?.map(({ id }) => id) || []
      setCheckedIds(allIds)
    }
  }

  return {
    data,
    onSubmit,
    onCancel,
    handleToggle,
    checkedIds,
    isSelectAll,
    handleToogleAll,
  }
}
