import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { TCurrentTrailerBreakdown } from 'redux/modules/currentTrailerBreakdowns/TReducer'
import { Actions_CurrentTrailerBreakdowns } from 'redux/modules/currentTrailerBreakdowns/Actions'

const client = RequestMethods()

export interface ISagaWorker_UpdateCurrentTrailerBreakdown {
  id: number
  data: {
    isConfirmed?: boolean
    isResolved?: boolean
    isRejected?: boolean
  }
}

export default function* SagaWorker_UpdateCurrentTrailerBreakdown(
  action: PayloadAction<ISagaWorker_UpdateCurrentTrailerBreakdown>,
) {
  yield put(Actions_CurrentTrailerBreakdowns.store.showLoader())
  const { data, id } = action.payload

  try {
    const breakdown: TStoreTemplate<TCurrentTrailerBreakdown> = yield call(
      async () => await client.put<TCurrentTrailerBreakdown>(`/trailers/issueReports/${id}`, data),
    )

    yield put(Actions_CurrentTrailerBreakdowns.store.editBreakdown(breakdown))

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Breakdown successfully updated', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentTrailerBreakdowns.store.hideLoader())
  }
}
