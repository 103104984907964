import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_ParentOrganizations } from 'redux/modules/parentOrganizations/Actions'
import { TParentOrganization } from 'redux/modules/parentOrganizations/TReducer'
import { PayloadAction } from '@reduxjs/toolkit'

const { get } = RequestMethods()

export default function* SagaWorker_GetParentOrganizations() {
  try {
    yield put(Actions_ParentOrganizations.store.showLoader())

    const organizations: TStoreTemplate<TParentOrganization[]> = yield call(
      async () => await get<TParentOrganization[]>('/organizations/parents', { hideInactive: true }),
    )
    yield put(Actions_ParentOrganizations.store.saveParentOrganizations(organizations))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_ParentOrganizations.store.hideLoader())
  }
}
