import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import { TPromoCode } from 'redux/modules/promoCode/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const client = RequestMethods()

export interface ISagaWorker_UpdatePromoCode {
  id: number
  data: {
    code: string
    activeUntil: string
    bonusMinutes: number
  }
}

export default function* SagaWorker_UpdatePromoCode(action: PayloadAction<ISagaWorker_UpdatePromoCode>) {
  yield put(Actions_PromoCode.store.showLoader())
  const { data, id } = action.payload

  try {
    const promoCode: TStoreTemplate<TPromoCode> = yield call(
      async () => await client.put<TPromoCode>(`/promo_codes/${id}`, data),
    )

    yield put(Actions_PromoCode.store.editPromoCode(promoCode))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Promo code successfully updated', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PromoCode.store.hideLoader())
  }
}
