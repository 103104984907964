import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { AxiosRequestConfig } from 'axios'

const { post, get } = RequestMethods()
export interface IMedia {
  id: number
  ownerId: number
  name: string
  createdAt: string
  updatedAt: string
}

export async function getMedia(file: File) {
  const headers: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const formData = new FormData()
  formData.append('file', file)
  const result: TStoreTemplate<IMedia> = await post<IMedia>(`/media`, formData, headers)
  return result.data
}
