import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentStaffMember } from 'redux/modules/currentStaffMember/Actions'
import { TUser } from 'redux/modules/users/TReducer'
import { TRole, TRules } from 'redux/modules/templateRoles/TReducer'

const client = RequestMethods()

export type ISagaWorker_UpdateStaff = {
  id: string
  data: {
    roleId?: number
    firstName: string
    lastName: string
    email: string
    organizations: number[]
    parentOrganizations: number[]
    regions: number[]
  }
  roleName: string
  rules: TRules
}

export default function* SagaWorker_UpdateStaff(action: PayloadAction<ISagaWorker_UpdateStaff>) {
  const { data, roleName, rules, id } = action.payload
  try {
    yield put(Actions_CurrentStaffMember.store.showLoader())

    yield call(async () => await client.put<TRole>(`/users/roles/${data.roleId}`, { name: roleName, ...rules }))

    yield call(async () => await client.put<TUser>(`/users/${id}`, data))

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Staff member successfully updated', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentStaffMember.store.hideLoader())
  }
}
