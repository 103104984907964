import { createReducer } from '@reduxjs/toolkit'
import { IStatePositions } from './TReducer'
import TableFilterReduxAdapter from 'utils/helpers/table'
import { Actions_Positions } from './Actions'

const initialState: IStatePositions = {
  isLoading: false,
  data: null,
  isAllDataLoaded: false,
  responseInfo: null,
  filter: {
    ...TableFilterReduxAdapter.DEFAULT_FILTERS,
    limit: 500, //TODO: change limit
  },
}

const ReducerPositions = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Positions.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Positions.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Positions.store.savePositions, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Positions.store.changeFilter, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Positions.store.increaseFilterOffset, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Positions.store.reset, () => {
      return initialState
    })
})

export default ReducerPositions
