import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_CurrentStation } from './TActions'
import { TCurrentStation } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_CreateStation } from 'redux/sagas/events/station/SagaWorker_CreateStation'
import { ISagaWorker_UpdateStation } from 'redux/sagas/events/station/SagaWorker_UpdateStation'
import { ISagaWorker_LockStation } from 'redux/sagas/events/station/SagaWorker_LockStation'
import { ISagaWorker_UnlockStation } from 'redux/sagas/events/station/SagaWorker_UnlockStation'
import { ISagaWorker_GetStationQRCode } from 'redux/sagas/events/station/SagaWorker_GetStationQRCode'

const { SagaEvents, StoreActions } = ActionTypes_CurrentStation

export const Actions_CurrentStation = {
  saga: {
    createStation: createAction<ISagaWorker_CreateStation>(SagaEvents.CREATE_STATION),
    updateStation: createAction<ISagaWorker_UpdateStation>(SagaEvents.UPDATE_STATION),
    lockStation: createAction<ISagaWorker_LockStation>(SagaEvents.LOCK_STATION),
    unlockStation: createAction<ISagaWorker_UnlockStation>(SagaEvents.UNLOCK_STATION),
    getQRCode: createAction<ISagaWorker_GetStationQRCode>(SagaEvents.GET_QR_CODE),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveCurrentStation: createAction<TStoreTemplate<TCurrentStation>>(StoreActions.SAVE_CURRENT_STATION),
  },
}
