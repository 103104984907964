import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Actions_CurrentStaffMember } from 'redux/modules/currentStaffMember/Actions'
import { TRegion } from 'redux/modules/regions/TReducer'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { useMemo } from 'react'
import { TParentOrganization } from 'redux/modules/parentOrganizations/TReducer'

interface IForm {
  role: number | string
  name: string
  login: string
  organization: number[]
  parentOrganizations: number[]
  regions: number[]
}

export const useStaffCreate = () => {
  const { t } = useTranslation(I18nModal.staffCreate)
  const dispatch = useDispatch()

  const isLoading = useSelector(({ staff }: TState) => staff.isLoading)
  const parentOrganizations = useSelector(({ parentOrganizations }: TState) => parentOrganizations.data || [])
  const regions = useSelector(({ regions }: TState) => regions.data || [])

  const parentsOptions = useMemo(() => toParentsDropdownOption(parentOrganizations), [parentOrganizations])
  const regionOptions = useMemo(() => toRegionsDropdownOption(regions), [regions])

  const { handleSubmit, register, errors, control, watch } = useForm<IForm>({
    defaultValues: {
      role: '',
      name: '',
      login: '',
      organization: [],
      parentOrganizations: [],
      regions: [],
    },
  })

  const selectedParentOrganizations = watch('parentOrganizations') || []
  const selectedRegions = watch('regions') || []
  const isSelectedParentOrganizations = (id: number) => selectedParentOrganizations.includes(id)
  const isSelectedRegions = (id: number) => selectedRegions.includes(id)

  const onSubmit = (data: IForm) => {
    const newStaffMember = {
      email: data.login,
      roleId: parseInt(data.role as string),
      firstName: data.name,
      organizations: data.organization,
      parentOrganizations: data.parentOrganizations,
      regions: data.regions,
    }
    dispatch(Actions_CurrentStaffMember.saga.createStaff(newStaffMember))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    isLoading,
    closeModal,
    parentsOptions,
    regionOptions,
    isSelectedParentOrganizations,
    isSelectedRegions,
    form: {
      control,
      errors,
      register,
      handleSubmit: handleSubmit(onSubmit),
      watch,
    },
  }
}

const toParentsDropdownOption = (parentOrganizations: TParentOrganization[]): TDropdownOption[] =>
  parentOrganizations.map((parentOrganization) => ({
    id: parentOrganization.id,
    label: parentOrganization.name,
    value: parentOrganization.id,
  }))

const toRegionsDropdownOption = (regions: TRegion[]): TDropdownOption[] =>
  regions.map((region) => ({
    id: region.id,
    label: region.name,
    value: region.id,
  }))
