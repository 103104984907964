import { makeStyles } from 'tss-react/mui'
import { TNotificationType } from 'redux/modules/notification/TReducer'
import { notificationIcons } from 'utils/constants/notificationIcons'
import { useTranslation } from 'react-i18next'
import { I18nNotifications } from 'utils/i18n/i18nNotifications'

export const useMessage = (type: TNotificationType) => {
  const { t } = useTranslation(I18nNotifications.common)
  const { classes } = useStyles()

  const Icon = notificationIcons[type]
  const isShowTitle = !(type === 'Info' || type === 'Success' || type === 'Error')
  const title = isShowTitle ? t(type) : undefined

  return {
    classes,
    title,
    Icon,
  }
}

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 16,
    flexShrink: 0,
  },
  text: {
    fontSize: 14,
    lineHeight: '16.41px',
  },
  title: {
    fontSize: 14,
    lineHeight: '16.41px',
    fontWeight: 700,
    marginBottom: 10,
  },
  item: {
    display: 'flex',

    '&:not(:last-child)': {
      marginBottom: 2,
    },
  },
})
