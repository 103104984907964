import { Notification } from './Notification/Notification'
import { ModalPortal } from './ModalPortal/ModalPortal'
import { StaticControls } from './StaticControls/StaticControls'
import { SnackbarProvider } from './SnackbarProvider/SnackbarProvider'
import { PusherGlobalEvents } from './PusherGlobalEvents/PusherGlobalEvents'

export const RootComponent = {
  Notification,
  ModalPortal,
  StaticControls,
  SnackbarProvider,
  PusherGlobalEvents,
}
