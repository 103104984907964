import { RequestMethods } from 'utils/axios/methods'
import { call, put, select } from 'redux-saga/effects'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { TUser, TUsers } from 'redux/modules/users/TReducer'
import { Actions_Users } from 'redux/modules/users/Actions'
import TableFilterReduxAdapter, { TTableFilterRedux } from 'utils/helpers/table'
import type { PayloadAction } from '@reduxjs/toolkit'

const { get } = RequestMethods()

type TGetUsersFilters = {
  hideStaff: boolean
  hideDeleted: boolean
} & TTableFilterRedux

export default function* SagaPage_Users({ payload }: PayloadAction<TTableFilterRedux>) {
  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting and saving users ----------------------//
    const getFilter = (state: TState) => state.users.filter
    const getUsers = (state: TState) => state.users.data

    const filter: ReturnType<typeof getFilter> = yield select(getFilter)
    const oldUsers: ReturnType<typeof getUsers> = yield select(getUsers)

    const isNeedToConcatData = !!payload

    const formData: TGetUsersFilters = {
      ...filter,
      hideStaff: true,
      hideDeleted: true,
    }

    const response: TStoreTemplate<TUsers> = yield call(async () => await get<TUsers>('/users', formData))
    const newUsers = response.data?.users || []
    const responseInfo = response.responseInfo

    const data = isNeedToConcatData ? TableFilterReduxAdapter.concatData<TUser>(newUsers, oldUsers) : newUsers
    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newUsers, filter)

    yield put(Actions_Users.store.saveUsers({ data, responseInfo, isAllDataLoaded }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
