import React, { FC, Suspense, useState } from 'react'
import { Controls } from 'assets/images/Controls'
import { Shared } from 'ui/shared/index'
import DateConverter from 'utils/helpers/date'
import Dialog from '@mui/material/Dialog'
import { RangeCalendarModal } from './RangeCalendarModal/RangeCalendarModal'

export type TDatePickerEvent = (startDate?: Date | null, endDate?: Date | null) => void

const dateToString = (startDate?: Date | null, endDate?: Date | null): string => {
  let result = ' '
  if (DateConverter.isValid(startDate)) {
    result += DateConverter.getDateStringWithoutTime(startDate)
  }

  if (endDate) {
    result += `${DateConverter.isValid(startDate) ? ' - ' : ''}${DateConverter.getDateStringWithoutTime(endDate)}`
  }

  if (DateConverter.isSameDay(startDate, endDate)) {
    result = DateConverter.getDateStringWithoutTime(endDate)
  }

  return result
}

interface IProps {
  title?: string
  name: string
  onSubmit: TDatePickerEvent
  startDate?: Date | null
  endDate?: Date | null
}

const RangeDatePicker: FC<IProps> = ({ onSubmit, startDate, endDate, title, name }) => {
  const inputValue = dateToString(startDate, endDate)

  const [isOpenModal, seModalOpen] = useState(false)

  const handleOpenModal = () => {
    seModalOpen(true)
  }

  const handleCloseModal = () => {
    seModalOpen(false)
  }

  const onDatesChange = (startDate?: Date | null | undefined, endDate?: Date | null | undefined) => {
    const isStartDateSelected = !!startDate
    const isEndDateSelected = !!endDate
    const isOnlyStartDateSelected = isStartDateSelected && !isEndDateSelected
    const isOnlyEndDateSelected = !isStartDateSelected && isEndDateSelected
    if (isOnlyStartDateSelected) {
      onSubmit(startDate, startDate)
    } else if (isOnlyEndDateSelected) {
      onSubmit(endDate, endDate)
    } else {
      onSubmit(startDate, endDate)
    }
    handleCloseModal()
  }

  return (
    <div>
      <Shared.InputField
        value={inputValue}
        onClick={handleOpenModal}
        EndAdornment={Controls.Calendar}
        title={title}
        name={name}
        inputProps={{ autoComplete: 'off' }}
      />

      <Dialog onClose={handleCloseModal} aria-labelledby="range-calendar-dialog" open={isOpenModal}>
        <Suspense fallback={<Shared.Preloader color={'secondary'} />}>
          <RangeCalendarModal
            initialStartDate={startDate}
            initialEndDate={endDate}
            onDatesChange={onDatesChange}
            onClose={handleCloseModal}
          />
        </Suspense>
      </Dialog>
    </div>
  )
}

export default RangeDatePicker
