import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Positions } from './TActions'
import { TPosition, TPositionsFilter } from './TReducer'
import { TStoreTemplateWithFilter } from 'redux/config/root-types'

const { SagaEvents, StoreActions } = ActionTypes_Positions

export const Actions_Positions = {
  saga: {
    getPositions: createAction(SagaEvents.GET_POSITIONS),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    savePositions: createAction<TStoreTemplateWithFilter<TPosition[]>>(StoreActions.SAVE_POSITIONS),
    changeFilter: createAction<TPositionsFilter>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TPositionsFilter>(StoreActions.INCREASE_FILTER_OFFSET),
    reset: createAction(StoreActions.RESET),
  },
}
