import { TState, TStoreTemplate } from 'redux/config/root-types'
import { call, put, select } from 'redux-saga/effects'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { TParkings } from 'redux/modules/parkings/TReducer'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TTableFilterRedux } from 'utils/helpers/table'
import { PayloadAction } from '@reduxjs/toolkit'

const { get } = RequestMethods()

export default function* SagaWorker_LoadAllParkings(action?: PayloadAction<TTableFilterRedux>) {
  yield put(Actions_Parkings.store.showLoader())

  try {
    const getFilter = (state: TState) => state.parkings.filter
    const filter: ReturnType<typeof getFilter> = yield select(getFilter)
    const isNeedToConcatData = !!action?.payload
    const formData = {
      organizationName: filter.organizationName === null ? undefined : filter.organizationName,
      parkingName: filter.parkingName === null ? undefined : filter.parkingName,
      parkingImei: filter.imei === null ? undefined : filter.imei,
      showDrainedBattery: filter.batteryCharge === null ? undefined : filter.batteryCharge,
      hideFreeStations: filter.hideFreeStations === null ? undefined : filter.hideFreeStations,
      hideNoFreeStations: filter.hideNoFreeStations === null ? undefined : filter.hideNoFreeStations,
      hideOneFreeStation: filter.hideOneFreeStation === null ? undefined : filter.hideOneFreeStation,
      limit: filter.limit,
      orderBy: filter.orderBy,
      sorting: filter.sorting,
      offset: !isNeedToConcatData ? 0 : filter.offset,
    }
    //------------------------- Getting and saving parkings ---------------------------//
    const parkingResponse: TStoreTemplate<TParkings> = yield call(
      async () => await get<TParkings>('/parking', formData),
    )
    const parkings = parkingResponse.data?.parking || []
    yield put(Actions_Parkings.store.saveAllParkings(parkings))
    const result: TStoreTemplate<TTrailerTypes> = yield call(async () => await get<TTrailerTypes>('/trailer_types'))
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(result))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Parkings.store.hideLoader())
  }
}
