import React, { FC } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import { Shared } from 'ui/shared'
import { usePromoCodeNameFilter } from './PromoCodeNameFilter-ViewModel'
import { Grid } from '@mui/material'

export const DAY_WIDTH = 50

const PromoCodeNameFilter: FC = () => {
  const { classes } = useStyles()
  const { t, onCancel, form, isFilterOn, onClearFilter } = usePromoCodeNameFilter()
  const { handleSubmit, register, formValidation } = form

  return (
    <Shared.Card style={{ width: 375 }}>
      <Shared.CardTitle title={'Promocode'} />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Shared.Divider />
        </Grid>
        <Grid item xs={12}>
          <Shared.InputField
            name="promoCodeName"
            title={'Name'}
            register={register(formValidation.promoCodeName)}
            type="text"
          />
        </Grid>
        <Grid item xs={6} className={classes.buttons}>
          <Shared.Button className={classes.button} title={'No'} onClick={onCancel} color={'secondary'} />
          <Shared.Button className={classes.button} width={'100%'} title={'Yes'} type="submit" color={'primary'} />
        </Grid>
        {isFilterOn && (
          <Shared.Button
            className={classes.button}
            width={'100%'}
            title={'Clear filter'}
            type="submit"
            color={'primary'}
            onClick={onClearFilter}
          />
        )}
      </form>
    </Shared.Card>
  )
}

export default PromoCodeNameFilter

const useStyles = makeStyles()((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
  },
  button: {
    margin: 4,
  },
}))
