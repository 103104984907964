import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Shared } from 'ui/shared'
import { useParkingStatusFilter } from './ParkingStatusFilter-ViewModel'
import { Autocomplete, Checkbox, List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material'

export const ParkingStatusFilter: FC = () => {
  const { data, onSubmit, onCancel, isFilterOn, onClearFilter, checkedIds, handleToggle, classes } =
    useParkingStatusFilter()
  return (
    <Shared.Card style={{ width: 375 }}>
      <Shared.CardTitle title={'Status'} />
      <Grid item xs={12}>
        <Shared.Divider />
      </Grid>
      <Grid item xs={12}>
        <List className={classes.list}>
          {data.map((status) => {
            const labelId = `checkbox-list-label-${status.id}`
            return (
              <ListItem key={status.id} role={undefined} dense button onClick={handleToggle(status.id)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checkedIds.indexOf(status.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    color={'primary'}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={status.title || '-'}
                  classes={{ root: classes.listItemText }}
                  disableTypography
                />
              </ListItem>
            )
          })}
        </List>
      </Grid>
      <Grid item xs={6} className={classes.buttons}>
        <Shared.Button className={classes.button} title={'Cancel'} onClick={onCancel} color={'secondary'} />
        <Shared.Button
          className={classes.button}
          width={'100%'}
          title={'Submit'}
          onClick={onSubmit}
          color={'primary'}
        />
      </Grid>
      {isFilterOn && (
        <Shared.Button width={'100%'} title={'Clear filter'} type="submit" color={'primary'} onClick={onClearFilter} />
      )}
    </Shared.Card>
  )
}
