import React, { FC } from 'react'
import Typography from '@mui/material/Typography'

export type TCellKey = string | 'action'

export type TableCellProps = {
  cellData: string | null | undefined
}

export const TableSimpleCell: FC<TableCellProps> = ({ cellData }) => {
  const value = cellData === null || cellData === undefined ? '-' : cellData

  return (
    <Typography title={value} noWrap>
      {value}
    </Typography>
  )
}
