import React, { FC } from 'react'
import TrailerTypeItem from 'ui/shared/TrailerTypeList/TrailerTypeItem'
import { headerHeight } from 'ui/root-components/StaticControls/StaticControls-ViewModel'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useTrailerTypeList } from 'ui/shared/TrailerTypeList/TrailerTypeList-ViewModel'

export const trailerTypesHeight = headerHeight

interface IProps {
  onClick: (id: number) => void
  isSelected: (id: number) => boolean
  allIsActive?: boolean
  children?: React.ReactNode
}

const TrailerTypeList: FC<IProps> = ({ onClick, isSelected, children, allIsActive }) => {
  const vm = useTrailerTypeList(onClick, isSelected)
  const { classes, onChange, sortedTrailerTypes } = vm
  return (
    <Tabs
      value={false}
      onChange={onChange}
      indicatorColor="primary"
      textColor="secondary"
      variant="scrollable"
      scrollButtons
      aria-label="scrollable types"
      classes={{
        root: classes.root,
        indicator: classes.indicator,
        scrollButtons: classes.scrollButtons,
        scroller: classes.scroller,
        flexContainer: classes.flexContainer,
      }}
      allowScrollButtonsMobile
    >
      {sortedTrailerTypes.map(({ type }, index) => (
        <Tab
          id={`scrollable-auto-tab-${index}`}
          aria-controls={`scrollable-auto-tabpanel-${index}`}
          disabled={!(allIsActive || type.isActive)}
          centerRipple
          classes={{ root: classes.tab, disabled: classes.tabDisabled }}
          key={type.id}
          label={<TrailerTypeItem isSelected={isSelected(type.id)} type={type} />}
        />
      ))}
      {children}
    </Tabs>
  )
}

export default React.memo(TrailerTypeList)
