import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'

export const usePhoto = () => {
  const dispatch = useDispatch()
  const [choosen, setChoosen] = useState<number>(1)
  const rent = useSelector(({ modals }: TState) => modals.data)
  const cdnUrl = useSelector((state: TState) => state.settings.data?.cdnUrl || '')

  const closeModal = () => {
    dispatch(Actions_Trailers.store.showPhoto())
    dispatch(Actions_Modals.store.hideModal())
  }

  const onImageClick = useCallback(
    (image: string) => {
      if (image) {
        window.open(cdnUrl + image, '_blank')
      }
    },
    [cdnUrl],
  )

  return {
    closeModal,
    choosen,
    setChoosen,
    rent,
    cdnUrl,
    onImageClick,
  }
}
