import React, { FC } from 'react'
import { useAddParkingToOrganization } from 'ui/modals/AddParkingToOrganization/AddParkingToOrganization-ViewModel'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { Form } from 'utils/helpers/form'

export const AddParkingToOrganization: FC = () => {
  const vm = useAddParkingToOrganization()
  const { closeModal, form, isLoading, t } = vm
  const { errors, handleSubmit, control, parkingOptions } = form

  return (
    <Shared.Card style={{ width: 375 }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
          </Grid>

          <Grid item xs={12}>
            <Shared.Dropdown
              name="parkingId"
              label={t('parking')}
              options={parkingOptions}
              fullWidth
              control={control}
              rules={Form.required}
              error={errors.parkingId?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
            />
            <Shared.Button title={t('submit')} type={'submit'} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
