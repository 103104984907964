import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { TBreakdowns } from 'redux/modules/breakdowns/TReducer'
import { Actions_Breakdowns } from 'redux/modules/breakdowns/Actions'

const { get } = RequestMethods()

export default function* SagaPage_Breakdowns() {
  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting and saving organizations ----------------------//

    const breakdowns: TStoreTemplate<TBreakdowns> = yield call(
      async () =>
        await get<TBreakdowns>('/trailers/issueReports', {
          hideResolved: false,
          hideRejected: false,
          hideConfirmed: false,
        }),
    )

    yield put(Actions_Breakdowns.store.saveBreakdowns(breakdowns))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
