import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import { TStoreTemplate } from 'redux/config/root-types'
import { TVeriffInfo } from 'redux/modules/currentUser/TReducer'

const client = RequestMethods()

export interface ISagaWorker_GetVeriffInfo {
  id: number
}

export default function* SagaWorker_GetVeriffInfo(action: PayloadAction<ISagaWorker_GetVeriffInfo>) {
  const { id } = action.payload

  yield put(Actions_CurrentUser.store.showLoader())

  try {
    const veriffInfo: TStoreTemplate<TVeriffInfo> = yield call(
      async () => await client.get<TVeriffInfo>(`/users/${id}/verification`, { id: id }),
    )
    yield put(Actions_CurrentUser.store.saveVeriffInfo(veriffInfo))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentUser.store.hideLoader())
  }
}
