import { createReducer } from '@reduxjs/toolkit'
import { IStateStations } from './TReducer'
import { Actions_Stations } from './Actions'

const initialState: IStateStations = {
  isLoading: false,
  data: null,
  responseInfo: null,
  selectedTrailerTypeIds: [],
  filter: {
    stationName: null,
    organizationName: null,
    stationImei: null,
    stationNumber: null,
    stationStatus: null,
    imei: null,
    gvmnNumber: null,
    connection: null,
    lock: null,
  },
}

const ReducerStations = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Stations.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Stations.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Stations.store.saveStations, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Stations.store.clearStations, (state) => {
      state.data = null
    })
    .addCase(Actions_Stations.store.changeFilter, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Stations.store.changeSelectedTrailerTypesIds, (state, action) => {
      state.selectedTrailerTypeIds = action.payload
    })
})

export default ReducerStations
