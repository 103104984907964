import React, { useMemo } from 'react'
import { Shared } from '..'
import { DEFAULT_COLUMN_WIDTH, IColumn } from '../Table/Table'
import { makeStyles } from 'tss-react/mui'
import { ITableIconCell } from '../TableIconCell/TableIconCell'

type IProps = {
  column: IColumn
  cellData: any
}

export const TableCell = ({ cellData, column }: IProps) => {
  const componentType = column?.componentType

  const { classes } = useStyles({ column })

  const isArray = Array.isArray(cellData)

  return (
    <div className={classes.cell}>
      {componentType === 'link' && <Shared.TableLinkCell {...cellData} />}

      {componentType === 'checkbox' && <Shared.TableCheckboxCell {...cellData} />}

      {componentType === 'status' && <Shared.TableStatusCell statusColor={cellData} />}

      {componentType === 'charge' && <Shared.TableBatteryCell {...cellData} />}

      {componentType === 'batteryCharge' && <Shared.TableBatteryChargeCell cellData={cellData} />}

      {componentType === 'action' && !isArray && <Shared.TableIconCell value={cellData as ITableIconCell} />}

      {componentType === 'action' && isArray && (
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexGrow: 1 }}>
          {cellData.map((_itemValue: ITableIconCell, index: number) => (
            <Shared.TableIconCell value={_itemValue} key={index} />
          ))}
        </div>
      )}

      {!componentType && !isArray && <Shared.TableSimpleCell cellData={cellData} />}
    </div>
  )
}

interface IStyles {
  column?: IColumn
}
const useStyles = makeStyles<IStyles>()((theme, { column }) => ({
  cell: {
    padding: '0.4em',
    borderRight: `1px solid ${theme.palette.background.default}`,
    borderBottom: `1px solid ${theme.palette.background.default}`,
    display: column ? 'flex' : 'none',
    flexBasis: column?.width || DEFAULT_COLUMN_WIDTH,
    flexGrow: 1,
    flexShrink: 1,
    minWidth: column?.minWidth || 0,
    maxWidth: column?.maxWidth || 'none',
    alignItems: 'center',
    '&:first-of-type': {
      borderLeft: `1px solid ${theme.palette.background.default}`,
    },
  },
}))
