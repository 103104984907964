import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import { Form } from 'utils/helpers/form'
import { I18nModal } from 'utils/i18n/i18nModal'

interface IForm {
  promoCodeName: string | null
}

const formValidation = {
  promoCodeName: Form.combine(Form.required, Form.minLength(1)),
}

export const usePromoCodeNameFilter = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.promoCodeCreate)
  const { promoCodeName } = useSelector(
    ({ promoCode }: TState) => ({
      promoCodeName: promoCode.promoCodeNameFilter,
    }),
    shallowEqual,
  )
  const { handleSubmit, register, errors, control, watch } = useForm<IForm>({
    defaultValues: { promoCodeName: promoCodeName ? promoCodeName : null },
  })
  const isFilterOn = promoCodeName ? true : false

  const onSubmit = (formData: IForm) => {
    formData.promoCodeName && dispatch(Actions_PromoCode.store.setPromoCodeNameFilter(formData.promoCodeName))
    dispatch(Actions_Modals.store.hideModal())
  }

  const onCancel = () => dispatch(Actions_Modals.store.hideModal())

  const onClearFilter = () => {
    dispatch(Actions_PromoCode.store.setPromoCodeNameFilter(null))
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    onCancel,
    form: {
      formValidation,
      errors,
      register,
      control,
      handleSubmit: handleSubmit(onSubmit),
      watch,
    },
    isFilterOn,
    onClearFilter,
  }
}
