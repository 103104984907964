import React, { forwardRef } from 'react'
import { SnackbarContent as NotistakSnackbarContent, SnackbarKey } from 'notistack'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { TNotificationType } from 'redux/modules/notification/TReducer'
import { useSnackbarContent } from './SnackbarContent-ViewModel'

type TProps = {
  id: SnackbarKey
  message: React.ReactNode
  type?: TNotificationType
}

const SnackbarContent = forwardRef<HTMLDivElement, TProps>(({ id, message, type }, ref) => {
  const vm = useSnackbarContent(id, type)
  const { classes, handleDismiss, onCardClick } = vm

  return (
    <NotistakSnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }} onClick={onCardClick}>
          {message}
          <IconButton className={classes.closeButton} onClick={handleDismiss} size="large">
            <CloseIcon />
          </IconButton>
        </CardActions>
      </Card>
    </NotistakSnackbarContent>
  )
})

export default SnackbarContent
