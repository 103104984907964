import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { TCurrentTrailer } from 'redux/modules/currentTrailer/TReducer'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'

const client = RequestMethods()

export interface ISagaWorker_AddTrailerToOrganization {
  trailerId: number
  organizationId: number | ''
}

export default function* SagaWorker_AddTrailerToOrganization(
  action: PayloadAction<ISagaWorker_AddTrailerToOrganization>,
) {
  const { trailerId, organizationId } = action.payload
  let trailerStatus: number[] = []

  try {
    yield put(Actions_CurrentTrailer.store.showLoader())

    const data = { organization: [organizationId] }

    yield call(async () => await client.put<TCurrentTrailer>(`/trailers/${trailerId}`, data))

    //------------------------- Getting trailer statuses -------------------//

    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await client.get<TTrailerStatuses>('/trailers/statuses'),
    )
    if (t_statuses.data?.statuses) {
      trailerStatus = t_statuses.data.statuses.map((t_status) => t_status.id)
    }

    //------------------------- Getting and saving trailers ---------------------------//
    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () =>
        await client.get<TTrailers>('/trailers/list', {
          status: trailerStatus,
          organization: [organizationId],
        }),
    )
    yield put(Actions_Trailers.store.saveTrailers(trailerResponse))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Trailer successfully added to the organization',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CurrentTrailer.store.hideLoader())
}
