/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { Actions_Auth } from 'redux/modules/auth/Actions'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TAuth, TFormData_Auth } from 'redux/modules/auth/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AuthStorage } from 'utils/helpers/auth'

const { post } = RequestMethods()

export default function* SagaWorker_Auth(action: PayloadAction<TFormData_Auth>) {
  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting token and authorize ---------------------------//
    const auth: TStoreTemplate<TAuth> = yield call(async () => await post<TAuth>('/users/signin/email', action.payload))
    AuthStorage.signIn(auth.data!.token)
    yield put(Actions_Auth.store.saveAuth(auth))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_PageLoader.store.hideLoader())
}
