import React, { FC } from 'react'
import MaterialDivider from '@mui/material/Divider'
import { makeStyles } from 'tss-react/mui'

interface IProps {
  style?: {}
}

export const Divider: FC<IProps> = ({ style = {} }) => {
  const { classes } = useStyles()

  return <MaterialDivider style={{ ...style }} classes={classes} />
}

const useStyles = makeStyles()({
  root: {
    backgroundColor: '#E3E3E3',
  },
})
