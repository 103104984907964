import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Actions_ParentOrganizations } from 'redux/modules/parentOrganizations/Actions'

export const useParentOrganizationActivation = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.parentOrganizationActivation)

  const { id, isActive } = useSelector(({ modals }: TState) => modals.data)
  const title = useMemo(() => (isActive ? t('deactivationTitle') : t('activationTitle')), [t, isActive])
  const message = useMemo(() => (isActive ? t('deactivationMessage') : t('activationMessage')), [t, isActive])

  const onSubmit = useCallback(() => {
    if (isActive) {
      dispatch(Actions_ParentOrganizations.saga.deactivateParentOrganization({ id: id }))
    } else {
      dispatch(Actions_ParentOrganizations.saga.activateParentOrganization({ id: id }))
    }
    dispatch(Actions_Modals.store.hideModal())
  }, [isActive, dispatch, id])

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  return { t, title, message, onSubmit, onCancel }
}
