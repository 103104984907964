import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_ActivateStaff } from 'redux/sagas/events/staff/SagaWorker_ActivateStaff'
import { ISagaWorker_CreateStaff } from 'redux/sagas/events/staff/SagaWorker_CreateStaff'
import { ISagaWorker_DeactivateStaff } from 'redux/sagas/events/staff/SagaWorker_DeactivateStaff'
import { ISagaWorker_RestorePassword } from 'redux/sagas/events/staff/SagaWorker_RestorePassword'
import { ISagaWorker_UpdateStaff } from 'redux/sagas/events/staff/SagaWorker_UpdateStaff'
import { ISagaWorker_StaffDetails } from 'redux/sagas/pages/SagaPage_StaffDetails'
import { ISagaWorker_DeleteStaff } from 'redux/sagas/events/staff/SagaWorker_DeleteStaff'
import { TUser } from 'redux/modules/users/TReducer'
import { ActionTypes_CurrentStaffMember } from './TAction'

const { StoreActions, SagaEvents } = ActionTypes_CurrentStaffMember

export const Actions_CurrentStaffMember = {
  saga: {
    getStaffDetails: createAction<ISagaWorker_StaffDetails>(SagaEvents.GET_STAFF_DETAILS),
    createStaff: createAction<ISagaWorker_CreateStaff>(SagaEvents.CREATE_STAFF),
    updateStaff: createAction<ISagaWorker_UpdateStaff>(SagaEvents.UPDATE_STAFF),
    activateStaff: createAction<ISagaWorker_ActivateStaff>(SagaEvents.ACTIVATE_STAFF),
    deactivateStaff: createAction<ISagaWorker_DeactivateStaff>(SagaEvents.DEACTIVATE_STAFF),
    restorePasword: createAction<ISagaWorker_RestorePassword>(SagaEvents.RESTORE_PASSWORD),
    deleteStaff: createAction<ISagaWorker_DeleteStaff>(SagaEvents.DELETE_STAFF),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveCurrentStaffMember: createAction<TStoreTemplate<TUser>>(StoreActions.SAVE_CURRENT_STAFF_MEMBER),
    clearCurrentStaffMember: createAction(StoreActions.CLEAR_CURRENT_STAFF_MEMBER),
  },
}
