import { RequestMethods } from 'utils/axios/methods'
import { call, put, select } from 'redux-saga/effects'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import TableFilterReduxAdapter, { TTableFilterRedux } from 'utils/helpers/table'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TPromoCode } from 'redux/modules/promoCode/TReducer'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { IOrder } from 'utils/types'
import { TOrganizations } from 'redux/modules/organizations/TReducer'
import { Actions_Organizations } from 'redux/modules/organizations/Actions'
import { format } from 'date-fns'

const { get } = RequestMethods()

export default function* SagaWorker_GetPromoCode(action?: PayloadAction<TTableFilterRedux>) {
  try {
    yield put(Actions_PromoCode.store.showLoader())
    const getFilter = (state: TState) => state.promoCode.filter
    const getPromoCodeList = (state: TState) => state.promoCode.data

    const filter: ReturnType<typeof getFilter> = yield select(getFilter)

    const oldPromoCodeList: ReturnType<typeof getPromoCodeList> = yield select(getPromoCodeList)
    const getSelectedTrailerTypeIds = (state: TState) => state.promoCode.selectedTrailerTypeIds
    const getSelectedOrganizationIds = (state: TState) => state.promoCode.selectedOrganizationIds
    const getSelectedDate = (state: TState) => state.promoCode.dateFilter
    const getSelectedBonusMinutes = (state: TState) => state.promoCode.bonusMinutesFilter
    const getSelectedPromoCodeName = (state: TState) => state.promoCode.promoCodeNameFilter

    const types: ReturnType<typeof getSelectedTrailerTypeIds> = yield select(getSelectedTrailerTypeIds)
    const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
    const dateFilter: ReturnType<typeof getSelectedDate> = yield select(getSelectedDate)
    const bonusMinutesFilter: ReturnType<typeof getSelectedBonusMinutes> = yield select(getSelectedBonusMinutes)
    const promoCodeNameFilter: ReturnType<typeof getSelectedPromoCodeName> = yield select(getSelectedPromoCodeName)
    const isNeedToConcatData = !!action?.payload

    const formData = {
      types: types,
      organization: organization,
      offset: !isNeedToConcatData ? 0 : filter.offset,
      toDate: dateFilter !== null ? format(dateFilter, 'yyyy-MM-dd') + 'T23:59:59.999Z' : undefined,
      code: promoCodeNameFilter === null ? undefined : promoCodeNameFilter.replace(/\s/g, ''),
      bonusMinutes: bonusMinutesFilter === null ? undefined : bonusMinutesFilter,
      limit: filter.limit,
      orderBy: filter.orderBy,
      sorting: filter.sorting,
    }
    //------------------------- Getting and saving promo codes ----------------------//

    const response: TStoreTemplate<TPromoCode[]> = yield call(
      async () => await get<TPromoCode[]>('/promo_codes', formData),
    )
    const result: TStoreTemplate<TTrailerTypes> = yield call(async () => await get<TTrailerTypes>('/trailer_types'))
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(result))
    const orgs: TStoreTemplate<TOrganizations> = yield call(
      async () => await get<TOrganizations>('/organizations', { hideInactive: true }),
    )
    yield put(Actions_Organizations.store.saveOrganizations(orgs))
    const newPromoCodeList = response.data || []
    const responseInfo = response.responseInfo

    const data = isNeedToConcatData
      ? TableFilterReduxAdapter.concatData<TPromoCode>(newPromoCodeList, oldPromoCodeList)
      : newPromoCodeList
    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newPromoCodeList, filter)

    yield put(Actions_PromoCode.store.savePromoCode({ data, responseInfo, isAllDataLoaded }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PromoCode.store.hideLoader())
  }
}
