import { useRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useOutsideAlerter } from 'ui/hooks/useOutsideClick'

export const useInfoWindow = (onClickOutside: () => void) => {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, onClickOutside)

  const { classes, cx } = useStyles()

  return {
    classes,
    cx,
    wrapperRef,
  }
}

const useStyles = makeStyles()((theme) => ({
  root: {
    transform: 'translate(45px, -50%)',
    top: -57,
    position: 'absolute',
    transition: '1s',
    width: 235,
    height: 198,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: '16px',
    zIndex: 100,
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  cardTail: {
    position: 'absolute',
    width: 20,
    height: 40,
    zIndex: 100,
    left: -18,
    bottom: 76,
  },
}))
