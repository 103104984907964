import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_CustomRoles } from './TActions'
import { TCustomRoles } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { StoreActions, SagaEvents } = ActionTypes_CustomRoles

export const Actions_CustomRoles = {
  saga: {
    getRoles: createAction(SagaEvents.GET_ROLES),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveRoles: createAction<TStoreTemplate<TCustomRoles>>(StoreActions.SAVE_ROLES),
  },
}
