import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useEffect, useMemo } from 'react'
import { IOrganization } from 'redux/modules/organizations/TReducer'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { Actions_Organizations } from 'redux/modules/organizations/Actions'
import { Actions_ParentOrganizations } from 'redux/modules/parentOrganizations/Actions'
import { TParentDropdownOption } from '../Dropdown/Select'
import { TParentOrganization } from 'redux/modules/parentOrganizations/TReducer'

interface IProps {
  isSelected?: (id: number) => boolean
  watch?: (name?: string, defaultValue?: number[]) => number[]
  name: string
  loadOptions: boolean
  filterOptions?: (item: TDropdownOption) => boolean
}

export const useMultiOrganization = ({ isSelected = () => false, watch, loadOptions, name, filterOptions }: IProps) => {
  const dispatch = useDispatch()

  const organizations = useSelector(({ organizations }: TState) => organizations?.data?.organizations || [])
  const parentOrganizations = useSelector(({ parentOrganizations }: TState) => parentOrganizations.data || [])

  useEffect(
    function reloadOptions() {
      if (loadOptions) {
        dispatch(Actions_Organizations.saga.getOrganizations())
        dispatch(Actions_ParentOrganizations.saga.getParentOrganizations())
      }
    },
    [loadOptions, dispatch],
  )

  const organizationOptions = useMemo(() => {
    const result = toDropdownOptions(organizations)
    return filterOptions ? result.filter(filterOptions) : result
  }, [organizations, filterOptions])

  const parentOrganizationOptions = useMemo(() => toParentDropdownOptions(parentOrganizations), [parentOrganizations])

  let isSelectedOrganization = isSelected

  if (watch) {
    const selectedOrganizationIds = watch(name) || []

    isSelectedOrganization = (id: number) => (selectedOrganizationIds as number[]).includes(id)
  }

  const selectedIds: number[] = useMemo(
    () => organizationOptions.map(({ id }) => id).filter(isSelectedOrganization),
    [isSelectedOrganization, organizationOptions],
  )

  return {
    options: organizationOptions,
    parentOptions: parentOrganizationOptions,
    isSelected: isSelectedOrganization,
    value: selectedIds,
  }
}

export type TParentFilterOption = {
  id: number
  value: string | number
  label?: string
  parentId?: number
}

const toDropdownOptions = (organizations: IOrganization[]): TParentFilterOption[] =>
  organizations.map((organization) => ({
    id: organization.id,
    value: organization.id,
    label: organization.name,
    parentId: organization.parentId,
  }))

const toParentDropdownOptions = (organizations: TParentOrganization[]): TParentDropdownOption[] =>
  organizations.map((organization) => ({
    id: organization.id,
    title: organization.name,
  }))
