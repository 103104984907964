import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { Form } from 'utils/helpers/form'
import { useStaffCreate } from 'ui/modals/StaffCreate/StaffCreate-ViewModel'

export const StaffCreate: FC = () => {
  const vm = useStaffCreate()
  const {
    t,
    form,
    isLoading,
    closeModal,
    regionOptions,
    parentsOptions,
    isSelectedParentOrganizations,
    isSelectedRegions,
  } = vm
  const { errors, handleSubmit, register, control, watch } = form

  return (
    <Shared.Card style={{ width: 375 }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={'Create staff'} />
            <Shared.Role
              type={'form'}
              showTitle={false}
              control={control}
              divider={false}
              hideBorder={false}
              label={t('role')}
              rules={Form.required}
              error={errors.role?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="name"
              title={t('name')}
              register={register(Form.required)}
              error={errors.name?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="login"
              title={t('login')}
              register={register(Form.required)}
              error={errors.login?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.MultiOrganization
              loadOptions
              type={'form'}
              showTitle={false}
              control={control}
              divider={false}
              hideBorder={false}
              label={t('additionalOrganizations')}
              watch={watch}
              rules={Form.multiDropdown}
              error={(errors.organization as any)?.message} // TODO: fix types
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Dropdown
              label={t('parentOrganizations')}
              name="parentOrganizations"
              options={parentsOptions}
              isSelected={isSelectedParentOrganizations}
              multiple
              fullWidth
              control={control}
              marginTop={0}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Dropdown
              label={t('regions')}
              name="regions"
              options={regionOptions}
              isSelected={isSelectedRegions}
              multiple
              fullWidth
              control={control}
              marginTop={0}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
            />
            <Shared.Button title={t('submit')} type={'submit'} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
