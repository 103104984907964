import { createReducer } from '@reduxjs/toolkit'
import { IStateCurrentParking } from './TReducer'
import { Actions_CurrentParking } from './Actions'

const initialState: IStateCurrentParking = {
  isLoading: false,
  data: null,
  responseInfo: null,
  selectedParkingInfo: null,
}

const ReducerCurrentParking = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_CurrentParking.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_CurrentParking.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_CurrentParking.store.saveCurrentParking, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_CurrentParking.store.clearCurrentParking, (state) => {
      state.data = null
    })
    .addCase(Actions_CurrentParking.store.changeSelectedParkingInfo, (state, action) => {
      state.selectedParkingInfo = action.payload
    })
    .addCase(Actions_CurrentParking.store.clearSelectedParkingInfo, (state) => {
      state.selectedParkingInfo = null
    })
    .addCase(Actions_CurrentParking.store.updateCurrentParkingStation, (state, action) => {
      if (!state.data) {
        return {
          ...state,
        }
      }
      const payload = action.payload
      const updatedStation = payload.data

      const newStations = state.data.stations.map((station) => {
        if (station.id === updatedStation?.id) {
          return updatedStation
        }
        return station
      })

      return {
        ...state,
        data: {
          ...state.data,
          stations: newStations,
        },
      }
    })
})

export default ReducerCurrentParking
