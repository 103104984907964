import { createReducer } from '@reduxjs/toolkit'
import { IStateTrailerTypeSubtype } from './TReducer'
import { Actions_TrailerTypeSubtype } from './Actions'

const initialState: IStateTrailerTypeSubtype = {
  isLoading: false,
  data: null,
  responseInfo: null,
}

const ReducerTrailerTypeSubtype = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_TrailerTypeSubtype.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_TrailerTypeSubtype.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_TrailerTypeSubtype.store.saveTrailerTypeSubtype, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
})

export default ReducerTrailerTypeSubtype
