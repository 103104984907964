import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_PageLoader } from './TActions'

const { StoreActions } = ActionTypes_PageLoader

export const Actions_PageLoader = {
  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
  },
}
