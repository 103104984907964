import { IOrder } from 'utils/types'
import { TTableFilter } from 'ui/shared/Table/Table'

export type TTableFilterRedux = {
  orderBy?: IOrder
  limit?: number
  offset?: number
  sorting?: string
  toDate?: Date | null
  types?: number[]
  organization?: number[]
}

export default class TableFilterReduxAdapter {
  static DEFAULT_FILTERS: TTableFilterRedux = {
    limit: 30,
    offset: 0,
    orderBy: 'ASC',
  }

  static updatedDataFromServer = <TData, TFilter extends TTableFilterRedux>(
    oldData: TData[] | undefined,
    newData: TData[] | undefined,
    filter: TFilter,
  ): { updatedData: TData[]; isAllDataLoaded: boolean } => {
    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newData, filter)
    const updatedData = TableFilterReduxAdapter.concatData(newData, oldData)
    return { updatedData, isAllDataLoaded }
  }

  static checkIsAllDataLoaded = (newData: any[] | undefined | null, filter: TTableFilterRedux): boolean => {
    const _newData = newData || []

    const _limit = filter.limit || 0
    let isAllDataLoaded = false
    if (_newData.length < _limit) {
      isAllDataLoaded = true
    }
    return isAllDataLoaded
  }

  static concatData = <TData>(newData: TData[] | null | undefined, oldData: TData[] | null | undefined): TData[] => {
    const _oldData = oldData || []
    const _newData = newData || []
    return [..._oldData, ..._newData]
  }

  static resetOffset = <T extends TTableFilterRedux>(updatedFilter: TTableFilter, filter: TTableFilterRedux): T => {
    return { ...filter, ...updatedFilter, offset: 0 } as T
  }

  static increaseOffset = <T extends TTableFilterRedux>(filter: Partial<TTableFilterRedux>, dataLength?: number): T => {
    const limit = filter.limit || 0
    const offset = filter.offset || 0
    return { ...filter, offset: dataLength ? dataLength : 5 + offset } as T
  }
}
