enum StoreActions {
  IS_LOADER_SHOW = 'PARKING_NOTES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'PARKING_NOTES/IS_LOADER_HIDE',
  SAVE_PARKING_NOTES = 'PARKING_NOTES/SAVE_NOTES',
  RESET = 'PARKING_NOTES/RESET',
}

enum SagaEvents {
  GET_PARKING_NOTES = 'PARKING_NOTES/GET_NOTES',
  CREATE_PARKING_NOTE = 'PARKING_NOTES/CREATE_NOTE',
  DELETE_PARKING_NOTE = 'PARKING_NOTES/DELETE_NOTE',
}

export const ActionTypes_ParkingNotes = {
  SagaEvents,
  StoreActions,
}
