import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'
import { TStoreTemplate } from 'redux/config/root-types'
import { IOrganization } from 'redux/modules/organizations/TReducer'

const client = RequestMethods()

export interface ISagaWorker_DeactivateOrganization {
  id: number
}

export default function* SagaWorker_DeactivateOrganization(action: PayloadAction<ISagaWorker_DeactivateOrganization>) {
  const { id } = action.payload

  try {
    yield put(Actions_CurrentOrganization.store.showLoader())

    const organization: TStoreTemplate<IOrganization> = yield call(
      async () => await client.put<IOrganization>(`/organizations/${id}/deactivate`),
    )
    yield put(Actions_CurrentOrganization.store.saveCurrentOrganization(organization))

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Organization successfully deactivated',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentOrganization.store.hideLoader())
  }
}
