enum StoreActions {
  IS_LOADER_SHOW = 'TRAILER_NOTES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'TRAILER_NOTES/IS_LOADER_HIDE',
  SAVE_TRAILER_NOTES = 'TRAILER_NOTES/SAVE_NOTES',
  RESET = 'TRAILER_NOTES/RESET',
}

enum SagaEvents {
  GET_TRAILER_NOTES = 'TRAILER_NOTES/GET_NOTES',
  CREATE_TRAILER_NOTE = 'TRAILER_NOTES/CREATE_NOTE',
  DELETE_TRAILER_NOTE = 'TRAILER_NOTES/DELETE_NOTE',
}

export const ActionTypes_TrailerNotes = {
  SagaEvents,
  StoreActions,
}
