import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Trailers } from './TActions'
import { TTrailers } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_ChangeMapChargeStatus } from 'redux/sagas/events/trailer/SagaWorker_LoadTrailerMarkers'
import { ISagaWorker_LoadTrailersList } from 'redux/sagas/events/trailer/SagaWorker_LoadTrailersList'
import { ISagaWorker_DeleteTrailerFromTrailersList } from 'redux/sagas/events/trailer/SagaWorker_DeleteTrailerFromTrailersList'
import { ISagaWorker_GetTrailersListXlsx } from 'redux/sagas/events/trailer/SagaWorker_GetTrailersListXlsx'

const { SagaEvents, StoreActions } = ActionTypes_Trailers

export const Actions_Trailers = {
  saga: {
    getTrailers: createAction<ISagaWorker_ChangeMapChargeStatus>(SagaEvents.GET_TRAILERS),
    getAllTrailers: createAction(SagaEvents.GET_ALL_TRAILERS),
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    updateTrailers: createAction(SagaEvents.UPDATE_TRAILERS),
    loadTrailersListPage: createAction(SagaEvents.LOAD_TRAILERS_LIST_PAGE),
    getTrailersList: createAction<ISagaWorker_LoadTrailersList>(SagaEvents.GET_TRAILERS_LIST),
    deleteTrailerFromTrailersList: createAction<ISagaWorker_DeleteTrailerFromTrailersList>(
      SagaEvents.DELETE_TRAILER_FROM_TRAILERS_LIST,
    ),
    loadXlsxForTable: createAction<ISagaWorker_GetTrailersListXlsx>(SagaEvents.LOAD_TABLE_XLSX),
    getChecklistParamas: createAction(SagaEvents.GET_CHECKLIST_PARAMETERS),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveTrailers: createAction<TStoreTemplate<TTrailers>>(StoreActions.SAVE_TRAILERS),
    saveAllTrailers: createAction<TTrailers>(StoreActions.SAVE_ALL_TRAILERS),
    clearTrailers: createAction(StoreActions.CLEAR_TRAILERS),
    showChecklist: createAction(StoreActions.SHOW_CHECKLIST),
    showPhoto: createAction(StoreActions.SHOW_PHOTO),
  },
}
