import { Grid, IconButton, Typography } from '@mui/material'
import { Controls } from 'assets/images/Controls'
import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { makeStyles } from 'tss-react/mui'
import { Shared } from 'ui/shared'
import { useChecklist } from './Checklist-ViewModel'

export const Checklist: FC = () => {
  const { classes } = useStyles()
  const vm = useChecklist()
  const { closeModal, checklist, rent } = vm
  const checklistItems = useCallback(
    (item: any) => {
      return (
        <Grid
          container
          justifyContent={'space-between'}
          direction={'column'}
          display={'flex'}
          style={{
            marginLeft: 16,
            marginRight: 24,
            maxHeight: 50,
            marginBottom: 8,
          }}
          key={item.id}
        >
          <Typography
            style={{
              display: 'flex',
              verticalAlign: 'center',
              marginTop: 8,
              width: '40%',
              fontWeight: '400',
              fontSize: 18,
            }}
            color={'#3E3E3E'}
          >
            {item.title}
          </Typography>
          <Grid item className={classes.radioButtonContainer}>
            <Grid className={classes.radioButtonBorder}>
              {item.status === true ? <Grid className={classes.radioButtonContent} /> : null}
            </Grid>
          </Grid>
          <Grid item className={classes.radioButtonContainer}>
            <Grid className={classes.radioButtonBorder}>
              {item.status === false ? <Grid className={classes.radioButtonContent} /> : null}
            </Grid>
          </Grid>
        </Grid>
      )
    },
    [checklist],
  )

  return (
    <Shared.Card style={{ width: 1000, overflowY: 'auto', maxHeight: '100%' }}>
      <Grid container spacing={1}>
        <Grid
          container
          justifyContent={'space-between'}
          direction={'row'}
          style={{ marginBottom: 16 }}
          display={'flex'}
        >
          <Typography style={{ color: 'orange', fontWeight: '700', fontSize: 24 }}>
            {'Checklist and Comments'}
          </Typography>
          <IconButton onClick={closeModal}>
            <Controls.Close style={{ color: '#3C3B3B' }} />
          </IconButton>
        </Grid>
        <Grid
          container
          spacing={1}
          direction={'row'}
          display={'flex'}
          justifyContent={'space-between'}
          style={{ marginBottom: 16 }}
        >
          <Grid item xs={6} style={{ borderRight: '1 solid', borderColor: 'rgba(227, 227, 227, 1)' }}>
            <Typography style={{ color: '#3E3E3E', fontWeight: '700', fontSize: 20 }}>{'Checklist'}</Typography>
            <Grid
              container
              justifyContent={'space-between'}
              direction={'column'}
              display={'flex'}
              style={{
                marginLeft: 16,
                marginRight: 24,
                maxHeight: 50,
                marginTop: 8,
              }}
            >
              <Typography style={{ display: 'flex', verticalAlign: 'center', marginTop: 8, width: '28%' }}>
                {''}
              </Typography>
              <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Controls.Like />
                <Typography color={'#797979'} style={{ fontWeight: '400', fontSize: 12 }}>
                  {'Ok'}
                </Typography>
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: 30 }}>
                <Controls.Dislike />
                <Typography color={'#797979'} style={{ fontWeight: '400', fontSize: 12 }}>
                  {'Broken'}
                </Typography>
              </Grid>
            </Grid>
            {checklist.map((item: any) => checklistItems(item))}
          </Grid>
          <Grid item xs={6} style={{ height: 100 }}>
            <Typography style={{ color: '#3E3E3E', fontWeight: '700', fontSize: 20 }}>{'Comments'}</Typography>
            <Typography
              style={{
                color: rent.startCheck.comment ? '#3E3E3E' : '#D7D7D7',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                fontWeight: '400',
                fontSize: 18,
                marginTop: 8,
              }}
            >
              {rent.startCheck.comment ? rent.startCheck.comment : 'No comments'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Shared.Card>
  )
}

const useStyles = makeStyles()((theme) => ({
  radioButtonContainer: {
    justifyContent: 'center',
    margin: 16,
    alignItems: 'center',
    paddingLeft: 4,
  },
  radioButtonBorder: {
    height: 24,
    width: 24,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonContent: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: 'black',
  },
}))
