import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Shared } from 'ui/shared'
import { useUserToOrganizationDiscountDelete } from './UserToOrganizationDiscountDelete-ViewModel'

export const UserToOrganizationDiscountDelete: FC = () => {
  const { t, onSubmit, onCancel } = useUserToOrganizationDiscountDelete()

  return (
    <Shared.Card style={{ width: 375 }}>
      <Shared.CardTitle title={t('title')} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Shared.Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{t('message')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Shared.Button title={t('no')} onClick={onCancel} color={'primary'} />
        </Grid>
        <Grid item xs={6}>
          <Shared.Button width={'100%'} title={t('yes')} onClick={onSubmit} color={'secondary'} />
        </Grid>
      </Grid>
    </Shared.Card>
  )
}
