import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import { I18nModal } from 'utils/i18n/i18nModal'

export const usePromoCodeDelete = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.promoCodeDelete)
  const { selectedIdsState } = useSelector(
    ({ promoCode }: TState) => ({
      selectedIdsState: promoCode.selectedIds,
    }),
    shallowEqual,
  )

  const promoCodeId = useSelector(({ modals }: TState) => modals.data?.id)

  const onSubmit = () => {
    dispatch(
      Actions_PromoCode.saga.deletePromoCode(
        selectedIdsState.length > 0 ? { ids: selectedIdsState } : { id: promoCodeId },
      ),
    )
    dispatch(Actions_PromoCode.store.clearSelectedIds())
    dispatch(Actions_PromoCode.store.setBonusMinutesFilter(null))
    dispatch(Actions_PromoCode.store.setDateFilter(null))
    dispatch(Actions_PromoCode.store.setPromoCodeNameFilter(null))
    dispatch(Actions_Modals.store.hideModal())
  }

  const onCancel = () => dispatch(Actions_Modals.store.hideModal())

  return { t, onSubmit, onCancel }
}
