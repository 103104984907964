import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_TrailerNotes } from './TActions'
import { TTrailerNote } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_GetTrailerNotes } from 'redux/sagas/events/notes/SagaWorker_GetTrailerNotes'
import { ISagaWorker_CreateTrailerNote } from 'redux/sagas/events/notes/SagaWorker_CreateTrailerNote'
import { ISagaWorker_DeleteTrailerNote } from 'redux/sagas/events/notes/SagaWorker_DeleteTrailerNote'

const { SagaEvents, StoreActions } = ActionTypes_TrailerNotes

export const Actions_TrailerNotes = {
  saga: {
    getTrailerNotes: createAction<ISagaWorker_GetTrailerNotes>(SagaEvents.GET_TRAILER_NOTES),
    createTrailerNote: createAction<ISagaWorker_CreateTrailerNote>(SagaEvents.CREATE_TRAILER_NOTE),
    deleteTrailerNote: createAction<ISagaWorker_DeleteTrailerNote>(SagaEvents.DELETE_TRAILER_NOTE),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveTrailerNotes: createAction<TStoreTemplate<TTrailerNote[]>>(StoreActions.SAVE_TRAILER_NOTES),
    reset: createAction(StoreActions.RESET),
  },
}
