import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { makeStyles } from 'tss-react/mui'
import { Actions_TrailerStatuses } from 'redux/modules/trailerStatuses/Actions'

export const useStatusFilter = () => {
  const { t } = useTranslation(I18nModal.statusFilter)
  const dispatch = useDispatch()
  const { classes } = useStyles()

  const data = useSelector(({ trailerStatuses }: TState) => trailerStatuses.data)
  const isLoading = useSelector(({ trailerStatuses }: TState) => trailerStatuses.isLoading)
  const initialCheckedIds = useSelector(({ trailerStatuses }: TState) => trailerStatuses.selectedStatusIds)

  const [checkedIds, setCheckedIds] = useState(initialCheckedIds)

  const handleToggle = (value: number) => () => {
    const currentIndex = checkedIds.indexOf(value)
    const newCheckedIds = [...checkedIds]

    if (currentIndex === -1) {
      newCheckedIds.push(value)
    } else {
      newCheckedIds.splice(currentIndex, 1)
    }

    setCheckedIds(newCheckedIds)
  }

  const isSelectAll = checkedIds.length === data?.statuses.length

  const handleToogleAll = () => {
    if (isSelectAll) {
      setCheckedIds([])
    } else {
      const allIds = data?.statuses.map(({ id }) => id) || []
      setCheckedIds(allIds)
    }
  }

  const onSubmit = () => {
    dispatch(Actions_TrailerStatuses.store.changeSelectedStatusesIds(checkedIds))
    dispatch(Actions_Modals.store.hideModal())
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    classes,
    closeModal,
    isLoading,
    onSubmit,
    data: data?.statuses || [],
    checkedIds,
    isSelectAll,
    handleToggle,
    handleToogleAll,
  }
}

const useStyles = makeStyles()((theme) => ({
  card: {
    width: 375,
    overflowY: 'auto',
    maxHeight: '100%',
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
  },
  listItemText: {
    // ...theme.typography.body1, TODO:
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  cancel: {
    margin: '0.875em 0',
  },
}))
