import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_StationStatuses } from './TActions'
import { TStationStatuses } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { StoreActions, SagaEvents } = ActionTypes_StationStatuses

export const Actions_StationStatuses = {
  saga: {
    getStationStatuses: createAction(SagaEvents.GET_STATION_STATUSES),
  },
  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveStationStatuses: createAction<TStoreTemplate<TStationStatuses>>(StoreActions.SAVE_STATION_STATUSES),
  },
}
