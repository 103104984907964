import React, { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { TNotificationMessage } from 'redux/modules/notification/TReducer'

type TProps = {
  title?: string
  message: string | TNotificationMessage[]
}

const Description: FC<TProps> = ({ title, message }) => {
  const { classes, cx } = useStyles()

  return (
    <div>
      {!!title && <p className={classes.title}>{title}</p>}
      {Array.isArray(message) &&
        message.map((item, index) => (
          <div className={classes.item} key={index}>
            <p className={classes.text}>{`${item.key}:`}</p>
            <p className={cx(classes.text, classes.value)}>{item.value}</p>
          </div>
        ))}

      {!Array.isArray(message) && <p className={classes.text}>{message}</p>}
    </div>
  )
}

export default Description

const useStyles = makeStyles()({
  title: {
    fontSize: 14,
    lineHeight: '16.41px',
    fontWeight: 700,
    marginBottom: 10,
  },
  item: {
    display: 'flex',

    '&:not(:last-child)': {
      marginBottom: 2,
    },
  },
  text: {
    fontSize: 14,
    lineHeight: '16.41px',
    flexShrink: 0,
  },
  value: {
    marginLeft: 8,
    wordBreak: 'break-word',
  },
})
