import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { ICurrentParkingHoliday, ICurrentParkingWorkday, TCurrentParking } from 'redux/modules/currentParking/TReducer'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const client = RequestMethods()

export interface ISagaWorker_UpdateParking {
  id: number
  data: {
    parkingCode: string
    organizationId: number | ''
    city: string
    street: string
    postIndex: string
    latitude: number
    longitude: number
    imei: string
    whitelist: number[]
    workdays: ICurrentParkingWorkday[]
    holidays: ICurrentParkingHoliday[]
  }
}

export default function* SagaWorker_UpdateParking(action: PayloadAction<ISagaWorker_UpdateParking>) {
  const { data, id } = action.payload
  try {
    yield put(Actions_CurrentParking.store.showLoader())

    yield call(async () => await client.put<TCurrentParking>(`/parking/${id}`, data))

    const { latitude, longitude } = data
    yield put(Actions_CurrentParking.store.changeSelectedParkingInfo({ id, lat: latitude, lng: longitude }))

    yield put(Actions_Modals.store.hideModal())

    yield put(Actions_Notifications.store.setNotification({ message: 'Parking successfully updated', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CurrentParking.store.hideLoader())
}
