import { useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import React, { useCallback, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { trailerTypesHeight } from 'ui/shared/TrailerTypeList/TrailerTypeList'
import sortBy from 'lodash.sortby'

export const useTrailerTypeList = (onClick: (id: number) => void, isSelected: (id: number) => boolean) => {
  const { classes } = useStyles()
  const trailerTypesData = useSelector(({ trailerTypes }: TState) => trailerTypes.data)
  const trailerTypes = useMemo(() => trailerTypesData || [], [trailerTypesData])
  const sortedTrailerTypes = useMemo(() => sortBy(trailerTypes, ['order']), [trailerTypes])

  const onChange = useCallback(
    (event: React.ChangeEvent<{}>, index: number) => {
      const typeId = sortedTrailerTypes[index]?.type.id
      onClick(typeId)
    },
    [onClick, sortedTrailerTypes],
  )

  return { classes, onChange, sortedTrailerTypes }
}

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.25)',
    height: `${trailerTypesHeight.lg}px`,
    flexShrink: 0,
    [theme.breakpoints.only('xl')]: {
      height: `${trailerTypesHeight.xl}px`,
    },
  },
  flexContainer: {
    alignItems: 'center',
  },
  typeList: {
    display: 'flex',
  },
  scroller: {
    display: 'flex',
  },
  indicator: {
    display: 'none',
  },
  scrollButtons: {
    width: 30,
  },
  tab: {
    padding: 0,
    textTransform: 'none',
  },
  tabDisabled: {
    opacity: 0.5,
  },
}))
