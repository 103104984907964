import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'

const { remove } = RequestMethods()

export interface ISagaWorker_DeleteUser {
  id: number
  navigateToUserTable: () => void
}

export default function* SagaWorker_DeleteUser(action: PayloadAction<ISagaWorker_DeleteUser>) {
  const { id, navigateToUserTable } = action.payload
  yield put(Actions_CurrentUser.store.showLoader())

  try {
    yield call(async () => await remove(`/users/${id}`))

    yield put(Actions_CurrentUser.store.clearCurrentUser())

    navigateToUserTable()
    yield put(Actions_Notifications.store.setNotification({ message: 'User deleted successfully', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentUser.store.hideLoader())
  }
}
