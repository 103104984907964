import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Staff } from 'redux/modules/staff/Actions'
import { I18nModal } from 'utils/i18n/i18nModal'

export const useRemoveStaffFromStaffTable = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.removeStaffFromStaffTable)

  const staffId = useSelector(({ modals }: TState) => modals.data.id)

  const onSubmit = () => {
    dispatch(Actions_Staff.saga.deleteStaff({ id: staffId }))
    dispatch(Actions_Modals.store.hideModal())
  }

  const onCancel = () => dispatch(Actions_Modals.store.hideModal())

  return { t, onSubmit, onCancel }
}
