import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { makeStyles } from 'tss-react/mui'

interface IFilterStatus {
  id: number
  status: boolean
  title: string
}

export const useParkingStatusFilter = () => {
  const dispatch = useDispatch()
  const { parkings } = useSelector(
    ({ parkings }: TState) => ({
      parkings: parkings,
    }),
    shallowEqual,
  )
  const data = [
    { id: 0, status: false, title: 'Free stations' },
    { id: 1, status: false, title: 'No free stations' },
  ] as IFilterStatus[]
  const isFilterOn = parkings.filter.hideFreeStations !== null ? true : false
  const initialCheckedIds =
    parkings.filter.hideFreeStations !== null ? (parkings.filter.hideNoFreeStations === true ? [0] : [1]) : []
  const [checkedIds, setCheckedIds] = useState(initialCheckedIds)
  const { classes } = useStyles()

  const onSubmit = useCallback(() => {
    if (checkedIds[0] === 0) {
      dispatch(
        Actions_Parkings.store.changeFilter({
          ...parkings.filter,
          hideFreeStations: false,
          hideNoFreeStations: true,
          hideOneFreeStation: false,
        }),
      )
    } else {
      dispatch(
        Actions_Parkings.store.changeFilter({
          ...parkings.filter,
          hideFreeStations: true,
          hideNoFreeStations: false,
          hideOneFreeStation: true,
        }),
      )
    }
    dispatch(Actions_Modals.store.hideModal())
  }, [dispatch, parkings, checkedIds])

  const handleToggle = (value: number) => () => {
    const currentIndex = checkedIds.indexOf(value)
    const newCheckedIds = [...checkedIds]

    if (currentIndex === -1) {
      if (checkedIds.length === 0) {
        newCheckedIds.push(value)
      } else {
        setCheckedIds([value])
        return
      }
    } else {
      newCheckedIds.splice(currentIndex, 1)
    }

    setCheckedIds(newCheckedIds)
  }

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  const onClearFilter = () => {
    dispatch(
      Actions_Parkings.store.changeFilter({
        ...parkings.filter,
        hideFreeStations: null,
        hideNoFreeStations: null,
        hideOneFreeStation: null,
      }),
    )
    dispatch(Actions_Modals.store.hideModal())
  }

  return { data, onSubmit, onCancel, isFilterOn, onClearFilter, classes, checkedIds, handleToggle }
}

const useStyles = makeStyles()((theme) => ({
  card: {
    width: 375,
    overflowY: 'auto',
    maxHeight: '100%',
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
  },
  listItemText: {
    // ...theme.typography.body1, TODO:
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  cancel: {
    margin: '0.875em 0',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 8,
  },
  button: {
    margin: 4,
  },
}))
