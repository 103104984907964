import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_UserNotes } from './TActions'
import { TUserNote } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_GetUserNotes } from 'redux/sagas/events/notes/SagaWorker_GetUserNotes'
import { ISagaWorker_CreateUserNote } from 'redux/sagas/events/notes/SagaWorker_CreateUserNote'
import { ISagaWorker_DeleteUserNote } from 'redux/sagas/events/notes/SagaWorker_DeleteUserNote'

const { SagaEvents, StoreActions } = ActionTypes_UserNotes

export const Actions_UserNotes = {
  saga: {
    getUserNotes: createAction<ISagaWorker_GetUserNotes>(SagaEvents.GET_USER_NOTES),
    createUserNote: createAction<ISagaWorker_CreateUserNote>(SagaEvents.CREATE_USER_NOTE),
    deleteUserNote: createAction<ISagaWorker_DeleteUserNote>(SagaEvents.DELETE_USER_NOTE),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveUserNotes: createAction<TStoreTemplate<TUserNote[]>>(StoreActions.SAVE_USER_NOTES),
    reset: createAction(StoreActions.RESET),
  },
}
