import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { TCurrentParking } from 'redux/modules/currentParking/TReducer'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { TParkings } from 'redux/modules/parkings/TReducer'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'

const client = RequestMethods()

export interface ISagaWorker_AddParkingToOrganization {
  parkingId: number
  organizationId: number | ''
}

export default function* SagaWorker_AddParkingToOrganization(
  action: PayloadAction<ISagaWorker_AddParkingToOrganization>,
) {
  const { parkingId, organizationId } = action.payload
  try {
    yield put(Actions_CurrentParking.store.showLoader())

    const data = { organizationId }

    yield call(async () => await client.put<TCurrentParking>(`/parking/${parkingId}`, data))

    //------------------------- Getting and saving parkings ---------------------------//

    const parkingResponse: TStoreTemplate<TParkings> = yield call(
      async () => await client.get<TParkings>('/parking', { organization: [organizationId] }),
    )
    yield put(Actions_Parkings.store.saveParkings({ ...parkingResponse }))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Parking successfully added to the organization',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CurrentParking.store.hideLoader())
}
