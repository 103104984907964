enum StoreActions {
  IS_LOADER_SHOW = 'TRAILER_TYPES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'TRAILER_TYPES/IS_LOADER_HIDE',
  SAVE_TRAILERTYPES = 'TRAILER_TYPES/SAVE_TRAILERTYPES',
  SELECT_TRAILER_TYPES_IDS = 'TRAILER_TYPES/SELECT_TRAILER_TYPES_IDS',
  SELECT_CURRENT_TRAILER_TYPE_ID = 'TRAILER_TYPES/SELECT_CURRENT_TRAILER_TYPE_ID',
}

enum SagaEvents {
  LOAD_PAGE = 'TRAILER_TYPES/LOAD_PAGE',
  CREATE_TRAILER_TYPE = 'TRAILER_TYPES/CREATE_TRAILER_TYPE',
  UPDATE_TRAILER_TYPE = 'TRAILER_TYPES/UPDATE_TRAILER_TYPE',
  ACTIVATE_TRAILER_TYPE = 'TRAILER_TYPES/ACTIVATE_TRAILER_TYPE',
  DEACTIVATE_TRAILER_TYPE = 'TRAILER_TYPES/DEACTIVATE_TRAILER_TYPE',
  DELETE_TRAILER_TYPE = 'TRAILER_TYPES/DELETE_TRAILER_TYPE',
}

export const ActionTypes_TrailerTypes = {
  SagaEvents,
  StoreActions,
}
