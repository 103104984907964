import React, { FC } from 'react'
import Box from '@mui/material/Box'

export type TableStatusCellProps = {
  statusColor: string
}

export const TableStatusCell: FC<TableStatusCellProps> = ({ statusColor }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
      <div style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: statusColor }} />
    </Box>
  )
}
