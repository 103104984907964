import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import { TPromoCode } from 'redux/modules/promoCode/TReducer'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import TableFilterReduxAdapter, { TTableFilterRedux } from 'utils/helpers/table'

const { get, remove, post } = RequestMethods()

export interface ISagaWorker_DeletePromoCode {
  id?: number
  ids?: number[]
}

export default function* SagaWorker_DeletePromoCode(action: PayloadAction<ISagaWorker_DeletePromoCode>) {
  const { id, ids } = action.payload
  yield put(Actions_PromoCode.store.showLoader())

  try {
    if (id) {
      yield call(async () => await remove<TPromoCode>(`/promo_codes/${id}`))
    } else if (ids) {
      yield call(async () => await post<TPromoCode>(`/promo_codes/bulk-delete/`, { id: ids }))
    }

    //------------------------- Getting and saving promo code list ----------------------//
    const getFilter = (state: TState) => state.promoCode.filter
    const filter: ReturnType<typeof getFilter> = yield select(getFilter)
    const updatedFilter = { ...filter, offset: 0 }

    yield put(Actions_PromoCode.store.changeFilter(updatedFilter))

    const formData: TTableFilterRedux = {
      ...updatedFilter,
    }

    const response: TStoreTemplate<TPromoCode[]> = yield call(
      async () => await get<TPromoCode[]>('/promo_codes', formData),
    )
    const newPromoCodeList = response.data || []
    const responseInfo = response.responseInfo

    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newPromoCodeList, updatedFilter)

    yield put(Actions_PromoCode.store.savePromoCode({ data: newPromoCodeList, responseInfo, isAllDataLoaded }))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Promo code successfully deleted', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PromoCode.store.hideLoader())
  }
}
