import { createReducer } from '@reduxjs/toolkit'
import { IStatePageLoader } from './TReducer'
import { Actions_PageLoader } from './Actions'

const initialState: IStatePageLoader = {
  isLoading: false,
}

const ReducerPageLoader = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_PageLoader.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_PageLoader.store.hideLoader, (state) => {
      state.isLoading = false
    })
})

export default ReducerPageLoader
