import { createReducer } from '@reduxjs/toolkit'
import { IStateSearch } from './TReducer'
import { Actions_Search } from './Actions'

const initialState: IStateSearch = {
  isLoading: false,
  data: [],
  searchTerm: '',
  responseInfo: null,
}

const ReducerSearch = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Search.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Search.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Search.store.saveSearchResult, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Search.store.clearSearchResult, (state) => {
      state.data = []
    })
    .addCase(Actions_Search.store.saveSearchTerm, (state, action) => {
      state.searchTerm = action.payload
    })
    .addCase(Actions_Search.store.clearSearchTerm, (state, action) => {
      state.searchTerm = ''
    })
})

export default ReducerSearch
