import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TUser } from 'redux/modules/users/TReducer'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_CurrentStaffMember } from 'redux/modules/currentStaffMember/Actions'
import { Actions_CurrentRole } from 'redux/modules/currentRole/Actions'
import { TStoreTemplate } from 'redux/config/root-types'
import SagaWorker_AccessGroups from 'redux/sagas/events/staff/SagaWorker_AccessGroups'
import SagaWorker_AccessRules from 'redux/sagas/events/staff/SagaWorker_AccessRules'
import { TCurrentRole } from 'redux/modules/currentRole/TReducer'
import SagaWorker_TemplateRoles from 'redux/sagas/events/SagaWorker_TemplateRoles'
import SagaWorker_GetParentOrganizations from '../events/parentOrganization/SagaWorker_GetParentOrganizations'
import SagaWorker_GetRegions from '../events/regions/SagaWorker_GetRegions'
import SagaWorker_Organizations from '../events/SagaWorker_Organizations'

const { get } = RequestMethods()

export interface ISagaWorker_StaffDetails {
  id: string
}

export default function* SagaPage_StaffDetails(action: PayloadAction<ISagaWorker_StaffDetails>) {
  const { id } = action.payload

  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting and saving staff member ----------------------//
    const user: TStoreTemplate<TUser> = yield call(async () => await get<TUser>(`/users/${id}`))
    yield put(Actions_CurrentStaffMember.store.saveCurrentStaffMember(user))
    //------------------------- Getting and saving organizations ---------------------------//
    yield call(SagaWorker_Organizations)
    //------------------------- Getting and saving parent organizations ----------------------//
    yield call(SagaWorker_GetParentOrganizations)
    //------------------------- Getting and saving regions ----------------------//
    yield call(SagaWorker_GetRegions)
    //------------------------- Getting and saving tempate roles ----------------------//
    yield call(SagaWorker_TemplateRoles)
    //------------------------- Getting and saving staff access groups ----------------------//
    yield call(SagaWorker_AccessGroups)
    //------------------------- Getting and saving staff access rules ----------------------//
    yield call(SagaWorker_AccessRules)
    //------------------------- Getting and saving current role ----------------------//
    const currentRole: TStoreTemplate<TCurrentRole> = yield call(
      async () => await get<TCurrentRole>(`/users/roles/${user.data?.roleId}`),
    )
    yield put(Actions_CurrentRole.store.saveCurrentRole(currentRole))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
