enum StoreActions {
  IS_LOADER_SHOW = 'BREAKDOWNS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'BREAKDOWNS/IS_LOADER_HIDE',
  SAVE_BREAKDOWNS = 'BREAKDOWNS/SAVE_BREAKDOWNS',
  CLEAR_BREAKDOWNS = 'BREAKDOWNS/CLEAR_BREAKDOWNS',
  EDIT_BREAKDOWN = 'BREAKDOWNS/EDIT_BREAKDOWN',
}

enum SagaEvents {
  GET_BREAKDOWNS = 'BREAKDOWNS/GET_BREAKDOWNS',
  UPDATE_BREAKDOWN = 'BREAKDOWNS/UPDATE_BREAKDOWN',
}

export const ActionTypes_Breakdowns = {
  SagaEvents,
  StoreActions,
}
