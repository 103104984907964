import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_Stations } from 'redux/modules/stations/Actions'
import { useTranslation } from 'react-i18next'
import { I18nPage } from 'utils/i18n/i18nPage'

export const useStationLockFilter = () => {
  const dispatch = useDispatch()
  const { stations } = useSelector(
    ({ stations }: TState) => ({
      stations: stations,
    }),
    shallowEqual,
  )
  const { t } = useTranslation(I18nPage.parkingDetails)
  const [value, setValue] = useState<string>(stations.filter.lock ? t(`${stations.filter.lock}`) : '')
  const data = [t('LOCK_CLOSED'), t('LOCK_OPENED'), t('LOCK_NULL'), t('LOCK_STUCKED'), t('LOCK_NOT_CLOSED')]
  const isFilterOn = stations.filter.lock ? true : false

  const onSubmit = useCallback(
    (value?: string) => {
      value === 'closed' && dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, lock: 'LOCK_CLOSED' }))
      value === 'open' && dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, lock: 'LOCK_OPENED' }))
      value === '-' && dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, lock: 'LOCK_NULL' }))
      value === 'stuck' && dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, lock: 'LOCK_STUCKED' }))
      value === 'not closed' &&
        dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, lock: 'LOCK_NOT_CLOSED' }))
      dispatch(Actions_Modals.store.hideModal())
    },
    [dispatch, value, stations],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  const onClearFilter = () => {
    dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, lock: null }))
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    data,
    onSubmit,
    onCancel,
    onChange,
    setValue,
    value,
    isFilterOn,
    onClearFilter,
  }
}
