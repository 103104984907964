/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Search } from 'redux/modules/search/Actions'
import { TSearchResults } from 'redux/modules/search/TReducer'

const { get } = RequestMethods()

export default function* SagaWorker_GetSearchResult() {
  try {
    yield put(Actions_Search.store.showLoader())

    const getSearchTerm = (state: TState) => state.search.searchTerm
    const searchTerm: ReturnType<typeof getSearchTerm> = yield select(getSearchTerm)

    const response: TStoreTemplate<TSearchResults> = yield call(
      async () => await get<TSearchResults>('/search', { searchStr: searchTerm }),
    )
    yield put(Actions_Search.store.saveSearchResult(response))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_Search.store.hideLoader())
}
