import { TState } from 'redux/config/root-types'
import { call, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AxiosRequestConfig } from 'axios'

const { download } = RequestMethods()

export default function* SagaWorker_GetRentsXlsx() {
  const getFilter = (state: TState) => state.rents.filter

  //------------------------- Getting request params ---------------------------//
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)

  const formData = {
    ...filter,
    limit: undefined,
    offset: undefined,
  }

  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig

  try {
    //------------------------- Getting report ---------------------------//
    yield call(async () => download('/rents/xlsx', formData, config, 'rents.xlsx'))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
