import { call, put } from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentStation } from 'redux/modules/currentStation/Actions'
import { RequestMethods } from 'utils/axios/methods'
import { AxiosRequestConfig } from 'axios'

const { download } = RequestMethods()

export interface ISagaWorker_GetStationQRCode {
  id: number
}

export default function* SagaWorker_GetStationQRCode(action: PayloadAction<ISagaWorker_GetStationQRCode>) {
  yield put(Actions_CurrentStation.store.showLoader())

  try {
    const id = action.payload.id

    const config = {
      responseType: 'blob',
    } as AxiosRequestConfig

    yield call(async () => download(`/stations/${id}/qrcode/pdf`, undefined, config))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentStation.store.hideLoader())
  }
}
