/* eslint react/jsx-key: 0 */
import React, { createContext, useEffect, useRef } from 'react'
import Paper from '@mui/material/Paper'
import { TCellKey } from '../TableSimpleCell/TableSimpleCell'
import { useTable } from 'ui/shared/Table/Table-ViewModel'
import { IOrder, TObject } from 'utils/types'
import InfiniteLoader from 'react-window-infinite-loader'
import AutoSizer from 'react-virtualized-auto-sizer'
import TableWithHeader from './TableWithHeader/TableWithHeader'
import { makeStyles } from 'tss-react/mui'
import { Shared } from 'ui/shared/index'

export const DEFAULT_COLUMN_WIDTH = 100

export type TTableFilter = {
  orderBy?: IOrder
  sorting?: string
}

export type TTableOnChangeFilter = (filter: TTableFilter) => void

export type TTableOnLoadMore = (startIndex: number, stopIndex: number) => Promise<void>

export type TTableRow = Record<string, any>

export type TColumns = IColumn[]
export type TComponentType = 'charge' | 'status' | 'checkbox' | 'link' | 'action' | 'batteryCharge'
export interface IColumn {
  dataKey: TCellKey
  width?: number
  minWidth?: number
  maxWidth?: number
  label: string
  disableSort?: boolean
  sortMethod?: Function
  componentType?: TComponentType
  id?: number
  isShowFilter?: boolean
  isFilterSelected?: boolean
  canHide?: boolean
}

interface IProps<T extends TTableRow> {
  columns: IColumn[]
  data: T[]
  height?: number | string
  onExcelClick?: () => void
  onViewColumnsClick?: () => void
  onFilterClick?: (dataKey: string) => void
  filter?: TTableFilter
  onChangeFilter?: TTableOnChangeFilter
  headerStyle?: TObject
  isLoading?: boolean
  onLoadMore?: TTableOnLoadMore
  isAllDataLoaded?: boolean
  tableClassName?: string
  isSortByServer?: boolean
}

export const StickyGridContext = createContext({
  headerHeight: 36,
})

export const Table = <T extends object>({
  onLoadMore = async () => {},
  filter,
  onChangeFilter,
  columns,
  data = [],
  height,
  onExcelClick,
  onViewColumnsClick,
  onFilterClick,
  isAllDataLoaded,
  isLoading,
  tableClassName,
  isSortByServer,
}: IProps<T>) => {
  const vm = useTable({ data, columns, filter, onChangeFilter, isSortByServer })
  const { sortedList, sortBy: _sortBy, sortDirection, onHeaderClick, columnHeight } = vm

  const infiniteLoaderRef = useRef<any>(null)
  const autoSizerRef = useRef<any>(null)
  const hasMountedRef = useRef(false)

  const { classes } = useStyles({ columnHeight })

  const isShowMenuButton = Boolean(onExcelClick || onViewColumnsClick)

  useEffect(() => {
    if (infiniteLoaderRef.current && hasMountedRef.current) {
      autoSizerRef.current._autoSizer?.children?.[0]?.scrollTo(0, 0)
      infiniteLoaderRef.current.resetloadMoreItemsCache()
    }
    hasMountedRef.current = true
  }, [_sortBy, sortDirection])

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: height ? height : '100%',
      }}
    >
      {isShowMenuButton && (
        <Shared.TableHeaderMenu
          columnHeight={columnHeight}
          onExcelClick={onExcelClick}
          onViewColumnsClick={onViewColumnsClick}
        />
      )}

      {!isShowMenuButton && (
        <div
          style={{
            position: 'absolute',
            right: 1,
            height: columnHeight,
            width: '1.5em',
            backgroundColor: '#FFF2DC',
            zIndex: 2,
            borderTop: '1px solid #797979',
            borderBottom: '1px solid #797979',
          }}
        />
      )}

      <Paper className={tableClassName} style={{ height: '100%', position: 'relative', boxShadow: 'none' }}>
        <AutoSizer ref={autoSizerRef}>
          {({ height, width }) => (
            <>
              <InfiniteLoader
                ref={infiniteLoaderRef}
                isItemLoaded={(index) => !!data[index]}
                itemCount={isAllDataLoaded ? data.length : Number.MAX_SAFE_INTEGER}
                loadMoreItems={onLoadMore as TTableOnLoadMore}
              >
                {({ onItemsRendered, ref }) => (
                  <TableWithHeader
                    itemSize={columnHeight}
                    height={height!}
                    itemCount={data.length}
                    headerHeight={columnHeight}
                    width={width!}
                    itemData={sortedList}
                    columns={columns}
                    isShowMenuButton={isShowMenuButton}
                    sortDirection={sortDirection}
                    sortBy={_sortBy as string}
                    onSortClick={onHeaderClick}
                    onFilterClick={onFilterClick}
                    className={classes.table}
                    innerRef={ref}
                    onItemsRendered={onItemsRendered}
                  >
                    {(props) => <Shared.TableRow {...props} columns={columns} />}
                  </TableWithHeader>
                )}
              </InfiniteLoader>
            </>
          )}
        </AutoSizer>
        {isLoading && <Shared.Preloader mask />}
      </Paper>
    </div>
  )
}

const useStyles = makeStyles<{ columnHeight: number }>()((theme, { columnHeight }) => ({
  table: {
    '&::-webkit-scrollbar': {
      width: '1.5em',
      height: '1.5em',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.text.secondary,
      borderRadius: 5,
      backgroundClip: 'content-box',
      border: '3px solid transparent',
    },

    '&::-webkit-scrollbar-track': {
      marginTop: columnHeight,
    },

    '&::-webkit-scrollbar-button': {
      height: 25,
      display: 'none',
    },
  },
}))
