import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { ActionTypes_AccessGroups } from './TAction'
import { TAccessGroupList } from './TReducer'

const { StoreActions, SagaEvents } = ActionTypes_AccessGroups

export const Actions_AccessGroups = {
  saga: {
    getAccessGroups: createAction(SagaEvents.GET_ACCESS_GROUPS),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveAccessGroups: createAction<TStoreTemplate<TAccessGroupList>>(StoreActions.SAVE_ACCESS_GROUPS),
  },
}
