import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { TDropdownOption } from './Select'
import Checkbox from '@mui/material/Checkbox'

export const renderSelectOptions = (
  parentId: string,
  childOptions: TDropdownOption[],
  isSelected: (id: number) => boolean,
  multiple?: boolean,
  isItemCapitalize?: boolean,
  hiddenOptionsIds?: number[],
): JSX.Element[] => {
  const items = childOptions.map((option) => {
    const { label, id, value } = option
    const isHideItem = hiddenOptionsIds?.includes(id) || false

    if (multiple) {
      return (
        <MenuItem
          key={`${parentId}-${id}`}
          value={value}
          style={{ display: isHideItem ? 'none' : 'flex' }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Checkbox checked={isSelected(id)} color={'primary'} />
          {label || value}
        </MenuItem>
      )
    }
    return (
      <MenuItem
        key={`${parentId}-${id}`}
        value={value}
        style={{
          textTransform: isItemCapitalize ? 'capitalize' : 'inherit',
          display: isHideItem ? 'none' : 'flex',
        }}
      >
        {label || value}
      </MenuItem>
    )
  })

  return items
}
