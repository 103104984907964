import { createReducer } from '@reduxjs/toolkit'
import { IStateCurrentTrailerBreakdowns } from './TReducer'
import { Actions_CurrentTrailerBreakdowns } from './Actions'

const initialState: IStateCurrentTrailerBreakdowns = {
  isLoading: false,
  data: [],
  responseInfo: null,
}

const ReducerCurrentTrailerBreakdowns = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_CurrentTrailerBreakdowns.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_CurrentTrailerBreakdowns.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_CurrentTrailerBreakdowns.store.saveBreakdowns, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_CurrentTrailerBreakdowns.store.clearBreakdowns, () => {
      return initialState
    })
    .addCase(Actions_CurrentTrailerBreakdowns.store.editBreakdown, (state, action) => {
      if (!state.data) {
        return {
          ...state,
        }
      }

      const payload = action.payload
      const updatedBreakdowns = state.data?.map((breakdown) => {
        if (breakdown.id !== payload.data?.id) {
          return breakdown
        }
        return {
          ...breakdown,
          ...payload.data,
        }
      })
      return {
        ...state,
        data: updatedBreakdowns,
      }
    })
})

export default ReducerCurrentTrailerBreakdowns
