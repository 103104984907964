import React, { FC } from 'react'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import { Controls } from 'assets/images/Controls'
import { useParkingsProblemDrawer } from './ParkingsProblemDrawer-ViewModel'
import { Shared } from 'ui/shared'

const ParkingsProblemDrawer = () => {
  const vm = useParkingsProblemDrawer()
  const { classes, data, isShowParkingsDrawer, isLoading, onClose, onClickOpen } = vm

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={isShowParkingsDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography title={'Parkings with low battery'} variant="h3" color="primary" noWrap>
            {`Parkings with low battery: ${data.problemParkings}`}
          </Typography>

          <IconButton onClick={onClose} className={classes.closeIcon} size="large">
            <Controls.CloseNotificationDrawer />
          </IconButton>
        </div>

        <div className={classes.content}>
          {isLoading && <CircularProgress className={classes.loader} size={24} />}
          <div className={classes.buttons}>
            <Shared.Button
              className={classes.button}
              width={'80%'}
              title={'Open parkings'}
              onClick={onClickOpen}
              color={'primary'}
            />
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default ParkingsProblemDrawer
