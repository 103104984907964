import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Report } from './TActions'
import { IDetailedReport, TDetailedReport, TInvoicesFilter, TReport, TReportFilter } from './TReducer'
import { TStoreTemplateWithFilter } from 'redux/config/root-types'

const { SagaEvents, StoreActions } = ActionTypes_Report

export const Actions_report = {
  saga: {
    getReport: createAction(SagaEvents.GET_REPORT),
    getDetailedReport: createAction(SagaEvents.GET_DETAILED_REPORT),
    getDetailedReportTotalCost: createAction(SagaEvents.GET_DETAILED_REPORT_TOTAL_COST),
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    loadXlsx: createAction(SagaEvents.LOAD_XLSX),
    loadDetailedXlsx: createAction(SagaEvents.LOAD_DETAILED_XLSX),
    loadCsv: createAction(SagaEvents.LOAD_CSV),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveReport: createAction<TStoreTemplateWithFilter<TReport>>(StoreActions.SAVE_REPORT),
    saveDetailedReportRents: createAction<{ rents: IDetailedReport[]; isAllInvoicesDataLoaded: boolean }>(
      StoreActions.SAVE_DETAILED_REPORT_RENTS,
    ),
    saveDetailedReportRentsTotalCost: createAction<number>(StoreActions.SAVE_DETAILED_REPORT_TOTAL_COST),
    changeFilter: createAction<TReportFilter>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TReportFilter>(StoreActions.INCREASE_FILTER_OFFSET),
    increaseInvoiceFilterOffset: createAction<TInvoicesFilter>(StoreActions.INCREASE_INVOICE_FILTER_OFFSET),
    setInvoiceFilter: createAction<TInvoicesFilter>(StoreActions.SET_INVOICE_FILTER),
    resetInvoiceFilter: createAction(StoreActions.RESET_INVOICE_FILTER),
    setInvoiceNumberInInvoiceFilter: createAction<string>(StoreActions.SET_INVOICE_NUMBER_IN_INVOICE_FILTER),
    openDetailedTable: createAction(StoreActions.OPEN_DETAILED_TABLE),
    closeDetailedTable: createAction(StoreActions.CLOSE_DETAILED_TABLE),
    choosenTurnOver: createAction(StoreActions.CHOOSEN_TURN_OVER),
    changedTurnOver: createAction(StoreActions.CHANGED_TURN_OVER),
    reset: createAction(StoreActions.RESET),
  },
}
