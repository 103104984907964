enum StoreActions {
  IS_LOADER_SHOW = 'STATIONS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'STATIONS/IS_LOADER_HIDE',
  SAVE_STATIONS = 'STATIONS/SAVE_STATIONS',
  CLEAR_STATIONS = 'STATIONS/CLEAR_STATIONS',
  INCREASE_FILTER_OFFSET = 'STATIONS/INCREASE_FILTER_OFFSET',
  CHANGE_FILTER = 'STATIONS/CHANGE_FILTER',
  CHANGE_OCCUPANCY_FILTER = 'STATIONS/CHANGE_OCCUPANCY_FILTER',
  SELECT_TRAILER_TYPES_IDS = 'STATIONS/SELECT_TRAILER_TYPES_IDS',
}

enum SagaEvents {
  GET_ALL_STATIONS = 'STATIONS/GET_ALL_STATIONS',
}

export const ActionTypes_Stations = {
  SagaEvents,
  StoreActions,
}
