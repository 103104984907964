import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { PayloadAction } from '@reduxjs/toolkit'
import { TGetNotesPayload } from 'redux/modules/parkingNotes/TReducer'
import { Actions_UserNotes } from 'redux/modules/userNotes/Actions'
import { TUserNote } from 'redux/modules/userNotes/TReducer'

const { get } = RequestMethods()

export interface ISagaWorker_GetUserNotes {
  userId: number
}

export default function* SagaWorker_GetUserNotes(action: PayloadAction<ISagaWorker_GetUserNotes>) {
  try {
    yield put(Actions_UserNotes.store.showLoader())

    const { userId } = action.payload

    const data: TGetNotesPayload = {
      entityId: userId,
      type: 'user',
    }

    const userNotes: TStoreTemplate<TUserNote[]> = yield call(async () => await get<TUserNote[]>('/notes', data))

    yield put(Actions_UserNotes.store.saveUserNotes(userNotes))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_UserNotes.store.hideLoader())
  }
}
