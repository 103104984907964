import React, { FC } from 'react'
import { useStationsTrailerTypeFilter } from 'ui/modals/StationsTrailerTypeFilter/StationsTrailerTypeFilter-ViewModel'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'

export const StationsTrailerTypeFilter: FC = () => {
  const vm = useStationsTrailerTypeFilter()
  const { closeModal, onSubmit, isLoading, data, checkedIds, isSelectAll, handleToggle, handleToogleAll, t, classes } =
    vm

  return (
    <Shared.Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Shared.CardTitle title={t('title')} />
        </Grid>

        <Grid item xs={12}>
          {data.length && (
            <ListItem role={undefined} dense button onClick={handleToogleAll}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={isSelectAll}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'selectAll' }}
                  color={'primary'}
                />
              </ListItemIcon>
              <ListItemText
                id={'selectAll'}
                primary={t('selectAll')}
                classes={{ root: classes.listItemText }}
                disableTypography
              />
            </ListItem>
          )}

          <List className={classes.list}>
            {data.map((trailerType) => {
              const labelId = `checkbox-list-label-${trailerType.type.id}`
              return (
                <ListItem
                  key={trailerType.type.id}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(trailerType.type.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checkedIds.indexOf(trailerType.type.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      color={'primary'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={trailerType.type.name || '-'}
                    classes={{ root: classes.listItemText }}
                    disableTypography
                  />
                </ListItem>
              )
            })}
          </List>
        </Grid>

        <Grid item xs={12}>
          <Shared.Button className={classes.cancel} color={'secondary'} title={t('cancel')} onClick={closeModal} />
          <Shared.Button title={t('submit')} onClick={onSubmit} />
        </Grid>

        {isLoading && <Shared.Preloader mask />}
      </Grid>
    </Shared.Card>
  )
}
