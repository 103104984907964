import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_CurrentStation } from 'redux/modules/currentStation/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { ICurrentParkingStation } from 'redux/modules/currentParking/TReducer'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { Actions_Stations } from 'redux/modules/stations/Actions'

const client = RequestMethods()

export interface ISagaWorker_UpdateParkingStation {
  id: number
  data: {
    stationCode: string
    serialNumber: string
    imei: string
    idHigh: string
    idMiddle: string
    idLow: string
    statusId: number | null
    trailerId: number | null
  }
}

export default function* SagaWorker_UpdateParkingStation(action: PayloadAction<ISagaWorker_UpdateParkingStation>) {
  yield put(Actions_CurrentStation.store.showLoader())

  try {
    //------------------------- Update and save parking station ---------------------------//
    const station: TStoreTemplate<ICurrentParkingStation> = yield call(
      async () => await client.put<ICurrentParkingStation>(`/stations/${action.payload.id}`, action.payload.data),
    )

    yield put(Actions_CurrentParking.store.updateCurrentParkingStation(station))
    yield put(Actions_Stations.saga.getAllStations())

    yield put(Actions_Modals.store.hideModal())

    yield put(Actions_Notifications.store.setNotification({ message: 'Station successfully updated', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CurrentStation.store.hideLoader())
}
