import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_TrailerTypes } from './TActions'
import { TCurrentTrailerTypeIdPayload, TSelectedTrailerTypeIdsPayload, TTrailerTypes } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_CreateTrailerType } from 'redux/sagas/events/trailerTypes/SagaWorker_CreateTrailerType'
import { ISagaWorker_UpdateTrailerType } from 'redux/sagas/events/trailerTypes/SagaWorker_UpdateTrailerType'
import { ISagaWorker_ActivateTrailerType } from 'redux/sagas/events/trailerTypes/SagaWorker_ActivateTrailerType'
import { ISagaWorker_DeactivateTrailerType } from 'redux/sagas/events/trailerTypes/SagaWorker_DeactivateTrailerType'
import { ISagaWorker_DeleteTrailerType } from 'redux/sagas/events/trailerTypes/SagaWorker_DeleteTrailerType'

const { StoreActions, SagaEvents } = ActionTypes_TrailerTypes

export const Actions_TrailerTypes = {
  saga: {
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    createTrailerType: createAction<ISagaWorker_CreateTrailerType>(SagaEvents.CREATE_TRAILER_TYPE),
    updateTrailerType: createAction<ISagaWorker_UpdateTrailerType>(SagaEvents.UPDATE_TRAILER_TYPE),
    activateTrailerType: createAction<ISagaWorker_ActivateTrailerType>(SagaEvents.ACTIVATE_TRAILER_TYPE),
    deactivateTrailerType: createAction<ISagaWorker_DeactivateTrailerType>(SagaEvents.DEACTIVATE_TRAILER_TYPE),
    deleteTrailerType: createAction<ISagaWorker_DeleteTrailerType>(SagaEvents.DELETE_TRAILER_TYPE),
  },

  store: {
    changeSelectedTrailerTypesIds: createAction<TSelectedTrailerTypeIdsPayload>(StoreActions.SELECT_TRAILER_TYPES_IDS),
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveTrailerTypes: createAction<TStoreTemplate<TTrailerTypes>>(StoreActions.SAVE_TRAILERTYPES),
    selectCurrentTrailerTypeId: createAction<TCurrentTrailerTypeIdPayload>(StoreActions.SELECT_CURRENT_TRAILER_TYPE_ID),
  },
}
