import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { TCurrentParking } from 'redux/modules/currentParking/TReducer'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'
import { Actions_TrailerStatuses } from 'redux/modules/trailerStatuses/Actions'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'
import SagaWorker_Organizations from '../events/SagaWorker_Organizations'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'

const { get } = RequestMethods()

export interface ISagaPage_ParkingDetails {
  id: string
}

export default function* SagaPage_ParkingDetails(action: PayloadAction<ISagaPage_ParkingDetails>) {
  const { id } = action.payload
  try {
    yield put(Actions_PageLoader.store.showLoader())

    //------------------------- Getting and saving trailer types ----------------------//
    const trailerTypesData: TStoreTemplate<TTrailerTypes> = yield call(
      async () => await get<TTrailerTypes>('/trailer_types'),
    )

    yield put(Actions_TrailerTypes.store.saveTrailerTypes(trailerTypesData))
    //------------------------- Getting and saving organizations ---------------------------//
    yield call(SagaWorker_Organizations)
    //------------------------- Getting and saving parking ---------------------------//
    const parkingResponse: TStoreTemplate<TCurrentParking> = yield call(
      async () => await get<TCurrentParking>(`/parking/${id}`),
    )
    yield put(Actions_CurrentParking.store.saveCurrentParking(parkingResponse))
    const parkingInfo = parkingResponse.data?.parking

    if (parkingInfo) {
      const { id, latitude, longitude } = parkingInfo
      yield put(Actions_CurrentParking.store.changeSelectedParkingInfo({ id, lat: latitude, lng: longitude }))
    }

    //------------------------- Getting and saving trailer statuses -------------------//
    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await get<TTrailerStatuses>('/trailers/statuses'),
    )
    yield put(Actions_TrailerStatuses.store.saveTrailerStatuses(t_statuses))
    const status: number[] = t_statuses.data?.statuses.map((item) => item.id) || []
    //------------------------- Getting and saving trailers ---------------------------//
    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () =>
        await get<TTrailers>('/trailers/list', {
          status,
        }),
    )
    yield put(Actions_Trailers.store.saveTrailers(trailerResponse))

    yield put(Actions_PageLoader.store.hideLoader())
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
