import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Shared } from 'ui/shared'
import { useStationOrganizationNameFilter } from './StationOrganizationNameFilter-ViewModel'
import { makeStyles } from 'tss-react/mui'
import { Form } from 'utils/helpers/form'

export const StationOrganizationNameFilter: FC = () => {
  const { onCancel, isFilterOn, onClearFilter, form } = useStationOrganizationNameFilter()
  const { register, handleSubmit } = form
  const { classes } = useStyles()
  return (
    <Shared.Card style={{ width: 375 }}>
      <Shared.CardTitle title={'Organization'} />
      <Grid item xs={12}>
        <Shared.Divider />
      </Grid>
      <Grid item xs={12}>
        <Shared.InputField name="value" title={'Organization'} register={register(Form.minLength(2))} />
      </Grid>
      <Grid item xs={6} className={classes.buttons}>
        <Shared.Button className={classes.button} title={'Cancel'} onClick={onCancel} color={'secondary'} />
        <Shared.Button
          className={classes.button}
          width={'100%'}
          title={'Submit'}
          onClick={handleSubmit}
          color={'primary'}
        />
      </Grid>
      {isFilterOn && (
        <Shared.Button width={'100%'} title={'Clear filter'} type="submit" color={'primary'} onClick={onClearFilter} />
      )}
    </Shared.Card>
  )
}

const useStyles = makeStyles()((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 8,
  },
  button: {
    margin: 4,
  },
}))
