import React, { FC } from 'react'
import { usePromoCodeUpdate } from 'ui/modals/PromoCodeUpdate/PromoCodeUpdate-ViewModel'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'

export const PromoCodeUpdate: FC = () => {
  const vm = usePromoCodeUpdate()
  const { closeModal, form, isLoading, t, isSelectedTrailerTypes, trailerTypeOptions } = vm
  const { errors, handleSubmit, register, formValidation, control, watch } = form

  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
            <Shared.InputField
              name="code"
              title={t('name')}
              register={register(formValidation.code)}
              error={errors.code?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="bonusMinutes"
              title={t('bonusMinutes')}
              register={register(formValidation.bonusMinutes)}
              type="number"
              error={errors.bonusMinutes?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.MultiOrganization
              type={'form'}
              showTitle={false}
              control={control}
              divider={false}
              hideBorder={false}
              name="organizationIds"
              label={t('organizations')}
              watch={watch}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.Dropdown
              name="trailerTypeIds"
              label={t('trailerTypes')}
              options={trailerTypeOptions}
              marginTop={0}
              fullWidth
              multiple
              control={control}
              isSelected={isSelectedTrailerTypes}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.SingleDatePickerForForm
              title={t('expirationDate')}
              name="activeUntil"
              control={control}
              error={errors.activeUntil?.message}
              rules={formValidation.activeUntil}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
            />
            <Shared.Button title={t('submit')} type="submit" />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
