import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import config from 'utils/conf/config'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'

export const languageOptions: TDropdownOption[] = [
  { id: 1, value: 'de', label: 'DEU' },
  { id: 2, value: 'en-US', label: 'ENG' },
]

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      backendOptions: [
        // TODO after stable release increase browser cache time 7 * 24 * 60 * 60 * 1000 (7 days)
        {
          expirationTime: 24 * 60 * 60 * 1000,
        },
      ],
    },
    fallbackLng: false,
    load: 'languageOnly',
    cleanCode: true,
    ns: [],
    returnNull: false,
    returnEmptyString: true,
    debug: config.IS_DEV,
    supportedLngs: ['de', 'en'],
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
