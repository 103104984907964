import React, { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { Input, ListSubheader, Select as MUISelect, SelectProps as MuiSelectProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Controls } from 'assets/images/Controls'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { renderSelectOptions } from './SelectOptions'
import { renderGroupedSelectOptions } from './GroupedSelectOptions'

export type TDropdownOption = {
  id: number
  value: string | string[] | number
  label?: string
  parentId?: number
}

export type TParentDropdownOption = {
  id: number
  title: string
}

type TGoupedOptions = Record<number | string, TDropdownOption[]>

interface IStyles {
  iconColor: 'primary' | 'secondary'
  hideBorder?: boolean
  color?: string
  isItemCapitalize?: boolean
}

export type TSelectValue = string | number | TDropdownOption | TDropdownOption[] | string[] | number[]

export interface TSelectProps extends Omit<MuiSelectProps<TSelectValue>, 'onChange' | 'color' | 'error'> {
  options: TDropdownOption[]
  parentOptions?: TParentDropdownOption[]
  hiddenOptionsIds?: number[]
  borderColor?: string
  color?: string
  iconColor?: 'primary' | 'secondary'
  hideBorder?: boolean
  isItemCapitalize?: boolean
  isSelected?: (id: number) => boolean
  onChange?: (value: any) => void
  error?: null | string
  isSearchable?: boolean
}

export const Select: FC<TSelectProps> = ({
  options,
  parentOptions,
  hiddenOptionsIds,
  borderColor,
  iconColor = 'primary',
  hideBorder,
  isItemCapitalize,
  isSelected = () => false,
  onChange,
  color,
  error,
  isSearchable,
  placeholder,
  multiple,
  ...muiSelectProps
}) => {
  const isHideBorder = !error && hideBorder
  const { classes } = useStyles({ iconColor, hideBorder: isHideBorder, color, isItemCapitalize })

  let selectProps: any = {}

  const [localOptions, setLocalOptions] = useState<TDropdownOption[]>([])
  const [searchStr, setSearchStr] = useState('')

  useEffect(() => {
    setLocalOptions(options)
  }, [options])

  if (multiple) {
    const renderValue = (selected: number[] | string[]) => {
      return options
        .filter((option: TDropdownOption) => (selected as any).includes(option.value))
        .map((option: TDropdownOption) => option.label || option.value)
        .join(', ')
    }
    selectProps = { multiple, renderValue }
  }

  if (error) {
    borderColor = 'red'
  }

  const groupByParent = (options: TDropdownOption[], parentOptions?: TParentDropdownOption[]): TGoupedOptions => {
    const parentsIds = parentOptions?.map((parentOption) => parentOption.id) || []
    return options.reduce<TGoupedOptions>((accumulator, option) => {
      const key = option.parentId && parentsIds.includes(option.parentId) ? option.parentId : 'single'
      const currentGroup = accumulator[key] ?? []

      return { ...accumulator, [key]: [...currentGroup, option] }
    }, {})
  }

  const groupedOptions = useMemo(
    () => groupByParent(localOptions, parentOptions),
    [options, parentOptions, localOptions],
  )

  const handleChange = (event: SelectChangeEvent<number | number[] | string[]>) => {
    const selected = event.target.value

    if (!Array.isArray(selected)) {
      onChange && onChange(selected)
      return
    }

    const lastItem = selected[selected.length - 1]
    const parentRegexp = /parentId-(\w+)/
    const parentValue = lastItem?.toString().match(parentRegexp)

    if (!parentValue) {
      onChange && onChange(selected)
      return
    }

    const parentId = parentValue[1]

    const newSelected = groupedOptions[parentId].map((option) => option.value as number)
    const prevSelected = selected.slice(0, -1) as number[]
    const isAllParentChildsSelected = newSelected.every((newItem) => prevSelected.includes(newItem))

    if (isAllParentChildsSelected) {
      const selectedWithoutParentChilds = prevSelected.filter((item) => !newSelected.includes(item))
      onChange && onChange(selectedWithoutParentChilds)
      return
    }

    const result = removeDuplicates([...prevSelected, ...newSelected])
    onChange && onChange(result)
  }

  const removeDuplicates = (items: number[]) => {
    const result = items.filter((item, index) => {
      return items.indexOf(item) === index
    })
    return result
  }

  const onHandleSearch = (e: any) => {
    const str = e.currentTarget.value
    setSearchStr(str)
    const searcOptions = options.filter((item) => item.label?.toLocaleLowerCase().includes(str.toLowerCase()))
    setLocalOptions(searcOptions)
  }

  const onCloseSelect = () => {
    setSearchStr('')
    setLocalOptions(options)
  }

  return (
    <MUISelect
      {...muiSelectProps}
      {...selectProps}
      variant="standard"
      onChange={handleChange}
      IconComponent={Controls.ArrowDownPrimary}
      onClose={onCloseSelect}
      MenuProps={{
        autoFocus: false,
        style: {
          maxHeight: 600,
        },
      }}
      className={classes.select}
      classes={{
        icon: classes.icon,
        select: classes.selectClass,
      }}
      style={{ borderColor }}
    >
      {isSearchable && (
        <ListSubheader sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Input
            value={searchStr}
            placeholder="Search"
            onChange={onHandleSearch}
            onKeyDown={(e) => e.stopPropagation()}
            autoFocus
            fullWidth
          />
        </ListSubheader>
      )}

      {placeholder && (
        <MenuItem value="" disabled style={{ display: 'none' }}>
          {placeholder}
        </MenuItem>
      )}

      {!localOptions.length && (
        <MenuItem value="no options" disabled>
          no options
        </MenuItem>
      )}

      {parentOptions?.length &&
        Object.entries(groupedOptions).map(([parentId, childOptions]) =>
          renderGroupedSelectOptions(
            parentId,
            parentOptions,
            childOptions,
            isSelected,
            multiple,
            isItemCapitalize,
            hiddenOptionsIds,
          ),
        )}

      {!parentOptions?.length &&
        renderSelectOptions('single', localOptions, isSelected, multiple, isItemCapitalize, hiddenOptionsIds)}
    </MUISelect>
  )
}

const useStyles = makeStyles<IStyles>()((theme, { iconColor, color, hideBorder, isItemCapitalize }) => ({
  icon: {
    fill: theme.palette[iconColor].main,
    right: '1.1875rem',
    top: '35%',
  },
  select: {
    height: 48,
    border: hideBorder ? 'none' : '1px solid #ABABAB',
    borderRadius: 50,
    width: '100%',
    '&::before': {
      borderBottom: 'none',
    },
    '&::after': {
      borderBottom: 'none',
    },
  },
  selectClass: {
    color: `${color || theme.palette.text.secondary} !important`,
    textTransform: isItemCapitalize ? 'capitalize' : 'inherit',
  },
}))
