import { call, put } from 'redux-saga/effects'
import { TStoreTemplate } from 'redux/config/root-types'
import { RequestMethods } from 'utils/axios/methods'
import { TStationsCounters } from 'redux/modules/stationsCounters/TReducer'
import { Actions_StationsCounters } from 'redux/modules/stationsCounters/Actions'
import { IParkingOccupancyFilter } from 'redux/modules/parkings/TReducer'

const { get } = RequestMethods()

export interface ISagaAction_StationsCounters {
  organization: number[]
  occupancy: IParkingOccupancyFilter
}

export function* sagaAction_StationsCounters(action: ISagaAction_StationsCounters) {
  const { organization, occupancy } = action
  yield put(Actions_StationsCounters.store.showLoader())

  const counters: TStoreTemplate<TStationsCounters> = yield call(
    async () => await get<TStationsCounters>('/stations/counters', { organization, ...occupancy }),
  )
  yield put(Actions_StationsCounters.store.saveStationsCounters(counters))

  yield put(Actions_StationsCounters.store.hideLoader())
}
