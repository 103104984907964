import React, { FC } from 'react'

import { makeStyles } from 'tss-react/mui'
import MuiButton from '@mui/material/Button'
import { TColor } from 'utils/types'

interface IProps {
  title: string
  onClick?: () => void
  color?: TColor
  hideBorder?: boolean
  width?: number | string
  height?: number | string
  className?: string
  style?: {}
  type?: 'submit' | 'reset' | 'button'
  hideBoxShadow?: boolean
  startIcon?: React.ReactNode
  disabled?: boolean
}

export const Button: FC<IProps> = ({
  className,
  width,
  title,
  onClick,
  color = 'primary',
  hideBorder = false,
  height = 48,
  style,
  type,
  hideBoxShadow,
  startIcon,
  disabled,
}) => {
  const { classes, cx } = useStyles({ color, hideBorder, width, height, hideBoxShadow })

  return (
    <MuiButton
      type={type}
      color={color}
      style={style}
      onClick={onClick}
      className={cx(classes.root, className)}
      startIcon={startIcon}
      disabled={disabled}
    >
      {title}
    </MuiButton>
  )
}

interface IStyle {
  color: TColor
  hideBorder?: boolean
  width?: number | string
  height?: number | string
  hideBoxShadow?: boolean
}

const useStyles = makeStyles<IStyle>()((theme, { color, hideBorder, width, height, hideBoxShadow }) => {
  const isPrimary = color === 'primary'
  return {
    root: {
      fontWeight: 'bold',
      height: '6vh',
      maxHeight: height,
      width: width ? width : '100%',
      backgroundColor: theme.palette[color].main,
      color: isPrimary ? theme.palette.secondary.main : theme.palette.primary.main,
      border: hideBorder ? 'none' : `1px solid ${theme.palette.primary.main}`,
      borderRadius: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: hideBoxShadow ? '' : '0px 3px 8px rgba(137, 137, 137, 0.26)',

      '&:hover': {
        backgroundColor: theme.palette[color].main,
        color: isPrimary ? theme.palette.secondary.main : theme.palette.primary.main,
        border: hideBorder ? 'none' : `1px solid ${theme.palette.primary.main}`,
      },

      '&.Mui-disabled': {
        color: isPrimary ? theme.palette.secondary.main : theme.palette.primary.main,
        backgroundColor: theme.palette[color].main,
        opacity: 0.5,
      },
    },
  }
})
