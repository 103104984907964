import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { I18nNotifications } from 'utils/i18n/i18nNotifications'
import { notificationIcons } from 'utils/constants/notificationIcons'
import { TNotificationType } from 'redux/modules/notification/TReducer'
import { useMessage } from './Message-ViewModel'

type TProps = {
  message: TNotificationType
  isShowTitle: boolean
  parkingId?: number
  parkingName?: string
  stationId?: number
  trailerId?: number
  trailerPlate?: string
  userId?: number
  userName?: string
}

export const Message: FC<TProps> = ({
  isShowTitle,
  message,
  parkingId,
  parkingName,
  stationId,
  trailerId,
  trailerPlate,
  userId,
  userName,
}) => {
  const { t } = useTranslation(I18nNotifications.common)

  const vm = useMessage()
  const { classes, cx } = vm

  const Icon = notificationIcons[message]

  return (
    <div className={classes.hoverSpace}>
      {isShowTitle && <p className={classes.title}>{t(message)}</p>}

      <div className={classes.container}>
        <div className={classes.message}>
          {!isShowTitle && <p className={classes.text}>{t(message)}</p>}

          {!!userId && (
            <div className={classes.messageItem}>
              <p className={classes.text}>User ID:</p>
              <p className={cx(classes.text, classes.value)}>{userId}</p>
            </div>
          )}

          {!!userName && (
            <div className={classes.messageItem}>
              <p className={classes.text}>User name:</p>
              <p className={cx(classes.text, classes.value)}>{userName}</p>
            </div>
          )}

          {!!stationId && (
            <div className={classes.messageItem}>
              <p className={classes.text}>Stand:</p>
              <p className={cx(classes.text, classes.value)}>{stationId}</p>
            </div>
          )}

          {!!parkingId && (
            <div className={classes.messageItem}>
              <p className={classes.text}>Parking ID:</p>
              <p className={cx(classes.text, classes.value)}>{parkingId}</p>
            </div>
          )}

          {!!parkingName && (
            <div className={classes.messageItem}>
              <p className={classes.text}>Parking name:</p>
              <p className={cx(classes.text, classes.value)}>{parkingName}</p>
            </div>
          )}

          {Boolean(trailerPlate || trailerId) && (
            <div className={classes.messageItem}>
              <p className={classes.text}>Trailer number:</p>
              <p className={cx(classes.text, classes.value)}>
                {`${trailerPlate || ''}${trailerId ? `(${trailerId})` : ''}`}
              </p>
            </div>
          )}
        </div>

        {!!Icon && <Icon className={classes.icon} />}
      </div>
    </div>
  )
}

export default Message
