import { createReducer } from '@reduxjs/toolkit'
import { IStateRents, TRentsFilter } from './TReducer'
import TableFilterReduxAdapter from 'utils/helpers/table'
import { Actions_Rents } from './Actions'

const initialFilter: TRentsFilter = {
  ...TableFilterReduxAdapter.DEFAULT_FILTERS,
  sorting: 'startDate',
  orderBy: 'DESC',
  trailerId: undefined,
  userId: undefined,
}

const initialState: IStateRents = {
  isLoading: false,
  isAllDataLoaded: false,
  data: null,
  responseInfo: null,
  filter: initialFilter,
  insurances: null,
  checklistParams: null,
}

const ReducerRents = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Rents.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Rents.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Rents.store.saveRents, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Rents.store.saveInsurances, (state, action) => {
      state.insurances = action.payload
    })
    .addCase(Actions_Rents.store.changeFilter, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Rents.store.increaseFilterOffset, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_Rents.store.resetFilter, (state) => {
      state.filter = initialFilter
    })
    .addCase(Actions_Rents.store.saveChecklistsParameters, (state, action) => {
      const data = action.payload ? action.payload : null
      state.checklistParams = data
    })
})

export default ReducerRents
