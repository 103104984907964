enum StoreActions {
  IS_LOADER_SHOW = 'TRAILER_STATUS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'TRAILER_STATUS/IS_LOADER_HIDE',
  SAVE_TRAILERSTATUSES = 'TRAILER_STATUS/SAVE_TRAILERSTATUSES',
  SELECT_STATUS_IDS = 'TRAILER_STATUS/SELECT_STATUS_IDS',
  RESET = 'TRAILER_STATUS/RESET',
}

enum SagaEvents {
  GET_TRAILER_STATUSES = 'TRAILER_STATUS/GET_TRAILER_STATUSES',
}

export const ActionTypes_TrailerStatuses = {
  SagaEvents,
  StoreActions,
}
