import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_ParkingNotes } from 'redux/modules/parkingNotes/Actions'
import { TGetNotesPayload, TParkingNote } from 'redux/modules/parkingNotes/TReducer'
import { PayloadAction } from '@reduxjs/toolkit'

const { get } = RequestMethods()

export interface ISagaWorker_GetParkingNotes {
  parkingId: number
}

export default function* SagaWorker_GetParkingNotes(action: PayloadAction<ISagaWorker_GetParkingNotes>) {
  try {
    yield put(Actions_ParkingNotes.store.showLoader())

    const { parkingId } = action.payload

    const data: TGetNotesPayload = {
      entityId: parkingId,
      type: 'parking',
    }

    const parkingNotes: TStoreTemplate<TParkingNote[]> = yield call(
      async () => await get<TParkingNote[]>('/notes', data),
    )

    yield put(Actions_ParkingNotes.store.saveParkingNotes(parkingNotes))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_ParkingNotes.store.hideLoader())
  }
}
