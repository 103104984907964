import React, { FC } from 'react'
import { SnackbarProvider as NotistakSnackbarProvider } from 'notistack'

type TProps = {
  children?: React.ReactNode
}

export const SnackbarProvider: FC<TProps> = ({ children }) => {
  return (
    <NotistakSnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </NotistakSnackbarProvider>
  )
}
