import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_ParentOrganizations } from './TActions'
import { TParentOrganization } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_CreateParentOrganization } from 'redux/sagas/events/parentOrganization/SagaWorker_CreateParentOrganization'
import { ISagaWorker_UpdateParentOrganization } from 'redux/sagas/events/parentOrganization/SagaWorker_UpdateParentOrganization'
import { ISagaWorker_ActivateParentOrganization } from 'redux/sagas/events/parentOrganization/SagaWorker_ActivateParentOrganization'
import { ISagaWorker_DeactivateParentOrganization } from 'redux/sagas/events/parentOrganization/SagaWorker_DeactivateParentOrganization'

const { StoreActions, SagaEvents } = ActionTypes_ParentOrganizations

export const Actions_ParentOrganizations = {
  saga: {
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    getParentOrganizations: createAction(SagaEvents.GET_PARENT_ORGANIZATIONS),
    createParentOrganization: createAction<ISagaWorker_CreateParentOrganization>(SagaEvents.CREATE_PARENT_ORGANIZATION),
    updateParentOrganization: createAction<ISagaWorker_UpdateParentOrganization>(SagaEvents.UPDATE_PARENT_ORGANIZATION),
    activateParentOrganization: createAction<ISagaWorker_ActivateParentOrganization>(
      SagaEvents.ACTIVATE_PARENT_ORGANIZATION,
    ),
    deactivateParentOrganization: createAction<ISagaWorker_DeactivateParentOrganization>(
      SagaEvents.DEACTIVATE_PARENT_ORGANIZATION,
    ),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveParentOrganizations: createAction<TStoreTemplate<TParentOrganization[]>>(
      StoreActions.SAVE_PARENT_ORGANIZATIONS,
    ),
    editParentOrganization: createAction<TStoreTemplate<TParentOrganization>>(StoreActions.EDIT_PARENT_ORGANIZATION),
    reset: createAction(StoreActions.RESET),
  },
}
