import React, { FC } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'

export type TTableCheckboxCellProps = {
  value: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const TableCheckboxCell: FC<TTableCheckboxCellProps> = ({ value, onChange, disabled }) => {
  return (
    <Box display="flex" alignItems="center" flexGrow={1} style={{ width: '100%', height: '100%' }}>
      <Checkbox checked={value} onChange={onChange} color={'primary'} disabled={disabled} />
    </Box>
  )
}
