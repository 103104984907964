import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { IOrder } from 'utils/types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { get } = RequestMethods()

export interface ISagaWorker_LoadTrailersList {
  trailers?: {
    orderBy?: IOrder
    sorting?: 'id' | 'status' | 'batteryPercent'
    limit?: number
    offset?: number
  }
}

export default function* SagaWorker_LoadTrailersList(action?: PayloadAction<ISagaWorker_LoadTrailersList>) {
  const getSelectedTrailerTypeIds = (state: TState) => state.trailerTypes.selectedTrailerTypeIds
  const getSelectedTrailerStatusesIds = (state: TState) => state.trailerStatuses.selectedStatusIds
  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds

  const types: ReturnType<typeof getSelectedTrailerTypeIds> = yield select(getSelectedTrailerTypeIds)
  const status: ReturnType<typeof getSelectedTrailerStatusesIds> = yield select(getSelectedTrailerStatusesIds)
  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)

  const formData = {
    types,
    status,
    organization,
  }

  yield put(Actions_Trailers.store.showLoader())
  try {
    //------------------------- Getting and saving trailers ---------------------------//
    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () => await get<TTrailers>('/trailers/list', formData),
    )
    yield put(Actions_Trailers.store.saveTrailers(trailerResponse))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_Trailers.store.hideLoader())
}
