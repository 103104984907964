import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_CurrentParking } from './TActions'
import { ICurrentParkingStation, TCurrentParking, TSelectedParkingInfoPayload } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_UpdateParking } from 'redux/sagas/events/parking/SagaWorker_UpdateParking'
import { ISagaWorker_CreateParking } from 'redux/sagas/events/parking/SagaWorker_CreateParking'
import { ISagaPage_ParkingDetails } from 'redux/sagas/pages/SagaPage_ParkingDetails'
import { ISagaWorker_UpdateParkingStation } from 'redux/sagas/events/parking/SagaWorker_UpdateParkingStation'
import { ISagaWorker_CreateStationAndGetCurrentParking } from 'redux/sagas/events/parking/SagaWorker_CreateStationAndGetCurrentParking'
import { ISagaWorker_RefreshParking } from 'redux/sagas/events/parking/SagaWorker_RefreshParking'

const { SagaEvents, StoreActions } = ActionTypes_CurrentParking

export const Actions_CurrentParking = {
  saga: {
    getParkingDetails: createAction<ISagaPage_ParkingDetails>(SagaEvents.GET_PARKING_DETAILS),
    createParking: createAction<ISagaWorker_CreateParking>(SagaEvents.CREATE_PARKING),
    updateParking: createAction<ISagaWorker_UpdateParking>(SagaEvents.UPDATE_PARKING),
    refreshParking: createAction<ISagaWorker_RefreshParking>(SagaEvents.REFRESH_PARKING),
    updateCurrentParkingStation: createAction<ISagaWorker_UpdateParkingStation>(SagaEvents.UPDATE_PARKING_STATION),
    createStationAndGetParkingDetails: createAction<ISagaWorker_CreateStationAndGetCurrentParking>(
      SagaEvents.CREATE_STATION_AND_GET_PARKING_DETAILS,
    ),
  },
  store: {
    changeSelectedParkingInfo: createAction<TSelectedParkingInfoPayload>(StoreActions.SELECT_PARKING_INFO),
    clearSelectedParkingInfo: createAction(StoreActions.CLEAR_SELECTED_PARKING_INFO),
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveCurrentParking: createAction<TStoreTemplate<TCurrentParking>>(StoreActions.SAVE_CURRENT_PARKING),
    clearCurrentParking: createAction(StoreActions.CLEAR_CURRENT_PARKING),
    updateCurrentParkingStation: createAction<TStoreTemplate<ICurrentParkingStation>>(
      StoreActions.UPDATE_CURRENT_PARKING_STATION,
    ),
  },
}
