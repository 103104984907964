enum StoreActions {
  IS_LOADER_SHOW = 'POSITIONS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'POSITIONS/IS_LOADER_HIDE',
  SAVE_POSITIONS = 'POSITIONS/SAVE_POSITIONS',
  CHANGE_FILTER = 'POSITIONS/CHANGE_FILTER',
  INCREASE_FILTER_OFFSET = 'POSITIONS/INCREASE_FILTER_OFFSET',
  RESET = 'POSITIONS/RESET',
}

enum SagaEvents {
  GET_POSITIONS = 'POSITIONS/GET_POSITIONS',
}

export const ActionTypes_Positions = {
  SagaEvents,
  StoreActions,
}
