import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import { TUser } from 'redux/modules/users/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const client = RequestMethods()

export interface ISagaWorker_UpdateUserDiscount {
  id: number
  percent: number
  endDate: string
  usageAmount: number | null
  organizationIds: number[]
  trailerTypeIds: number[]
}

export default function* SagaWorker_UpdateUserDiscount(action: PayloadAction<ISagaWorker_UpdateUserDiscount>) {
  const { id, ...data } = action.payload

  yield put(Actions_CurrentUser.store.showLoader())

  try {
    const user: TStoreTemplate<TUser> = yield call(async () => await client.put<TUser>(`/users/${id}/discount`, data))
    yield put(Actions_CurrentUser.store.saveCurrentUser(user))

    yield put(
      Actions_Notifications.store.setNotification({ message: 'User discount successfully updated', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentUser.store.hideLoader())
  }
}
