import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { SagaWorker_GetTrailerNotes } from 'redux/sagas'
import { Actions_TrailerNotes } from 'redux/modules/trailerNotes/Actions'

const { remove } = RequestMethods()

export interface ISagaWorker_DeleteTrailerNote {
  id: number
  trailerId: number
}

export default function* SagaWorker_DeleteTrailerNote(action: PayloadAction<ISagaWorker_DeleteTrailerNote>) {
  try {
    yield put(Actions_TrailerNotes.store.showLoader())

    const { id } = action.payload

    yield call(async () => await remove(`/notes/${id}`))

    yield call(SagaWorker_GetTrailerNotes, action)

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'The note has been successfully deleted',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_TrailerNotes.store.hideLoader())
  }
}
