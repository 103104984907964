import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { useSelector, useDispatch } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'
import { Actions_Modals } from 'redux/modules/modals/Actions'

type TModalProps = {
  trailerId: number
  organizationId: number
}

export const useRemoveTrailerFromOrganizationConfirm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.removeTrailerFromOrganizationConfirm)

  const modalProps = useSelector(({ modals }: TState) => modals.data as TModalProps)

  const onSubmit = () => {
    const data = {
      trailerId: modalProps.trailerId,
      organizationId: modalProps.organizationId,
    }

    dispatch(Actions_CurrentOrganization.saga.removeTrailerFromOrganization(data))
  }

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  return { t, onSubmit, onCancel }
}
