import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm, useWatch } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { Form } from 'utils/helpers/form'
import { TRegion } from 'redux/modules/regions/TReducer'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { Actions_Regions } from 'redux/modules/regions/Actions'

export const formValidation = {
  parkingCode: Form.combine(Form.required, Form.maxLength(10)),
}

interface IForm {
  parkingCode: string
  organizationId: number | string
  imei: string
  country: string
  regionId: number
  city: string
  street: string
  postIndex: string
  coordinatesString: string
}

export type TCoordinates = {
  latitude: number
  longitude: number
}

export const useParkingCreate = () => {
  const { isLoading, coordinates, organizations, regions } = useSelector(
    ({ currentParking, modals, organizations, regions }: TState) => ({
      isLoading: currentParking.isLoading,
      coordinates: modals.data as TCoordinates,
      organizations: organizations.data?.organizations || [],
      regions: regions.data || [],
    }),
    shallowEqual,
  )
  const dispatch = useDispatch()

  const { handleSubmit, register, errors, control, reset, getValues, setValue } = useForm<IForm>({
    defaultValues: {
      parkingCode: '',
      organizationId: '',
      imei: '',
      country: '',
      regionId: 0,
      city: '',
      street: '',
      postIndex: '',
      coordinatesString: '',
    },
  })
  const { t } = useTranslation(I18nModal.parkingCreate)
  const country: string | undefined = useWatch({ control, name: 'country' })

  const regionsForCountry = useMemo(() => toCountryRegions(regions, country), [regions, country])
  const regionOptions = useMemo(() => toDropdownOption(regionsForCountry), [regionsForCountry])

  useEffect(
    function initFormData() {
      const { latitude, longitude } = coordinates
      dispatch(Actions_Regions.saga.getRegions())
      reset({
        parkingCode: '',
        organizationId: '',
        imei: '',
        country: '',
        regionId: -1,
        city: '',
        street: '',
        postIndex: '',
        coordinatesString: `${latitude.toFixed(10)} - ${longitude.toFixed(10)}`,
      })
    },
    [coordinates, reset],
  )

  useEffect(() => {
    const value = getValues()
    value.country === 'null' && setValue('regionId', 0)
  }, [control])

  const organizationOptions = useMemo(
    () => organizations.map(({ name, id }) => ({ id, value: id, label: name })),
    [organizations],
  )

  const onSubmit = ({ coordinatesString, ...formData }: IForm) => {
    const parkingCreateData = {
      ...formData,
      country: formData.country === 'null' || formData.country === '' ? null : formData.country,
      regionId:
        formData.country === 'null'
          ? null
          : formData.regionId === -1 || formData.regionId === 0
          ? null
          : formData.regionId,
      organizationId: parseInt(formData.organizationId as string),
      ...coordinates,
    }
    dispatch(Actions_CurrentParking.saga.createParking(parkingCreateData))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    closeModal,
    isLoading,
    organizationOptions,
    form: {
      formValidation,
      errors,
      register,
      handleSubmit: handleSubmit(onSubmit),
      control,
    },
    regionOptions,
  }
}

const toCountryRegions = (regions: TRegion[], country?: string): TRegion[] =>
  regions.filter((region) => {
    return region.country === country
  })

const toDropdownOption = (regions: TRegion[]): TDropdownOption[] => {
  regions.unshift({ id: 0, name: '-', country: 'null' })
  return regions.map((region) => ({
    id: region.id,
    label: region.name,
    value: region.id,
  }))
}
