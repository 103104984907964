import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import { Form } from 'utils/helpers/form'
import { I18nModal } from 'utils/i18n/i18nModal'

interface IForm {
  bonusMinutes: number | null
}

const formValidation = {
  bonusMinutes: Form.combine(Form.required, Form.min(1)),
}

export const useBonusMinutesFilter = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.promoCodeCreate)
  const { bonusMinutes } = useSelector(
    ({ promoCode }: TState) => ({
      bonusMinutes: promoCode.bonusMinutesFilter,
    }),
    shallowEqual,
  )
  const { handleSubmit, register, errors, control, watch } = useForm<IForm>({
    defaultValues: { bonusMinutes: bonusMinutes ? bonusMinutes : null },
  })
  const isFilterOn = bonusMinutes ? true : false

  const onSubmit = (formData: IForm) => {
    formData.bonusMinutes && dispatch(Actions_PromoCode.store.setBonusMinutesFilter(+formData.bonusMinutes))
    dispatch(Actions_Modals.store.hideModal())
  }

  const onCancel = () => dispatch(Actions_Modals.store.hideModal())

  const onClearFilter = () => {
    dispatch(Actions_PromoCode.store.setBonusMinutesFilter(null))
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    onCancel,
    form: {
      formValidation,
      errors,
      register,
      control,
      handleSubmit: handleSubmit(onSubmit),
      watch,
    },
    isFilterOn,
    onClearFilter,
  }
}
