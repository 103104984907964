import React from 'react'
import { Controls } from 'assets/images/Controls'
import { TSearchOption, useSearch } from './Search-ViewModel'
import CircularProgress from '@mui/material/CircularProgress'

const Search = () => {
  const vm = useSearch()
  const { classes, isLoading, onOptionClick, autocompleteOptions, onChange, value } = vm

  return (
    <div>
      <div className={classes.search} {...autocompleteOptions.getRootProps()}>
        {!isLoading && <Controls.Search />}
        {isLoading && <CircularProgress className={classes.loader} size={23} />}
        <input
          placeholder="Search"
          {...autocompleteOptions.getInputProps()}
          className={classes.searchInput}
          onChange={onChange}
          value={value}
        />
      </div>

      {autocompleteOptions.groupedOptions.length > 0 && (
        <ul className={classes.listbox} {...autocompleteOptions.getListboxProps()}>
          {(autocompleteOptions.groupedOptions as TSearchOption[]).map((option, index) => {
            const Icon = option.icon
            return (
              <li
                {...autocompleteOptions.getOptionProps({ option, index })}
                key={option.id}
                onClick={() => onOptionClick(option)}
              >
                <div className={classes.option}>
                  <div className={classes.icon}>{Icon && <Icon />}</div>
                  <div className={classes.label}>{option.label}</div>
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default Search
