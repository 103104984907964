import { createReducer } from '@reduxjs/toolkit'
import { Actions_ParentOrganizations } from './Actions'
import { IStateParentOrganizations } from './TReducer'

const initialState: IStateParentOrganizations = {
  isLoading: false,
  data: null,
  responseInfo: null,
}

const ReducerParentOrganizations = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_ParentOrganizations.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_ParentOrganizations.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_ParentOrganizations.store.saveParentOrganizations, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_ParentOrganizations.store.editParentOrganization, (state, action) => {
      if (!state.data) {
        return {
          ...state,
        }
      }

      const payload = action.payload
      const updatedParentOrganizations = state.data?.map((organization) => {
        if (organization.id !== payload.data?.id) {
          return organization
        }
        return {
          ...organization,
          ...payload.data,
        }
      })
      return {
        ...state,
        data: updatedParentOrganizations,
      }
    })
    .addCase(Actions_ParentOrganizations.store.reset, () => {
      return initialState
    })
})

export default ReducerParentOrganizations
