import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { IOrganization } from 'redux/modules/organizations/TReducer'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'
import { TOrganizationTypes } from 'redux/modules/organizationTypes/TReducer'
import { Actions_OrganizationTypes } from 'redux/modules/organizationTypes/Actions'
import { TParkings } from 'redux/modules/parkings/TReducer'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { Actions_Staff } from 'redux/modules/staff/Actions'
import { TStaff } from 'redux/modules/staff/TReducer'
import SagaWorker_CustomRoles from '../events/SagaWorker_CustomRoles'
import SagaWorker_GetParentOrganizations from '../events/parentOrganization/SagaWorker_GetParentOrganizations'
import SagaWorker_GetRegions from '../events/regions/SagaWorker_GetRegions'
import { Actions_OrganizationInvitationCodes } from 'redux/modules/organizationInvitationCodes/Actions'
import { TOrganizationInvitationCode } from 'redux/modules/organizationInvitationCodes/TReducer'

const { get } = RequestMethods()

export interface ISagaPage_OrganizationDetails {
  id: string
}

export default function* SagaPage_OrganizationDetails(action: PayloadAction<ISagaPage_OrganizationDetails>) {
  const { id } = action.payload
  let trailerStatus: number[] = []

  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting and saving organizations ----------------------//

    const organizations: TStoreTemplate<IOrganization> = yield call(
      async () => await get<IOrganization>(`/organizations/${id}`),
    )

    yield put(Actions_CurrentOrganization.store.saveCurrentOrganization(organizations))

    //------------------------- Getting and saving parent organizations ----------------------//

    yield call(SagaWorker_GetParentOrganizations)

    //------------------------- Getting and saving regions ----------------------//

    yield call(SagaWorker_GetRegions)

    //------------------------- Getting and saving organization types ----------------------//

    const organizationTypes: TStoreTemplate<TOrganizationTypes> = yield call(
      async () => await get<TOrganizationTypes>('/organizations/types'),
    )
    yield put(Actions_OrganizationTypes.store.saveOrganizationTypes(organizationTypes))

    //------------------------- Change and saving staff ----------------------//

    const staffFormData = {
      organization: [id],
      hideCustomers: true,
      hideDeleted: true,
    }

    const staffResponse: TStoreTemplate<TStaff> = yield call(async () => await get<TStaff>('/users', staffFormData))
    const staffData = staffResponse.data?.users || []
    const staffResponseInfo = staffResponse.responseInfo

    const newStaff = { data: staffData, responseInfo: staffResponseInfo, isAllDataLoaded: true }

    yield put(Actions_Staff.store.saveStaff(newStaff))

    //------------------------- Getting and saving custom roles ----------------------//

    yield call(SagaWorker_CustomRoles)

    //------------------------- Getting and saving parkings ---------------------------//

    const parkingResponse: TStoreTemplate<TParkings> = yield call(
      async () => await get<TParkings>('/parking', { organization: [id] }),
    )
    yield put(Actions_Parkings.store.saveParkings({ ...parkingResponse }))

    //------------------------- Getting and saving organization invitation codes ---------------------------//

    const organizationInvitationCodesResponse: TStoreTemplate<TOrganizationInvitationCode[]> = yield call(
      async () =>
        await get<TOrganizationInvitationCode[]>(`/organizations/${id}/invitationCodes`, { hideInactive: false }),
    )
    yield put(Actions_OrganizationInvitationCodes.store.saveCodes({ ...organizationInvitationCodesResponse }))

    //------------------------- Getting and saving trailer types ----------------------//

    const trailerTypesResponse: TStoreTemplate<TTrailerTypes> = yield call(
      async () => await get<TTrailerTypes>('/trailer_types'),
    )
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(trailerTypesResponse))

    //------------------------- Getting trailer statuses -------------------//

    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await get<TTrailerStatuses>('/trailers/statuses'),
    )
    if (t_statuses.data?.statuses) {
      trailerStatus = t_statuses.data.statuses.map((t_status) => t_status.id)
    }

    //------------------------- Getting and saving trailers ---------------------------//

    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () =>
        await get<TTrailers>('/trailers/list', {
          status: trailerStatus,
          organization: [id],
        }),
    )
    yield put(Actions_Trailers.store.saveTrailers(trailerResponse))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
