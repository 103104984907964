enum StoreActions {
  IS_LOADER_SHOW = 'PARENT_ORGANIZATIONS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'PARENT_ORGANIZATIONS/IS_LOADER_HIDE',
  SAVE_PARENT_ORGANIZATIONS = 'PARENT_ORGANIZATIONS/SAVE_ORGANIZATIONS',
  EDIT_PARENT_ORGANIZATION = 'PARENT_ORGANIZATIONS/EDIT_PARENT_ORGANIZATION',
  RESET = 'PARENT_ORGANIZATIONS/RESET',
}

enum SagaEvents {
  LOAD_PAGE = 'PARENT_ORGANIZATIONS/LOAD_PAGE',
  GET_PARENT_ORGANIZATIONS = 'PARENT_ORGANIZATIONS/GET_ORGANIZATIONS',
  CREATE_PARENT_ORGANIZATION = 'PARENT_ORGANIZATIONS/CREATE_ORGANIZATION',
  UPDATE_PARENT_ORGANIZATION = 'PARENT_ORGANIZATIONS/UPDATE_ORGANIZATION',
  ACTIVATE_PARENT_ORGANIZATION = 'PARENT_ORGANIZATIONS/ACTIVATE_ORGANIZATION',
  DEACTIVATE_PARENT_ORGANIZATION = 'PARENT_ORGANIZATIONS/DEACTIVATE_ORGANIZATION',
}

export const ActionTypes_ParentOrganizations = {
  SagaEvents,
  StoreActions,
}
