import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TStoreTemplate } from 'redux/config/root-types'
import { TUser } from 'redux/modules/users/TReducer'
import { TRent } from 'redux/modules/rents/TReducer'

const client = RequestMethods()

export interface ISagaWorker_CompleteRent {
  userId: number
  chargeUser: boolean
  chargeOnlyInsurance?: boolean
  finishStationId: number
}

export default function* SagaWorker_CompleteRent(action: PayloadAction<ISagaWorker_CompleteRent>) {
  yield put(Actions_CurrentUser.store.showLoader())

  try {
    yield call(async () => await client.post<TRent>('/rents/stop', action.payload))

    yield put(Actions_Notifications.store.setNotification({ message: 'Rent successfully completed', type: 'Success' }))
    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Modals.store.showModal({
        type: 'LockStation',
        data: { stationId: action.payload.finishStationId },
      }),
    )

    //------------------------- Getting and saving user ----------------------//
    const user: TStoreTemplate<TUser> = yield call(
      async () => await client.get<TUser>(`/users/${action.payload.userId}`),
    )
    yield put(Actions_CurrentUser.store.saveCurrentUser(user))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentUser.store.hideLoader())
  }
}
