enum StoreActions {
  IS_LOADER_SHOW = 'USER_NOTES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'USER_NOTES/IS_LOADER_HIDE',
  SAVE_USER_NOTES = 'USER_NOTES/SAVE_NOTES',
  RESET = 'USER_NOTES/RESET',
}

enum SagaEvents {
  GET_USER_NOTES = 'USER_NOTES/GET_NOTES',
  CREATE_USER_NOTE = 'USER_NOTES/CREATE_NOTE',
  DELETE_USER_NOTE = 'USER_NOTES/DELETE_NOTE',
}

export const ActionTypes_UserNotes = {
  SagaEvents,
  StoreActions,
}
