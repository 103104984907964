import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_OrganizationInvitationCodes } from './TActions'
import { TOrganizationInvitationCode } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { StoreActions } = ActionTypes_OrganizationInvitationCodes

export const Actions_OrganizationInvitationCodes = {
  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveCodes: createAction<TStoreTemplate<TOrganizationInvitationCode[]>>(StoreActions.SAVE_INVITATION_CODES),
    reset: createAction(StoreActions.RESET),
  },
}
