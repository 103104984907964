import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import { IBreakdownsTableData, useBreakdownHistoryTable } from './BreakdownHistoryTable-ViewModel'

const BreakdownHistoryTable: FC = () => {
  const vm = useBreakdownHistoryTable()
  const { table } = vm
  return <Shared.Table<IBreakdownsTableData> {...table} />
}

export default React.memo(BreakdownHistoryTable)
