import React, { FC } from 'react'
import { TNotificationMessage, TNotificationType } from 'redux/modules/notification/TReducer'
import { useMessage } from './Message-ViewModel'
import Description from './Description'

type TProps = {
  type: TNotificationType
  message: string | TNotificationMessage[]
}

export const Message: FC<TProps> = ({ type, message }) => {
  const vm = useMessage(type)
  const { classes, title, Icon } = vm

  return (
    <div className={classes.container}>
      {!!Icon && <Icon className={classes.icon} />}
      <Description title={title} message={message} />
    </div>
  )
}

export default Message
