import { put } from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'

export interface ISagaWorker_ShowOnMap {
  id: number
  lat: number
  lng: number
  navigateToTrailersMap: () => void
}

export default function* SagaWorker_ShowOnMap(action: PayloadAction<ISagaWorker_ShowOnMap>) {
  const { id, lat, lng, navigateToTrailersMap } = action.payload

  yield put(Actions_CurrentTrailer.store.changeSelectedTrailerInfo({ id, lat, lng }))

  navigateToTrailersMap()
}
