import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import { CalendarHeader } from 'ui/shared/SingleDatePickerForForm/CalendarHeader/CalendarHeader'
import { CustomInput } from 'ui/shared/SingleDatePickerForForm/CustomInput/CustomInput'
import { Shared } from 'ui/shared'
import { useDateFilter } from './DateFilter-ViewModel'
import { Grid } from '@mui/material'

export const DAY_WIDTH = 50

const DateFilter: FC = () => {
  const { classes } = useStyles()
  const { value, onChange, onSubmit, onCancel, isFilterOn, onClearFilter } = useDateFilter()

  return (
    <Shared.Card style={{ width: 375 }}>
      <Shared.CardTitle title={'Expiration date'} />
      <Grid item xs={12}>
        <div className={classes.datePicker}>
          <DatePicker
            selected={value}
            onChange={onChange}
            withPortal
            className={classes.datePicker}
            calendarClassName={classes.calendar}
            dayClassName={() => classes.day}
            renderCustomHeader={CalendarHeader}
            dateFormat="yyyy MMM dd"
            customInput={<CustomInput name={'date'} />}
          />
        </div>
      </Grid>
      <Grid item xs={6} className={classes.buttons}>
        <Shared.Button className={classes.button} title={'Cancel'} onClick={onCancel} color={'secondary'} />
        <Shared.Button
          className={classes.button}
          width={'100%'}
          title={'Submit'}
          onClick={onSubmit}
          color={'primary'}
        />
      </Grid>
      {isFilterOn && (
        <Shared.Button width={'100%'} title={'Clear filter'} type="submit" color={'primary'} onClick={onClearFilter} />
      )}
    </Shared.Card>
  )
}

export default DateFilter

const useStyles = makeStyles()((theme) => ({
  label: {
    // ...theme.typography.body1, TODO:
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  card: {
    width: 400,
    overflowY: 'auto',
    maxHeight: '100%',
    padding: 24,
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
  },
  listItemText: {
    // ...theme.typography.body1, TODO:
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  cancel: {
    margin: '0.875em 0',
  },

  datePicker: {
    '& .react-datepicker__portal': {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',

      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      animation: `${keyframes`
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
      `} .2s ease-in-out`,
    },

    '& .react-datepicker': {
      border: 'none',
      padding: 24,
      borderRadius: 0,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
  calendar: {
    '&& .react-datepicker__month': {
      margin: 0,
    },

    '&& .react-datepicker__header': {
      border: 'none',
      borderRadius: 0,
      backgroundColor: 'transparent',
      padding: 0,
    },

    '&& .react-datepicker__day-names': {
      display: 'none',
    },

    '&& .react-datepicker__day': {
      borderRadius: 0,
      width: DAY_WIDTH,
      margin: 0,
      color: theme.palette.text.primary,
      lineHeight: '38px',
    },
  },
  day: {
    '&&:hover': {
      borderRadius: 0,
      background: '#e4e7e7',
    },

    '&&:focus-visible': {
      outlineColor: theme.palette.text.secondary,
      outlineWidth: 2,
      outlineStyle: 'solid',
      borderRadius: '0',
    },

    '&&.react-datepicker__day--in-selecting-range': {
      background: '#fff2dc',
    },

    '&&.react-datepicker__day--selected': {
      background: theme.palette.primary.main,
    },

    '&&.react-datepicker__day--in-range': {
      background: '#fff2dc',

      '&&:hover': {
        background: theme.palette.primary.main,
      },
    },
  },

  edgeSelectedDays: {
    '&&.react-datepicker__day--in-range': {
      background: theme.palette.primary.main,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 8,
  },
  button: {
    margin: 4,
  },
}))
