enum StoreActions {
  IS_LOADER_SHOW = 'ME_ROLE/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'ME_ROLE/IS_LOADER_HIDE',
  SAVE_ME_ROLE = 'ME_ROLE/SAVE_ME_ROLE',
  SET_INITIALIZED = 'ME_ROLE/SET_INITIALIZED',
}

enum SagaEvents {}

export const ActionTypes_MeRole = {
  StoreActions,
  SagaEvents,
}
