enum StoreActions {
  IS_LOADER_SHOW = 'CURRENT_PARKING/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CURRENT_PARKING/IS_LOADER_HIDE',
  SAVE_CURRENT_PARKING = 'CURRENT_PARKING/SAVE_CURRENT_PARKING',
  CLEAR_CURRENT_PARKING = 'CURRENT_PARKING/CLEAR_CURRENT_PARKING',
  SELECT_PARKING_INFO = 'CURRENT_PARKING/SELECT_PARKING_INFO',
  CLEAR_SELECTED_PARKING_INFO = 'CURRENT_PARKING/CLEAR_SELECTED_PARKING_INFO',
  UPDATE_CURRENT_PARKING_STATION = 'CURRENT_PARKING/UPDATE_CURRENT_PARKING_STATION',
}

enum SagaEvents {
  GET_MORE_BY_PARKING_FOR_CARD = 'CURRENT_PARKING/GET_MORE_BY_PARKING_FOR_CARD',
  GET_PARKING_DETAILS = 'CURRENT_PARKING/GET_PARKING_DETAILS',
  CREATE_STATION_AND_GET_PARKING_DETAILS = 'PARKINGS/CREATE_STATION_AND_GET_PARKING_DETAILS',
  CREATE_PARKING = 'CURRENT_PARKING/CREATE_PARKING',
  UPDATE_PARKING = 'CURRENT_PARKING/UPDATE_PARKING',
  UPDATE_PARKING_STATION = 'CURRENT_PARKING/UPDATE_PARKING_STATION',
  REFRESH_PARKING = 'CURRENT_PARKING/REFRESH',
}

export const ActionTypes_CurrentParking = {
  SagaEvents,
  StoreActions,
}
