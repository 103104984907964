enum StoreActions {
  IS_LOADER_SHOW = 'REGIONS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'REGIONS/IS_LOADER_HIDE',
  SAVE_REGIONS = 'REGIONS/SAVE_REGIONS',
  RESET = 'REGIONS/RESET',
}

enum SagaEvents {
  GET_REGIONS = 'REGIONS/GET_ORGANIZATIONS',
}

export const ActionTypes_Regions = {
  SagaEvents,
  StoreActions,
}
