import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_CurrentPosition } from './TActions'
import { TSelectedPositionInfoPayload } from './TReducer'

const { StoreActions } = ActionTypes_CurrentPosition
export const Actions_CurrentPosition = {
  saga: {},
  store: {
    changeSelectedPositionInfo: createAction<TSelectedPositionInfoPayload>(StoreActions.SELECT_POSITION_INFO),
    clearSelectedPositionInfo: createAction(StoreActions.CLEAR_SELECTED_POSITION_INFO),
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
  },
}
