enum StoreActions {
  IS_HIDE_DRAWER = 'NOTIFICATION_HISTORY/IS_HIDE_DRAWER',
  IS_SHOW_DRAWER = 'NOTIFICATION_HISTORY/IS_SHOW_DRAWER',
  IS_HIDE_PARKINGS_DRAWER = 'NOTIFICATION_HISTORY/IS_HIDE_PARKINGS_DRAWER',
  IS_SHOW_PARKINGS_DRAWER = 'NOTIFICATION_HISTORY/IS_SHOW_PARKINGS_DRAWER',
  IS_HIDE_STATIONS_DRAWER = 'NOTIFICATION_HISTORY/IS_HIDE_STATIONS_DRAWER',
  IS_SHOW_STATIONS_DRAWER = 'NOTIFICATION_HISTORY/IS_SHOW_STATIONS_DRAWER',
  IS_LOADER_SHOW = 'NOTIFICATION_HISTORY/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'NOTIFICATION_HISTORY/IS_LOADER_HIDE',
  SAVE_NOTIFICATIONS = 'NOTIFICATION_HISTORY/SAVE_NOTIFICATIONS',
  CLEAR_NOTIFICATIONS = 'NOTIFICATION_HISTORY/CLEAR_NOTIFICATIONS',
  IS_SHOW_UNREAD_MESSAGES_INDICATOR = 'NOTIFICATION_HISTORY/IS_SHOW_UNREAD_MESSAGES_INDICATOR',
  IS_HIDE_UNREAD_MESSAGES_INDICATOR = 'NOTIFICATION_HISTORY/IS_HIDE_UNREAD_MESSAGES_INDICATOR',
  IS_SHOW_PARKINGS_PROBLEM_INDICATOR = 'NOTIFICATION_HISTORY/IS_SHOW_PARKINGS_PROBLEM_INDICATOR',
  IS_HIDE_PARKINGS_PROBLEM_INDICATOR = 'NOTIFICATION_HISTORY/IS_HIDE_PARKINGS_PROBLEM_INDICATOR',
  IS_SHOW_STATIONS_PROBLEM_INDICATOR = 'NOTIFICATION_HISTORY/IS_SHOW_STATIONS_PROBLEM_INDICATOR',
  IS_HIDE_STATIONS_PROBLEM_INDICATOR = 'NOTIFICATION_HISTORY/IS_HIDE_STATIONS_PROBLEM_INDICATOR',
  ADD_PROBLEMS = 'NOTIFICATION_HISTORY/PROBLEMS',
}

enum SagaEvents {
  GET_NOTIFICATIONS = 'NOTIFICATION_HISTORY/GET_NOTIFICATIONS',
  SHOW_NOTIFICATION_AND_GET_NOTIFICATIONS = 'NOTIFICATION_HISTORY/SHOW_NOTIFICATION_AND_GET_NOTIFICATIONS',
}

export const ActionTypes_NotificationHistory = {
  SagaEvents,
  StoreActions,
}
