import { TStoreTemplate } from 'redux/config/root-types'
import { call, put } from 'redux-saga/effects'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'

const { get } = RequestMethods()

export default function* SagaWorker_LoadAllTrailers() {
  yield put(Actions_Parkings.store.showLoader())
  let trailerStatus: number[] = []

  try {
    //------------------------- Getting trailer statuses -------------------//

    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await get<TTrailerStatuses>('/trailers/statuses'),
    )
    if (t_statuses.data?.statuses) {
      trailerStatus = t_statuses.data.statuses.map((t_status) => t_status.id)
    }

    //------------------------- Getting and saving all trailers ---------------------------//
    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () =>
        await get<TTrailers>('/trailers/list', {
          status: trailerStatus,
        }),
    )

    const trailers = trailerResponse.data || []

    yield put(Actions_Trailers.store.saveAllTrailers(trailers))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Parkings.store.hideLoader())
  }
}
