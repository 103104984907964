import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { TBreakdown } from 'redux/modules/breakdowns/TReducer'
import { Actions_Breakdowns } from 'redux/modules/breakdowns/Actions'

const client = RequestMethods()

export interface ISagaWorker_UpdateBreakdown {
  id: number
  data: {
    isConfirmed?: boolean
    isResolved?: boolean
    isRejected?: boolean
  }
}

export default function* SagaWorker_UpdateBreakdown(action: PayloadAction<ISagaWorker_UpdateBreakdown>) {
  yield put(Actions_Breakdowns.store.showLoader())
  const { data, id } = action.payload

  try {
    const breakdown: TStoreTemplate<TBreakdown> = yield call(
      async () => await client.put<TBreakdown>(`/trailers/issueReports/${id}`, data),
    )

    yield put(Actions_Breakdowns.store.editBreakdown(breakdown))

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Breakdown successfully updated', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Breakdowns.store.hideLoader())
  }
}
