import React, { FC } from 'react'
import IconButton from '@mui/material/IconButton'
import { Controls } from 'assets/images/Controls'
import { SxProps, Theme } from '@mui/material/styles'

type TPorps = {
  onSendClick: () => void
  sx?: SxProps<Theme>
}

const NoteSendButton: FC<TPorps> = ({ onSendClick, sx }) => {
  return (
    <IconButton onClick={onSendClick} size="large" sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Controls.Send />
    </IconButton>
  )
}

export default NoteSendButton
