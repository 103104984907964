import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Box from '@mui/material/Box'
import { useBreakdownHistory } from './BreakdownHistory-ViewModel'
import { Controls } from 'assets/images/Controls'
import { BreakdownHistoryTable } from './components'

export const BreakdownHistory: FC = () => {
  const vm = useBreakdownHistory()
  const { closeModal, t } = vm

  return (
    <Shared.Card
      style={{ width: 'calc(100vw - 40px)', height: 'calc(100vh - 128px)', display: 'flex', flexDirection: 'column' }}
    >
      <Box display="flex" justifyContent="space-between">
        <Shared.CardTitle title={t('title')} />
        <Controls.CloseDark
          style={{ cursor: 'pointer', alignSelf: 'center', marginLeft: 20 }}
          width="20px"
          onClick={closeModal}
        />
      </Box>

      <Box marginTop={4} height="inherit">
        <BreakdownHistoryTable />
      </Box>
    </Shared.Card>
  )
}
