import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Form } from 'utils/helpers/form'
import { Actions_ParentOrganizations } from 'redux/modules/parentOrganizations/Actions'

interface IForm {
  name: string
  discount: string
}

const formValidation = {
  name: Form.required,
  discount: Form.max(100),
}

export const useParentOrganizationCreate = () => {
  const { t } = useTranslation(I18nModal.parentOrganizationCreate)
  const dispatch = useDispatch()

  const isLoading = useSelector(({ parentOrganizations }: TState) => parentOrganizations.isLoading)

  const { handleSubmit, register, errors } = useForm<IForm>({
    defaultValues: {
      name: '',
      discount: '',
    },
  })

  const onSubmit = (formData: IForm) => {
    const data = {
      ...formData,
      discount: Number(formData.discount),
    }
    dispatch(Actions_ParentOrganizations.saga.createParentOrganization(data))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    closeModal,
    isLoading,
    form: {
      formValidation,
      errors,
      register,
      handleSubmit: handleSubmit(onSubmit),
    },
  }
}
