import React, { FC } from 'react'
import { useStationCreate } from './StationCreate-ViewModel'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { Form } from 'utils/helpers/form'

export const StationCreate: FC = () => {
  const vm = useStationCreate()
  const { closeModal, form, isLoading, t } = vm
  const { errors, handleSubmit, register, control, parkingOptions, formValidation } = form

  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
            <Shared.InputField
              name="stationCode"
              title={t('stationCode')}
              register={register(formValidation.stationCode)}
              error={errors.stationCode?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="serialNumber"
              title={t('serialNumber')}
              register={register(formValidation.serialNumber)}
              error={errors.serialNumber?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="imei"
              title={t('imei')}
              register={register(formValidation.imei)}
              error={errors.imei?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="idHigh"
              title={t('idHigh')}
              register={register(formValidation.idHigh)}
              error={errors.idHigh?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="idMiddle"
              title={t('idMiddle')}
              register={register(formValidation.idMiddle)}
              error={errors.idMiddle?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="idLow"
              title={t('idLow')}
              register={register(formValidation.idLow)}
              error={errors.idLow?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Dropdown
              name="parkingId"
              label={t('parkingId')}
              options={parkingOptions}
              marginTop={0}
              fullWidth
              control={control}
              rules={Form.required}
              error={errors.parkingId?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
            />
            <Shared.Button title={t('submit')} type={'submit'} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
