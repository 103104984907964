import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'

const { remove } = RequestMethods()

export interface ISagaWorker_DeleteParking {
  id: number
  isList?: boolean
}

export default function* SagaWorker_DeleteParking(action: PayloadAction<ISagaWorker_DeleteParking>) {
  const { id, isList } = action.payload
  yield put(Actions_Parkings.store.showLoader())

  try {
    //------------------------- Remove parking ---------------------------//
    yield call(async () => await remove(`/parking/${id}`))

    yield put(Actions_CurrentParking.store.clearSelectedParkingInfo())

    if (isList) {
      yield put(Actions_Parkings.store.deleteParkingFromAllParkings({ id }))
    } else {
      yield put(Actions_Parkings.store.deleteParking({ id }))
    }

    yield put(Actions_Notifications.store.setNotification({ message: 'Parking deleted successfully', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Parkings.store.hideLoader())
  }
}
