import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { TNotificationPayload } from '../notification/TReducer'
import { ActionTypes_NotificationHistory } from './TActions'
import { IProblemsNotification, TNotifications } from './TReducer'

const { SagaEvents, StoreActions } = ActionTypes_NotificationHistory

export const Actions_notificationHistory = {
  saga: {
    getNotificationHistory: createAction(SagaEvents.GET_NOTIFICATIONS),
    showNotificationAndgetNotificationHistory: createAction<TNotificationPayload>(
      SagaEvents.SHOW_NOTIFICATION_AND_GET_NOTIFICATIONS,
    ),
  },

  store: {
    showDrawer: createAction(StoreActions.IS_SHOW_DRAWER),
    hideDrawer: createAction(StoreActions.IS_HIDE_DRAWER),
    showParkingsDrawer: createAction(StoreActions.IS_SHOW_PARKINGS_DRAWER),
    hideParkingsDrawer: createAction(StoreActions.IS_HIDE_PARKINGS_DRAWER),
    showStationsDrawer: createAction(StoreActions.IS_SHOW_STATIONS_DRAWER),
    hideStationsDrawer: createAction(StoreActions.IS_HIDE_STATIONS_DRAWER),
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    showUnreadMessagesIndicator: createAction(StoreActions.IS_SHOW_UNREAD_MESSAGES_INDICATOR),
    hideUnreadMessagesIndicator: createAction(StoreActions.IS_HIDE_UNREAD_MESSAGES_INDICATOR),
    saveNotifications: createAction<TStoreTemplate<TNotifications>>(StoreActions.SAVE_NOTIFICATIONS),
    clearNotifications: createAction(StoreActions.CLEAR_NOTIFICATIONS),
    addProblems: createAction<IProblemsNotification>(StoreActions.ADD_PROBLEMS),
    showParkingsProblemIndicator: createAction(StoreActions.IS_SHOW_PARKINGS_PROBLEM_INDICATOR),
    hideParkingsProblemIndicator: createAction(StoreActions.IS_HIDE_PARKINGS_PROBLEM_INDICATOR),
    showStationsProblemIndicator: createAction(StoreActions.IS_SHOW_STATIONS_PROBLEM_INDICATOR),
    hideStationsProblemIndicator: createAction(StoreActions.IS_HIDE_STATIONS_PROBLEM_INDICATOR),
  },
}
