import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { IOrder } from 'utils/types'
import { TTrailersCounters } from 'redux/modules/trailersCounters/TReducer'
import { Actions_TrailersCounters } from 'redux/modules/trailersCounters/Actions'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { Actions_Organizations } from 'redux/modules/organizations/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_TrailerStatuses } from 'redux/modules/trailerStatuses/Actions'

const { get } = RequestMethods()

export interface ISagaWorker_ChangeMapChargeStatus {
  trailers?: {
    orderBy?: IOrder
    sorting?: 'id' | 'status' | 'batteryPercent'
    limit?: number
    offset?: number
  }
  updatedTrailerTypeId?: number
  updatedStatusId?: number
  updatedOrganization?: number[]
}

export default function* SagaWorker_LoadTrailerMarkers(action: PayloadAction<ISagaWorker_ChangeMapChargeStatus>) {
  const {
    payload: { updatedTrailerTypeId, updatedStatusId, updatedOrganization },
  } = action

  const getSelectedTrailerTypeIds = (state: TState) => state.trailerTypes.selectedTrailerTypeIds
  const getSelectedTrailerStatusIds = (state: TState) => state.trailerStatuses.selectedStatusIds
  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds

  let types: ReturnType<typeof getSelectedTrailerTypeIds> = yield select(getSelectedTrailerTypeIds)
  if (updatedTrailerTypeId) {
    types = addOrRemoveSelected(updatedTrailerTypeId, types)
    yield put(Actions_TrailerTypes.store.changeSelectedTrailerTypesIds(types))
  }

  let status: ReturnType<typeof getSelectedTrailerStatusIds> = yield select(getSelectedTrailerStatusIds)
  if (updatedStatusId) {
    status = addOrRemoveSelected(updatedStatusId, status)
    yield put(Actions_TrailerStatuses.store.changeSelectedStatusesIds(status))
  }

  let organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
  if (updatedOrganization) {
    organization = updatedOrganization
    yield put(Actions_Organizations.store.changeSelectedOrganizationIds(organization))
  }

  yield put(Actions_Trailers.store.showLoader())
  try {
    //------------------------- Getting and saving trailers ---------------------------//
    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () =>
        await get<TTrailers>('/trailers/list', {
          types,
          status,
          organization,
        }),
    )
    yield put(Actions_Trailers.store.saveTrailers(trailerResponse))

    //------------------------- Getting and saving trailer counters ----------------------//

    const t_counters: TStoreTemplate<TTrailersCounters> = yield call(
      async () => await get<TTrailersCounters>('/trailers/counters', { types, status, organization }),
    )
    yield put(Actions_TrailersCounters.store.saveTrailersCounters(t_counters))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_Trailers.store.hideLoader())
}

const addOrRemoveSelected = (newId: number, arr: number[]) => {
  if (arr.includes(newId)) {
    return arr.filter((id) => id !== newId)
  }
  return [...arr, newId]
}
