import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { TParentOrganization } from 'redux/modules/parentOrganizations/TReducer'
import { Actions_ParentOrganizations } from 'redux/modules/parentOrganizations/Actions'

const client = RequestMethods()

export interface ISagaWorker_UpdateParentOrganization {
  id: number
  data: {
    name: string
    discount: number
  }
}

export default function* SagaWorker_UpdateParentOrganization(
  action: PayloadAction<ISagaWorker_UpdateParentOrganization>,
) {
  yield put(Actions_ParentOrganizations.store.showLoader())
  const { data, id } = action.payload

  try {
    const organization: TStoreTemplate<TParentOrganization> = yield call(
      async () => await client.put<TParentOrganization>(`/organizations/parents/${id}`, data),
    )

    yield put(Actions_ParentOrganizations.store.editParentOrganization(organization))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Parent organization successfully updated',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_ParentOrganizations.store.hideLoader())
  }
}
