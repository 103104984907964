import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_OrganizationTypes } from './TActions'
import { TOrganizationTypes } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { StoreActions, SagaEvents } = ActionTypes_OrganizationTypes

export const Actions_OrganizationTypes = {
  saga: {
    getOrganizationTypes: createAction(SagaEvents.GET_ORGANIZATION_TYPES),
  },
  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveOrganizationTypes: createAction<TStoreTemplate<TOrganizationTypes>>(StoreActions.SAVE_ORGANIZATION_TYPES),
  },
}
