import React, { useEffect, FC, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import useKeyPress from 'utils/hooks/useKeyPress'
import { SxProps, Theme } from '@mui/material/styles'

type TPorps = {
  onChangeNote: (text: string) => void
  note: string
  onSend: () => void
  sx?: SxProps<Theme>
}

const NoteInput: FC<TPorps> = ({ onChangeNote, note, onSend, sx }) => {
  const enterPress = useKeyPress('Enter')
  const shiftPress = useKeyPress('Shift')

  const [focused, setFocused] = useState(false)

  const inputRef = useRef<HTMLInputElement>()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (enterPress && !shiftPress) return
    onChangeNote(event.target.value)
  }

  const onFocus = () => {
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)
  }

  useEffect(() => {
    if (focused && enterPress && !shiftPress) {
      onSend()
    }
  }, [enterPress, shiftPress])

  return (
    <TextField
      aria-label="Notes input"
      name="notesInput"
      value={note}
      onChange={handleChange}
      multiline
      maxRows={8}
      fullWidth
      onFocus={onFocus}
      onBlur={onBlur}
      sx={[textFieldStyle, ...(Array.isArray(sx) ? sx : [sx])]}
      inputRef={inputRef}
    />
  )
}

export default NoteInput

const textFieldStyle = {
  '& .MuiInputBase-root': {
    padding: '14px',
    borderRadius: '24px',
  },
}
