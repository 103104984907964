import React, { FC } from 'react'
import { Shared } from 'ui/shared/index'
import { useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { makeStyles } from 'tss-react/mui'
import { headerHeight } from 'ui/root-components/StaticControls/StaticControls-ViewModel'

interface IProps {
  className?: string
  children?: React.ReactNode
}

export const Page: FC<IProps> = ({ children, className }) => {
  const isLoading = useSelector(({ pageloader }: TState) => pageloader.isLoading)
  const { classes, cx } = useStyles()
  return (
    <div className={cx(classes.content, className)}>
      {children}
      {isLoading && <Shared.Preloader mask color={'primary'} />}
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'relative',
    minHeight: `calc(100vh - ${headerHeight.lg}px)`,
    [theme.breakpoints.only('xl')]: {
      minHeight: `calc(100vh - ${headerHeight.xl}px)`,
    },
  },
}))
