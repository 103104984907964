import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'
import { TCurrentTrailer } from 'redux/modules/currentTrailer/TReducer'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { getMedia, IMedia } from 'redux/sagas/actions/mediaAction'

const client = RequestMethods()

export interface ISagaWorker_UpdateTrailer {
  id: number
  data: {
    imei: string
    vin: string
    plate: string
    sim: string
    identifyNumber: string
    organization: number[]
    vehicleLicence: string
    idHigh: string
    idLow: string
    statusId: number | string
  }
  licenseFile?: File
}

export default function* SagaWorker_UpdateTrailer(action: PayloadAction<ISagaWorker_UpdateTrailer>) {
  const {
    payload: { licenseFile, data, id },
  } = action
  yield put(Actions_CurrentTrailer.store.showLoader())

  //------------------------- Getting token and authorize ---------------------------//
  const getCurrentTrailer = (state: TState) => state.currentTrailer

  const trailerData: ReturnType<typeof getCurrentTrailer> = yield select(getCurrentTrailer)

  try {
    if (licenseFile) {
      const mediaData: IMedia = yield call(() => getMedia(licenseFile))
      data.vehicleLicence = mediaData.name || ''
    }
    //------------------------- Getting and saving trailer ---------------------------//
    yield call(async () => await client.put<TCurrentTrailer>(`/trailers/${id}`, data))

    yield put(Actions_Modals.store.hideModal())

    if (trailerData.data) {
      const { organization, ...trailer } = action.payload.data
      yield put(
        Actions_CurrentTrailer.store.saveCurrentTrailer({
          ...trailerData,
          data: { ...trailerData.data, trailer: { ...trailerData.data.trailer, ...trailer }, organization },
        }),
      )
    }
    yield put(Actions_Notifications.store.setNotification({ message: 'Trailer successfully updated', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CurrentTrailer.store.hideLoader())
}
