import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { TRole } from 'redux/modules/templateRoles/TReducer'
import { Actions_TemplateRoles } from 'redux/modules/templateRoles/Actions'

export const useRole = () => {
  const rolesData = useSelector(({ templateRoles }: TState) => templateRoles.data?.roles)
  const templateRoles = useMemo(() => rolesData || [], [rolesData])
  const dispatch = useDispatch()
  useEffect(
    function reloadOptions() {
      if (!templateRoles.length) {
        dispatch(Actions_TemplateRoles.saga.getTemplateRoles())
      }
    },
    [dispatch, templateRoles.length],
  )

  const options = useMemo(() => toDropdownOptions(templateRoles), [templateRoles])

  return {
    options,
  }
}

const toDropdownOptions = (roles: TRole[]): TDropdownOption[] =>
  roles.map(({ name, id }) => ({ id, value: id, label: name }))
