import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'
import { IOrganization } from 'redux/modules/organizations/TReducer'
import { To } from 'router/To'
import type { NavigateFunction } from 'react-router-dom'

const client = RequestMethods()

export type ISagaWorker_UpdateOrganization = {
  id: string
  data: {
    name: string
    taxNumber: string
    typeId: number | null
    parentId: number | null
    regionId: number | null
    street: string
    house: string
    postCode: string
    city: string
    country: string
  }
  navigate: NavigateFunction
}

export default function* SagaWorker_UpdateOrganization(action: PayloadAction<ISagaWorker_UpdateOrganization>) {
  const { data, id, navigate } = action.payload
  try {
    yield put(Actions_CurrentOrganization.store.showLoader())

    yield call(async () => await client.put<IOrganization>(`/organizations/${id}`, data))

    yield call(() => navigate(To.organizations))

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Organization successfully updated', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentOrganization.store.hideLoader())
  }
}
