import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { TCurrentParking } from 'redux/modules/currentParking/TReducer'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const client = RequestMethods()

export interface ISagaWorker_ResetParking {
  id: number
}

export default function* SagaWorker_ResetParking(action: PayloadAction<ISagaWorker_ResetParking>) {
  const { id } = action.payload
  try {
    yield put(Actions_CurrentParking.store.showLoader())

    yield call(async () => await client.put<TCurrentParking>(`/parking/${id}/reset`))

    yield put(Actions_Modals.store.hideModal())

    yield put(Actions_Notifications.store.setNotification({ message: 'Parking successfully reset', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CurrentParking.store.hideLoader())
}
