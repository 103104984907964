import React, { createContext, FC, forwardRef, useContext } from 'react'
import { FixedSizeList as List, FixedSizeListProps } from 'react-window'
import { Shared } from 'ui/shared'
import { IOrder } from 'utils/types'
import { IColumn } from '../Table'

interface IStickyListContextProps {
  headerHeight: number
  columns: IColumn[]
  isShowMenuButton: boolean
  sortDirection: IOrder
  sortBy?: string
  onSortClick?: (dataKey: string) => void
  onFilterClick?: (dataKey: string) => void
}

interface ITableWithHeaderProps extends FixedSizeListProps, IStickyListContextProps {}

export const StickyListContext = createContext<IStickyListContextProps>({
  headerHeight: 36,
  columns: [],
  isShowMenuButton: false,
  sortDirection: 'ASC',
  sortBy: undefined,
  onSortClick: () => {},
  onFilterClick: () => {},
})

const TableWithHeader: FC<ITableWithHeaderProps> = ({
  headerHeight,
  columns,
  isShowMenuButton,
  sortDirection,
  children,
  sortBy,
  onSortClick,
  onFilterClick,
  ...rest
}) => (
  <StickyListContext.Provider
    value={{ headerHeight, columns, isShowMenuButton, sortDirection, sortBy, onSortClick, onFilterClick }}
  >
    <List innerElementType={innerElementType} {...rest}>
      {children}
    </List>
  </StickyListContext.Provider>
)

export default TableWithHeader

type TProps = {
  children?: React.ReactNode
}

const innerElementType = forwardRef<HTMLDivElement, TProps>(({ children, ...rest }, ref) => {
  const { headerHeight } = useContext(StickyListContext)
  return (
    <div ref={ref} {...rest}>
      <StickyRow style={{ top: 0, left: 0, width: '100%', height: headerHeight }} />
      <div style={{ top: headerHeight, width: '100%', position: 'absolute' }}>{children}</div>
    </div>
  )
})

const StickyRow = ({ style }: any) => {
  const { columns, ...otherContext } = useContext(StickyListContext)

  return (
    <div className="sticky" style={{ ...style, position: 'sticky', zIndex: 2, display: 'flex' }}>
      {columns.map((column) => (
        <Shared.TableHeaderCell key={column.dataKey} columns={columns} column={column} {...otherContext} />
      ))}
    </div>
  )
}
