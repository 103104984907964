import { ReactComponent as Error } from './Error.svg'
import { ReactComponent as Success } from './Success.svg'
import { ReactComponent as Lock } from './Lock.svg'
import { ReactComponent as Unlock } from './Unlock.svg'
import { ReactComponent as Time } from './Time.svg'
import { ReactComponent as UserError } from './UserError.svg'
import { ReactComponent as StationError } from './StationError.svg'
import { ReactComponent as TrailerError } from './TrailerError.svg'

export const NotificationIcons = {
  Error,
  Success,
  Lock,
  Unlock,
  Time,
  StationError,
  UserError,
  TrailerError,
}
