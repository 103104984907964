import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentStaffMember } from 'redux/modules/currentStaffMember/Actions'

const { post } = RequestMethods()

export interface ISagaWorker_RestorePassword {
  email: string
}

export default function* SagaWorker_RestorePassword(action: PayloadAction<ISagaWorker_RestorePassword>) {
  try {
    yield put(Actions_CurrentStaffMember.store.showLoader())

    yield call(async () => await post('/users/password/restore', action.payload))

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'A link to reset the password was sent to the email.',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentStaffMember.store.hideLoader())
  }
}
