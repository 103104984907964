enum StoreActions {
  IS_LOADER_SHOW = 'CURRENT_ORGANIZATION/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CURRENT_ORGANIZATION/IS_LOADER_HIDE',
  SAVE_CURRENT_ORGANIZATION = 'CURRENT_ORGANIZATION/SAVE_CURRENT_ORGANIZATION',
  CLEAR_CURRENT_ORGANIZATION = 'CURRENT_ORGANIZATION/CLEAR_CURRENT_ORGANIZATION',
}

enum SagaEvents {
  LOAD_PAGE = 'CURRENT_ORGANIZATION/LOAD_PAGE',
  CREATE_ORGANIZATION = 'CURRENT_ORGANIZATION/CREATE_ORGANIZATION',
  UPDATE_ORGANIZATION = 'CURRENT_ORGANIZATION/UPDATE_ORGANIZATION',
  ADD_PARKING_TO_ORGANIZATION = 'CURRENT_ORGANIZATION/ADD_PARKING_TO_ORGANIZATION',
  ADD_TRAILER_TO_ORGANIZATION = 'CURRENT_ORGANIZATION/ADD_TRAILER_TO_ORGANIZATION',
  CREATE_ORGANIZATION_INVITATION_CODES = 'CURRENT_ORGANIZATION/CREATE_ORGANIZATION_INVITATION_CODES',
  LOAD_ORGANIZATION_INVITATION_CODES_XLSX = 'CURRENT_ORGANIZATION/LOAD_ORGANIZATION_INVITATION_CODES_XLSX',
  REMOVE_PARKING_FROM_ORGANIZATION = 'CURRENT_ORGANIZATION/REMOVE_PARKING_FROM_ORGANIZATION',
  REMOVE_TRAILER_FROM_ORGANIZATION = 'CURRENT_ORGANIZATION/REMOVE_TRAILER_FROM_ORGANIZATION',
  ACTIVATE_ORGANIZATION = 'CURRENT_ORGANIZATION/ACTIVATE_ORGANIZATION',
  DEACTIVATE_ORGANIZATION = 'CURRENT_ORGANIZATION/DEACTIVATE_ORGANIZATION',
}

export const ActionTypes_CurrentOrganization = {
  StoreActions,
  SagaEvents,
}
