import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { IOrganization } from 'redux/modules/organizations/TReducer'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'
import { SagaWorker_Organizations } from 'redux/sagas'

const { post } = RequestMethods()

export interface ISagaWorker_CreateOrganization {
  name: string
  taxNumber: string
  typeId: number | null
  parentId: number | null
  regionId: number | null
  city: string
  street: string
  house: string
  postCode: string
  discount: number
}

export default function* SagaWorker_CreateOrganization(action: PayloadAction<ISagaWorker_CreateOrganization>) {
  yield put(Actions_CurrentOrganization.store.showLoader())

  try {
    yield call(async () => await post<IOrganization>(`/organizations`, action.payload))

    yield call(SagaWorker_Organizations)

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Organization successfully created', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentOrganization.store.hideLoader())
  }
}
