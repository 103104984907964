import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { ActionTypes_Search } from './TActions'
import { TSearchResults, TSearchTerm } from './TReducer'

const { StoreActions, SagaEvents } = ActionTypes_Search

export const Actions_Search = {
  saga: {
    getSearchResult: createAction(SagaEvents.GET_SEARCH_RESULT),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveSearchTerm: createAction<TSearchTerm>(StoreActions.SAVE_SEARCH_TERM),
    clearSearchTerm: createAction(StoreActions.CLEAR_SEARCH_TERM),
    saveSearchResult: createAction<TStoreTemplate<TSearchResults>>(StoreActions.SAVE_SEARCH_RESULT),
    clearSearchResult: createAction(StoreActions.CLEAR_SEARCH_RESULT),
  },
}
