import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import DateConverter from 'utils/helpers/date'
import { Form } from 'utils/helpers/form'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { Actions_Organizations } from 'redux/modules/organizations/Actions'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'

interface IForm {
  amount?: number
  bonusMinutes?: number
  organizationIds: number[] | []
  trailerTypeIds: number[]
  activeUntil?: Date | null
}

const formValidation = {
  amount: Form.combine(Form.required, Form.min(1)),
  bonusMinutes: Form.combine(Form.required, Form.min(1)),
  activeUntil: Form.combine(Form.required, Form.isSameOrAfter()),
}

export const usePromoCodeGenerate = () => {
  const { t } = useTranslation(I18nModal.promoCodeCreate)
  const dispatch = useDispatch()

  const isLoading = useSelector(({ promoCode }: TState) => promoCode.isLoading)
  const inputFile = useRef<any>()
  const [file, changeFile] = useState<File>()
  const [fileBase64, changeFileBase64] = useState('')
  const organizations = useSelector(({ organizations }: TState) => organizations.data?.organizations || [])
  const trailerTypes = useSelector(({ trailerTypes }: TState) => trailerTypes.data || [])

  const { handleSubmit, register, errors, control, watch } = useForm<IForm>({
    defaultValues: { organizationIds: [], trailerTypeIds: [] },
  })

  useEffect(() => {
    dispatch(Actions_Organizations.saga.getOrganizations())
    dispatch(Actions_Trailers.saga.updateTrailers())
  }, [dispatch])

  const onSubmit = (formData: IForm) => {
    const data = {
      amount: formData.amount && +formData?.amount,
      organizationIds: formData.organizationIds,
      trailerTypeIds: formData.trailerTypeIds ? formData.trailerTypeIds : [],
      bonusMinutes: formData.bonusMinutes ? +formData.bonusMinutes : 0,
      activeUntil: DateConverter.toUtcEndOfDay(formData.activeUntil),
    }
    dispatch(Actions_PromoCode.saga.generatePromoCode(data))
    closeModal()
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const trailerTypeOptions = useMemo(() => toTrailerTypeDropdownOptions(trailerTypes), [trailerTypes])
  const selectedTrailerTypesIds = watch('trailerTypeIds') || []
  const isSelectedTrailerTypes = (id: number) => (selectedTrailerTypesIds as number[]).includes(id)

  return {
    t,
    closeModal,
    isLoading,
    trailerTypeOptions,
    isSelectedTrailerTypes,
    inputFile,
    form: {
      formValidation,
      errors,
      register,
      control,
      watch,
      handleSubmit: handleSubmit(onSubmit),
    },
  }
}

const toTrailerTypeDropdownOptions = (trailerTypes: TTrailerTypes): TDropdownOption[] =>
  trailerTypes.map((trailerType) => ({
    id: trailerType.type.id,
    label: trailerType.type.name,
    value: trailerType.type.id,
  }))
