import React, { FC, Suspense } from 'react'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import { useModalPortal } from './ModalPortal-ViewModel'
import { Modals } from 'ui/modals'
import { Shared } from 'ui/shared'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'

export const ModalPortal: FC = () => {
  const vm = useModalPortal()
  const { closeModal, isOpen, modalToShow } = vm
  const EmptyView: FC = () => <div />
  const DynamicModalContent = modalToShow ? Modals[modalToShow] : EmptyView

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    borderRadius: '10px',
    maxHeight: 'calc(100vh - 48px)',
    display: 'flex',
  }

  return (
    <Modal
      className="modal-portal"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <Suspense fallback={<Shared.Preloader color={'secondary'} />}>
            <DynamicModalContent />
          </Suspense>
        </Box>
      </Fade>
    </Modal>
  )
}
