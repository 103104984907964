import { createReducer } from '@reduxjs/toolkit'
import { IStateNotifications } from './TReducer'
import { Actions_Notifications } from './Actions'

const initialState: IStateNotifications = {
  notifications: [],
}

const ReducerNotifications = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Notifications.store.setNotification, (state, action) => {
      const newNotification = action.payload

      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...newNotification,
            key: new Date().getTime() + Math.random(),
            dismissed: false,
          },
        ],
      }
    })
    .addCase(Actions_Notifications.store.closeNotification, (state, action) => {
      const notificationKey = action.payload

      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.key === notificationKey ? { ...notification, dismissed: true } : { ...notification },
        ),
      }
    })
    .addCase(Actions_Notifications.store.removeNotification, (state, action) => {
      const notificationKey = action.payload

      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.key !== notificationKey),
      }
    })
})

export default ReducerNotifications
