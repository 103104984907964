import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_CurrentRole } from 'redux/modules/currentRole/Actions'
import { TCurrentRole } from 'redux/modules/currentRole/TReducer'
import type { PayloadAction } from '@reduxjs/toolkit'

const { get } = RequestMethods()

export type ISagaWorker_GetCurrentRole = {
  id: number
}

export default function* SagaWorker_GetCurrentRole(action: PayloadAction<ISagaWorker_GetCurrentRole>) {
  yield put(Actions_CurrentRole.store.showLoader())

  try {
    const currentRole: TStoreTemplate<TCurrentRole> = yield call(
      async () => await get<TCurrentRole>(`/users/roles/${action.payload.id}`),
    )
    yield put(Actions_CurrentRole.store.saveCurrentRole(currentRole))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentRole.store.hideLoader())
  }
}
