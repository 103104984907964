import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { ActionTypes_MeUser } from './TAction'
import { TUser } from 'redux/modules/users/TReducer'

const { StoreActions } = ActionTypes_MeUser

export const Actions_MeUser = {
  saga: {},

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveMeUser: createAction<TStoreTemplate<TUser>>(StoreActions.SAVE_ME_USER),
    clearMeUser: createAction(StoreActions.CLEAR_ME_USER),
  },
}
