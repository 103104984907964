import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_AccessGroups } from 'redux/modules/accessGroups/Actions'
import { TAccessGroupList } from 'redux/modules/accessGroups/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { get } = RequestMethods()

export default function* SagaWorker_AccessGroups() {
  yield put(Actions_AccessGroups.store.showLoader())

  try {
    const response: TStoreTemplate<TAccessGroupList> = yield call(
      async () => await get<TAccessGroupList>(`/users/access/groups`),
    )
    yield put(Actions_AccessGroups.store.saveAccessGroups(response))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_AccessGroups.store.hideLoader())
  }
}
