import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import { Shared } from 'ui/shared'
import { useStationStatusFilter } from './StationStatusFilter-ViewModel'
import { Checkbox, List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const StationStatusFilter: FC = () => {
  const { data, onSubmit, onCancel, handleToogleAll, isSelectAll, checkedIds, handleToggle } = useStationStatusFilter()
  const { classes } = useStyles()
  return (
    <Shared.Card style={{ width: 375 }}>
      <Shared.CardTitle title={'Station status'} />
      <Grid item xs={12}>
        <Shared.Divider />
      </Grid>
      <Grid item xs={12}>
        {data.length && (
          <ListItem role={undefined} dense button onClick={handleToogleAll}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={isSelectAll}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': 'selectAll' }}
                color={'primary'}
              />
            </ListItemIcon>
            <ListItemText
              id={'selectAll'}
              primary={'Select all'}
              classes={{ root: classes.listItemText }}
              disableTypography
            />
          </ListItem>
        )}
        <List className={classes.list}>
          {data.map((status) => {
            const labelId = `checkbox-list-label-${status.id}`
            return (
              <ListItem key={status.id} role={undefined} dense button onClick={handleToggle(status.id)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checkedIds.indexOf(status.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    color={'primary'}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={status.name || '-'}
                  classes={{ root: classes.listItemText }}
                  disableTypography
                />
              </ListItem>
            )
          })}
        </List>
      </Grid>
      <Grid item xs={6} className={classes.buttons}>
        <Shared.Button className={classes.button} title={'Cancel'} onClick={onCancel} color={'secondary'} />
        <Shared.Button
          className={classes.button}
          width={'100%'}
          title={'Submit'}
          onClick={onSubmit}
          color={'primary'}
        />
      </Grid>
    </Shared.Card>
  )
}

const useStyles = makeStyles()((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 8,
  },
  button: {
    margin: 4,
  },
  card: {
    width: 375,
    overflowY: 'auto',
    maxHeight: '100%',
  },
  list: {
    overflow: 'auto',
    maxHeight: 300,
  },
  listItemText: {
    // ...theme.typography.body1, TODO:
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.typography.body1.color,
  },
  cancel: {
    margin: '0.875em 0',
  },
}))
