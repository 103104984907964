import { NotificationIcons } from 'assets/images/NotificationIcons'
import { TNotificationType } from 'redux/modules/notification/TReducer'

type TSVGIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined
  }
>

type TNotificationIcons = Record<TNotificationType, TSVGIcon | null>

export const notificationIcons: TNotificationIcons = {
  Error: NotificationIcons.Error,
  Success: NotificationIcons.Success,
  UNLOCK_SUCCESS: NotificationIcons.Success,
  LOCK_SUCCESS: NotificationIcons.Success,
  LOCK_FAIL: NotificationIcons.Error,
  UNLOCK_FAIL: NotificationIcons.Error,
  BOOKING_START: NotificationIcons.Time,
  BOOKING_START_CANCEL: NotificationIcons.Time,
  RENT_START: NotificationIcons.Unlock,
  RENT_START_COMPLETED: NotificationIcons.Unlock,
  RENT_START_ERROR: NotificationIcons.Error,
  BOOKING_FINISH: NotificationIcons.Time,
  BOOKING_FINISH_CANCEL: NotificationIcons.Time,
  RENT_FINISH: NotificationIcons.Lock,
  RENT_FINISH_COMPLETED: NotificationIcons.Lock,
  RENT_FINISH_ERROR: NotificationIcons.Error,
  STATION_OFFLINE: NotificationIcons.StationError,
  STATION_OFFLINE_LONG: NotificationIcons.StationError,
  WHEELS: NotificationIcons.TrailerError,
  CABLE: NotificationIcons.TrailerError,
  ROPE: NotificationIcons.TrailerError,
  SUPPORT_WHEEL: NotificationIcons.TrailerError,
  LIGHTS: NotificationIcons.TrailerError,
  AXIS: NotificationIcons.TrailerError,
  OTHER: NotificationIcons.TrailerError,
  Info: null,
  RENT_DURATION_WEEK_TODAY: NotificationIcons.Time,
  RENT_DURATION_WEEK_TOMORROW: NotificationIcons.Time,
}
