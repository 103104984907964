import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { Form } from 'utils/helpers/form'
import { ICurrentParkingStation } from 'redux/modules/currentParking/TReducer'
import { IStationStatus, TStationStatusName } from 'redux/modules/stationStatuses/TReducer'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { Actions_CurrentStation } from 'redux/modules/currentStation/Actions'
import { Actions_Stations } from 'redux/modules/stations/Actions'

const formValidation = {
  stationCode: Form.combine(Form.required),
  serialNumber: Form.combine(Form.required, Form.minLength(1), Form.maxLength(20)),
  imei: Form.combine(Form.required),
  idHigh: Form.combine(Form.required),
  idMiddle: Form.combine(Form.required),
  idLow: Form.combine(Form.required),
}

interface IForm {
  stationCode: string
  serialNumber: string
  imei: string
  idHigh: string
  idMiddle: string
  idLow: string
  statusId: number | string
  trailerId: number | string
}

type TModalProps = {
  station: ICurrentParkingStation
}

const stationStatusesIds: Record<TStationStatusName, number> = {
  booked: 1,
  free: 2,
  trailer: 3,
  inspection: 4,
  deactivated: 5,
}

export const useStationUpdate = () => {
  const { t } = useTranslation(I18nModal.stationUpdate)
  const dispatch = useDispatch()

  const { isLoading, stationStatuses, trailers, modalProps } = useSelector(
    ({ currentStation, stationStatuses, trailers, modals }: TState) => ({
      isLoading: currentStation.isLoading || stationStatuses.isLoading || trailers.isLoading,
      stationStatuses: stationStatuses.data?.statuses || [],
      trailers: trailers.data || [],
      modalProps: modals.data as TModalProps,
    }),
    shallowEqual,
  )

  const stationId = modalProps.station.id

  const statusOptions: TDropdownOption[] = toStatusDropdownOption(stationStatuses)
  const hiddenStatusOptionsIds: number[] = [stationStatusesIds.booked]
  const trailerOptions: TDropdownOption[] = toTrailerDropdownOption(trailers)
  const hiddenTrailerOptionsIds: number[] = tohiddenTrailerOptionsIds(trailers)

  const { handleSubmit, register, errors, control, watch } = useForm<IForm>({
    defaultValues: {
      stationCode: modalProps.station.stationCode || '',
      serialNumber: modalProps.station.serialNumber || '',
      imei: modalProps.station.imei || '',
      idHigh: modalProps.station.idHigh || '',
      idMiddle: modalProps.station.idMiddle || '',
      idLow: modalProps.station.idLow || '',
      statusId: modalProps.station.statusId || '',
      trailerId: modalProps.station.trailerId || '',
    },
  })

  const onSubmit = (data: IForm) => {
    const dataForServer = {
      ...data,
      statusId: typeof data.statusId === 'number' ? data.statusId : null,
      trailerId: typeof data.trailerId === 'number' ? data.trailerId : null,
    }
    dispatch(Actions_CurrentParking.saga.updateCurrentParkingStation({ id: stationId, data: dataForServer }))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const onLockClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    dispatch(Actions_CurrentStation.saga.lockStation({ id: stationId }))
  }

  const onUnlockCLick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    dispatch(Actions_CurrentStation.saga.unlockStation({ id: stationId }))
  }

  const onQRCodeCLick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    dispatch(Actions_CurrentStation.saga.getQRCode({ id: stationId }))
  }

  const statusIdField = watch('statusId')
  const isShowTrailerField = statusIdField === stationStatusesIds.trailer

  return {
    t,
    closeModal,
    onLockClick,
    onUnlockCLick,
    onQRCodeCLick,
    isLoading,
    form: {
      formValidation,
      statusOptions,
      hiddenStatusOptionsIds,
      trailerOptions,
      hiddenTrailerOptionsIds,
      isShowTrailerField,
      errors,
      register,
      handleSubmit: handleSubmit(onSubmit),
      control,
    },
  }
}

const toStatusDropdownOption = (stationStatuses: IStationStatus[]) =>
  stationStatuses.map((stationStatus) => ({
    id: stationStatus.id,
    label: stationStatus.name,
    value: stationStatus.id,
  }))

const toTrailerDropdownOption = (trailers: TTrailers) =>
  trailers.map(({ trailer }) => ({
    id: trailer.id,
    label: trailer.plate,
    value: trailer.id,
  }))

const tohiddenTrailerOptionsIds = (trailers: TTrailers) => {
  const filteredTrailers = trailers.filter(({ trailer }) => trailer.statusId === stationStatusesIds.free)
  return filteredTrailers.map(({ trailer }) => trailer.id)
}
