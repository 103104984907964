import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Notifications } from './TActions'
import { TNotificationKey, TNotificationPayload } from './TReducer'

const { StoreActions } = ActionTypes_Notifications

export const Actions_Notifications = {
  store: {
    setNotification: createAction<TNotificationPayload>(StoreActions.SET_NOTIFICATION),
    closeNotification: createAction<TNotificationKey>(StoreActions.CLOSE_NOTIFICATION),
    removeNotification: createAction<TNotificationKey>(StoreActions.REMOVE_NOTIFICATION),
  },
}
