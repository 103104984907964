import React, { FC } from 'react'
import { Controls } from 'assets/images/Controls'
import { makeStyles } from 'tss-react/mui'
import { IOrder } from 'utils/types'

interface IProps {
  sortDirection?: IOrder
  isSelected: boolean
  onClick?: (dataKey: string) => void
  dataKey: string
}

const SortButton: FC<IProps> = ({ sortDirection, isSelected, onClick, dataKey }) => {
  const { classes } = useStyles({ isSelected })

  const handleClick = () => {
    onClick && onClick(dataKey)
  }

  return sortDirection === 'ASC' ? (
    <Controls.ArrowDownPrimary className={classes.sortIcon} onClick={handleClick} />
  ) : (
    <Controls.ArrowUpPrimary className={classes.sortIcon} onClick={handleClick} />
  )
}

export default SortButton

const useStyles = makeStyles<{ isSelected: boolean }>()((theme, { isSelected }) => ({
  sortIcon: {
    cursor: 'pointer',
    fill: isSelected ? theme.palette.background.default : theme.palette.primary.main,
  },
}))
