enum StoreActions {
  IS_LOADER_SHOW = 'CURRENT_TRAILER_BREAKDOWNS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CURRENT_TRAILER_BREAKDOWNS/IS_LOADER_HIDE',
  SAVE_CURRENT_TRAILER_BREAKDOWNS = 'CURRENT_TRAILER_BREAKDOWNS/SAVE_CURRENT_TRAILER_BREAKDOWNS',
  CLEAR_CURRENT_TRAILER_BREAKDOWNS = 'CURRENT_TRAILER_BREAKDOWNS/CLEAR_CURRENT_TRAILER_BREAKDOWNS',
  EDIT_CURRENT_TRAILER_BREAKDOWN = 'CURRENT_TRAILER_BREAKDOWNS/EDIT_CURRENT_TRAILER_BREAKDOWN',
}

enum SagaEvents {
  GET_CURRENT_TRAILER_BREAKDOWNS = 'CURRENT_TRAILER_BREAKDOWNS/GET_CURRENT_TRAILER_BREAKDOWNS',
  LOAD_XLSX = 'CURRENT_TRAILER_BREAKDOWNS/LOAD_XLSX',
  UPDATE_CURRENT_TRAILER_BREAKDOWN = 'CURRENT_TRAILER_BREAKDOWNS/UPDATE_CURRENT_TRAILER_BREAKDOWN',
}

export const ActionTypes_CurrentTrailerBreakdowns = {
  SagaEvents,
  StoreActions,
}
