import { call } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AxiosRequestConfig } from 'axios'
import type { PayloadAction } from '@reduxjs/toolkit'

const { download } = RequestMethods()

export interface ISagaWorker_GetOrganizationInvitationCodesXlsx {
  organizationId: number
}

export default function* SagaWorker_GetOrganizationInvitationCodesXlsx(
  action: PayloadAction<ISagaWorker_GetOrganizationInvitationCodesXlsx>,
) {
  const { organizationId } = action.payload

  const formData = {
    hideInactive: false,
  }

  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig

  try {
    yield call(async () =>
      download(`/organizations/${organizationId}/invitationCodes/xlsx`, formData, config, 'invitationCodes.xlsx'),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
