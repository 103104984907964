import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentStation } from 'redux/modules/currentStation/Actions'

const client = RequestMethods()

export interface ISagaWorker_LockStation {
  id: number
}

export default function* SagaWorker_LockStation(action: PayloadAction<ISagaWorker_LockStation>) {
  const { id } = action.payload

  yield put(Actions_CurrentStation.store.showLoader())

  try {
    yield call(async () => await client.put(`/stations/${id}/close-lock`))

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Station lock command successfully sent',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentStation.store.hideLoader())
  }
}
