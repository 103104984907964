import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Modals } from './TActions'
import { ModalPayload } from './TReducer'

const { StoreActions } = ActionTypes_Modals

export const Actions_Modals = {
  saga: {},

  store: {
    hideModal: createAction(StoreActions.HIDE_MODAL),
    showModal: createAction<ModalPayload>(StoreActions.SHOW_MODAL),
  },
}
