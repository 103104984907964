import { createReducer } from '@reduxjs/toolkit'
import { IStateReport } from './TReducer'
import TableFilterReduxAdapter from 'utils/helpers/table'
import DateConverter from 'utils/helpers/date'
import { Actions_report } from './Actions'

const initialState: IStateReport = {
  isLoading: false,
  isAllDataLoaded: false,
  data: null,
  detailedReport: { rents: [], totalCost: 0 },
  responseInfo: null,
  filter: {
    ...TableFilterReduxAdapter.DEFAULT_FILTERS,
    sorting: 'vin',
    fromDate: DateConverter.toStartOfDay(new Date()),
    toDate: DateConverter.addDays(DateConverter.toEndOfDay(new Date()), 1),
  },
  isAllInvoicesDataLoaded: false,
  invoicesFilter: {
    ...TableFilterReduxAdapter.DEFAULT_FILTERS,
    sorting: 'invoiceNumber',
    invoiceNumber: '',
    startParkings: [],
    finishParkings: [],
  },
  isFilterTypeTurnOver: false,
  isDetailed: false,
}

const ReducerReport = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_report.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_report.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_report.store.saveReport, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_report.store.saveDetailedReportRents, (state, action) => {
      state.detailedReport.rents = action.payload.rents
      state.isAllInvoicesDataLoaded = action.payload.isAllInvoicesDataLoaded
    })
    .addCase(Actions_report.store.saveDetailedReportRentsTotalCost, (state, action) => {
      state.detailedReport.totalCost = action.payload
    })
    .addCase(Actions_report.store.changeFilter, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_report.store.increaseFilterOffset, (state, action) => {
      state.filter = action.payload
    })
    .addCase(Actions_report.store.increaseInvoiceFilterOffset, (state, action) => {
      state.invoicesFilter = action.payload
    })
    .addCase(Actions_report.store.setInvoiceFilter, (state, action) => {
      state.invoicesFilter = action.payload
      state.isAllInvoicesDataLoaded = false
    })
    .addCase(Actions_report.store.setInvoiceNumberInInvoiceFilter, (state, action) => {
      state.invoicesFilter.invoiceNumber = action.payload
      state.invoicesFilter.offset = 0
    })

    .addCase(Actions_report.store.resetInvoiceFilter, (state, action) => {
      state.invoicesFilter = initialState.invoicesFilter
      state.isAllInvoicesDataLoaded = false
    })
    .addCase(Actions_report.store.choosenTurnOver, (state) => {
      state.isFilterTypeTurnOver = true
    })
    .addCase(Actions_report.store.changedTurnOver, (state) => {
      state.isFilterTypeTurnOver = false
    })
    .addCase(Actions_report.store.openDetailedTable, (state) => {
      state.isDetailed = true
    })
    .addCase(Actions_report.store.closeDetailedTable, (state) => {
      state.isDetailed = false
    })
    .addCase(Actions_report.store.reset, () => {
      return initialState
    })
})

export default ReducerReport
