import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_PromoCode } from 'redux/modules/promoCode/Actions'
import { ActionTypes_PromoCode } from 'redux/modules/promoCode/TActions'
import { I18nModal } from 'utils/i18n/i18nModal'

export const useDateFilter = () => {
  const dispatch = useDispatch()
  const { dateFilter } = useSelector(
    ({ promoCode }: TState) => ({
      dateFilter: promoCode.dateFilter,
    }),
    shallowEqual,
  )

  const [value, setValue] = useState<Date | null>(dateFilter ? dateFilter : null)
  const isFilterOn = dateFilter ? true : false

  const onSubmit = () => {
    dispatch(Actions_PromoCode.store.setDateFilter(value))
    dispatch(Actions_Modals.store.hideModal())
  }

  const onChange = (date: Date | null) => {
    setValue(date)
  }

  const onCancel = () => dispatch(Actions_Modals.store.hideModal())

  const onClearFilter = () => {
    dispatch(Actions_PromoCode.store.setDateFilter(null))
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    value,
    onSubmit,
    onCancel,
    onChange,
    isFilterOn,
    onClearFilter,
  }
}
