import { createReducer } from '@reduxjs/toolkit'
import { IStateRedirect } from './TReducer'
import { Actions_Redirect } from './Actions'

const initialState: IStateRedirect = {
  data: '/',
}

const ReducerRedirect = createReducer(initialState, (builder) => {
  builder.addCase(Actions_Redirect.store.redirectTo, (state, action) => {
    state.data = action.payload
  })
})

export default ReducerRedirect
