import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TNote } from 'redux/modules/parkingNotes/TReducer'
import { theme } from 'utils/conf/theme'
import DateConverter from 'utils/helpers/date'
import { I18nShared } from 'utils/i18n/i18nShared'

type TProps = {
  note: TNote
  isShowDate?: boolean
}

const lineBreaksRegEx = /\r\n|\r|\n/

const Note: FC<TProps> = ({ note, isShowDate }) => {
  const { t } = useTranslation(I18nShared.notes)

  const formatedDate = note.createdAt ? DateConverter.getDateStringWithoutTime(note.createdAt) : t('datePlaceholder')

  const formatedTime = note.createdAt ? DateConverter.getTimeString(note.createdAt) : t('timePlaceholder')

  const dateLabel = DateConverter.isToday(note.createdAt) ? t('today') : formatedDate

  const title = [note?.authorFirstName, note?.authorLastName].join(' ')

  const textLines = note.text?.split(lineBreaksRegEx) || []

  return (
    <Box sx={containerStyle}>
      {isShowDate && (
        <Box sx={dateContainerStyle}>
          <Typography>{dateLabel}</Typography>
        </Box>
      )}

      <Box sx={{ display: 'flex' }}>
        <Box sx={noteContainerStyle}>
          <Box>
            <Typography variant={'body1'} component={'p'} sx={titleStyle}>
              <strong>{title}</strong>
            </Typography>
          </Box>

          {textLines.map((text, index) => (
            <Box key={index}>
              <Typography variant={'body1'} sx={textStyle}>
                {text}
              </Typography>
            </Box>
          ))}

          <Box sx={timeContainerStyle}>
            <Typography variant={'body2'} color={theme.palette.text.secondary}>
              {formatedTime}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Note

const containerStyle = {
  mx: '1.5rem',
  paddingBottom: '10px',

  '&:last-of-type': {
    paddingBottom: '1.5rem',
  },
}

const noteContainerStyle = {
  border: '1px solid #ABABAB',
  backgroundColor: '#F0F0F0',
  borderRadius: '10px',
  display: 'inline-block',
  wordBreak: 'break-word',
  marginLeft: 0,
  padding: '12px 12px 4px',
  width: '100%',
}

const titleStyle = {
  display: 'flex',
}

const textStyle = {
  color: '#3C3B3B',
}

const timeContainerStyle = {
  textAlign: 'end',
}

const dateContainerStyle = {
  marginBottom: 1,
  textAlign: 'center',
}
