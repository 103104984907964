import { ChangeEvent, ChangeEventHandler, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { Actions_Stations } from 'redux/modules/stations/Actions'
import { useForm } from 'react-hook-form'

export const useOrganizationNameFilter = () => {
  const dispatch = useDispatch()
  const { parkings } = useSelector(
    ({ parkings }: TState) => ({
      parkings: parkings,
    }),
    shallowEqual,
  )

  const isFilterOn = parkings.filter.organizationName ? true : false
  const { handleSubmit, register, errors, control } = useForm<{ value: string }>({
    defaultValues: {
      value: parkings.filter.organizationName ? parkings.filter.organizationName : '',
    },
  })

  const onSubmit = useCallback(
    (data: { value: string }) => {
      if (data.value.length >= 2) {
        dispatch(
          Actions_Parkings.store.changeFilter({
            ...parkings.filter,
            organizationName: data.value,
          }),
        )
        dispatch(Actions_Modals.store.hideModal())
      }
    },
    [dispatch, parkings],
  )

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  const onClearFilter = () => {
    dispatch(
      Actions_Parkings.store.changeFilter({
        ...parkings.filter,
        organizationName: null,
      }),
    )
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    onCancel,
    isFilterOn,
    onClearFilter,
    form: { register, handleSubmit: handleSubmit(onSubmit) },
  }
}
