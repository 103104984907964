import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { ITrailerType, ITrailerTypeLocaleInfo, TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TTrailerTypeSubtype } from 'redux/modules/trailerTypeSubtype/TReducer'
import { Actions_TrailerTypeSubtype } from 'redux/modules/trailerTypeSubtype/Actions'
import { TStoreTemplate } from 'redux/config/root-types'
import { getMedia, IMedia } from 'redux/sagas/actions/mediaAction'

const { post, get, put: clientPut } = RequestMethods()

export type ICreateSubtype = {
  weight: number
  maxLoad: number
  height: number
  width: number
  length: number
  countItems: boolean
  items: number
  orderIndex: number
}

export type ICreateType = {
  id?: number
  name: string
  image: string
  minutePrice: number
  dayPrice: number
  weekPrice: number
  monthPrice: number
  model: string
}

export type ISagaWorker_CreateTrailerType = {
  type: ICreateType
  typeFile?: File
  subtypes: ICreateSubtype[]
  locales: ITrailerTypeLocaleInfo[]
  cb: (id: number) => void
}

export default function* SagaWorker_CreateTrailerType(action: PayloadAction<ISagaWorker_CreateTrailerType>) {
  const {
    type: { id, ...typeData },
    subtypes = [],
    locales = [],
    cb,
    typeFile,
  } = action.payload

  try {
    yield put(Actions_TrailerTypes.store.showLoader())

    if (typeFile) {
      const imageData: IMedia = yield call(() => getMedia(typeFile))
      typeData.image = imageData.name || ''
    }

    const trailerTypeResult: TStoreTemplate<ITrailerType> = yield call(
      async () => await post<ITrailerType>(`/trailer_types`, typeData),
    )

    const newTrailerType = trailerTypeResult.data
    const typeId = newTrailerType?.type.id as number

    const subtypePromises = subtypes.map(async (subType) =>
      post<TTrailerTypeSubtype>(`/trailer_types/subtypes`, { ...subType, typeId }),
    )
    const subtypeResult: TStoreTemplate<TTrailerTypeSubtype> = yield call(() => Promise.all(subtypePromises))
    yield put(Actions_TrailerTypeSubtype.store.saveTrailerTypeSubtype(subtypeResult))

    const localePromises = locales.map(async (localeItem) =>
      clientPut<ITrailerType>(`/trailer_types/${typeId}/locale`, localeItem),
    )
    yield call(() => Promise.all(localePromises))

    const result: TStoreTemplate<TTrailerTypes> = yield call(async () => await get<TTrailerTypes>('/trailer_types'))

    yield put(Actions_TrailerTypes.store.saveTrailerTypes(result))
    cb(typeId)

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Trailer type successfully created', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_TrailerTypes.store.hideLoader())
}
