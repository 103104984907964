import React, { FC } from 'react'
import { useViewTrailersTableColumns } from 'ui/modals/ViewTrailersTableColumns/ViewTrailersTableColumns-ViewModel'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'

export const ViewTrailersTableColumns: FC = () => {
  const vm = useViewTrailersTableColumns()
  const { closeModal, onSubmit, data, checked, isSelectAll, handleToggle, handleToogleAll, t, classes } = vm

  return (
    <Shared.Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Shared.CardTitle title={t('title')} />
        </Grid>

        <Grid item xs={12}>
          {data.length && (
            <ListItem role={undefined} dense button onClick={handleToogleAll}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={isSelectAll}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'selectAll' }}
                  color={'primary'}
                />
              </ListItemIcon>
              <ListItemText
                id={'selectAll'}
                primary={t('selectAll')}
                classes={{ root: classes.listItemText }}
                disableTypography
              />
            </ListItem>
          )}

          <List className={classes.list}>
            {data.map((column) => {
              const labelId = `checkbox-list-label-${column}`
              return (
                <ListItem key={column.dataKey} role={undefined} dense button onClick={handleToggle(column.dataKey)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(column.dataKey) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      color={'primary'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={column.label || '-'}
                    classes={{ root: classes.listItemText }}
                    disableTypography
                  />
                </ListItem>
              )
            })}
          </List>
        </Grid>

        <Grid item xs={12}>
          <Shared.Button className={classes.cancel} color={'secondary'} title={t('cancel')} onClick={closeModal} />
          <Shared.Button title={t('submit')} onClick={onSubmit} />
        </Grid>
      </Grid>
    </Shared.Card>
  )
}
