import React, { FC } from 'react'

const chargeAmountByType = {
  empty: 0,
  low: 1,
  medium: 2,
  full: 3,
}

const chargeIconPositionY: { [key: number]: string } = {
  0: '30',
  1: '19',
  2: '8',
}

interface IProps {
  charge: number
}

export const ButteryIcon: FC<IProps> = ({ charge }) => {
  let type: 'full' | 'medium' | 'low' | 'empty' = 'empty'
  if (charge > 0 && charge <= 33) {
    type = 'low'
  } else if (charge > 33 && charge <= 66) {
    type = 'medium'
  } else if (charge > 66) {
    type = 'full'
  }
  const generatedSequence = Array.from(Array(chargeAmountByType[type]).keys())
  return (
    <svg width="22" height={'100%'} viewBox="0 0 22 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.44444 5.44444V1H16.5556V5.44444H21V42H1V5.44444H5.44444Z"
        fill="white"
        stroke="#F59D0E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {generatedSequence.map((index) => (
        <rect key={index} y={chargeIconPositionY[index]} x="4" width="14" height="9" fill="#F59D0E" />
      ))}
    </svg>
  )
}
