import React, { FC } from 'react'
import { Controls } from 'assets/images/Controls'
import { makeStyles } from 'tss-react/mui'

interface IProps {
  isSelected?: boolean
}

const FilterButton: FC<IProps> = ({ isSelected }) => {
  const { classes } = useStyles({ isSelected })

  return <Controls.Filter className={classes.filterIcon} />
}

export default FilterButton

const useStyles = makeStyles<{ isSelected?: boolean }>()((theme, { isSelected }) => ({
  filterIcon: {
    cursor: 'pointer',
    fill: isSelected ? theme.palette.background.default : theme.palette.primary.main,
  },
}))
