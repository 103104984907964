enum StoreActions {
  IS_LOADER_SHOW = 'TRAILER_TYPE_SUBTYPE/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'TRAILER_TYPE_SUBTYPE/IS_LOADER_HIDE',
  SAVE_TRAILER_TYPE_SUBTYPE = 'TRAILER_TYPE_SUBTYPE/SAVE_TRAILER_TYPE_SUBTYPE',
}

enum SagaEvents {}

export const ActionTypes_TrailerTypeSubtype = {
  SagaEvents,
  StoreActions,
}
