import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentStaffMember } from 'redux/modules/currentStaffMember/Actions'
import { TUser } from 'redux/modules/users/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const client = RequestMethods()

export interface ISagaWorker_DeactivateStaff {
  id: number
}

export default function* SagaWorker_DeactivateStaff(action: PayloadAction<ISagaWorker_DeactivateStaff>) {
  const { id } = action.payload

  try {
    yield put(Actions_CurrentStaffMember.store.showLoader())

    const user: TStoreTemplate<TUser> = yield call(async () => await client.put<TUser>(`/users/${id}/deactivate`))
    yield put(Actions_CurrentStaffMember.store.saveCurrentStaffMember(user))

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Stuff member successfully deactivated',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentStaffMember.store.hideLoader())
  }
}
