import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import { TUser } from 'redux/modules/users/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const client = RequestMethods()

export interface ISagaWorker_VerifyUser {
  id: number
}

export default function* SagaWorker_VerifyUser(action: PayloadAction<ISagaWorker_VerifyUser>) {
  const { id } = action.payload
  const data = {
    verificationDecision: 'approved',
    verificationReasonCode: null,
  }

  yield put(Actions_CurrentUser.store.showLoader())

  try {
    const user: TStoreTemplate<TUser> = yield call(async () => await client.put<TUser>(`/users/${id}`, data))
    yield put(Actions_CurrentUser.store.saveCurrentUser(user))

    yield put(Actions_Notifications.store.setNotification({ message: 'User verify successfully', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentUser.store.hideLoader())
  }
}
