import React, { FC } from 'react'
import Typography from '@mui/material/Typography'

export type TCellKey = string

export type TableCellProps = {
  cellData: number
}

export const TableBatteryChargeCell: FC<TableCellProps> = ({ cellData }) => {
  const value = cellData === null || cellData === undefined ? 0 : cellData
  const isBatteryChargeOkey = typeof value === 'number' && value >= 11000

  return (
    <Typography color={isBatteryChargeOkey ? 'green' : 'red'} noWrap>
      {value}
    </Typography>
  )
}
