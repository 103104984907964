import { createReducer } from '@reduxjs/toolkit'
import { IStateCurrentPosition } from './TReducer'
import { Actions_CurrentPosition } from './Actions'

const initialState: IStateCurrentPosition = {
  isLoading: false,
  selectedPositionInfo: null,
}

const ReducerCurrentParking = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_CurrentPosition.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_CurrentPosition.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_CurrentPosition.store.changeSelectedPositionInfo, (state, action) => {
      state.selectedPositionInfo = action.payload
    })
    .addCase(Actions_CurrentPosition.store.clearSelectedPositionInfo, (state) => {
      state.selectedPositionInfo = null
    })
})

export default ReducerCurrentParking
