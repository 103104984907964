import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { Actions_report } from 'redux/modules/report/Actions'
import DateConverter from 'utils/helpers/date'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { IDetailedReport, TDetailedReport, TReportFilter } from 'redux/modules/report/TReducer'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import TableFilterReduxAdapter from 'utils/helpers/table'

const { get } = RequestMethods()

export default function* SagaWorker_GetDetailedReport({ payload }: PayloadAction<TReportFilter>) {
  yield put(Actions_report.store.showLoader())

  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds
  const getFilter = (state: TState) => state.report.filter
  const getInvoicesFilter = (state: TState) => state.report.invoicesFilter
  const getInvoicesData = (state: TState) => state.report.detailedReport?.rents || []

  //------------------------- Getting request params ---------------------------//
  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)
  const invoicesFilter: ReturnType<typeof getInvoicesFilter> = yield select(getInvoicesFilter)
  const oldInvoicesData: ReturnType<typeof getInvoicesData> = yield select(getInvoicesData)

  const formData = {
    organization,
    limit: invoicesFilter.limit,
    offset: invoicesFilter.offset,
    orderBy: invoicesFilter.orderBy,
    sorting: invoicesFilter.sorting,
    invoiceNumber: invoicesFilter.invoiceNumber.length > 0 ? invoicesFilter.invoiceNumber : undefined,
    fromDate: filter.fromDate ? DateConverter.toISOString(DateConverter.toStartOfDay(filter.fromDate)) : undefined,
    toDate: filter.toDate ? DateConverter.toISOString(DateConverter.toEndOfDay(filter.toDate)) : undefined,
    startParking: invoicesFilter.startParkings,
    finishParking: invoicesFilter.finishParkings,
  }

  const isNeedToConcatData = !!payload

  try {
    //------------------------- Getting report ---------------------------//
    const result: TStoreTemplate<TDetailedReport> = yield call(
      async () => await get<TStoreTemplate<TDetailedReport>>('/trailers/report/details', formData),
    )

    const newReportData = result.data?.rents || []

    const data = isNeedToConcatData
      ? {
          ...(result.data as TDetailedReport),
          rents: TableFilterReduxAdapter.concatData<IDetailedReport>(newReportData, oldInvoicesData),
        }
      : result.data
    const isAllInvoicesDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newReportData, invoicesFilter)

    yield put(
      Actions_report.store.saveDetailedReportRents({
        rents: data!.rents,
        isAllInvoicesDataLoaded,
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_report.store.hideLoader())
}
