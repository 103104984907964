import { useCallback, useEffect, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_notificationHistory } from 'redux/modules/notificationHistory/Actions'
import { useNavigate } from 'react-router-dom'
import { To } from 'router/To'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'

const drawerWidth = 477

export const useParkingsProblemDrawer = () => {
  const isShowParkingsDrawer = useSelector(
    ({ notificationHistory }: TState) => notificationHistory.isShowParkingsDrawer,
  )
  const isLoading = useSelector(({ notificationHistory }: TState) => notificationHistory.isLoading)
  const problems = useSelector(({ notificationHistory }: TState) => notificationHistory.problems)
  const startPage = useSelector(({ parkings }: TState) => parkings.startPage)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onClose = () => {
    dispatch(Actions_notificationHistory.store.hideParkingsDrawer())
  }

  const onClickOpen = useCallback(() => {
    dispatch(Actions_notificationHistory.store.hideParkingsDrawer())
    dispatch(Actions_Parkings.store.changeStartPage(0))
    dispatch(Actions_Parkings.store.changeFilter({ batteryCharge: true, hideFreeStations: null }))
    navigate(To.parkingShowListTable)
  }, [startPage])

  const { classes } = useStyles()

  const data = useMemo(() => problems, [problems])

  return {
    classes,
    data,
    isShowParkingsDrawer,
    isLoading,
    onClose,
    onClickOpen,
  }
}

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1201,
    borderRadius: '10px 0 0 10px',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '24px 24px 12px',
  },
  closeIcon: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  content: {
    overflowY: 'auto',
    paddingLeft: 24,
    justifyContent: 'space-between',
    height: '100%',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    margin: 4,
  },
  buttons: {
    marginTop: 24,
  },
}))
