import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import { renderSelectOptions } from './SelectOptions'
import { TDropdownOption, TParentDropdownOption } from './Select'

export const renderGroupedSelectOptions = (
  parentId: string,
  parentOptions: TParentDropdownOption[],
  childOptions: TDropdownOption[],
  isSelected: (id: number) => boolean,
  multiple?: boolean,
  isItemCapitalize?: boolean,
  hiddenOptionsIds?: number[],
) => {
  const selectOptions = renderSelectOptions(
    parentId,
    childOptions,
    isSelected,
    multiple,
    isItemCapitalize,
    hiddenOptionsIds,
  )

  const getParentName = (parentId: string) => {
    const parentOption = parentOptions?.find((organization) => organization.id?.toString() === parentId)
    return parentOption?.title || 'Independent'
  }

  const isAllChildsSelected = childOptions.every((option) => isSelected(option.id))
  const isPartChildsSelected =
    !isAllChildsSelected &&
    childOptions.reduce((isIncludeSelected, option) => isIncludeSelected || isSelected(option.id), false)

  return [
    <MenuItem disableGutters key={parentId} value={`parentId-${parentId}`} sx={parentMenuStyle}>
      <Checkbox checked={isAllChildsSelected} indeterminate={isPartChildsSelected} color={'primary'} />
      {getParentName(parentId)}
    </MenuItem>,
    selectOptions,
  ]
}

const parentMenuStyle = {
  paddingX: '4px',
  fontWeight: 'bold',
}
