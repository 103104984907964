import { RequestMethods } from 'utils/axios/methods'
import { call, put, select } from 'redux-saga/effects'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import TableFilterReduxAdapter from 'utils/helpers/table'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TPosition, TPositions, TPositionsFilter } from 'redux/modules/positions/TReducer'
import { Actions_Positions } from 'redux/modules/positions/Actions'

const { get } = RequestMethods()

export default function* SagaWorker_GetPositions(action?: PayloadAction<TPositionsFilter>) {
  try {
    yield put(Actions_Positions.store.showLoader())
    const getFilter = (state: TState) => state.positions.filter
    const getPositions = (state: TState) => state.positions.data

    const filter: ReturnType<typeof getFilter> = yield select(getFilter)
    const oldPositions: ReturnType<typeof getPositions> = yield select(getPositions)
    const isNeedToConcatData = !!action?.payload

    const formData: TPositionsFilter = {
      ...filter,
    }
    //------------------------- Getting and saving positions ----------------------//

    const response: TStoreTemplate<TPositions> = yield call(async () => await get<TPositions>('/positions', formData))
    const newPostitions = response.data?.positions || []
    const responseInfo = response.responseInfo

    const data = isNeedToConcatData
      ? TableFilterReduxAdapter.concatData<TPosition>(newPostitions, oldPositions)
      : newPostitions
    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newPostitions, filter)

    yield put(Actions_Positions.store.savePositions({ data, responseInfo, isAllDataLoaded }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Positions.store.hideLoader())
  }
}
