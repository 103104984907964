enum StoreActions {
  IS_LOADER_SHOW = 'ORGANIZATIONS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'ORGANIZATIONS/IS_LOADER_HIDE',
  SAVE_ORGANIZATIONS = 'ORGANIZATIONS/SAVE_ORGANIZATIONS',
  SELECT_ORGANIZATION_IDS = 'ORGANIZATIONS/SELECT_ORGANIZATION_IDS',
  RESET = 'ORGANIZATIONS/RESET',
}

enum SagaEvents {
  GET_ORGANIZATIONS = 'ORGANIZATIONS/GET_ORGANIZATIONS',
  LOAD_PAGE = 'ORGANIZATIONS/LOAD_PAGE',
  LOAD_XLSX = 'ORGANIZATIONS/LOAD_XLSX',
}

export const ActionTypes_Organizations = {
  SagaEvents,
  StoreActions,
}
