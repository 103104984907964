import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { useUserToOrganizationDiscountCreate } from './UserToOrganizationDiscountCreate-ViewModel'

export const UserToOrganizationDiscountCreate: FC = () => {
  const vm = useUserToOrganizationDiscountCreate()
  const { closeModal, form, isLoading, t, options } = vm
  const { errors, handleSubmit, formValidation, control } = form

  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
            <Shared.Dropdown
              name="organization"
              label={t('organization')}
              options={options}
              fullWidth
              control={control}
              rules={formValidation.organization}
              error={errors.organization?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.SingleDatePickerForForm
              title={t('expirationDate')}
              name="activeUntil"
              control={control}
              error={errors.activeUntil?.message}
              rules={formValidation.activeUntil}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
              disabled={isLoading}
            />
            <Shared.Button title={t('submit')} type="submit" disabled={isLoading} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
