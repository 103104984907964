import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { Actions_report } from 'redux/modules/report/Actions'
import DateConverter from 'utils/helpers/date'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { IReportTrailer, TReport, TReportFilter } from 'redux/modules/report/TReducer'
import type { PayloadAction } from '@reduxjs/toolkit'
import TableFilterReduxAdapter from 'utils/helpers/table'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { get } = RequestMethods()

export default function* SagaWorker_GetReport({ payload }: PayloadAction<TReportFilter>) {
  yield put(Actions_report.store.showLoader())
  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds
  const getFilter = (state: TState) => state.report.filter
  const getReportData = (state: TState) => state.report.data?.trailers || []
  //------------------------- Getting request params ---------------------------//
  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
  const filter: ReturnType<typeof getFilter> = yield select(getFilter)
  const oldReportData: ReturnType<typeof getReportData> = yield select(getReportData)

  const isNeedToConcatData = !!payload

  const formData = {
    organization,
    ...filter,
    fromDate: filter.fromDate ? DateConverter.toISOString(DateConverter.toStartOfDay(filter.fromDate)) : undefined,
    toDate: filter.toDate ? DateConverter.toISOString(DateConverter.toEndOfDay(filter.toDate)) : undefined,
  }

  try {
    //------------------------- Getting report ---------------------------//
    const result: TStoreTemplate<TReport> = yield call(
      async () => await get<TStoreTemplate<TReport>>('/trailers/report', formData),
    )

    const newReportData = result.data?.trailers || []

    const data = isNeedToConcatData
      ? {
          ...(result.data as TReport),
          trailers: TableFilterReduxAdapter.concatData<IReportTrailer>(newReportData, oldReportData),
        }
      : result.data

    yield put(
      Actions_report.store.saveReport({
        ...result,
        data,
        isAllDataLoaded: TableFilterReduxAdapter.checkIsAllDataLoaded(newReportData, filter),
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_report.store.hideLoader())
}
