import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'

export const useBatteryChargeFilter = () => {
  const dispatch = useDispatch()
  const { parkings } = useSelector(
    ({ parkings }: TState) => ({
      parkings: parkings,
    }),
    shallowEqual,
  )

  const [value, setValue] = useState<string>(parkings.filter.batteryCharge === true ? 'Low' : 'All')
  const data = ['All', 'Low']
  const isFilterOn = parkings.filter.batteryCharge ? true : false

  const onSubmit = useCallback(
    (value?: string) => {
      dispatch(
        Actions_Parkings.store.changeFilter({
          ...parkings.filter,
          batteryCharge: value === 'All' ? false : true,
        }),
      )
      dispatch(Actions_Modals.store.hideModal())
    },
    [dispatch, value, parkings],
  )

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  const onClearFilter = () => {
    dispatch(Actions_Parkings.store.changeFilter({ ...parkings.filter, batteryCharge: null }))
    dispatch(Actions_Modals.store.hideModal())
  }

  return { data, onSubmit, onCancel, setValue, value, isFilterOn, onClearFilter }
}
