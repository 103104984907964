import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_notificationHistory } from 'redux/modules/notificationHistory/Actions'
import { useAuthRights } from 'ui/hooks/useAuthRights'

const drawerWidth = {
  full: { xl: 270, lg: 240 },
  short: { xl: 72, lg: 66 },
}

export const headerHeight = { xl: 72, lg: 66 }

export const useStaticControls = () => {
  const theme = useTheme()
  const isXl = useMediaQuery(theme.breakpoints.only('xl'))
  const showUsername = useMediaQuery(theme.breakpoints.between('md', 'xl'))
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const { classes, cx } = useStyles({ open })
  const { hasFullAccess } = useAuthRights()

  const meUser = useSelector((state: TState) => state.meUser.data)
  const isShowUnreadMessageIndicator = useSelector((state: TState) => state.notificationHistory.isUnreadMessages)
  const isParkingsHaveProblem = useSelector((state: TState) => state.notificationHistory.isParkingsHaveProblem)
  const isStationsHaveProblem = useSelector((state: TState) => state.notificationHistory.isStationsHaveProblem)
  const isShowNotificationButton = hasFullAccess

  const showConfirmExitModal = () => {
    dispatch(Actions_Modals.store.showModal({ type: 'ExitConfirm' }))
  }

  const xlStyles = isXl
    ? {
        marginLeft: open ? drawerWidth.full.xl : drawerWidth.short.xl,
        width: `calc(100vw - ${open ? drawerWidth.full.xl : drawerWidth.short.xl}px)`,
      }
    : {}

  const handleDrawerOpen = () => {
    setOpen((currentState) => !currentState)
  }

  const handleNotificationsDrawerOpen = () => {
    dispatch(Actions_notificationHistory.store.showDrawer())
  }

  const handleParkingsNotificationsDrawerOpen = () => {
    dispatch(Actions_notificationHistory.store.showParkingsDrawer())
  }

  const handleStationsNotificationsDrawerOpen = () => {
    dispatch(Actions_notificationHistory.store.showStationsDrawer())
  }

  return {
    data: {
      classes,
      cx,
      open,
      email: meUser?.email || '',
      showUsername,
      isShowUnreadMessageIndicator,
      isShowNotificationButton,
      isParkingsHaveProblem,
      isStationsHaveProblem,
    },
    methods: {
      showConfirmExitModal,
      handleDrawerOpen,
      handleNotificationsDrawerOpen,
      handleParkingsNotificationsDrawerOpen,
      handleStationsNotificationsDrawerOpen,
    },
    styles: {
      xlStyles,
    },
  }
}

interface IStyle {
  open: boolean
}

const useStyles = makeStyles<IStyle>()((theme, { open }) => ({
  appBar: {
    backgroundColor: '#4F4E4E',
    paddingLeft: 24,
    position: 'fixed',
    marginLeft: open ? drawerWidth.full.lg : drawerWidth.short.lg,
    width: `calc(100vw - ${open ? drawerWidth.full.lg : drawerWidth.short.lg}px)`,
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer,
    transition: '0.2s',
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth.full.lg,
    overflowX: 'hidden',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.only('xl')]: {
      width: drawerWidth.full.xl,
    },
  },
  drawerOpen: {
    overflowX: 'hidden',
    width: drawerWidth.full.lg,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.only('xl')]: {
      width: drawerWidth.full.xl,
    },
  },
  drawerClose: {
    overflowX: 'hidden',
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerWidth.short.lg,
    [theme.breakpoints.only('xl')]: {
      width: drawerWidth.short.xl,
    },
  },
  leftMenu: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar, TODO:
  },
  toolbar: {
    transition: '0.2s',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: headerHeight.lg,
    [theme.breakpoints.only('xl')]: {
      height: headerHeight.xl,
    },
  },
  content: {
    marginLeft: open ? drawerWidth.full.lg : drawerWidth.short.lg,
    width: `calc(100vw - ${open ? drawerWidth.full.lg : drawerWidth.short.lg}px)`,
    transition: '0.2s',
    overflowX: 'hidden',
    marginTop: headerHeight.lg,
    height: `calc(100vh - ${headerHeight.lg}px)`,
    [theme.breakpoints.only('xl')]: {
      marginTop: headerHeight.xl,
      height: `calc(100vh - ${headerHeight.xl}px)`,
    },
  },
  lockIcon: {
    width: 28,
    height: 28,
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
}))
