import { createReducer } from '@reduxjs/toolkit'
import { IStateParkingNotes } from './TReducer'
import { Actions_ParkingNotes } from './Actions'

const initialState: IStateParkingNotes = {
  isLoading: false,
  data: null,
  responseInfo: null,
}

const ReducerParkingNotes = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_ParkingNotes.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_ParkingNotes.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_ParkingNotes.store.saveParkingNotes, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_ParkingNotes.store.reset, () => {
      return initialState
    })
})

export default ReducerParkingNotes
