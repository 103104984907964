enum StoreActions {
  IS_LOADER_SHOW = 'ME_USER/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'ME_USER/IS_LOADER_HIDE',
  SAVE_ME_USER = 'ME_USER/SAVE_ME_USER',
  CLEAR_ME_USER = 'ME_USER/CLEAR_ME_USER',
}

enum SagaEvents {}

export const ActionTypes_MeUser = {
  StoreActions,
  SagaEvents,
}
