import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_notificationHistory } from 'redux/modules/notificationHistory/Actions'

const { post } = RequestMethods()

export default function* SagaWorker_SetIsRead() {
  try {
    yield call(async () => await post('/pusher_action_messages/setIsRead', null))
    yield put(Actions_notificationHistory.store.hideUnreadMessagesIndicator())
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
