enum StoreActions {
  IS_LOADER_SHOW = 'SETTINGS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'SETTINGS/IS_LOADER_HIDE',
  SAVE_SETTINGS = 'SETTINGS/SAVE_SETTINGS',
}

enum SagaEvents {}

export const ActionTypes_Settings = {
  SagaEvents,
  StoreActions,
}
