import { createReducer } from '@reduxjs/toolkit'
import { Actions_MeRole } from './Actions'
import { IStateMeRole } from './TReducer'

const initialState: IStateMeRole = {
  isLoading: false,
  data: null,
  responseInfo: null,
  isInitialized: false,
}

const ReducerMeRole = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_MeRole.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_MeRole.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_MeRole.store.saveMeRole, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_MeRole.store.setInitialized, (state) => {
      state.isInitialized = true
    })
})

export default ReducerMeRole
