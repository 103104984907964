import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { IParking } from 'redux/modules/parkings/TReducer'
import { Form } from 'utils/helpers/form'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'

const formValidation = {
  stationCode: Form.combine(Form.required),
  serialNumber: Form.combine(Form.required, Form.minLength(1), Form.maxLength(20)),
  imei: Form.combine(Form.required),
  idHigh: Form.combine(Form.required),
  idMiddle: Form.combine(Form.required),
  idLow: Form.combine(Form.required),
}

interface IForm {
  parkingId: number | string
  stationCode: string
  serialNumber: string
  imei: string
  idHigh: string
  idMiddle: string
  idLow: string
}

type TModalProps = {
  parkingId?: number
  currentParkingId?: number
}

export const useStationCreate = () => {
  const { isLoading, allParkings, modalProps } = useSelector(
    ({ currentStation, parkings, modals }: TState) => ({
      isLoading: currentStation.isLoading || parkings.isLoading,
      allParkings: parkings.allParkings,
      modalProps: modals.data as TModalProps,
    }),
    shallowEqual,
  )

  const parkingOptions: TDropdownOption[] = toDropdownOption(allParkings)

  const { handleSubmit, register, errors, control } = useForm<IForm>({
    defaultValues: {
      parkingId: modalProps?.parkingId || '',
      stationCode: '',
      serialNumber: '',
      imei: '',
      idHigh: '',
      idMiddle: '',
      idLow: '',
    },
  })
  const { t } = useTranslation(I18nModal.stationCreate)

  const dispatch = useDispatch()

  const onSubmit = (data: IForm) => {
    const dataForServer = {
      ...data,
      parkingId: parseInt(data.parkingId as string),
    }

    if (modalProps?.currentParkingId) {
      dispatch(
        Actions_CurrentParking.saga.createStationAndGetParkingDetails({
          id: modalProps.currentParkingId,
          data: dataForServer,
        }),
      )
    } else {
      dispatch(Actions_Parkings.saga.createStationAndGetParkings(dataForServer))
    }
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    closeModal,
    isLoading,
    form: {
      formValidation,
      parkingOptions,
      errors,
      register,
      handleSubmit: handleSubmit(onSubmit),
      control,
    },
  }
}

const toDropdownOption = (parking: IParking[]) =>
  parking.map(({ parkingInfo }) => ({
    id: parkingInfo.id,
    label: parkingInfo.parkingCode,
    value: parkingInfo.id,
  }))
