import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TState } from 'redux/config/root-types'
import { Actions_Stations } from 'redux/modules/stations/Actions'
import { useForm } from 'react-hook-form'

export const useStationCodeFilter = () => {
  const dispatch = useDispatch()
  const { stations } = useSelector(
    ({ stations }: TState) => ({
      stations: stations,
    }),
    shallowEqual,
  )
  const isFilterOn = stations.filter.stationNumber ? true : false
  const { handleSubmit, register, errors, control } = useForm<{ value: string }>({
    defaultValues: {
      value: stations.filter.stationNumber ? stations.filter.stationNumber : '',
    },
  })

  const onSubmit = useCallback(
    (data: { value: string }) => {
      if (data.value.length >= 2) {
        dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, stationNumber: data.value }))
        dispatch(Actions_Modals.store.hideModal())
      }
    },
    [dispatch, stations],
  )

  const onCancel = useCallback(() => dispatch(Actions_Modals.store.hideModal()), [dispatch])

  const onClearFilter = () => {
    dispatch(Actions_Stations.store.changeFilter({ ...stations.filter, stationNumber: null }))
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    onCancel,
    isFilterOn,
    onClearFilter,
    form: { register, handleSubmit: handleSubmit(onSubmit) },
  }
}
