import React, { FC } from 'react'
import Link from '@mui/material/Link'

export type TTableLinkCellProps = {
  text: string
  onClick: () => void
}

export const TableLinkCell: FC<TTableLinkCellProps> = ({ text, onClick }) => {
  return (
    <Link component="button" variant="body2" onClick={onClick}>
      {text}
    </Link>
  )
}
