import React, { FC } from 'react'
import List from '@mui/material/List'
import MenuListItem from './MenuListItem'
import { useMenuList } from 'ui/root-components/StaticControls/MenuList/MenuList-ModalView'

interface IProps {
  open: boolean
}

const MenuList: FC<IProps> = ({ open }) => {
  const vm = useMenuList()
  const { data } = vm
  return (
    <div style={{ flexGrow: 1, minWidth: 120, overflow: 'hidden' }}>
      <List component="nav">
        {data.map((item) => (
          <MenuListItem open={open} key={item.route} {...item} />
        ))}
      </List>
    </div>
  )
}

export default React.memo(MenuList)
