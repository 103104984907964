import { useCallback, useMemo, useState } from 'react'
import _sortBy from 'lodash.sortby'
import { TColumns, TTableFilter, TTableOnChangeFilter } from 'ui/shared/Table/Table'
import { IOrder } from 'utils/types'

export const COLUMN_HEIGHT = 36

interface IUseTable<T> {
  data: T[]
  columns: TColumns
  filter?: TTableFilter
  onChangeFilter?: TTableOnChangeFilter
  isSortByServer?: boolean
}

export function useTable<T>({ columns, data, onChangeFilter = () => {}, filter = {}, isSortByServer }: IUseTable<T>) {
  const [sortBy, changeSortBy] = useState<keyof T | undefined>(filter?.sorting as keyof T)
  const [sortDirection, changeSortDirection] = useState<IOrder>(filter?.orderBy || 'ASC')

  const sortedList = useMemo(() => {
    if (isSortByServer) return data

    const sortMethod = columns.find((column) => column.dataKey === sortBy)?.sortMethod
    let newList

    if (sortMethod) {
      newList = [...data]
      newList.sort((a, b) => sortMethod(a[sortBy as keyof T], b[sortBy as keyof T]))
    } else {
      newList = _sortBy(data, [sortBy])
    }

    if (sortDirection === 'DESC') {
      newList.reverse()
    }
    return newList as T[]
  }, [data, columns, sortBy, sortDirection])

  const onHeaderClick = useCallback(
    (updatedSortBy: string) => {
      const column = columns.find((column) => column.dataKey === updatedSortBy)

      if (column?.disableSort) {
        return
      }

      if (sortBy === updatedSortBy) {
        const newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC'
        changeSortDirection(newSortDirection)
        onChangeFilter({ sorting: updatedSortBy, orderBy: newSortDirection })
      } else {
        const initialSortDirection = 'ASC'
        changeSortBy(updatedSortBy as keyof T)
        changeSortDirection(initialSortDirection)
        onChangeFilter({ sorting: updatedSortBy, orderBy: initialSortDirection })
      }
    },
    [sortBy, columns, sortDirection, onChangeFilter],
  )
  return { columnHeight: COLUMN_HEIGHT, sortedList, onHeaderClick, sortBy, sortDirection }
}
