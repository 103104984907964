enum StoreActions {
  IS_LOADER_SHOW = 'ACCESS_RULES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'ACCESS_RULES/IS_LOADER_HIDE',
  SAVE_ACCESS_RULES = 'ACCESS_RULES/SAVE_ACCESS_RULES',
}

enum SagaEvents {
  GET_ACCESS_RULES = 'ACCESS_RULES/GET_ACCESS_RULES',
}

export const ActionTypes_AccessRules = {
  StoreActions,
  SagaEvents,
}
