import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Regions } from './TActions'
import { TRegion } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { StoreActions, SagaEvents } = ActionTypes_Regions

export const Actions_Regions = {
  saga: {
    getRegions: createAction(SagaEvents.GET_REGIONS),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveRegions: createAction<TStoreTemplate<TRegion[]>>(StoreActions.SAVE_REGIONS),
    reset: createAction(StoreActions.RESET),
  },
}
