import type { PayloadAction } from '@reduxjs/toolkit'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { call, put, select } from 'redux-saga/effects'
import { Actions_Organizations } from 'redux/modules/organizations/Actions'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { IFilter, IParkingOccupancyFilter, TParkings } from 'redux/modules/parkings/TReducer'
import { sagaAction_StationsCounters } from 'redux/sagas/actions/SagaAction_LoadStationsCounters'
import { TStations } from 'redux/modules/stations/TReducer'
import { TStationStatuses } from 'redux/modules/stationStatuses/TReducer'
import { Actions_Stations } from 'redux/modules/stations/Actions'
import { RequestMethods } from 'utils/axios/methods'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { get } = RequestMethods()

export interface ISagaWorker_LoadParkings {
  filter?: IFilter
  updatedOrganization?: number[]
  updatedOccupancyFilter?: { name: keyof IParkingOccupancyFilter; value: boolean }
}

export default function* SagaWorker_LoadMoreParkings(action?: PayloadAction<ISagaWorker_LoadParkings>) {
  const updatedOrganization = action?.payload.updatedOrganization
  const updatedOccupancyFilter = action?.payload.updatedOccupancyFilter
  const filter = action?.payload.filter

  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds
  const getOccupancyFilters = (state: TState) => state.parkings.occupancyFilter

  let organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)
  if (updatedOrganization) {
    organization = updatedOrganization
    yield put(Actions_Organizations.store.changeSelectedOrganizationIds(organization))
  }

  const occupancyFilter: ReturnType<typeof getOccupancyFilters> = yield select(getOccupancyFilters)
  let occupancy = occupancyFilter
  if (updatedOccupancyFilter) {
    const { name, value } = updatedOccupancyFilter
    occupancy = {
      ...occupancyFilter,
      [name]: value,
    }
    yield put(Actions_Parkings.store.changeOccupancyFilter(occupancy))
  }

  yield put(Actions_Parkings.store.showLoader())

  try {
    //------------------------- Getting and saving parkings ---------------------------//
    const parkingResponse: TStoreTemplate<TParkings> = yield call(
      async () =>
        await get<TTrailers>('/parking', {
          parkingName: filter?.parkingName,
          organization,
          ...occupancy,
        }),
    )
    yield put(Actions_Parkings.store.saveParkings(parkingResponse))

    //------------------------- Getting and saving stations counters ----------------------//
    yield call(() => sagaAction_StationsCounters({ organization, occupancy }))

    //------------------------- Getting and saving stations ----------------------//

    const stations: TStoreTemplate<TStations> = yield call(
      async () => await get<TStationStatuses>('/stations', { ...occupancy, organization }),
    )
    yield put(Actions_Stations.store.saveStations(stations))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_Parkings.store.hideLoader())
}
