import { createReducer } from '@reduxjs/toolkit'
import { Actions_Organizations } from './Actions'
import { IStateOrganizations } from './TReducer'

const initialState: IStateOrganizations = {
  isLoading: false,
  data: null,
  responseInfo: null,
  selectedOrganizationIds: [],
}

const ReducerOrganizations = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Organizations.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Organizations.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Organizations.store.saveOrganizations, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Organizations.store.changeSelectedOrganizationIds, (state, action) => {
      state.selectedOrganizationIds = action.payload
    })
    .addCase(Actions_Organizations.store.reset, () => {
      return initialState
    })
})

export default ReducerOrganizations
