import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_CurrentParking } from 'redux/modules/currentParking/Actions'
import { TCurrentParking } from 'redux/modules/currentParking/TReducer'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import SagaWorker_LoadMoreParkings from 'redux/sagas/events/parking/SagaWorker_LoadMoreParkings'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { post } = RequestMethods()

export interface ISagaWorker_CreateParking {
  parkingCode: string
  organizationId: number
  city: string
  street: string
  postIndex: string
  latitude: number
  longitude: number
  imei: string
}

export default function* SagaWorker_CreateParking(action: PayloadAction<ISagaWorker_CreateParking>) {
  try {
    yield put(Actions_CurrentParking.store.showLoader())

    yield call(async () => await post<TCurrentParking>(`/parking`, action.payload))

    yield put(Actions_Notifications.store.setNotification({ message: 'Parking successfully created', type: 'Success' }))

    yield call(SagaWorker_LoadMoreParkings)
    yield put(Actions_Modals.store.hideModal())
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_CurrentParking.store.hideLoader())
}
