import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Shared } from 'ui/shared'
import { useStationConnectionFilter } from './StationConnectionFilter-ViewModel'
import { Autocomplete, TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const StationConnectionFilter: FC = () => {
  const { data, onSubmit, onCancel, setValue, value, isFilterOn, onClearFilter } = useStationConnectionFilter()
  const { classes } = useStyles()
  return (
    <Shared.Card style={{ width: 375 }}>
      <Shared.CardTitle title={'Connection'} />
      <Grid item xs={12}>
        <Shared.Divider />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          autoHighlight
          value={value}
          onInputChange={(e, v) => setValue(v)}
          options={data}
          sx={{ width: '100%' }}
          renderInput={(params) => (
            <TextField {...params} onChange={(e) => setValue(e.target.value)} placeholder={'Connection'} />
          )}
        />
      </Grid>
      <Grid item xs={6} className={classes.buttons}>
        <Shared.Button className={classes.button} title={'Cancel'} onClick={onCancel} color={'secondary'} />
        <Shared.Button
          className={classes.button}
          width={'100%'}
          title={'Submit'}
          onClick={() => onSubmit(value)}
          color={'primary'}
        />
      </Grid>
      {isFilterOn && (
        <Shared.Button width={'100%'} title={'Clear filter'} type="submit" color={'primary'} onClick={onClearFilter} />
      )}
    </Shared.Card>
  )
}

const useStyles = makeStyles()((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 8,
  },
  button: {
    margin: 4,
  },
}))
