import React, { FC } from 'react'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import { Controls } from 'assets/images/Controls'
import { useNotificationDrawer } from './NotificationDrawer-ViewModel'
import { Message } from './Message/Message'

const NotificationDrawer = () => {
  const vm = useNotificationDrawer()
  const { classes, data, isShowDrawer, isLoading, onClose } = vm

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={isShowDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography title={'Notifications'} variant="h3" color="primary" noWrap>
            {'Notifications'}
          </Typography>

          <IconButton onClick={onClose} className={classes.closeIcon} size="large">
            <Controls.CloseNotificationDrawer />
          </IconButton>
        </div>

        <div className={classes.content}>
          {isLoading && <CircularProgress className={classes.loader} size={24} />}

          {data.map(({ isShowDate, date, isShowDivider, ...otherProps }, index) => (
            <div key={index}>
              {isShowDate && <p className={classes.date}>{date}</p>}

              <Message {...otherProps} />

              {isShowDivider && <div className={classes.divider} />}
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  )
}

export default NotificationDrawer
