import { useEffect, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_notificationHistory } from 'redux/modules/notificationHistory/Actions'
import { TNotifications } from 'redux/modules/notificationHistory/TReducer'
import DateConverter from 'utils/helpers/date'

const drawerWidth = 477

export const useNotificationDrawer = () => {
  const isShowDrawer = useSelector(({ notificationHistory }: TState) => notificationHistory.isShowDrawer)
  const isLoading = useSelector(({ notificationHistory }: TState) => notificationHistory.isLoading)
  const notifications = useSelector(({ notificationHistory }: TState) => notificationHistory.data)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions_notificationHistory.saga.getNotificationHistory())
  }, [dispatch])

  const onClose = () => {
    dispatch(Actions_notificationHistory.store.hideDrawer())
  }

  const { classes } = useStyles()

  const data = useMemo(() => toNotificationsData(notifications), [notifications])

  return {
    classes,
    data,
    isShowDrawer,
    isLoading,
    onClose,
  }
}

const toNotificationsData = (notifications: TNotifications | null) => {
  const data = notifications || []

  return data.map((item) => {
    const isShowTitle = Boolean(
      item.parkingId ||
        item.parkingName ||
        item.stationId ||
        item.trailerId ||
        item.trailerPlate ||
        item.userId ||
        item.userName,
    )
    const isShowDivider = item.message !== 'Error' && item.message !== 'Info' && item.message !== 'Success'
    const date = DateConverter.formatDate(item.date) || ''

    return {
      date,
      isShowDate: true,
      isShowDivider,
      isShowTitle,
      message: item.message,
      parkingId: item.parkingId,
      parkingName: item.parkingName,
      stationId: item.stationId,
      trailerId: item.trailerId,
      trailerPlate: item.trailerPlate,
      userId: item.userId,
      userName: item.userName,
    }
  })
}

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1201,
    borderRadius: '10px 0 0 10px',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '24px 24px 12px',
  },
  closeIcon: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  date: {
    fontSize: 16,
    lineHeight: '18.75px',
    color: '#ABABAB',
    margin: '24px 24px 12px 24px',
  },
  divider: {
    margin: '8px 24px',
    borderBottom: '1px solid #E3E3E3',
  },
  content: {
    overflowY: 'auto',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))
