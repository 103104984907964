import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { TStationStatuses } from 'redux/modules/stationStatuses/TReducer'
import { Actions_StationStatuses } from 'redux/modules/stationStatuses/Actions'
import { sagaAction_StationsCounters } from 'redux/sagas/actions/SagaAction_LoadStationsCounters'
import { Actions_Stations } from 'redux/modules/stations/Actions'
import { TStations } from 'redux/modules/stations/TReducer'
import { TParkings } from 'redux/modules/parkings/TReducer'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_TrailerStatuses } from 'redux/modules/trailerStatuses/Actions'
import { Actions_Trailers } from 'redux/modules/trailers/Actions'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'

const { get } = RequestMethods()

export default function* SagaPage_Parkings() {
  yield put(Actions_PageLoader.store.showLoader())
  const getOccupancyFilter = (state: TState) => state.parkings.occupancyFilter
  const getSelectedOrganizations = (state: TState) => state.organizations.selectedOrganizationIds
  const occupancy: ReturnType<typeof getOccupancyFilter> = yield select(getOccupancyFilter)
  const organization: ReturnType<typeof getSelectedOrganizations> = yield select(getSelectedOrganizations)

  try {
    //------------------------- Getting and saving stations statuses ----------------------//

    const statuses: TStoreTemplate<TStationStatuses> = yield call(
      async () => await get<TStationStatuses>('/stations/statuses'),
    )
    yield put(Actions_StationStatuses.store.saveStationStatuses(statuses))

    //------------------------- Getting and saving stations counters ----------------------//
    yield call(() => sagaAction_StationsCounters({ organization, occupancy }))

    //------------------------- Getting and saving parkings ---------------------------//
    const parkingResponse: TStoreTemplate<TParkings> = yield call(
      async () => await get<TParkings>('/parking', { organization, ...occupancy }),
    )
    yield put(Actions_Parkings.store.saveParkings({ ...parkingResponse }))

    //------------------------- Getting and saving stations ----------------------//

    const stations: TStoreTemplate<TStations> = yield call(
      async () => await get<TStationStatuses>('/stations', { ...occupancy, organization }),
    )
    yield put(Actions_Stations.store.saveStations(stations))

    //------------------------- Getting and saving trailer statuses -------------------//
    let status: number[] = []

    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await get<TTrailerStatuses>('/trailers/statuses'),
    )
    yield put(Actions_TrailerStatuses.store.saveTrailerStatuses(t_statuses))
    if (t_statuses.data?.statuses) {
      status = t_statuses.data.statuses.map((t_statuse) => t_statuse.id)
    }
    //------------------------- Getting and saving trailers ---------------------------//
    const trailerResponse: TStoreTemplate<TTrailers> = yield call(
      async () =>
        await get<TTrailers>('/trailers/list', {
          status,
        }),
    )
    yield put(Actions_Trailers.store.saveTrailers(trailerResponse))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_PageLoader.store.hideLoader())
}
