import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Auth } from './TActions'
import { TAuth, TFormData_Auth } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_ChangePassword } from 'redux/sagas/events/SagaWorker_ChangePassword'

const { SagaEvents, StoreActions } = ActionTypes_Auth

export const Actions_Auth = {
  saga: {
    getAuth: createAction<TFormData_Auth>(SagaEvents.GET_AUTH_INFO),
    getInitialData: createAction(SagaEvents.GET_INITIAL_DATA),
    changePassword: createAction<ISagaWorker_ChangePassword>(SagaEvents.CHANGE_PASSWORD),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveAuth: createAction<TStoreTemplate<TAuth>>(StoreActions.SAVE_AUTH),
    logout: createAction(StoreActions.LOGOUT),
  },
}
