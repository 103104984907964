import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { ITrailerType, ITrailerTypeLocaleInfo, TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TTrailerTypeSubtype } from 'redux/modules/trailerTypeSubtype/TReducer'
import { getMedia, IMedia } from 'redux/sagas/actions/mediaAction'
import { TStoreTemplate } from 'redux/config/root-types'

const { get, put: clientPut, post, remove: clientDelete } = RequestMethods()
type TSubtypeAction = {
  id: number
  weight: number
  maxLoad: number
  height: number
  width: number
  length: number
  countItems: boolean
  items?: number
  orderIndex: number
}

export interface IUpdateType {
  id: number
  name: string
  image: string
  minutePrice: number
  dayPrice: number
  weekPrice: number
  monthPrice: number
  orderIndex?: number
  model: string
}

interface TSubtypeActions {
  create: TSubtypeAction[]
  update: TSubtypeAction[]
  remove: TSubtypeAction[]
}

export type ISagaWorker_UpdateTrailerType = {
  cb: () => void
  type: IUpdateType
  locales: ITrailerTypeLocaleInfo[]
  typeFile?: File
  actionsForSubtypes: TSubtypeActions
}

export default function* SagaWorker_UpdateTrailerType(action: PayloadAction<ISagaWorker_UpdateTrailerType>) {
  const {
    cb,
    type: { id, ...typeData },
    locales = [],
    actionsForSubtypes,
    typeFile,
  } = action.payload
  try {
    yield put(Actions_TrailerTypes.store.showLoader())
    if (typeFile) {
      const imageData: IMedia = yield call(() => getMedia(typeFile))
      typeData.image = imageData.name || ''
    }

    yield call(async () => await clientPut<ITrailerType>(`/trailer_types/${id}`, typeData))

    const localePromises = locales.map(async (localeItem) =>
      clientPut<ITrailerType>(`/trailer_types/${id}/locale`, localeItem),
    )
    yield call(() => Promise.all(localePromises))

    yield call(() => updateSubtypeInfo(id, actionsForSubtypes))
    const result: TStoreTemplate<TTrailerTypes> = yield call(async () => await get<TTrailerTypes>('/trailer_types'))
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(result))

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Trailer type successfully Updated', type: 'Success' }),
    )
    cb()
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
  yield put(Actions_TrailerTypes.store.hideLoader())
}

function* updateSubtypeInfo(typeId: number, { create, remove, update }: TSubtypeActions) {
  const createSubtypePromises = create.map(async (subtype) =>
    post<TTrailerTypeSubtype>(`/trailer_types/subtypes`, { ...subtype, typeId }),
  )
  const updateSubtypePromises = update.map(async ({ id, ...data }) =>
    clientPut<TTrailerTypeSubtype>(`/trailer_types/subtypes/${id}`, data),
  )
  const removeSubtypePromises = remove.map(async ({ id }) => clientDelete(`/trailer_types/subtypes/${id}`))

  yield call(() => Promise.all([...createSubtypePromises, ...updateSubtypePromises, ...removeSubtypePromises]))
}
