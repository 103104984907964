import React, { FC, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import { NoteInputGroup, NotesList } from './components'
import { TNote } from 'redux/modules/parkingNotes/TReducer'
import { Shared } from 'ui/shared'

export type TProps = {
  notes: TNote[]
  onAddNote: (text: string) => void
}

export const Notes: FC<TProps> = ({ notes, onAddNote }) => {
  const notesRef = useRef<HTMLDivElement>(null)

  const scrollToLastNote = () => {
    notesRef.current?.lastElementChild?.scrollIntoView({ block: 'nearest' })
  }

  const handleAddNote = (text: string) => {
    onAddNote(text)
  }

  useEffect(
    function scrollAfterAddNewNote() {
      scrollToLastNote()
    },
    [notes],
  )

  return (
    <Box display="flex" flexDirection="column" width={'100%'} flexGrow={1} minHeight={0}>
      <NotesList notes={notes} ref={notesRef} sx={{ pt: 2 }} />

      <Shared.Divider style={{ margin: '0 1.5rem 16px' }} />

      <NoteInputGroup sendNote={handleAddNote} sx={{ mx: '1.5rem' }} />
    </Box>
  )
}
