enum StoreActions {
  IS_LOADER_SHOW = 'ACCESS_GROUPS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'ACCESS_GROUPS/IS_LOADER_HIDE',
  SAVE_ACCESS_GROUPS = 'ACCESS_GROUPS/SAVE_ACCESS_GROUPS',
}

enum SagaEvents {
  GET_ACCESS_GROUPS = 'ACCESS_GROUPS/GET_ACCESS_GROUPS',
}

export const ActionTypes_AccessGroups = {
  StoreActions,
  SagaEvents,
}
