import React, { FC, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Shared } from 'ui/shared'
import { RootComponent } from 'ui/root-components'
import { useAuthorization } from './Router-ViewModel'

const MainRouter: FC = () => {
  const vm = useAuthorization()
  const { routes } = vm

  return (
    <Suspense fallback={<Shared.Preloader color={'secondary'} />}>
      <RootComponent.SnackbarProvider>
        <RootComponent.Notification />
        <BrowserRouter>
          <RootComponent.ModalPortal />
          {routes}
        </BrowserRouter>
      </RootComponent.SnackbarProvider>
    </Suspense>
  )
}

export default MainRouter
