import { createReducer } from '@reduxjs/toolkit'
import { Actions_Regions } from './Actions'
import { IStateRegions } from './TReducer'

const initialState: IStateRegions = {
  isLoading: false,
  data: null,
  responseInfo: null,
}

const ReducerRegions = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_Regions.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_Regions.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_Regions.store.saveRegions, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_Regions.store.reset, () => {
      return initialState
    })
})

export default ReducerRegions
