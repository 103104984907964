import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TState } from 'redux/config/root-types'
import { Actions_CurrentUser } from 'redux/modules/currentUser/Actions'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { To } from 'router/To'
import { I18nModal } from 'utils/i18n/i18nModal'

export const useUserDelete = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.userDelete)
  const navigate = useNavigate()

  const staffId = useSelector(({ modals }: TState) => modals.data.id)

  const onSubmit = () => {
    const navigateToUserTable = () => navigate(To.users)
    dispatch(Actions_CurrentUser.saga.deleteUser({ id: staffId, navigateToUserTable }))
    dispatch(Actions_Modals.store.hideModal())
  }

  const onCancel = () => dispatch(Actions_Modals.store.hideModal())

  return { t, onSubmit, onCancel }
}
