enum StoreActions {
  IS_LOADER_SHOW = 'RENTS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'RENTS/IS_LOADER_HIDE',
  SAVE_RENTS = 'RENTS/SAVE_RENTS',
  CHANGE_FILTER = 'RENTS/CHANGE_FILTER',
  INCREASE_FILTER_OFFSET = 'RENTS/INCREASE_FILTER_OFFSET',
  RESET_FILTER = 'RENTS/RESET_FILTER',
  SAVE_INSURANCES = 'RENTS/SAVE_INSURANCES',
  SAVE_CHECKLISTS_PARAMETERS = 'RENTS/SAVE_CHECKLISTS_PARAMETERS',
}

enum SagaEvents {
  GET_RENTS = 'RENTS/GET_RENTS',
  GET_INSURANCE = 'RENTS/GET_INSURANCE',
  LOAD_XLSX = 'RENTS/LOAD_XLSX',
  GET_CHECKLISTS_PARAMETERS = 'RENTS/GET_CHECKLISTS_PARAMETERS',
}

export const ActionTypes_Rents = {
  StoreActions,
  SagaEvents,
}
