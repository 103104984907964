import React, { forwardRef, useCallback } from 'react'
import { TNote } from 'redux/modules/parkingNotes/TReducer'
import Box from '@mui/material/Box'
import Note from '../Note/Note'
import DateConverter from 'utils/helpers/date'
import { SxProps, Theme } from '@mui/material/styles'

type TProps = {
  notes: TNote[]
  sx?: SxProps<Theme>
}

const NotesList = forwardRef<HTMLDivElement, TProps>(({ notes, sx }, ref) => {
  const isShowDate = useCallback(
    (index: number, currentDate: string) => {
      if (index === 0) return true

      const prevDate = notes[index - 1].createdAt

      if (!DateConverter.isSameDay(prevDate, currentDate)) return true

      return false
    },
    [notes],
  )

  return (
    <Box sx={[noteListStyle, ...(Array.isArray(sx) ? sx : [sx])]} ref={ref}>
      {notes.map((note, index) => {
        const showDate = isShowDate(index, note.createdAt)
        return <Note key={note.id} note={note} isShowDate={showDate} />
      })}
    </Box>
  )
})

export default NotesList

const noteListStyle = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  flexGrow: 1,
  flexBasis: '100px',
  flexShrink: 1,
}
