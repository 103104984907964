import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useForm, useWatch } from 'react-hook-form'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'

interface IForm {
  vin: string
  plate: string
  imei: string
  trailerTypeId: number | string
  trailerSubtypeId: number | string
  organization: number[]
  sim: string
  identifyNumber: string
  idHigh: string
  idLow: string
}

export const useTrailerCreate = () => {
  const { trailerTypes, isLoading } = useSelector(({ trailerTypes, currentTrailer }: TState) => ({
    trailerTypes: trailerTypes.data || [],
    isLoading: currentTrailer.isLoading,
  }))
  const { handleSubmit, register, errors, control, watch } = useForm<IForm>({
    defaultValues: {
      vin: '',
      plate: '',
      imei: '',
      trailerTypeId: '',
      trailerSubtypeId: '',
      organization: [],
      sim: '',
      identifyNumber: '',
      idHigh: '',
      idLow: '',
    },
  })
  const { t } = useTranslation(I18nModal.trailerCreate)

  const selectedTrailerTypeId = useWatch({
    control,
    name: 'trailerTypeId',
  })

  const trailerSubtypes = useMemo(
    () => trailerTypes.find(({ type }) => type.id === Number(selectedTrailerTypeId))?.subtypes || [],
    [selectedTrailerTypeId, trailerTypes],
  )

  const trailerSubtypesOptions = useMemo(
    () =>
      trailerSubtypes.map(({ id, name, width, weight, length, items }) => ({
        id,
        value: id,
        label: t('subtypeLabel', { name, width, weight, length, items: items || 0 }),
      })),
    [trailerSubtypes, t],
  )

  const trailerTypesOptions = useMemo(
    () => (trailerTypes ? trailerTypes.map(({ type: { id, name } }) => ({ id, value: id, label: name })) : []),
    [trailerTypes],
  )

  const onSubmit = (data: IForm) => {
    const dataForServer = {
      ...data,
      trailerTypeId: parseInt(data.trailerTypeId as string),
      trailerSubtypeId: parseInt(data.trailerSubtypeId as string),
    }
    dispatch(Actions_CurrentTrailer.saga.createTrailer(dataForServer))
  }

  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    trailerSubtypes,
    selectedTrailerTypeId,
    trailerSubtypesOptions,
    t,
    closeModal,
    isLoading,
    form: {
      trailerTypesOptions,
      errors,
      register,
      handleSubmit: handleSubmit(onSubmit),
      control,
      watch,
    },
  }
}
