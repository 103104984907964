enum StoreActions {
  IS_LOADER_SHOW = 'STATIONS_COUNTERS/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'STATIONS_COUNTERS/IS_LOADER_HIDE',
  SAVE_STATIONS_COUNTERS = 'STATIONS_COUNTERS/SAVE_STATIONS_COUNTERS',
}

enum SagaEvents {}

export const ActionTypes_StationsCounters = {
  SagaEvents,
  StoreActions,
}
