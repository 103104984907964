enum StoreActions {
  IS_LOADER_SHOW = 'ORGANIZATION_INVITATION_CODES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'ORGANIZATION_INVITATION_CODES/IS_LOADER_HIDE',
  SAVE_INVITATION_CODES = 'ORGANIZATION_INVITATION_CODES/SAVE_INVITATION_CODES',
  RESET = 'ORGANIZATION_INVITATION_CODES/RESET',
}

export const ActionTypes_OrganizationInvitationCodes = {
  StoreActions,
}
