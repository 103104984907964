import React, { FC } from 'react'
import { useTrailerCreate } from './TrailerCreate-ViewModel'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { Form } from 'utils/helpers/form'

export const TrailerCreate: FC = () => {
  const vm = useTrailerCreate()
  const { closeModal, form, isLoading, t, trailerSubtypesOptions, selectedTrailerTypeId } = vm
  const { errors, handleSubmit, register, control, trailerTypesOptions, watch } = form
  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="vin"
              title={t('vin')}
              register={register(Form.required)}
              error={errors.vin?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="plate"
              title={t('plate')}
              register={register(Form.required)}
              error={errors.plate?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="imei"
              title={t('imei')}
              register={register(Form.required)}
              error={errors.imei?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="sim"
              title={t('sim')}
              register={register(Form.combine(Form.required, Form.minLength(4), Form.maxLength(20)))}
              error={errors.sim?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="identifyNumber"
              title={t('identifyNumber')}
              register={register(Form.combine(Form.required, Form.minLength(17)))}
              error={errors.identifyNumber?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.Dropdown
              control={control}
              name={'trailerTypeId'}
              label={t('trailerTypeId')}
              options={trailerTypesOptions}
              marginTop={0}
              fullWidth
              rules={Form.required}
              error={errors.trailerTypeId?.message}
            />
          </Grid>

          {selectedTrailerTypeId ? (
            <Grid item xs={12}>
              <Shared.Dropdown
                control={control}
                name={'trailerSubtypeId'}
                label={t('trailerSubtypeId')}
                options={trailerSubtypesOptions}
                marginTop={0}
                fullWidth
                rules={Form.required}
                error={errors.trailerSubtypeId?.message}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12}>
            <Shared.InputField
              name="idHigh"
              title={t('idHigh')}
              register={register(Form.required)}
              error={errors.idHigh?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="idLow"
              title={t('idLow')}
              register={register(Form.required)}
              error={errors.idLow?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.MultiOrganization
              type={'form'}
              showTitle={false}
              control={control}
              divider={false}
              hideBorder={false}
              label={t('organization')}
              watch={watch}
            />
          </Grid>
        </Grid>

        <div>
          <Shared.Button
            style={{ margin: '3.75em 0 0.875em 0' }}
            color={'secondary'}
            title={t('cancel')}
            onClick={closeModal}
          />
          <Shared.Button title={t('submit')} type={'submit'} />
        </div>
      </form>

      {isLoading && <Shared.Preloader mask />}
    </Shared.Card>
  )
}
