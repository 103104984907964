import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Staff } from './TActions'
import { TStaffFilter } from './TReducer'
import { TStoreTemplateWithFilter } from 'redux/config/root-types'
import { TUser } from '../users/TReducer'
import { ISagaWorker_RemoveStaffFromStaffTable } from 'redux/sagas/events/staff/SagaWorker_RemoveStaffFromStaffTable'

const { SagaEvents, StoreActions } = ActionTypes_Staff

export const Actions_Staff = {
  saga: {
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    getStaff: createAction(SagaEvents.GET_STAFF),
    deleteStaff: createAction<ISagaWorker_RemoveStaffFromStaffTable>(SagaEvents.DELETE_STAFF),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveStaff: createAction<TStoreTemplateWithFilter<TUser[]>>(StoreActions.SAVE_STAFF),
    changeFilter: createAction<TStaffFilter>(StoreActions.CHANGE_FILTER),
    increaseFilterOffset: createAction<TStaffFilter>(StoreActions.INCREASE_FILTER_OFFSET),
    reset: createAction(StoreActions.RESET),
  },
}
