import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { TTrailers } from 'redux/modules/trailers/TReducer'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'

interface IForm {
  trailerId: number
}

type TModalProps = {
  organizationId: number
}

export const useAddTrailerToOrganization = () => {
  const { t } = useTranslation(I18nModal.addTrailerToOrganization)
  const dispatch = useDispatch()

  const { isLoading, allTrailers, modalProps } = useSelector(
    ({ trailers, modals }: TState) => ({
      isLoading: trailers.isLoading,
      allTrailers: trailers.allTrailers,
      modalProps: modals.data as TModalProps,
    }),
    shallowEqual,
  )

  const { handleSubmit, errors, control } = useForm<IForm>()

  const onSubmit = (data: IForm) => {
    const formData = {
      trailerId: data.trailerId,
      organizationId: modalProps.organizationId,
    }

    dispatch(Actions_CurrentOrganization.saga.addTrailerToOrganization(formData))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const filteredTrailers = useMemo(
    () => toFilteredTrailers(allTrailers, modalProps.organizationId),
    [allTrailers, modalProps.organizationId],
  )

  const trailersOptions = useMemo(() => toDropdownOption(filteredTrailers), [filteredTrailers])

  return {
    t,
    closeModal,
    isLoading,
    form: {
      control,
      trailersOptions,
      errors,
      handleSubmit: handleSubmit(onSubmit),
    },
  }
}

const toFilteredTrailers = (trailers: TTrailers, currentOrganizationId: number) =>
  trailers.filter((trailer) => !trailer.organization.includes(currentOrganizationId))

const toDropdownOption = (parking: TTrailers) =>
  parking.map(({ trailer }) => ({
    id: trailer.id,
    label: trailer.plate,
    value: trailer.id,
  }))
