import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { Select, TSelectProps } from './Select'

type TProps = {
  label?: string
  height?: string | number
  marginTop?: string | number
  formControlStyles?: {}
  control?: any
  rules?: any
  name: string
  isSearchable?: boolean
} & TSelectProps

export const Dropdown: FC<TProps> = ({
  label = '',
  height,
  marginTop,
  formControlStyles = {},
  control,
  rules,
  name,
  id,
  fullWidth,
  multiple,
  ...selectProps
}) => {
  const _formControlStyles: { width?: string } = { ...formControlStyles }
  if (fullWidth) {
    _formControlStyles.width = '100%'
  }
  const { classes } = useStyles({ marginTop })

  return (
    <FormControl style={{ height, ..._formControlStyles }} className={classes.formControl}>
      {label && (
        <InputLabel shrink={true} className={classes.label} htmlFor={id}>
          {label}
        </InputLabel>
      )}
      {control ? (
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={(field) => <Select id={id} multiple={multiple} {...selectProps} {...field} />}
        />
      ) : (
        <Select id={id} multiple={multiple} {...selectProps} />
      )}
    </FormControl>
  )
}

interface IStyles {
  marginTop?: string | number
}

const useStyles = makeStyles<IStyles>()((theme, { marginTop }) => ({
  formControl: {
    flexGrow: 1,
    marginTop: marginTop || Number.isInteger(marginTop) ? marginTop : '1.5em',
    borderRadius: 50,
  },
  label: {
    '&.MuiInputLabel-shrink': {
      left: '0 !important',
      top: '-7% !important',
    },
    '&+.MuiInputBase-root': {
      marginTop: '1.5em !important',
    },
  },
}))
