enum StoreActions {
  IS_LOADER_SHOW = 'CURRENT_STATION/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CURRENT_STATION/IS_LOADER_HIDE',
  SAVE_CURRENT_STATION = 'CURRENT_STATION/SAVE_CURRENT_STATION',
}

enum SagaEvents {
  GET_STATION_DETAILS = 'CURRENT_STATION/GET_STATION_DETAILS',
  CREATE_STATION = 'CURRENT_STATION/CREATE_STATION',
  UPDATE_STATION = 'CURRENT_STATION/UPDATE_STATION',
  LOCK_STATION = 'CURRENT_STATION/LOCK_STATION',
  UNLOCK_STATION = 'CURRENT_STATION/UNLOCK_STATION',
  GET_QR_CODE = 'CURRENT_STATION/GET_QR_CODE',
}

export const ActionTypes_CurrentStation = {
  SagaEvents,
  StoreActions,
}
