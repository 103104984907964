import { useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'
import { SnackbarKey, useSnackbar } from 'notistack'
import { TNotificationType } from 'redux/modules/notification/TReducer'
import { useDispatch } from 'react-redux'
import { Actions_notificationHistory } from 'redux/modules/notificationHistory/Actions'

export const useSnackbarContent = (id: SnackbarKey, type?: TNotificationType) => {
  const dispatch = useDispatch()
  const typeColor = toCardTypeColor(type)

  const { classes } = useStyles({ typeColor, type })
  const { closeSnackbar } = useSnackbar()

  const handleDismiss = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      closeSnackbar(id)
    },
    [id, closeSnackbar],
  )

  const onCardClick = useCallback(() => {
    if (type === 'Error') {
      return
    }

    dispatch(Actions_notificationHistory.store.showDrawer())
    closeSnackbar(id)
  }, [dispatch, id, closeSnackbar])

  return {
    classes,
    handleDismiss,
    onCardClick,
  }
}

const toCardTypeColor = (type?: TNotificationType): string => {
  switch (type) {
    case 'Error':
    case 'RENT_START_ERROR':
    case 'RENT_FINISH_ERROR':
      return '#FEF4F4'
    case 'Success':
      return '#F6FDF5'
    default:
      return '#FFFFFF'
  }
}

interface IStyle {
  typeColor: string
  type?: TNotificationType
}

const useStyles = makeStyles<IStyle>()((theme, { typeColor, type }) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '342px !important',
      width: '342px !important',
    },
  },
  card: {
    backgroundColor: typeColor,
    width: '100%',
    borderRadius: 10,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: 12,
    justifyContent: 'space-between',
    cursor: type !== 'Error' ? 'pointer' : 'default',
  },
  closeButton: {
    padding: '8px',
    marginRight: '-8px',
    marginTop: '-8px',
    alignSelf: 'flex-start',
  },
}))
