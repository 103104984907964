import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { SagaWorker_GetTrailerNotes } from 'redux/sagas'
import { TTrailerNote } from 'redux/modules/trailerNotes/TReducer'
import { TCreateNotePayload } from 'redux/modules/parkingNotes/TReducer'
import { Actions_TrailerNotes } from 'redux/modules/trailerNotes/Actions'

const { post } = RequestMethods()

export interface ISagaWorker_CreateTrailerNote {
  trailerId: number
  text: string
}

export default function* SagaWorker_CreateTrailerNote(action: PayloadAction<ISagaWorker_CreateTrailerNote>) {
  try {
    yield put(Actions_TrailerNotes.store.showLoader())

    const { trailerId, text } = action.payload

    const data: TCreateNotePayload = {
      type: 'trailer',
      entityId: trailerId,
      text,
    }

    yield call(async () => await post<TTrailerNote>(`/notes`, data))

    yield call(SagaWorker_GetTrailerNotes, action)

    yield put(Actions_Notifications.store.setNotification({ message: 'Note successfully added', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_TrailerNotes.store.hideLoader())
  }
}
