import { RequestMethods } from 'utils/axios/methods'

export const loadImageAndCreateObject = async (name: string) => {
  const { getImage } = RequestMethods()
  try {
    const res = await getImage('/media', { name })
    const blob = new Blob([res], { type: 'image/jpeg' })

    const imageObjectURL = URL.createObjectURL(blob)
    return imageObjectURL
  } catch (e) {
    return null
  }
}
