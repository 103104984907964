import React, { FC } from 'react'
import { Controls } from 'assets/images/Controls'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import Button from '@mui/material/Button'
import DateConverter from 'utils/helpers/date'
import { DAY_WIDTH } from '../SingleDatePickerForForm'

export const CalendarHeader: FC<ReactDatePickerCustomHeaderProps> = ({ date, decreaseMonth, increaseMonth }) => {
  const month = DateConverter.getFullMonthAndYear(date)
  const daysOfWeek = DateConverter.getDaysOfWeek()

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <Button
          onClick={decreaseMonth}
          style={{ width: 54, height: 36, backgroundColor: '#fff2dc' }}
          disableTouchRipple
        >
          <Controls.LeftArrowPrimary />
        </Button>
        <div style={{ flexGrow: 1, fontSize: '20px', lineHeight: '29px', fontWeight: 'bold', color: '#F59D0E' }}>
          {month}
        </div>
        <Button
          onClick={increaseMonth}
          style={{ width: 54, height: 36, backgroundColor: '#fff2dc' }}
          disableTouchRipple
        >
          <Controls.RightArrowPrimary />
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {daysOfWeek.map((day) => (
          <div
            key={day}
            style={{ fontSize: 16, lineHeight: '20px', color: '#BEBEBE', width: DAY_WIDTH, margin: '10px 0' }}
          >
            {day}
          </div>
        ))}
      </div>
    </>
  )
}
