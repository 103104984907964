import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { useForm } from 'react-hook-form'
import { Form } from 'utils/helpers/form'
import { TFunction } from 'i18next'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { useMemo } from 'react'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'

type TModalProps = {
  organizationId: number
}

interface IForm {
  amount: string
  duration: string
}

const formValidation = {
  amount: Form.combine(Form.required, Form.min(1)),
  duration: Form.combine(Form.required),
}

export const useOrganizationInvitationCodesCreate = () => {
  const { t } = useTranslation(I18nModal.organizationInvitationCodesCreate)
  const dispatch = useDispatch()

  const organizationId = useSelector(({ modals }: TState) => (modals.data as TModalProps).organizationId)
  const isLoading = useSelector(({ promoCode }: TState) => promoCode.isLoading)

  const { handleSubmit, register, errors, control } = useForm<IForm>({
    defaultValues: {
      amount: '',
      duration: '',
    },
  })

  const onSubmit = (formData: IForm) => {
    const data = {
      amount: Number(formData.amount),
      duration: Number(formData.duration),
    }
    dispatch(Actions_CurrentOrganization.saga.createInvitationCodes({ organizationId, data }))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const durationOptions = useMemo(() => toDurationOptions(t), [t])

  return {
    t,
    closeModal,
    isLoading,
    durationOptions,
    form: {
      formValidation,
      errors,
      register,
      control,
      handleSubmit: handleSubmit(onSubmit),
    },
  }
}

const toDurationOptions = (t: TFunction): TDropdownOption[] => {
  const durationOptions = []
  for (let i = 1; i <= 12; i++) {
    durationOptions.push({
      id: i,
      label: t('monhtKeyWithCount', { count: i }),
      value: i,
    })
  }
  return durationOptions
}
