import { createAction } from '@reduxjs/toolkit'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_GetCurrentRole } from 'redux/sagas/events/staff/SagaWorker_GetCurrentRole'
import { ActionTypes_CurrentRole } from './TAction'
import { TCurrentRole } from './TReducer'

const { StoreActions, SagaEvents } = ActionTypes_CurrentRole

export const Actions_CurrentRole = {
  saga: {
    getCurrentRole: createAction<ISagaWorker_GetCurrentRole>(SagaEvents.GET_CURRENT_ROLE),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveCurrentRole: createAction<TStoreTemplate<TCurrentRole>>(StoreActions.SAVE_CURRENT_ROLE),
    clearCurrentRole: createAction(StoreActions.CLEAR_CURRENT_ROLE),
  },
}
