import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_notificationHistory } from 'redux/modules/notificationHistory/Actions'
import { TNotifications } from 'redux/modules/notificationHistory/TReducer'

const { get } = RequestMethods()

export default function* SagaWorker_GetNotifications() {
  yield put(Actions_notificationHistory.store.showLoader())
  try {
    const notifications: TStoreTemplate<TNotifications> = yield call(
      async () => await get<TNotifications>('/pusher_action_messages'),
    )
    yield put(Actions_notificationHistory.store.saveNotifications(notifications))

    const isUnreadMessages = notifications.data?.some((notification) => !notification.isRead) || false
    if (isUnreadMessages) {
      yield put(Actions_notificationHistory.store.showUnreadMessagesIndicator())
    } else {
      yield put(Actions_notificationHistory.store.hideUnreadMessagesIndicator())
    }
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_notificationHistory.store.hideLoader())
  }
}
