import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_ParentOrganizations } from 'redux/modules/parentOrganizations/Actions'
import { TParentOrganization } from 'redux/modules/parentOrganizations/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'

const { post, get } = RequestMethods()

export interface ISagaWorker_CreateParentOrganization {
  name: string
  discount: number
}

export default function* SagaWorker_CreateParentOrganization(
  action: PayloadAction<ISagaWorker_CreateParentOrganization>,
) {
  yield put(Actions_ParentOrganizations.store.showLoader())

  try {
    yield call(async () => await post<TParentOrganization>(`/organizations/parents/`, action.payload))

    const organizations: TStoreTemplate<TParentOrganization[]> = yield call(
      async () => await get<TParentOrganization[]>('/organizations/parents', { hideInactive: false }),
    )
    yield put(Actions_ParentOrganizations.store.saveParentOrganizations(organizations))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({
        message: 'Parent organization successfully created',
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_ParentOrganizations.store.hideLoader())
  }
}
