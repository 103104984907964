import { AxiosResponse } from 'axios'

export const getResponseFileName = (response: AxiosResponse<any>): string | undefined => {
  const headerLine = response.headers['content-disposition']

  if (!headerLine) return

  const startFileNameIndex = headerLine.indexOf('"') + 1
  const endFileNameIndex = headerLine.lastIndexOf('"')
  const responseFileName = headerLine.substring(startFileNameIndex, endFileNameIndex)

  return responseFileName
}
