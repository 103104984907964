import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Actions_Modals } from 'redux/modules/modals/Actions'

export const useBreakdownHistory = () => {
  const { t } = useTranslation(I18nModal.breakdownHistory)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    closeModal,
  }
}
