import React, { FC } from 'react'

import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import { InputBaseComponentProps } from '@mui/material/InputBase'
import { makeStyles } from 'tss-react/mui'
import { TObject } from 'utils/types'

export type TInputEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
export type TInputOnChange = (event: TInputEvent) => void

export interface IInputFieldProps {
  name: string
  title?: string
  readOnly?: boolean
  disabled?: boolean
  borderColor?: string
  textColor?: string
  labelColor?: string
  formControlStyles?: TObject
  value?: string | number
  password?: boolean
  onChange?: TInputOnChange
  error?: null | string
  register?: React.Ref<any>
  height?: number
  hideErrorText?: boolean
  EndAdornment?: React.FC
  onClick?: () => void
  type?: string
  defaultValue?: string | number | null
  inputProps?: InputBaseComponentProps
  startAdornment?: React.ReactNode
}

/**
 * Input props
 * @param type
 * @param title
 * @param disabled
 * @param readOnly
 * @param labelColor
 * @param password
 * @param borderColor
 * @param textColor
 * @param formControlStyles
 * @param name
 * @param register react hook form object
 * @param error react hook error
 * @param value used when you don't need react hook form
 * @param height
 * @param hideErrorText show only red border without text
 * @param EndAdornment
 * @param onClick
 * @param defaultValue
 * @param step
 * @constructor
 */
export const InputField: FC<IInputFieldProps> = ({
  type = 'text',
  title,
  disabled = false,
  readOnly = false,
  labelColor = '',
  password,
  borderColor,
  textColor,
  formControlStyles = {},
  name,
  register,
  error,
  value,
  height = 48,
  hideErrorText = true,
  EndAdornment,
  onClick,
  defaultValue,
  inputProps,
  startAdornment,
}) => {
  const styles: TObject = { height }
  const otherProps: any = {}

  value && (otherProps.value = value)
  defaultValue && (otherProps.defaultValue = defaultValue)

  if (EndAdornment) {
    otherProps.endAdornment = (
      <InputAdornment position="end">
        <EndAdornment />
      </InputAdornment>
    )
  }

  if (borderColor || error) {
    styles.borderColor = error ? 'red' : borderColor
  }

  const { classes } = useStyles({ textColor, title })
  return (
    <FormControl style={{ ...formControlStyles }} className={classes.formControl}>
      {title && (
        <InputLabel shrink={true} className={classes.label} style={{ color: labelColor }}>
          {title}
        </InputLabel>
      )}
      <Input
        {...otherProps}
        inputProps={inputProps}
        inputRef={register}
        style={{ ...styles }}
        name={name}
        type={password ? 'password' : type}
        readOnly={readOnly}
        disabled={disabled}
        className={classes.input}
        classes={{ formControl: classes.inputFormControl }}
        onClick={onClick}
        startAdornment={startAdornment}
      />
      {error && !hideErrorText && <div className={classes.error}>{error}</div>}
    </FormControl>
  )
}

const useStyles = makeStyles<{ textColor?: string; title?: string }>()((theme, { textColor, title }) => ({
  formControl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  inputFormControl: {
    ...(title ? { marginTop: '1.5em !important' } : {}),
  },
  label: {
    '&.MuiInputLabel-shrink': {
      left: '0 !important',
      top: '-7% !important',
    },
  },
  error: { color: 'red', fontSize: '0.75rem', marginLeft: 16 },
  input: {
    '& .MuiInputBase-input': {
      color: textColor ? (textColor as string) : theme.palette.text.primary,
    },
  },
}))
