import { call } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AxiosRequestConfig } from 'axios'

const { download } = RequestMethods()

export default function* SagaWorker_GetOrganizationsXlsx() {
  const formData = {
    hideInactive: false,
  }

  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig

  try {
    yield call(async () => download('/organizations/xlsx', formData, config, 'organizations.xlsx'))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
