import { createReducer } from '@reduxjs/toolkit'
import { IStateTrailersCounters } from './TReducer'
import { Actions_TrailersCounters } from './Actions'

const initialState: IStateTrailersCounters = {
  isLoading: false,
  data: null,
  responseInfo: null,
}

const ReducerTrailersCounters = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_TrailersCounters.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_TrailersCounters.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_TrailersCounters.store.saveTrailersCounters, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
})

export default ReducerTrailersCounters
