import React, { FC } from 'react'
import { useOrganizationCreate } from 'ui/modals/OrganizationCreate/OrganizationCreate-ViewModel'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { Form } from 'utils/helpers/form'
import { countryOptions } from 'utils/constants/country'

export const OrganizationCreate: FC = () => {
  const vm = useOrganizationCreate()
  const { closeModal, form, isLoading, t } = vm
  const { errors, handleSubmit, register, control, typeOptions, parentOptions } = form

  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
            <Shared.InputField
              name="name"
              title={t('name')}
              register={register(Form.required)}
              error={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="taxNumber"
              title={t('taxNumber')}
              register={register(Form.combine(Form.required, Form.maxLength(20)))}
              error={errors.taxNumber?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.Dropdown
              name="typeId"
              label={t('type')}
              options={typeOptions}
              marginTop={0}
              fullWidth
              control={control}
              rules={Form.required}
              error={errors.typeId?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.Dropdown
              name="parentId"
              label={t('parentOrganizations')}
              options={parentOptions}
              marginTop={0}
              fullWidth
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="discount"
              title={t('discount')}
              register={register(Form.max(100))}
              error={errors.discount?.message}
              type="number"
            />
          </Grid>

          {/* <Grid item xs={12}>
            <Shared.Dropdown
              name="regionId"
              label={t('region')}
              options={typeOptions}
              marginTop={0}
              fullWidth
              control={control}
              rules={Form.required}
              error={errors.typeId?.message}
            />
          </Grid> */}

          <Shared.CardTitle title={t('address')} marginTop={16} />
          <Grid item xs={12}>
            <Shared.InputField
              name="city"
              title={t('city')}
              register={register(Form.required)}
              error={errors.city?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="street"
              title={t('street')}
              register={register(Form.required)}
              error={errors.street?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="house"
              title={t('house')}
              register={register(Form.required)}
              error={errors.house?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.InputField
              name="postCode"
              title={t('postCode')}
              register={register(Form.required)}
              error={errors.postCode?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Shared.Dropdown
              name="country"
              label={t('country')}
              options={countryOptions}
              marginTop={0}
              fullWidth
              control={control}
              rules={Form.required}
              error={errors.country?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '3.75em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
            />
            <Shared.Button title={t('submit')} type={'submit'} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
