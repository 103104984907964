import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Actions_Auth } from 'redux/modules/auth/Actions'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { AuthStorage } from 'utils/helpers/auth'
import { I18nModal } from 'utils/i18n/i18nModal'

export const useExitConfirm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(I18nModal.exitConfirm)

  const onSubmit = () => {
    AuthStorage.signOut()
    dispatch(Actions_Auth.store.logout())
    dispatch(Actions_Modals.store.hideModal())
  }

  const onCancel = () => dispatch(Actions_Modals.store.hideModal())
  return { t, onSubmit, onCancel }
}
