import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_CurrentTrailerBreakdowns } from 'redux/modules/currentTrailerBreakdowns/Actions'
import { TCurrentTrailerBreakdown } from 'redux/modules/currentTrailerBreakdowns/TReducer'
import type { PayloadAction } from '@reduxjs/toolkit'

const { get } = RequestMethods()

export interface ISagaWorker_GetCurrentTrailerBreakdowns {
  id: number
}

export default function* SagaWorker_GetCurrentTrailerBreakdowns(
  action: PayloadAction<ISagaWorker_GetCurrentTrailerBreakdowns>,
) {
  try {
    yield put(Actions_CurrentTrailerBreakdowns.store.showLoader())
    const { id } = action.payload

    //------------------------- Getting and saving organizations ----------------------//

    const breakdowns: TStoreTemplate<TCurrentTrailerBreakdown[]> = yield call(
      async () =>
        await get<TCurrentTrailerBreakdown[]>(`/trailers/${id}/issueReports`, {
          hideResolved: false,
          hideRejected: false,
          hideConfirmed: false,
        }),
    )

    yield put(Actions_CurrentTrailerBreakdowns.store.saveBreakdowns(breakdowns))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentTrailerBreakdowns.store.hideLoader())
  }
}
