import { createReducer } from '@reduxjs/toolkit'
import { IStateUserNotes } from './TReducer'
import { Actions_UserNotes } from './Actions'

const initialState: IStateUserNotes = {
  isLoading: false,
  data: null,
  responseInfo: null,
}

const ReducerUserNotes = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions_UserNotes.store.showLoader, (state) => {
      state.isLoading = true
    })
    .addCase(Actions_UserNotes.store.hideLoader, (state) => {
      state.isLoading = false
    })
    .addCase(Actions_UserNotes.store.saveUserNotes, (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    })
    .addCase(Actions_UserNotes.store.reset, () => {
      return initialState
    })
})

export default ReducerUserNotes
