import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_CurrentStaffMember } from 'redux/modules/currentStaffMember/Actions'
import { TUser } from 'redux/modules/users/TReducer'
import { SagaWorker_GetStaff } from 'redux/sagas'

const { post } = RequestMethods()

export interface ISagaWorker_CreateStaff {
  roleId: number
  email: string
  firstName: string
  organizations: number[]
  parentOrganizations: number[]
  regions: number[]
}

export default function* SagaWorker_CreateStaff(action: PayloadAction<ISagaWorker_CreateStaff>) {
  yield put(Actions_CurrentStaffMember.store.showLoader())

  try {
    yield call(async () => await post<TUser>(`/users`, action.payload))

    yield call(SagaWorker_GetStaff)

    yield put(Actions_Modals.store.hideModal())
    yield put(
      Actions_Notifications.store.setNotification({ message: 'Staff member successfully created', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentStaffMember.store.hideLoader())
  }
}
