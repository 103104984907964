import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_AccessRules } from 'redux/modules/accessRules/Actions'
import { TAccessRuleList } from 'redux/modules/accessRules/TReducer'

const { get } = RequestMethods()

export default function* SagaWorker_AccessRules() {
  yield put(Actions_AccessRules.store.showLoader())

  try {
    const response: TStoreTemplate<TAccessRuleList> = yield call(
      async () => await get<TAccessRuleList>(`/users/access/rules`),
    )
    yield put(Actions_AccessRules.store.saveAccessRules(response))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_AccessRules.store.hideLoader())
  }
}
