import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Notifications } from 'redux/modules/notification/Actions'

const { remove } = RequestMethods()

export interface ISagaWorker_DeleteTrailer {
  id: number
  navigateToTrailersMap: () => void
}

export default function* SagaWorker_DeleteTrailer(action: PayloadAction<ISagaWorker_DeleteTrailer>) {
  const { id, navigateToTrailersMap } = action.payload
  yield put(Actions_CurrentTrailer.store.showLoader())

  try {
    //------------------------- Remove trailer ---------------------------//
    yield call(async () => await remove(`/trailers/${id}`))

    yield put(Actions_CurrentTrailer.store.clearSelectedTrailerInfo())

    navigateToTrailersMap()
    yield put(Actions_Notifications.store.setNotification({ message: 'Trailer deleted successfully', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentTrailer.store.hideLoader())
  }
}
