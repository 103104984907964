import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import { Shared } from 'ui/shared'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DateConverter from 'utils/helpers/date'
import { CalendarHeader } from './CalendarHeader/CalendarHeader'
import { useRangeCalendarModal } from './RangeCalendarModal-ViewModel'

export type TRangeCalendarProps = {
  initialStartDate?: Date | null | undefined
  initialEndDate?: Date | null | undefined
  onDatesChange: (startDate?: Date | null, endDate?: Date | null) => void
  onClose: () => void
}

export const RangeCalendarModal: FC<TRangeCalendarProps> = (props) => {
  const vm = useRangeCalendarModal(props)
  const { t, classes, cx, closeModal, onSubmit, startDate, endDate, onChange } = vm

  return (
    <Shared.Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            className={classes.datePicker}
            calendarClassName={classes.calendar}
            dayClassName={(date) => {
              return cx(classes.day, {
                [classes.edgeSelectedDays]:
                  DateConverter.isSameDay(date, startDate) || DateConverter.isSameDay(date, endDate),
              })
            }}
            renderCustomHeader={CalendarHeader}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Shared.Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Shared.Button color={'secondary'} title={t('cancel')} onClick={closeModal} />
                </Grid>
                <Grid item xs={6}>
                  <Shared.Button title={t('submit')} onClick={onSubmit} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Shared.Card>
  )
}
