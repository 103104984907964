import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TStoreTemplate } from 'redux/config/root-types'

const { get } = RequestMethods()

export default function* SagaPage_TrailerTypes() {
  try {
    yield put(Actions_PageLoader.store.showLoader())
    const result: TStoreTemplate<TTrailerTypes> = yield call(async () => await get<TTrailerTypes>('/trailer_types'))
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(result))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
