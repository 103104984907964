import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TStoreTemplate } from 'redux/config/root-types'
import { TTrailersCounters } from 'redux/modules/trailersCounters/TReducer'
import { Actions_TrailersCounters } from 'redux/modules/trailersCounters/Actions'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'
import { Actions_TrailerStatuses } from 'redux/modules/trailerStatuses/Actions'

const { get } = RequestMethods()

export default function* SagaPage_Report() {
  yield put(Actions_PageLoader.store.showLoader())
  let status: number[] = []

  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting and saving trailer statuses -------------------//

    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await get<TTrailerStatuses>('/trailers/statuses'),
    )
    yield put(Actions_TrailerStatuses.store.saveTrailerStatuses(t_statuses))

    if (t_statuses.data?.statuses) {
      status = t_statuses.data.statuses.map((t_statuse) => t_statuse.id)
      yield put(Actions_TrailerStatuses.store.changeSelectedStatusesIds(status))
    }

    //------------------------- Getting and saving trailer types ----------------------//
    const result: TStoreTemplate<TTrailerTypes> = yield call(async () => await get<TTrailerTypes>('/trailer_types'))
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(result))

    //------------------------- Getting and saving trailer counters ----------------------//

    const t_counters: TStoreTemplate<TTrailersCounters> = yield call(
      async () => await get<TTrailersCounters>('/trailers/counters', { status }),
    )
    yield put(Actions_TrailersCounters.store.saveTrailersCounters(t_counters))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_PageLoader.store.hideLoader())
}
