import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useEffect, useMemo } from 'react'
import { IOrganization } from 'redux/modules/organizations/TReducer'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { Actions_Organizations } from 'redux/modules/organizations/Actions'

export const useOrganization = (loadOptions?: boolean) => {
  const organizations = useSelector(({ organizations }: TState) => organizations.data?.organizations || [])
  const dispatch = useDispatch()

  useEffect(
    function reloadOptions() {
      if (loadOptions) {
        dispatch(Actions_Organizations.saga.getOrganizations())
      }
    },
    [loadOptions, dispatch],
  )

  const options = useMemo(() => toDropdownOptions(organizations), [organizations])

  return {
    options,
  }
}

const toDropdownOptions = (organizations: IOrganization[]): TDropdownOption[] =>
  organizations.map(({ name, id }) => ({ id, value: id, label: name }))
