import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_Parkings } from './TActions'
import {
  IParking,
  TChangeIsCreateParkingPayload,
  TChangeOccupancyFilterPayload,
  TDeleteParkingPayload,
  TSaveParkingPayload,
} from './TReducer'
import { ISagaWorker_LoadParkings } from 'redux/sagas/events/parking/SagaWorker_LoadMoreParkings'
import { ISagaWorker_DeleteParking } from 'redux/sagas/events/parking/SagaWorker_DeleteParking'
import { ISagaWorker_CreateStation } from 'redux/sagas/events/station/SagaWorker_CreateStation'
import { TTableFilterRedux } from 'utils/helpers/table'
import { ISagaWorker_ResetParking } from 'redux/sagas/events/parking/SagaWorker_ResetParking'

const { SagaEvents, StoreActions } = ActionTypes_Parkings

export const Actions_Parkings = {
  saga: {
    getParkings: createAction<ISagaWorker_LoadParkings>(SagaEvents.GET_PARKINGS),
    getAllParkings: createAction(SagaEvents.GET_ALL_PARKINGS),
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    deleteParking: createAction<ISagaWorker_DeleteParking>(SagaEvents.DELETE_PARKING),
    createStationAndGetParkings: createAction<ISagaWorker_CreateStation>(SagaEvents.CREATE_STATION_AND_GET_PARKINGS),
    resetParking: createAction<ISagaWorker_ResetParking>(SagaEvents.RESET_PARKING),
  },
  store: {
    changeOccupancyFilter: createAction<TChangeOccupancyFilterPayload>(StoreActions.CHANGE_OCCUPANCY_FILTER),
    changeFilter: createAction<any>(StoreActions.CHANGE_FILTER),
    changeStartPage: createAction<number>(StoreActions.CHANGE_START_PAGE),
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveParkings: createAction<TSaveParkingPayload>(StoreActions.SAVE_PARKINGS),
    saveAllParkings: createAction<IParking[]>(StoreActions.SAVE_ALL_PARKINGS),
    changeIsCreateParking: createAction<TChangeIsCreateParkingPayload>(StoreActions.CHANGE_IS_CREATE_PARKING),
    increaseFilterOffset: createAction<TTableFilterRedux>(StoreActions.INCREASE_FILTER_OFFSET),
    deleteParking: createAction<TDeleteParkingPayload>(StoreActions.REMOVE_PARKING),
    deleteParkingFromAllParkings: createAction<TDeleteParkingPayload>(StoreActions.REMOVE_PARKING_FROM_ALL_PARKINGS),
    clearParkings: createAction(StoreActions.CLEAR_PARKINGS),
  },
}
