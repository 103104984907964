import React, { FC } from 'react'
import { useMatch, Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'
import { makeStyles } from 'tss-react/mui'

interface IProps {
  route: string
  title: string
  icon: FC
  open: boolean
}

const MenuListItem: FC<IProps> = ({ route, title, icon: Icon, open }) => {
  const { classes } = useStyles()

  const match = useMatch({ path: route, end: false })

  return (
    <Link key={title} className={classes.root} to={route}>
      <ListItemButton selected={!!match} title={title} sx={listItemButtonStyle}>
        <div className={classes.menuItemIcon}>
          <Icon />
        </div>
        {open && (
          <Typography color="secondary" variant="subtitle1">
            {title}
          </Typography>
        )}
      </ListItemButton>
    </Link>
  )
}

const listItemButtonStyle = {
  '&.Mui-selected': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
}

const useStyles = makeStyles()((theme) => ({
  root: { textDecoration: 'none', display: 'flex', alignItems: 'center' },
  menuItemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    height: 35,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 11,
    marginRight: 24,
    [theme.breakpoints.only('xl')]: {
      width: 40,
      height: 40,
    },
  },
}))
export default MenuListItem
