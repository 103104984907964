import React, { FC } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ITrailerTypeInfo } from 'redux/modules/trailerTypes/TReducer'
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'

interface IProps {
  type: ITrailerTypeInfo
  isSelected?: boolean
}

const TrailerTypeItem: FC<IProps> = ({ type, isSelected }) => {
  const { classes } = useStyles({ isSelected })
  const cdnUrl = useSelector((state: TState) => state.settings.data?.cdnUrl || '')

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <img className={classes.icon} src={cdnUrl + type.image} alt={' '} />

      <Typography variant="body1" noWrap>
        {type.name}
      </Typography>
    </Box>
  )
}

export default TrailerTypeItem

interface IStyle {
  isSelected?: boolean
}

const useStyles = makeStyles<IStyle>()(({ breakpoints, palette: { primary, secondary } }, { isSelected }) => ({
  root: {
    marginRight: '0.5em',
    padding: '0.3em',
    border: `1px solid ${primary.main}`,
    borderRadius: 10,
    minWidth: 170,
    transition: '0.3s',
    backgroundColor: isSelected ? primary.main : secondary.main,
    [breakpoints.down('md')]: {
      minWidth: 150,
    },
  },
  icon: {
    marginRight: 16,
    height: 40,
    width: 40,
  },
}))
