import React, { useMemo } from 'react'
import { ListChildComponentProps } from 'react-window'
import { Shared } from '..'
import { IColumn, TTableRow } from '../Table/Table'

type IProps<T> = {
  columns: IColumn[]
} & ListChildComponentProps<T>

export const TableRow = <T extends TTableRow>({ index, style, data, columns }: IProps<T[]>) => {
  const rowData = data[index]
  const columnCellsData = useMemo(() => toColumnCellsData(columns, rowData), [columns, rowData])

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        flexGrow: 1,
      }}
    >
      {columnCellsData.map(({ cellKey, cellData, column }) => (
        <Shared.TableCell key={`row-${index}-${cellKey}`} cellData={cellData} column={column} />
      ))}
    </div>
  )
}

const toColumnCellsData = <T extends TTableRow>(columns: IColumn[], rowData: T) =>
  columns.map((column) => {
    return {
      cellKey: column.dataKey,
      cellData: rowData[column.dataKey],
      column,
    }
  })
