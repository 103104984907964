import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TStoreTemplate } from 'redux/config/root-types'
import { TInsurance } from 'redux/modules/rents/TReducer'
import { Actions_Rents } from 'redux/modules/rents/Actions'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { get } = RequestMethods()

export default function* SagaWorker_GetInsurance({ payload }: PayloadAction<number>) {
  yield put(Actions_Rents.store.showLoader())

  const formData = {
    parkingId: payload ? payload : undefined,
  }

  try {
    //------------------------- Getting rents ---------------------------//
    const insurances: TStoreTemplate<TInsurance[]> = yield call(
      async () => await get<TInsurance[]>(`/rents/insurance`, formData),
    )

    yield put(Actions_Rents.store.saveInsurances(insurances.data))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_Rents.store.hideLoader())
}
