import axios from 'axios'

class _AuthStorage {
  get token(): string {
    return localStorage.getItem('token') as string
  }
  get isAuthorized(): boolean {
    return !!this.token
  }

  signIn = (token: string) => {
    localStorage.setItem('token', token)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  }

  signOut = () => {
    localStorage.removeItem('token')
    axios.defaults.headers.common['Authorization'] = undefined
  }
}

export const AuthStorage = new _AuthStorage()
