import { RequestMethods } from 'utils/axios/methods'
import { call, put, select } from 'redux-saga/effects'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { Actions_Staff } from 'redux/modules/staff/Actions'
import { TStaff, TStaffFilter } from 'redux/modules/staff/TReducer'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import TableFilterReduxAdapter from 'utils/helpers/table'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TUser } from 'redux/modules/users/TReducer'

const { get } = RequestMethods()

type TGetStaffFilters = {
  hideCustomers: boolean
  hideDeleted: boolean
} & TStaffFilter

export default function* SagaWorker_GetStaff(action?: PayloadAction<TStaffFilter>) {
  try {
    yield put(Actions_Staff.store.showLoader())
    const getFilter = (state: TState) => state.staff.filter
    const getStaff = (state: TState) => state.staff.data
    const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds

    const filter: ReturnType<typeof getFilter> = yield select(getFilter)
    const oldStaff: ReturnType<typeof getStaff> = yield select(getStaff)
    const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)

    const isNeedToConcatData = !!action?.payload

    const formData: TGetStaffFilters = {
      ...filter,
      organization,
      hideCustomers: true,
      hideDeleted: true,
    }
    //------------------------- Getting and saving staff ----------------------//

    const response: TStoreTemplate<TStaff> = yield call(async () => await get<TStaff>('/users', formData))
    const newStaff = response.data?.users || []
    const responseInfo = response.responseInfo

    const data = isNeedToConcatData ? TableFilterReduxAdapter.concatData<TUser>(newStaff, oldStaff) : newStaff
    const isAllDataLoaded = TableFilterReduxAdapter.checkIsAllDataLoaded(newStaff, filter)

    yield put(Actions_Staff.store.saveStaff({ data, responseInfo, isAllDataLoaded }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_Staff.store.hideLoader())
  }
}
