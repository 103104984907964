import React, { FC, useEffect, useState } from 'react'
import { loadImageAndCreateObject } from 'utils/helpers/loadImageAndCreateObject'

type Props = {
  name: string
}
export const ImageLoad: FC<Props> = ({ name }) => {
  const [img, setImg] = useState<any>()

  const fetchImage = async () => {
    const imgObg = await loadImageAndCreateObject(name)
    URL.revokeObjectURL(img)

    setImg(imgObg)
  }

  useEffect(() => {
    fetchImage()
  }, [name])

  const openInNewTab = () => {
    window.open(img, '_blank')
  }

  return (
    <>
      {img && (
        <img
          src={img}
          style={{ height: 360, width: 360, cursor: 'pointer', objectFit: 'contain' }}
          onClick={openInNewTab}
        />
      )}
    </>
  )
}
