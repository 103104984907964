import { useEffect, useState } from 'react'
import { Channel } from 'pusher-js'
import pusher from './config'
import { useAuthRights } from 'ui/hooks/useAuthRights'

export type TPusherChannel = 'MAP_CRM' | 'STATION_CRM' | 'RENT_CRM'

export const useChannel = (channelName: TPusherChannel) => {
  const [channel, setChannel] = useState<Channel | undefined>()
  const { hasFullAccess } = useAuthRights()

  useEffect(() => {
    if (hasFullAccess) {
      const pusherChannel = pusher.subscribe(channelName)
      setChannel(pusherChannel)

      return () => pusher.unsubscribe(channelName)
    }
  }, [channelName])

  return channel
}
