import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { Controls } from 'assets/images/Controls'
import { Shared } from 'ui/shared'
import { Form } from 'utils/helpers/form'
import { useStationUpdate } from './StationUpdate-ViewModel'

export const StationUpdate: FC = () => {
  const vm = useStationUpdate()
  const { closeModal, onLockClick, onUnlockCLick, onQRCodeCLick, form, isLoading, t } = vm
  const {
    errors,
    handleSubmit,
    register,
    control,
    statusOptions,
    hiddenStatusOptionsIds,
    trailerOptions,
    hiddenTrailerOptionsIds,
    isShowTrailerField,
    formValidation,
  } = form

  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
            <Shared.InputField
              name="stationCode"
              title={t('stationCode')}
              register={register(formValidation.stationCode)}
              error={errors.stationCode?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="serialNumber"
              title={t('serialNumber')}
              register={register(formValidation.serialNumber)}
              error={errors.serialNumber?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="imei"
              title={t('imei')}
              register={register(formValidation.imei)}
              error={errors.imei?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="idHigh"
              title={t('idHigh')}
              register={register(formValidation.idHigh)}
              error={errors.idHigh?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="idMiddle"
              title={t('idMiddle')}
              register={register(formValidation.idMiddle)}
              error={errors.idMiddle?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.InputField
              name="idLow"
              title={t('idLow')}
              register={register(formValidation.idLow)}
              error={errors.idLow?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Dropdown
              name="statusId"
              label={t('statusId')}
              options={statusOptions}
              hiddenOptionsIds={hiddenStatusOptionsIds}
              marginTop={0}
              fullWidth
              control={control}
              rules={Form.required}
              error={errors.statusId?.message}
              placeholder={'booked'}
            />
          </Grid>

          {isShowTrailerField && (
            <Grid item xs={12}>
              <Shared.Dropdown
                name="trailerId"
                label={t('trailerId')}
                options={trailerOptions}
                hiddenOptionsIds={hiddenTrailerOptionsIds}
                marginTop={0}
                fullWidth
                control={control}
                rules={Form.required}
                error={errors.trailerId?.message}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item>
                <Link component="button" variant="body1" onClick={onLockClick}>
                  <Box display="flex" alignItems="center">
                    <Controls.Lock />
                    <Box marginLeft={1}>{t('lock')}</Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item>
                <Link component="button" variant="body1" onClick={onUnlockCLick}>
                  <Box display="flex" alignItems="center">
                    <Controls.Unlock />
                    <Box marginLeft={1}>{t('unlock')}</Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item>
                <Link component="button" variant="body1" onClick={onQRCodeCLick}>
                  <Box display="flex" alignItems="center">
                    <Controls.QR />
                    <Box marginLeft={1}>QR Code</Box>
                  </Box>
                </Link>
              </Grid>
            </Grid>

            <Box marginTop={4}>
              <Shared.Button color={'secondary'} title={t('cancel')} onClick={closeModal} />
            </Box>

            <Box marginTop={2}>
              <Shared.Button title={t('submit')} type={'submit'} />
            </Box>
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
