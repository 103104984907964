enum StoreActions {
  IS_LOADER_SHOW = 'CURRENT_STAFF_MEMBER/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'CURRENT_STAFF_MEMBER/IS_LOADER_HIDE',
  SAVE_CURRENT_STAFF_MEMBER = 'CURRENT_STAFF_MEMBER/SAVE_CURRENT_STAFF_MEMBER',
  CLEAR_CURRENT_STAFF_MEMBER = 'CURRENT_STAFF_MEMBER/CLEAR_CURRENT_STAFF_MEMBER',
}

enum SagaEvents {
  GET_STAFF_DETAILS = 'CURRENT_STAFF_MEMBER/GET_STAFF_DETAILS',
  CREATE_STAFF = 'CURRENT_STAFF_MEMBER/CREATE_STAFF',
  UPDATE_STAFF = 'CURRENT_STAFF_MEMBER/UPDATE_STAFF',
  ACTIVATE_STAFF = 'CURRENT_STAFF_MEMBER/ACTIVATE_STAFF',
  DEACTIVATE_STAFF = 'CURRENT_STAFF_MEMBER/DEACTIVATE_STAFF',
  RESTORE_PASSWORD = 'CURRENT_STAFF_MEMBER/RESTORE_PASSWORD',
  DELETE_STAFF = 'CURRENT_STAFF_MEMBER/DELETE_STAFF',
}

export const ActionTypes_CurrentStaffMember = {
  StoreActions,
  SagaEvents,
}
