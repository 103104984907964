import { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { IParking } from 'redux/modules/parkings/TReducer'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'

interface IForm {
  parkingId: number
}

type TModalProps = {
  organizationId: number
}

export const useAddParkingToOrganization = () => {
  const { t } = useTranslation(I18nModal.addParkingToOrganization)
  const dispatch = useDispatch()

  const { isLoading, allParkings, modalProps } = useSelector(
    ({ parkings, modals }: TState) => ({
      isLoading: parkings.isLoading,
      allParkings: parkings.allParkings,
      modalProps: modals.data as TModalProps,
    }),
    shallowEqual,
  )

  const { handleSubmit, errors, control } = useForm<IForm>()

  const onSubmit = (data: IForm) => {
    const formData = {
      parkingId: data.parkingId,
      organizationId: modalProps.organizationId,
    }

    dispatch(Actions_CurrentOrganization.saga.addParkingToOrganization(formData))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const filteredParkings = useMemo(
    () => toFilteredParkings(allParkings, modalProps.organizationId),
    [allParkings, modalProps.organizationId],
  )

  const parkingOptions = useMemo(() => toDropdownOption(filteredParkings), [filteredParkings])

  return {
    t,
    closeModal,
    isLoading,
    form: {
      control,
      parkingOptions,
      errors,
      handleSubmit: handleSubmit(onSubmit),
    },
  }
}

const toFilteredParkings = (parking: IParking[], currentOrganizationId: number) =>
  parking.filter(({ parkingInfo }) => parkingInfo.organizationId !== currentOrganizationId)

const toDropdownOption = (parking: IParking[]) =>
  parking.map(({ parkingInfo }) => ({
    id: parkingInfo.id,
    label: parkingInfo.parkingCode,
    value: parkingInfo.id,
  }))
