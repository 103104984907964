import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { TOrganizationTypes } from 'redux/modules/organizationTypes/TReducer'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { Actions_CurrentOrganization } from 'redux/modules/currentOrganization/Actions'
import { TParentOrganization } from 'redux/modules/parentOrganizations/TReducer'

interface IForm {
  name: string
  taxNumber: string
  typeId: number | string
  city: string
  street: string
  house: string
  postCode: string
  country: string
  parentId: string
  regionId: string
  discount: string
}

export const useOrganizationCreate = () => {
  const { t } = useTranslation(I18nModal.organizationCreate)
  const dispatch = useDispatch()

  const organizationTypes = useSelector(({ organizationTypes }: TState) => organizationTypes.data || [])
  const parentOrganizations = useSelector(({ parentOrganizations }: TState) => parentOrganizations.data || [])
  const isLoading = useSelector(({ currentOrganization }: TState) => currentOrganization.isLoading)

  const { handleSubmit, register, errors, control } = useForm<IForm>({
    defaultValues: {
      name: '',
      taxNumber: '',
      typeId: '',
      city: '',
      street: '',
      house: '',
      postCode: '',
      country: '',
      parentId: '',
      regionId: '',
      discount: '',
    },
  })

  const onSubmit = (data: IForm) => {
    const dataForServer = {
      ...data,
      discount: Number(data.discount),
      typeId: typeof data.typeId === 'number' ? data.typeId : null,
      parentId: typeof data.parentId === 'number' ? data.parentId : null,
      regionId: typeof data.regionId === 'number' ? data.regionId : null,
    }
    dispatch(Actions_CurrentOrganization.saga.createOrganization(dataForServer))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  const typeOptions = useMemo(() => toTypeDropdownOption(organizationTypes), [organizationTypes])
  const parentOptions = useMemo(() => toParentDropdownOption(parentOrganizations), [parentOrganizations])

  return {
    t,
    closeModal,
    isLoading,
    form: {
      control,
      typeOptions,
      parentOptions,
      errors,
      register,
      handleSubmit: handleSubmit(onSubmit),
    },
  }
}

const toTypeDropdownOption = (organizationTypes: TOrganizationTypes): TDropdownOption[] =>
  organizationTypes.map((organizationType) => ({
    id: organizationType.id,
    label: organizationType.name,
    value: organizationType.id,
  }))

const toParentDropdownOption = (parentOrganizations: TParentOrganization[]): TDropdownOption[] =>
  parentOrganizations.map((parentOrganization) => ({
    id: parentOrganization.id,
    label: parentOrganization.name,
    value: parentOrganization.id,
  }))
