import { call, put, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { TState, TStoreTemplate } from 'redux/config/root-types'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TTrailerStatuses } from 'redux/modules/trailerStatuses/TReducer'
import { TTrailerTypes } from 'redux/modules/trailerTypes/TReducer'
import { Actions_TrailerStatuses } from 'redux/modules/trailerStatuses/Actions'
import { Actions_TrailerTypes } from 'redux/modules/trailerTypes/Actions'
import { TOrganizations } from 'redux/modules/organizations/TReducer'
import { Actions_Organizations } from 'redux/modules/organizations/Actions'

const { get } = RequestMethods()

export default function* SagaPage_TrailersList() {
  yield put(Actions_PageLoader.store.showLoader())

  const getSelectedTrailerStatusesIds = (state: TState) => state.trailerStatuses.selectedStatusIds
  let status: ReturnType<typeof getSelectedTrailerStatusesIds> = yield select(getSelectedTrailerStatusesIds)

  try {
    //------------------------- Getting and saving organizations ----------------------//

    const organizations: TStoreTemplate<TOrganizations> = yield call(
      async () => await get<TOrganizations>('/organizations', { hideInactive: false }),
    )

    yield put(Actions_Organizations.store.saveOrganizations(organizations))
    //------------------------- Getting and saving trailer types ----------------------//

    const t_types: TStoreTemplate<TTrailerTypes> = yield call(async () => await get<TTrailerTypes>('/trailer_types'))
    yield put(Actions_TrailerTypes.store.saveTrailerTypes(t_types))

    //------------------------- Getting and saving trailer statuses -------------------//

    const t_statuses: TStoreTemplate<TTrailerStatuses> = yield call(
      async () => await get<TTrailerStatuses>('/trailers/statuses'),
    )
    yield put(Actions_TrailerStatuses.store.saveTrailerStatuses(t_statuses))
    if (!status.length && t_statuses.data?.statuses) {
      status = t_statuses.data.statuses.map((t_statuse) => t_statuse.id)
      yield put(Actions_TrailerStatuses.store.changeSelectedStatusesIds(status))
    }
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }

  yield put(Actions_PageLoader.store.hideLoader())
}
