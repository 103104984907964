import { TState } from 'redux/config/root-types'
import { call, select } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { AxiosRequestConfig } from 'axios'
import type { PayloadAction } from '@reduxjs/toolkit'

const { download } = RequestMethods()

export interface ISagaWorker_GetTrailersListXlsx {
  fields: string[]
}

export default function* SagaWorker_GetTrailersListXlsx(action: PayloadAction<ISagaWorker_GetTrailersListXlsx>) {
  const {
    payload: { fields },
  } = action
  const getSelectedTrailerTypeIds = (state: TState) => state.trailerTypes.selectedTrailerTypeIds
  const getSelectedTrailerStatusesIds = (state: TState) => state.trailerStatuses.selectedStatusIds
  const getSelectedOrganizationIds = (state: TState) => state.organizations.selectedOrganizationIds

  const types: ReturnType<typeof getSelectedTrailerTypeIds> = yield select(getSelectedTrailerTypeIds)
  const status: ReturnType<typeof getSelectedTrailerStatusesIds> = yield select(getSelectedTrailerStatusesIds)
  const organization: ReturnType<typeof getSelectedOrganizationIds> = yield select(getSelectedOrganizationIds)

  const formData = {
    fields,
    types,
    status,
    organization,
    limit: undefined,
    offset: undefined,
  }

  const config = {
    responseType: 'blob',
  } as AxiosRequestConfig

  try {
    //------------------------- Getting report ---------------------------//
    yield call(async () => download('/trailers/list/report/xlsx', formData, config, ' trailers.xlsx'))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  }
}
