import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { TDropdownOption } from 'ui/shared/Dropdown/Select'
import { IParking } from 'redux/modules/parkings/TReducer'
import { Actions_Parkings } from 'redux/modules/parkings/Actions'

interface IProps {
  isSelected?: (id: number) => boolean
  watch?: (name?: string, defaultValue?: number[]) => number[]
  name: string
  loadOptions: boolean
  filterOptions?: (item: TDropdownOption) => boolean
}

export const useMultiParking = ({ isSelected = () => false, watch, loadOptions, name, filterOptions }: IProps) => {
  const parkingsData = useSelector(({ parkings }: TState) => parkings.allParkings)
  const parkings = useMemo(() => parkingsData || [], [parkingsData])
  const dispatch = useDispatch()

  useEffect(
    function reloadOptions() {
      if (loadOptions) {
        dispatch(Actions_Parkings.saga.getAllParkings())
      }
    },
    [loadOptions, dispatch],
  )

  const options = useMemo(() => {
    const result = toDropdownOptions(parkings)
    return filterOptions ? result.filter(filterOptions) : result
  }, [parkings, filterOptions])

  let isSelectedParking = isSelected

  if (watch) {
    const selectedParkingsIds = watch(name) || []

    isSelectedParking = (id: number) => (selectedParkingsIds as number[]).includes(id)
  }

  const selectedIds: number[] = useMemo(
    () => options.map(({ id }) => id).filter(isSelectedParking),
    [isSelectedParking, options],
  )

  return {
    options,
    isSelected: isSelectedParking,
    value: selectedIds,
  }
}

const toDropdownOptions = (parkings: IParking[]): TDropdownOption[] =>
  parkings.map(({ parkingInfo }) => ({ id: parkingInfo.id, value: parkingInfo.id, label: parkingInfo.parkingCode }))
