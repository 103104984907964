import { ValidationRules } from 'react-hook-form'
import DateConverter from './date'

const passwordError = 'Invalid password'
const confirmPasswordError = "Passwords don't match"
const emailError = 'Invalid email'
const minError = 'Min length should be'
const maxError = 'Max length should be'
const maxLengthError = 'Max Length should be'
const minLengthError = 'Min Length should be'
const requiredError = 'This field is required'
class _Form {
  email: ValidationRules = {
    required: emailError,
    maxLength: { value: 80, message: emailError },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: emailError },
  }
  password: ValidationRules = {
    required: passwordError,
    minLength: { value: 6, message: passwordError },
    maxLength: { value: 20, message: passwordError },
  }
  confirmPassword = (password: string): ValidationRules => ({
    required: requiredError,
    validate: (value) => value === password || confirmPasswordError,
  })
  timeRange = (dependentValue: string): ValidationRules => ({
    validate: (value) => !!value || !dependentValue || requiredError,
  })
  isSameOrAfter = (): ValidationRules => ({
    validate: (value?: Date | null) => DateConverter.isSameOrBefore(new Date(), value) || requiredError,
  })

  multiDropdown: ValidationRules = {
    validate: (value) => !!value.length || requiredError,
  }

  required: ValidationRules = {
    required: requiredError,
  }

  minLength = (value: number): ValidationRules => ({ minLength: { value, message: `${minLengthError} ${value}` } })

  maxLength = (value: number): ValidationRules => ({ maxLength: { value, message: `${maxLengthError} ${value}` } })

  min = (value: number): ValidationRules => ({ min: { value, message: `${minError} ${value}` } })

  max = (value: number): ValidationRules => ({ max: { value, message: `${maxError} ${value}` } })

  combine = (...objects: ValidationRules[]) => {
    return objects.reduce((acc, curr) => ({ ...acc, ...curr }), {})
  }
}

export const Form = new _Form()
