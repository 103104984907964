import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_CurrentTrailer } from 'redux/modules/currentTrailer/Actions'
import { TCurrentTrailer } from 'redux/modules/currentTrailer/TReducer'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'

const { post } = RequestMethods()

export interface ISagaWorker_CreateTrailer {
  vin: string
  plate: string
  imei: string
  trailerTypeId: number
  trailerSubtypeId: number
  organization: number[]
  sim: string
  identifyNumber: string
  idHigh: string
  idLow: string
}

export default function* SagaWorker_CreateTrailer(action: PayloadAction<ISagaWorker_CreateTrailer>) {
  //------------------------- Getting token and authorize ---------------------------//

  yield put(Actions_CurrentTrailer.store.showLoader())

  try {
    //------------------------- Getting and saving trailer ---------------------------//
    yield call(async () => await post<TCurrentTrailer>(`/trailers`, action.payload))

    yield put(Actions_Modals.store.hideModal())
    yield put(Actions_Notifications.store.setNotification({ message: 'Trailer successfully created', type: 'Success' }))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_CurrentTrailer.store.hideLoader())
  }
}
