import React, { FC } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import './Preloader.scss'
import { makeStyles } from 'tss-react/mui'
import { TColor, TObject } from 'utils/types'

interface IProps {
  color?: TColor
  style?: TObject
  mask?: boolean
}

export const Preloader: FC<IProps> = ({ color = 'primary', style = {}, mask }) => {
  const { classes, cx } = useStyles({ color })
  return (
    <div
      style={style}
      className={cx('preloader', {
        [classes.root]: mask,
      })}
    >
      <CircularProgress className={classes.circularProgress} />
    </div>
  )
}

interface IStyle {
  color: TColor
}

const useStyles = makeStyles<IStyle>()((theme, { color }) => ({
  circularProgress: {
    margin: 'auto',
    color: theme.palette[color].main,
  },
  root: {
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))
